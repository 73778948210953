import { MessageMaskType } from '@ncss/message';
import {
  DeviceTypeHelper,
  DeviceTypeIds,
  Gateway,
  IWirelessNetworkInterface,
  DeviceTypes,
  DeviceFamily,
  GatewaySystemFlags,
  GW3ReadDTO,
  GW3Read,
  GatewaySystemFlagsV0,
} from '@ncss/models';

import { ByteList } from 'byte-list';
import * as _ from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';

import { DirectConnectBaseDevice, DirectConnectDeviceStatus, IDirectConnectDevice } from '../baseDirectConnectDevice';
import { GatewayInfoMessage, GatewayInfoMessageTypes, IDeviceCheckInInfo, INetworkInterface } from './messages/gatewayInfoMessage';
import { GatewayLogMessage, GatewayLogMessageTypes } from './messages/gatewayLogMessage';
import { GatewayMessage, GatewayMessageTypes } from './messages/gatewayMessage';
import { IWifiAP, NetworkMessage, NetworkMessageTypes } from './messages/networkMessage';

const NCSS_GW301_UUID = '49535343-FE7D-4AE5-8FA9-9FAFD205E455';
const NCSS_GW301_NOTIFY = '49535343-1E4D-4BD9-BA61-23C647249616';
const NCSS_GW301_WRITE = '49535343-1E4D-4BD9-BA61-23C647249616';


type DirectConnectGatewayInfo = Gateway & { remoteHost: string, remotePort: number };

export interface IEthernetInfo extends INetworkInterface {
  isUsingDhcp?: boolean;
}

export interface IWlanInfo extends IWirelessNetworkInterface {
  isUsingDhcp?: boolean;
}

export enum GatewayEventTypes {
  RF_TRAFFIC_3RD_PARTY_DEVICE,
  RF_TRAFFIC_PROGRAMMED_DEVICE,
  RF_TRAFFIC_UNPROGRAMMED_DEVICE,
  SYNC_STARTED,
  SYNC_FINISHED,
}

export class DirectConnectGateway301 extends DirectConnectBaseDevice {

  public static useMetric = false;

  static create(bleDevice: any, manufacturerData: { serialNumber: number, byteList: ByteList }, isLimited = false): IDirectConnectDevice {
    if (manufacturerData) {
      return null; // Device is BLE Connect Device and not GW301
    }

    if ((bleDevice.name && bleDevice.name.indexOf('GW301') >= 0)
      || (bleDevice.advertising && bleDevice.advertising.kCBAdvDataLocalName &&
        bleDevice.advertising.kCBAdvDataLocalName.indexOf('GW301') >= 0)) {

      // Get Serial Number
      let parts = bleDevice.name.split('-');
      let serialNumber: number = null;
      if (parts.length === 2) {
        serialNumber = parseInt(parts[1], 16);
      } else if (bleDevice.advertising && bleDevice.advertising.kCBAdvDataLocalName) {
        parts = bleDevice.advertising.kCBAdvDataLocalName.split('-');
        if (parts.length === 2) {
          serialNumber = parseInt(parts[1], 16);
        }
      }

      if (DeviceTypeIds.GATEWAY_301 !== DeviceTypeHelper.GetIdBySerialNumber(serialNumber)) {
        return null;
      }

      const gateway301 = new DirectConnectGateway301();
      gateway301.addAdvertisingData(serialNumber);

      const device: IDirectConnectDevice = {
        status: DirectConnectDeviceStatus.ADVERTISING,
        serialNumber: serialNumber,
        bleSignalStrength: null,
        bleDevice: bleDevice,
        device: gateway301,
        subscriptions: [],
        timers: [],
      };

      return device;
    }

    return null;
  }

  public uuid = {
    service: NCSS_GW301_UUID,
    write: NCSS_GW301_NOTIFY,
    read: NCSS_GW301_WRITE,
  };
  public syncing$ = new BehaviorSubject<boolean>(false);
  public events$ = new Subject<GatewayEventTypes>();
  public info$ = new BehaviorSubject<DirectConnectGatewayInfo>(null);
  public eth$ = new BehaviorSubject<IEthernetInfo>(null);
  public wlan$ = new BehaviorSubject<IWlanInfo>(null);
  public availableNetworks$ = new BehaviorSubject<IWifiAP[]>([]);
  public transceiverCheckInInfo$ = new BehaviorSubject<IDeviceCheckInInfo>(null);
  public repeaterCheckInInfo$ = new BehaviorSubject<IDeviceCheckInInfo>(null);
  public partyModeEnabled$ = new BehaviorSubject<boolean>(false);

  private connectingToWifi = { resolve: null, reject: null, ssid: null };
  private configuringEth = { resolve: null, reject: null };
  private configuringWlan = { resolve: null, reject: null };
  private configuringRemoteServer = { resolve: null, reject: null };

  constructor() {
    super();
    this.messageService.mask = MessageMaskType.EIGHT_BIT;
    this.messageService.registerMessageGroup({
      mask: GatewayMessageTypes.GROUP_MASK,
      messageClass: GatewayMessage,
      handler: this.gatewayMessageHandler.bind(this),
    });
    this.messageService.registerMessageGroup({
      mask: GatewayInfoMessageTypes.GROUP_MASK,
      messageClass: GatewayInfoMessage,
      handler: this.gatewayInfoMessageHandler.bind(this),
    });
    this.messageService.registerMessageGroup({
      mask: GatewayLogMessageTypes.GROUP_MASK,
      messageClass: GatewayLogMessage,
      handler: this.gatewayLogMessageHandler.bind(this),
    });
    this.messageService.registerMessageGroup({
      mask: NetworkMessageTypes.GROUP_MASK,
      messageClass: NetworkMessage,
      handler: this.networkMessageHandler.bind(this),
    });
  }

  public requestInfo() {
    this.txBytes.next(this.messageService.serialize(new GatewayMessage(GatewayMessageTypes.REQUEST_INFO)));
  }


  public requestDHCPInfo() {
    this.txBytes.next(this.messageService.serialize(new NetworkMessage(NetworkMessageTypes.REQUEST_DHCP_STATE)));
  }

  public scanNetworks() {
    this.txBytes.next(this.messageService.serialize(new NetworkMessage(NetworkMessageTypes.SCAN_NETWORKS)));
  }

  public startTheParty(val: boolean) {
    const msg = new GatewayInfoMessage(val ? GatewayInfoMessageTypes.ENABLE_PARTY_MODE : GatewayInfoMessageTypes.DISABLE_PARTY_MODE);
    this.txBytes.next(this.messageService.serialize(msg));
  }

  public toggleCellular(value) {
    this.txBytes.next(this.messageService.serialize(
      new GatewayInfoMessage(value ? GatewayInfoMessageTypes.SET_CELLULAR_ENABLED : GatewayInfoMessageTypes.SET_CELLULAR_DISABLED)));
  }
  public forgetWifiNetwork() {
    this.txBytes.next(this.messageService.serialize(new NetworkMessage(NetworkMessageTypes.DISCONNECT_WIFI)));
  }
  public connectToWiFi(ssid: string, password: string, securityType: string) {
    return new Promise((resolve, reject) => {
      this.connectingToWifi.resolve = resolve;
      this.connectingToWifi.reject = reject;
      const msg = new NetworkMessage(NetworkMessageTypes.CONNECT_TO_WIFI);
      msg.ssid = ssid;
      msg.password = password;
      msg.securityType = securityType;
      this.connectingToWifi.ssid = ssid;
      this.txBytes.next(this.messageService.serialize(msg));
      setTimeout(() => {
        if (this.connectingToWifi.reject) {
          this.connectingToWifi.reject(new Error('Timeout'));
        }
        this.connectingToWifi.resolve = null;
        this.connectingToWifi.reject = null;
      }, 20000);
    });
  }

  public configureEth(useDhcp, address?, gateway?, netmask?) {
    return new Promise((resolve, reject) => {
      this.configuringEth.resolve = resolve;
      this.configuringEth.reject = reject;
      const msg = new NetworkMessage(NetworkMessageTypes.CONFIGURE_ETH0);
      msg.useDHCP = useDhcp;
      msg.ipAddress = address;
      msg.gateway = gateway;
      msg.netmask = netmask;
      this.txBytes.next(this.messageService.serialize(msg));
      setTimeout(() => {
        if (this.configuringEth.reject) {
          this.configuringEth.reject(new Error('Timeout'));
        }
        this.configuringEth.resolve = null;
        this.configuringEth.reject = null;
      }, 15000);
    });
  }

  public configureWlan(useDhcp, address?, gateway?, netmask?) {
    return new Promise((resolve, reject) => {
      this.configuringWlan.resolve = resolve;
      this.configuringWlan.reject = reject;
      const msg = new NetworkMessage(NetworkMessageTypes.CONFIGURE_WLAN0);
      msg.useDHCP = useDhcp;
      msg.ipAddress = address;
      msg.gateway = gateway;
      msg.netmask = netmask;
      this.txBytes.next(this.messageService.serialize(msg));
      setTimeout(() => {
        if (this.configuringWlan.reject) {
          this.configuringWlan.reject(new Error('Timeout'));
        }
        this.configuringWlan.resolve = null;
        this.configuringWlan.reject = null;
      }, 15000);
    });
  }

  public configureRemoteServer(host, port) {
    return new Promise((resolve, reject) => {
      this.configuringRemoteServer.resolve = resolve;
      this.configuringRemoteServer.reject = reject;
      const msg = new NetworkMessage(NetworkMessageTypes.CONFIGURE_TARGET_SERVER);
      msg.host = host;
      msg.port = port;
      this.txBytes.next(this.messageService.serialize(msg));

      setTimeout(() => {
        reject(new Error('Timeout'));
        this.configuringRemoteServer.reject = null;
        this.configuringRemoteServer.resolve = null;
      }, 15000);
    });
  }

  public forceSync() {
    this.txBytes.next(this.messageService.serialize(new GatewayMessage(GatewayMessageTypes.FORCE_SYNC)));
  }

  private gatewayMessageHandler(msg: GatewayMessage) {
    if (msg.type === GatewayMessageTypes.SYNC_STARTED) {
      this.syncing$.next(true);
      this.events$.next(GatewayEventTypes.SYNC_STARTED);
    } else if (msg.type === GatewayMessageTypes.SYNC_FINISHED) {
      this.syncing$.next(false);
      this.events$.next(GatewayEventTypes.SYNC_FINISHED);
    } else if (msg.type === GatewayMessageTypes.RF_TRAFFIC_3RD_PARTY_DEVICE) {
      this.events$.next(GatewayEventTypes.RF_TRAFFIC_3RD_PARTY_DEVICE);
    } else if (msg.type === GatewayMessageTypes.RF_TRAFFIC_PROGRAMMED_DEVICE) {
      this.events$.next(GatewayEventTypes.RF_TRAFFIC_PROGRAMMED_DEVICE);
    } else if (msg.type === GatewayMessageTypes.RF_TRAFFIC_UNPROGRAMMED_DEVICE) {
      this.events$.next(GatewayEventTypes.RF_TRAFFIC_UNPROGRAMMED_DEVICE);
    }
  }

  private gatewayInfoMessageHandler(msg: GatewayInfoMessage) {
    if (msg.type !== GatewayInfoMessageTypes.INFO && msg.type !== GatewayInfoMessageTypes.INFO_V1) {
      return;
    }
    const gateway: DirectConnectGatewayInfo = this.info$.value || new Gateway() as DirectConnectGatewayInfo;
    gateway.id = msg.serialNumber;
    gateway.remoteHost = msg.remoteHost;
    gateway.remotePort = msg.remotePort;
    gateway.version = {
      software: msg.softwareVersion,
      os: msg.osVersion,
      firmware: msg.mcuVersion,
      coordinator: msg.coordinatorVersion,
    };
    gateway.pin = msg.pin;

    const gatewayRead: GW3ReadDTO = {
      deviceId: msg.serialNumber,
      lastCheckIn: msg.lastSyncedAt,
      flags: msg.flags,
      resetCount: msg.resetCount,
      cloudNetworkStatus: {
        flags: msg.cloudNetworkStatusFlags,
        error: msg.cloudNetworkStatusError,
      } as any,
      cellularNetworkStatus: {
        flags: msg.cellularFlags,
        signalQuality: msg.cellularSignalQuality,
        registeredNetwork: msg.cellularRegisteredNetwork,
        error: msg.cellularError,
      } as any,
      osUpTime: msg.osUpTime,
      appUpTime: msg.appUpTime,
      cpuUsage: msg.cpuUsage as any,
      memoryUsage: msg.memoryUsage,
      lastReadsSyncedUpAt: msg.lastSyncedAt,

    };
    const systemFlags =
      new GatewaySystemFlagsV0(gatewayRead.flags instanceof GatewaySystemFlags ? gatewayRead.flags.value : gatewayRead.flags);
    gateway.deploymentGroup = msg.deploymentGroup;

    gateway.data = new GW3Read(gatewayRead);

    this.info$.next(gateway);
    this.partyModeEnabled$.next(systemFlags ? systemFlags.partyModeEnabled() : false);
    this.eth$.next(_.assign(this.eth$.value || {}, msg.eth));
    this.wlan$.next(_.assign(this.wlan$.value || {}, msg.wlan));

    // merging NCSS and 3rd party end devices into one stat
    const infos = _.filter(msg.deviceCheckInInfo, (i: IDeviceCheckInInfo) => {
      const type = DeviceTypes[i.deviceType];
      return type &&
        (type.family === DeviceFamily.TRANSCEIVER ||
          type.family === DeviceFamily.REMOTE_READER ||
          type.family === DeviceFamily.TRANSMITTER_3RD_PARTY);
    });
    const transceiverInfo: IDeviceCheckInInfo = {
      deviceType: DeviceTypeIds.TRANSCEIVER,
      checkedInCount: _.reduce(_.map(infos, (i) => i.checkedInCount), (prev, curr) => prev + curr, 0),
      totalCount: _.reduce(_.map(infos, (i) => i.totalCount), (prev, curr) => prev + curr, 0),
    };
    this.transceiverCheckInInfo$.next(transceiverInfo);

    // merging NCSS and 3rd Party repeaters into one stat
    const repeaterInfos = _.filter(msg.deviceCheckInInfo, (i: IDeviceCheckInInfo) => {
      const type = DeviceTypes[i.deviceType];
      return type &&
        (type.family === DeviceFamily.REPEATER_3RD_PARTY ||
          type.family === DeviceFamily.REPEATER);
    });
    const repeaterInfo: IDeviceCheckInInfo = {
      deviceType: DeviceTypeIds.REPEATER,
      checkedInCount: _.reduce(_.map(repeaterInfos, i => i.checkedInCount), (prev, curr) => prev + curr, 0),
      totalCount: _.reduce(_.map(repeaterInfos, i => i.totalCount), (prev, curr) => prev + curr, 0),
    };
    this.repeaterCheckInInfo$.next(repeaterInfo);
  }

  private networkMessageHandler(msg: NetworkMessage) {
    if (msg.type === NetworkMessageTypes.AVAILABLE_NETWORKS) {
      this.availableNetworks$.next(msg.availableNetworks);
    } else if (msg.type === NetworkMessageTypes.DHCP_STATE) {
      const eth = {
        isUsingDhcp: msg.isEthUsingDHCP,
      } as IEthernetInfo;
      if (!eth.isUsingDhcp) {
        eth.address = msg.ethIpAddress;
        eth.netmask = msg.ethNetmask;
        eth.gateway = msg.ethGateway;
      }
      const wlan = {
        isUsingDhcp: msg.isWlanUsingDHCP,
      } as IEthernetInfo;
      if (!wlan.isUsingDhcp) {
        wlan.address = msg.wlanIpAddress;
        wlan.netmask = msg.wlanNetmask;
        wlan.gateway = msg.wlanGateway;
      }
      this.eth$.next(_.assign(this.eth$.value || {}, eth));
      this.wlan$.next(_.assign(this.wlan$.value || {}, wlan));
    } else if (msg.type === NetworkMessageTypes.CONNECTED_TO_WIFI && this.connectingToWifi.resolve !== null) {
      if (this.wlan$.value.connectedSSID === this.connectingToWifi.ssid) {
        this.connectingToWifi.resolve();
        this.connectingToWifi.resolve = null;
        this.connectingToWifi.reject = null;
      } else {
        this.connectingToWifi.reject(new Error(msg.message));
        this.connectingToWifi.resolve = null;
        this.connectingToWifi.reject = null;
      }
    } else if (msg.type === NetworkMessageTypes.ERROR) {
      if (this.connectingToWifi.reject) {
        this.connectingToWifi.reject(new Error(msg.message));
        this.connectingToWifi.resolve = null;
        this.connectingToWifi.reject = null;
      }
      if (this.configuringEth.reject) {
        this.configuringEth.reject(new Error(msg.message));
        this.configuringEth.resolve = null;
        this.configuringEth.reject = null;
      }
      if (this.configuringWlan.reject) {
        this.configuringWlan.reject(new Error(msg.message));
        this.configuringWlan.resolve = null;
        this.configuringWlan.reject = null;
      }
    } else if (msg.type === NetworkMessageTypes.CONFIGURE_ETH0_ACK && this.configuringWlan.resolve) {
      this.configuringEth.resolve();
      this.configuringEth.resolve = null;
      this.configuringEth.reject = null;
    } else if (msg.type === NetworkMessageTypes.CONFIGURE_WLAN0_ACK) {
      this.configuringWlan.resolve();
      this.configuringWlan.resolve = null;
      this.configuringWlan.reject = null;
    } else if (msg.type === NetworkMessageTypes.CONFIGURE_TARGET_SERVER_ACK) {
      if (this.configuringRemoteServer.resolve) {
        this.configuringRemoteServer.resolve();
        this.configuringRemoteServer.resolve = null;
        this.configuringRemoteServer.reject = null;
      }
    }
  }

  private gatewayLogMessageHandler(msg: GatewayLogMessage) {
    // console.log('LOG_MESSAGE', msg);
  }

  private addAdvertisingData(serialNumber: number) {
    this.modelId = DeviceTypeHelper.GetIdBySerialNumber(serialNumber);
    this.hardwareVersion = {
      major: 0,
      minor: 0,
    };
    this.firmwareVersion = {
      major: 0,
      minor: 0,
    };
  }
}
