import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { SerialKeyboardService } from '../../../services/serial-keyboard.service';
import { SerialKeyboardComponent } from './serial-keyboard.component';

@NgModule({
  declarations: [SerialKeyboardComponent],
  imports: [
    IonicModule,
    CommonModule,
  ],
  exports: [SerialKeyboardComponent],
  providers: [SerialKeyboardService],
})
export class SerialKeyboardModule { }
