import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Observable } from 'rxjs';

import { AppSettingsService } from '../../../services/app-settings.service';

@Component({
  selector: 'app-direct-connect-modal-tabs',
  templateUrl: './direct-connect-modal-tabs.component.html',
  styleUrls: ['./direct-connect-modal-tabs.component.scss'],
})
export class DirectConnectModalTabsComponent implements OnInit {

  @Input() public tabSelected: string;
  @Input() public tabs: {name: string, value: string, icon: string, disabledText?: string, disabled$: Observable<boolean>}[] = [];
  @Output() public onSelect: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    public appSettings: AppSettingsService,
    private alertCtrl: AlertController,
  ) { }

  ngOnInit() {

  }

  public async showDisabledText(text: string) {
    const a = await this.alertCtrl.create({
      header: 'Save Changes',
      message: text,
      buttons: ['Ok'],
    });
    await a.present();
  }
}
