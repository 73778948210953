import { ByteList } from 'byte-list';

import { Ack } from './ack';

export const StatusType = 0x03;

export enum StatusCode {
  SYNC_START = 1,
  SYNC_PROGRESS = 2,
  SYNC_STOPPED = 3,
  SYNC_FAILED = 4,
}

export class Status extends Ack {

  type = StatusType;
  code?: StatusCode;

  constructor(code?: StatusCode) {
    super();
    this.code = code;
  }

  serialize(): ByteList {
    const bytes = super.serialize();
    bytes.writeByte(this.code);
    return bytes;
  }

  deserialize(bytes: ByteList): void {
    super.deserialize(bytes);
    this.code = bytes.readByte();
  }

}
