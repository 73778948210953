import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-fade-container',
  templateUrl: './fade-container.component.html',
  styleUrls: ['./fade-container.component.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({ height: '0', opacity: 0 }),
        animate('250ms ease-out', style({ height: '*', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ height: '*', opacity: 1 }),
        animate('250ms ease-out', style({ height: '0', opacity: 0 })),
      ]),
    ]),
  ],
})
export class FadeContainerComponent implements OnInit {
  @Input() open = false;

  constructor() { }

  ngOnInit() { }
}
