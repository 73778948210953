import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';

import { AppSettingsService } from '../../services/app-settings.service';

@Component({
  selector: 'app-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss'],
})
export class ModalHeaderComponent implements OnInit {

  @Input() public title: string;
  @Input() public titleIcon: string;
  @Input() public titleColor: string;
  @Input() public buttons: {
    id?: string;
    text: string;
    left?: boolean;
    right?: boolean;
    disabled?: boolean;
  }[];
  @Input() public hasChanged = false;
  @Output() public onClick: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    public appSettings: AppSettingsService,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
  ) { }

  async ngOnInit() {
    await this.modalCtrl.getTop();
  }

  public onButtonClick(id: string) {
    if (id === 'Close' && this.hasChanged) {
      this.showWarningAlert(id);
    } else {
      this.onClick.emit(id);
    }
  }

  private async showWarningAlert(id: string) {
    const alert = await this.alertCtrl.create({
      header: 'Confirm Close',
      message: 'You have some unsaved changes.' +
      ' Are you sure you want to close without saving?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Close',
          handler: () => {
            this.onClick.emit(id);
          },
        },
      ],
    });

    alert.present();
  }

}
