
import { PipeLogic } from '@ncss/models';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decimalToHex',
})
export class DecimalToHexPipe implements PipeTransform  {
  public transform(value: any) {
    return PipeLogic.DecimalToHex(value);
  }
}
