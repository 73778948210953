import { ConversionUtils, DeviceTypeHelper } from '@ncss/models';

import { Component } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import * as _ from 'lodash';

import { IDirectConnectDevice } from '../../services/direct-connect/baseDirectConnectDevice';

export enum DirectConnectPopoverEvent {
  Show = 'SHOW_DC_POPOVER', // <--- IN
}

@Component({
  selector: 'app-direct-connect-popover',
  templateUrl: './direct-connect-popover.component.html',
  styleUrls: ['./direct-connect-popover.component.scss'],
})
export class DirectConnectPopoverComponent {

  public directConnectDevice: IDirectConnectDevice;

  constructor(
    private navParams: NavParams,
    private popoverCtrl: PopoverController,
  ) {
    this.directConnectDevice = this.navParams.get('device');

    if (!this.directConnectDevice) {
      this.popoverCtrl.dismiss();
    }
  }

  public dismiss() {
    this.popoverCtrl.dismiss();
  }

  public connect() {
    this.popoverCtrl.dismiss({
      navToDevice: true,
    });
  }

  public getModelName(): string {
    if (!this.directConnectDevice.serialNumber) {
      return 'NextCentury device';
    }

    const dt = DeviceTypeHelper.GetDeviceTypeBySerialNumber(this.directConnectDevice.serialNumber);
    if (dt) {
      return dt.name + ' (' + dt.model + ')';
    } else {
      return '';
    }
  }

  public getImgUrl(): string {
    if (!this.directConnectDevice.serialNumber) {
      return '';
    }

    const model = DeviceTypeHelper.GetModelBySerialNumber(this.directConnectDevice.serialNumber);
    if (!model) { return ''; }
    switch (model) {
      case 'TR4':
        return 'assets/img/equipment/TR401-Flat.svg';
      case 'TR4-X':
        return 'assets/img/equipment/TR401-X-Flat.svg';
      default:
        return 'assets/img/equipment/' + model + '.png';
    }
  }

  public getSerialNumber(): string {
    if (this.directConnectDevice.device && this.directConnectDevice.device._deviceName$) {
      const name: String = this.directConnectDevice.device._deviceName$.value;
      if (_.isString(name) && !_.isEmpty(name)) {
        return name;
      }
    }

    if (!this.directConnectDevice.serialNumber) {
      return '-';
    }

    return ConversionUtils.ConvertSerialNumberToString(this.directConnectDevice.serialNumber);
  }
}
