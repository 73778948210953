import { DeviceFamily, DeviceTypeHelper } from '@ncss/models';

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-hops-away',
  templateUrl: './hops-away.component.html',
  styleUrls: ['./hops-away.component.scss'],
})
export class HopsAwayComponent implements OnInit {

  public DeviceFamily = DeviceFamily;
  @Input() public deviceType = DeviceFamily.REPEATER;
  public get hops() { return this._hops; }
  @Input() public set hops(val) {
    this._hops = val || 0;
    this.hopsChanged();
  }
  @Input() public hasChildren = false;
  public get serialNumber() { return this._serialNumber; }
  @Input() public set serialNumber(val) {
    this._serialNumber = val;
    this.serialNumberChanged();
  }

  public maxHops = 4;
  public hopList;

  public pluralHopMapping:
    { [k: string]: string } = { '=0': 'Unknown', '=1': '1 Hop From Gateway', 'other': '# Hops From Gateway' };

  private _hops = 0;
  private _serialNumber: number;

  constructor() { }

  public ngOnInit() {
    this.hopsChanged();
  }

  private hopsChanged() {
    this.hopList = [];
    for (let i = 1; i < this.hops; ++i) {
      this.hopList.push(i);
    }
  }

  private serialNumberChanged() {
    if (this._serialNumber) {
      this.deviceType = DeviceTypeHelper.GetFamilyBySerialNumber(this._serialNumber);
    }
  }

}
