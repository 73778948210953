import { Property } from '@ncss/models';

import { Component, Input, OnChanges, OnInit, SimpleChanges, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { Subscription, BehaviorSubject } from 'rxjs';

import { AppSettingsService } from '../../services/app-settings.service';
import { BillingCompanyService } from '../../services/billing-company.service';
import { MobileUserService } from './../../services/mobile-user.service';
import { IMenuPage } from './menu-group/menu-group.component';


enum PageKeys {
  DIRECT_CONNECT = 'Direct Connect',
  BARCODE_SCANNER = 'Barcode Scanner',
  TEST_CONNECT = 'Test Connect',
  REPLACE_DEVICE = 'Replace Device',
  DASHBOARD = 'Dashboard',
  PROPERTIES = 'Properties',
  SETTINGS = 'Settings',
  PROPERTY_DETAILS = 'Details',
  PROPERTY_UNITS = 'Units',
  PROPERTY_SETTINGS = 'Settings',
  PROPERTY_NETWORK = 'Network',
  METER_READ = 'Meter Read',
  WARRANTY_LOOKUP = 'Warranty Lookup',
}

const TOOL_PAGES = [
  {
    title: PageKeys.DIRECT_CONNECT,
    url: '/direct-connect',
    icon: 'icon-cast',
  },
  {
    title: PageKeys.BARCODE_SCANNER,
    url: '/barcode-scanner',
    icon: 'icon-barcode',
  },
  {
    title: PageKeys.TEST_CONNECT,
    url: '/test-connect',
    icon: 'icon-radio',
  },
  {
    title: PageKeys.REPLACE_DEVICE,
    url: '/replace-device',
    icon: 'icon-repeat',
  },
  {
    title: PageKeys.WARRANTY_LOOKUP,
    url: '/warranty-lookup',
    icon: 'icon-award',
  },
];
const HOME_PAGES = [
  {
    title: PageKeys.DASHBOARD,
    url: '/billing-home',
    icon: 'icon-globe',
  },
  {
    title: PageKeys.PROPERTIES,
    url: '/property-list',
    icon: 'icon-property',
  },
  {
    title: PageKeys.SETTINGS,
    url: '/settings',
    icon: 'icon-settings',
  },
];

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnChanges, OnDestroy {

  @Input() public viewingProperty = false;
  @Input() public property: Property;

  private lastProperty: Property = null;

  public isHomePagesOpen = true;
  public homePages$ = new BehaviorSubject<IMenuPage[]>(HOME_PAGES);
  public toolPages$ = new BehaviorSubject<IMenuPage[]>(TOOL_PAGES);

  public isPropertyPagesOpen = true;
  public isToolsPagesOpen = true;
  public propertyPages = [
    {
      title: PageKeys.PROPERTY_DETAILS,
      url: '',
      icon: 'icon-property',
    },
    {
      title: PageKeys.PROPERTY_UNITS,
      url: '',
      icon: 'icon-home',
    },
    {
      title: PageKeys.PROPERTY_NETWORK,
      url: '',
      icon: 'icon-radio',
    },
    {
      title: PageKeys.PROPERTY_SETTINGS,
      url: '',
      icon: 'icon-settings',
    },
  ];


  private _subscriptions: Subscription[] = [];
  private _limitAccess = false;

  constructor(
    public billingCompanyService: BillingCompanyService,
    public appSettings: AppSettingsService,
    private mobileUserService: MobileUserService,
  ) { }

  public ngOnInit() {
    this._subscriptions.push(this.mobileUserService.isDirectConnectOnlyUser$.subscribe((isDCUser) => {
      this._limitAccess = isDCUser;
      this.limitAccess(isDCUser);
    }));
  }

  public ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.property) {
      this.onPropertyChange(changes.property.currentValue);
    } else {
      this.toolPages$.next(this._limitAccess ?
        _.filter(TOOL_PAGES, (p) => p.title === PageKeys.DIRECT_CONNECT || p.title === PageKeys.WARRANTY_LOOKUP) : TOOL_PAGES);
    }
  }

  private limitAccess(limit: boolean) {
    this.homePages$.next(limit ? _.filter(HOME_PAGES, (p) => p.title === PageKeys.SETTINGS) : HOME_PAGES);
    this.toolPages$.next(limit ?
      _.filter(TOOL_PAGES, (p) => p.title === PageKeys.DIRECT_CONNECT || p.title === PageKeys.WARRANTY_LOOKUP) : TOOL_PAGES);
  }

  private onPropertyChange(property: Property) {
    if (!property || !property._id) {
      this.lastProperty = null;
      return;
    }

    if (!this.lastProperty && property) {
      this.isHomePagesOpen = false;
    }

    this.lastProperty = property;
    const id = property._id.split('_')[1];
    this.propertyPages[0].url = `/property/${id}/details`;
    this.propertyPages[1].url = `/property/${id}/units`;
    this.propertyPages[2].url = `/property/${id}/network`;
    this.propertyPages[3].url = `/property/${id}/settings`;
  }

}
