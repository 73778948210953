import { EncoderProtocol, MeterConfig } from '@ncss/models';

import { Component, OnInit, Input } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { createAnimation, AlertInput } from '@ionic/core';
import * as _ from 'lodash';

import { EncodedProtocolLabels, DirectConnectRemoteReader } from './../../../services/direct-connect/remoteReader/remoteReader';
import { DirectConnectRR4 } from './../../../services/direct-connect/RR4/RR4';
import { DirectConnectTR4 } from './../../../services/direct-connect/tr4/tr4';

@Component({
  selector: 'app-wiring-guide',
  templateUrl: './wiring-guide.component.html',
  styleUrls: ['./wiring-guide.component.scss'],
})
export class WiringGuideComponent implements OnInit {

  public EncodedProtocolLabels = EncodedProtocolLabels;

  public encodedProtocol: EncoderProtocol = EncoderProtocol.NEPTUNE;
  public imgUrl: string;
  public isEncoded = false;

  @Input() public device: DirectConnectTR4 | DirectConnectRemoteReader | DirectConnectRR4;

  constructor(
    private alertCtrl: AlertController,
  ) { }

  ngOnInit() {
    this.setIsEncoded();
    this.setImgUrl();
  }


  public async protocolTapped() {
    const inputs: AlertInput[] = [
      {
        type: 'radio',
        label: EncodedProtocolLabels[EncoderProtocol.AMCO],
        checked: this.encodedProtocol === EncoderProtocol.AMCO,
        value: EncoderProtocol.AMCO,
      },
      {
        type: 'radio',
        label: EncodedProtocolLabels[EncoderProtocol.SENSUS],
        checked: this.encodedProtocol === EncoderProtocol.SENSUS,
        value: EncoderProtocol.SENSUS,
      },
      {
        type: 'radio',
        label: EncodedProtocolLabels[EncoderProtocol.NEPTUNE],
        checked: this.encodedProtocol === EncoderProtocol.NEPTUNE,
        value: EncoderProtocol.NEPTUNE,
      },
    ];
    if (this.device && (this.device instanceof DirectConnectTR4 || this.device instanceof DirectConnectRR4)) {
      inputs.push({
        type: 'radio',
        label: EncodedProtocolLabels[EncoderProtocol.ECO],
        checked: this.encodedProtocol === EncoderProtocol.ECO,
        value: EncoderProtocol.ECO,
      });
    }
    const a = await this.alertCtrl.create({
      header: 'Encoded Protocol',
      inputs,
      buttons: [
        { text: 'Cancel' },
        {
          text: 'OK', handler: (val) => {
            if (val !== this.encodedProtocol) {
              this.switchProtocol(val);
            }
          },
        },
      ],
    });
    a.present();
  }

  private async switchProtocol(protocol: EncoderProtocol) {
    const el = document.getElementById('animated-container');
    this.encodedProtocol = protocol;
    await this.animateElOut(el);
    this.setImgUrl();
    await this.animateElIn(el);
  }

  private async animateElOut(el): Promise<void> {
    const animateOut = createAnimation()
      .addElement(el)
      .duration(300)
      .fromTo('opacity', 1, 0)
      .fromTo('transform', 'translateX(0px)', 'translateX(-300px)')
      .easing('ease-in');
    await animateOut.play();
  }

  private async animateElIn(el): Promise<void> {
    const animateIn = createAnimation()
      .addElement(el)
      .duration(300)
      .fromTo('opacity', 0, 1)
      .fromTo('transform', 'translateX(300px)', 'translateX(0px)')
      .easing('ease-out');
    await animateIn.play();
  }

  private setIsEncoded() {
    if (this.device instanceof DirectConnectRemoteReader || this.device instanceof DirectConnectRR4) {
      this.isEncoded = (this.device.meter1Info && this.device.meter1Info.configType === MeterConfig.ENCODER_IN) ||
        (this.device.meter2Info && this.device.meter2Info.configType === MeterConfig.ENCODER_IN);
    } else if (this.device instanceof DirectConnectTR4) {
      this.isEncoded = this.device.configType === MeterConfig.ENCODER_IN;
    }
  }

  private setImgUrl(): void {
    if (this.device instanceof DirectConnectRemoteReader || this.device instanceof DirectConnectRR4) {
      this.setRRImgUrl(this.device);
    } else {
      this.setTRImgUrl(this.device);
    }
  }

  private setRRImgUrl(d: DirectConnectRemoteReader | DirectConnectRR4) {
    if (!d) {
      this.imgUrl = `assets/img/equipment/wiring/RR301/Pulse-Pulse.svg`;
      return;
    }
    let meter1 = d.meter1Info && d.meter1Info.configType === MeterConfig.ENCODER_IN ?
      EncodedProtocolLabels[this.encodedProtocol] : 'Pulse';
    let meter2 = meter1;
    if (!d.meter2Info || !d.meter2Info.configType) {
      meter2 = 'None';
    } else {
      meter2 = d.meter2Info.configType === MeterConfig.ENCODER_IN ? EncodedProtocolLabels[this.encodedProtocol] : 'Pulse';
    }

    if (d.meter2Info.configType === MeterConfig.PULSE_OUT) {
      meter2 = 'PulseOut';
    }
    if (meter1 === EncodedProtocolLabels[EncoderProtocol.ECO]) {
      meter1 = EncodedProtocolLabels[EncoderProtocol.SENSUS];
    }
    if (meter2 === EncodedProtocolLabels[EncoderProtocol.ECO]) {
      meter2 = EncodedProtocolLabels[EncoderProtocol.SENSUS];
    }

    this.imgUrl = `assets/img/equipment/wiring/RR301/${meter1}-${meter2}.svg`;
  }

  private setTRImgUrl(d: DirectConnectTR4) {
    if (!d) {
      this.imgUrl = `assets/img/equipment/wiring/TR4/Pulse.svg`;
      return;
    }
    this.imgUrl = `assets/img/equipment/wiring/${d.deviceModel.toUpperCase()}/${d.configType === MeterConfig.ENCODER_IN ? EncodedProtocolLabels[this.encodedProtocol] : 'Pulse'}.svg`;
  }
}
