import {
  DeviceTypeHelper,
  DeviceTypeIds,
  RR4BLEMessageType,
  RR4BLEInfoMessageType,
  ConversionUtils,
  BitwiseHelper,
  RR4,
  UtilityTypeIds,
  UomTypeHelper,
  UomTypes,
  UtilityTypes,
  RRMeter,
  MeterConfig,
  UomTypeIds,
  Utils,
  RR4Info,
  RRMeterInfo,
  METER_2_PULSE_RESET_KEY,
  METER_1_PULSE_RESET_KEY,
  EncoderProtocol,
  Unit,
  UtilityTypesOrderedList,
  Meter,
  Device,
  RR4Read,
  Lorax,
} from '@ncss/models';

import { ByteList } from 'byte-list';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BehaviorSubject, combineLatest, Observable, Subject, timer, merge, of } from 'rxjs';
import {
  map,
  filter,
  debounceTime,
  tap,
  distinctUntilChanged,
  take,
  pairwise,
  takeWhile,
  mapTo,
  timeout,
  catchError,
} from 'rxjs/operators';


import {
  DirectConnectBaseDevice,
  IDirectConnectDevice,
  DirectConnectDeviceStatus,
  NCSS_BLE_WALK_BY_SERVICE_UUID,
  NCSS_BLE_WALK_BY_TX_ID,
  NCSS_BLE_WALK_BY_RX_ID,
} from './../baseDirectConnectDevice';
import { IDirectConnectMeterInfo, MeterConfigLabels, EncodedProtocolLabels, IUserChangeableMeterInfo, IRRUserChanges, IOpenAlert } from './../remoteReader/remoteReader';
import { RR4BLEDeviceMessage } from './messages/RR4BLEDeviceMessage';
import { RR4BLEInfoMessage } from './messages/RR4BLEInfoMessage';

interface IRR4DeviceInfo {
  lcdAlwaysOn?: boolean;
  rapidCheckInEnabled?: boolean;
  showTamperAlerts?: boolean;
  pulseOut?: boolean;
  clearTamper?: boolean;
  firmwareStr?: string;
  hardwareStr?: string;
  batteryLevelStr?: string;
  rfLinkQualityStr?: string;
}

const TIME_BETWEEN_RETRIES = 2000;


export class DirectConnectRR4 extends DirectConnectBaseDevice {

  public static useMetric = false;

  static create(bleDevice: any, manufacturerData: { serialNumber: number, byteList: ByteList }, isLimited = false) {
    if (!manufacturerData || !manufacturerData.serialNumber) {
      return null;
    }

    const deviceType = DeviceTypeHelper.GetDeviceTypeBySerialNumber(manufacturerData.serialNumber);
    // limited access users can't connect to TR devices.
    if (isLimited && deviceType && deviceType.id !== DeviceTypeIds.RR4) {
      return null;
    }
    if (deviceType && (deviceType.id === DeviceTypeIds.RR4 || deviceType.id === DeviceTypeIds.RR4_TR)) {
      const rr4 = new DirectConnectRR4();
      rr4.serialNumber = manufacturerData.serialNumber;
      rr4.serialNumberStr = manufacturerData.serialNumber.toString(16).toUpperCase();
      rr4.deviceModel = deviceType.model;
      rr4.deviceName = deviceType.name;
      rr4.modelId = deviceType.id;
      const deviceTypeOfUnit = DeviceTypeHelper.GetDeviceTypeBySerialNumber(rr4.serialNumber);
      if (deviceTypeOfUnit && deviceTypeOfUnit.id === DeviceTypeIds.RR4_TR) {
        rr4.isTR = true;
      } else {
        rr4.isTR = false;
      }
      if (bleDevice.rssi) {
        rr4.updateSignalStrength(bleDevice.rssi);
      }
      rr4.addAdvertisingData(manufacturerData.byteList);
      const directConnectDevice: IDirectConnectDevice = {
        status: DirectConnectDeviceStatus.ADVERTISING,
        serialNumber: manufacturerData.serialNumber,
        bleSignalStrength: bleDevice.rssi ? DirectConnectBaseDevice.rssiToSignalStrength(bleDevice.rssi) : null,
        bleDevice: bleDevice,
        device: rr4,
        subscriptions: [],
        timers: [],
        keepModalOpenOnDisconnect: true,
      };
      return directConnectDevice;
    } else {
      return null;
    }
  }

  // scan response data
  public uuid = {
    service: NCSS_BLE_WALK_BY_SERVICE_UUID,
    write: NCSS_BLE_WALK_BY_TX_ID,
    read: NCSS_BLE_WALK_BY_RX_ID,
  };
  public serialNumber: number;
  public serialNumberStr: string;
  public deviceName: string;
  public deviceModel: string;
  public lastMsgAt$ = new BehaviorSubject<Date>(null);

  public programmedUnit: Unit;
  public programmedMeters: [Meter, Meter];

  // manufacturing data
  public temperature$ = new BehaviorSubject<number>(0);
  public temperatureStr$ = this.temperature$.pipe(
    debounceTime(50),
    map((temp) => {
      return DirectConnectRR4.useMetric ? `${temp.toFixed(1)} C` : `${ConversionUtils.ConvertCelsiusToFahrenheit(temp).toFixed(1)} F`;
    }),
    distinctUntilChanged(),
  );

  public secondsSinceUserMagnet: number;
  // ble link quality for device tile
  public linkQualityStr: string;

  private flags$ = new BehaviorSubject<number>(0);

  // for device tile icons
  public batteryLevel$: Observable<number> = this.flags$.pipe(debounceTime(50), map((bits) => BitwiseHelper.GetBits(bits, 0, 3)));
  public hasFreezeAlert$: Observable<number> = this.flags$.pipe(map((bits) => BitwiseHelper.GetBits(bits, 3, 1)));
  public hasMeter1Leak$: Observable<number> = this.flags$.pipe(map((bits) => BitwiseHelper.GetBits(bits, 4, 1)));
  public hasMeter2Leak$: Observable<number> = this.flags$.pipe(map((bits) => BitwiseHelper.GetBits(bits, 5, 1)));
  public hasMeter1Regression$: Observable<number> = this.flags$.pipe(map((bits) => BitwiseHelper.GetBits(bits, 6, 1)));
  public hasMeter2Regression$: Observable<number> = this.flags$.pipe(map((bits) => BitwiseHelper.GetBits(bits, 7, 1)));
  public meter1BounceCount$: Observable<number> = this.flags$.pipe(map((bits) => BitwiseHelper.GetBits(bits, 8, 3)));
  public meter2BounceCount$: Observable<number> = this.flags$.pipe(map((bits) => BitwiseHelper.GetBits(bits, 11, 3)));
  public hasLowBattery$: Observable<number> = this.flags$.pipe(map((bits) => BitwiseHelper.GetBits(bits, 14, 1)));
  public hasTamper$: Observable<number> = this.flags$.pipe(map((bits) => BitwiseHelper.GetBits(bits, 15, 1)));
  public alertCount$ = combineLatest([
    this.hasFreezeAlert$,
    this.hasMeter1Leak$,
    this.hasMeter2Leak$,
    this.hasMeter1Regression$,
    this.hasMeter2Regression$,
    this.hasLowBattery$,
    this.hasTamper$,
  ]).pipe(
    debounceTime(50),
    map((counts) => {
      return _.sum(counts);
    }),
    distinctUntilChanged(),
  );
  public openAlerts$: Observable<IOpenAlert[]> = combineLatest([
    this.hasFreezeAlert$,
    this.hasMeter1Leak$,
    this.hasMeter2Leak$,
    this.hasMeter1Regression$,
    this.hasMeter2Regression$,
    this.hasLowBattery$,
    this.hasTamper$,
  ]).pipe(
    debounceTime(50),
    map((alerts) => {
      const openAlerts: IOpenAlert[] = [];
      if (alerts[0]) {
        openAlerts.push({ typeStr: 'Freeze', iconStr: 'icon-freeze' });
      }
      if (alerts[1]) {
        openAlerts.push({ typeStr: 'Leak - Meter 1', iconStr: 'icon-leak-B' });
      }
      if (alerts[2]) {
        openAlerts.push({ typeStr: 'Leak - Meter 2', iconStr: 'icon-leak-B' });
      }
      if (alerts[3]) {
        openAlerts.push({ typeStr: 'Meter Regression - Meter 1', iconStr: 'icon-meter-off' });
      }
      if (alerts[4]) {
        openAlerts.push({ typeStr: 'Meter Regression - Meter 2', iconStr: 'icon-meter-off' });
      }
      if (alerts[5]) {
        openAlerts.push({ typeStr: 'Low Battery', iconStr: 'icon-battery-low' });
      }
      if (alerts[6]) {
        openAlerts.push({ typeStr: 'Tamper', iconStr: 'icon-alert-triangle' });
      }
      return openAlerts;
    }),
  );
  public batteryIcon$: Observable<string> = this.batteryLevel$.pipe(
    distinctUntilChanged(),
    map((val) => {
      switch (val) {
        case 0:
          return 'assets/icon/battery-0.png';
        case 1:
          return 'assets/icon/battery-25.png';
        case 2:
          return 'assets/icon/battery-50.png';
        case 3:
          return 'assets/icon/battery-75.png';
        default:
          return 'assets/icon/battery-100.png';
      }
    }),
  );

  public applyingChanges$ = new BehaviorSubject<boolean>(false);
  public requestingInfo$ = new Subject<boolean>();

  // represents the current configurations of the rr4 (without user changes)
  public rr4$ = new BehaviorSubject<RR4>(null);
  private get rr4() { return this.rr4$.value; }

  // the pending changes that will be applied to the RR4
  public userChanges$ = new BehaviorSubject<IRRUserChanges>({ meter1: {}, meter2: {} });
  private get userChanges() { return this.userChanges$.value; }

  public hasUserChanges$: Observable<boolean> = this.userChanges$.pipe(
    map((changes) => !this.checkChangesAreEmpty(changes)),
    distinctUntilChanged(),
  );

  public overallStatus$: Observable<{ isOk: boolean, label: string, description: string }> = this.rr4$.pipe(
    filter((rr4) => !!rr4),
    map((rr4) => this.getOverallStatus(rr4)),
  );


  // the combination of the current configuration with the user changes (drives the view that the user sees)
  public meter1Info: IDirectConnectMeterInfo; // for component.ts use
  public meter1Info$: Observable<IDirectConnectMeterInfo> = combineLatest([ // for component.html use
    this.rr4$.pipe(filter((rr4) => !!rr4)),
    this.userChanges$,
  ]).pipe(
    map(([rr4, userChanges]) => this.rrMeterToInfo(rr4, rr4.meter1, userChanges.meter1 || {})),
    tap((meter1) => this.meter1Info = meter1),
  );

  public meter2Info: IDirectConnectMeterInfo;
  public meter2Info$: Observable<IDirectConnectMeterInfo> = combineLatest([
    this.rr4$.pipe(filter((rr4) => !!rr4)),
    this.userChanges$,
  ]).pipe(
    map(([rr4, userChanges]) => this.rrMeterToInfo(rr4, rr4.meter2, userChanges.meter2 || {})),
    tap((meter2) => this.meter2Info = meter2),
  );

  public deviceInfo: IRR4DeviceInfo;
  public deviceInfo$: Observable<IRR4DeviceInfo> = combineLatest([
    this.rr4$.pipe(filter((rr4) => !!rr4)),
    this.userChanges$,
  ]).pipe(
    map(([rr4, userChanges]) => this.rrToDeviceInfo(rr4, userChanges)),
    tap((info) => this.deviceInfo = info),
  );

  public gatewayReplied$: Observable<boolean> = this.rr4$.pipe(map((rr4) => rr4 ? rr4.gatewayReplied : false));
  public attemptReconnect: () => Promise<IDirectConnectDevice>;
  public isTR: boolean;


  constructor() {
    super();
    this.messageService.registerMessageGroup({
      mask: RR4BLEMessageType.DEVICE_MSG,
      messageClass: RR4BLEDeviceMessage,
      handler: this.onDeviceMessage.bind(this),
    });

    this.messageService.numberOfTxFlagBytes = 14;
  }

  public addAdvertisingData(bytes: ByteList) {
    const temperature = bytes.readInt8();
    this.temperature$.next(temperature);

    this.secondsSinceUserMagnet = bytes.readByte();

    const rssi = bytes.readByte();
    this.linkQualityStr = this.normalizeRSSI(rssi);

    const flags = bytes.readUInt16();
    this.flags$.next(flags);
  }


  public requestInfo() {
    this.requestingInfo$.next(true);
    const msg = new RR4BLEInfoMessage(RR4BLEInfoMessageType.REQUEST_INFO, this.serialNumber);
    this.txBytes.next(this.messageService.serialize(msg));
  }

  public onDeviceMessage(msg: RR4BLEDeviceMessage) {
    this.rr4$.next(msg.rr4);
    this.flags$.next(this.getFlagValuesFromRR4(msg.rr4));
    this.lastMsgAt$.next(moment().toDate());
    this.applyingChanges$.next(false);
    this.requestingInfo$.next(false);
  }

  public applyChanges(): Promise<boolean> {
    this.applyingChanges$.next(true);
    const msg = new RR4BLEInfoMessage(RR4BLEInfoMessageType.INFO_MSG, this.serialNumber);
    this.populateInfoFromChanges(msg.rr4Info, this.userChanges);
    const sendAttempts: Observable<boolean> = timer(0, TIME_BETWEEN_RETRIES).pipe(mapTo(false), take(3));
    const changesApplied: Observable<boolean> = this.applyingChanges$.pipe(
      pairwise(),
      filter(([prev, curr]) => prev === true && curr === false),
      take(1),
      mapTo(true),
      timeout(TIME_BETWEEN_RETRIES * 4),
      catchError((err) => of(false)),
    );
    merge(changesApplied, sendAttempts).pipe(
      takeWhile((val) => val !== true),
    ).subscribe(() => {
      this.txBytes.next(this.messageService.serialize(msg));
    });
    return changesApplied.toPromise();
  }

  public factorySleep() {
    this.applyingChanges$.next(true);
    const msg = new RR4BLEInfoMessage(RR4BLEInfoMessageType.INFO_MSG, this.serialNumber);
    this.populateInfoFromChanges(msg.rr4Info, this.userChanges);
    msg.rr4Info.enterFactorySleep = true;
    this.txBytes.next(this.messageService.serialize(msg));
  }

  public dropUserChanges() {
    this.userChanges$.next({ meter1: {}, meter2: {} });
  }

  public updateLCDAlwaysOn(value: boolean) {
    const changes = this.userChanges;
    if ((value && this.rr4.lcdAlwaysOn) || (!value && !this.rr4.lcdAlwaysOn)) {
      delete changes.lcdAlwaysOn;
    } else {
      changes.lcdAlwaysOn = value;
    }
    this.userChanges$.next(changes);
  }

  public updateShowTamperAlerts(value: boolean) {
    const changes = this.userChanges;
    if ((value && this.rr4.tamperAlertEnabled) || (!value && !this.rr4.tamperAlertEnabled)) {
      delete changes.showTamperAlerts;
    } else {
      changes.showTamperAlerts = value;
    }
    this.userChanges$.next(changes);
  }

  public updateRapidCheckInEnabled(value: boolean) {
    const changes = this.userChanges;
    if ((value && this.rr4.rapidCheckInEnabled) || (!value && !this.rr4.rapidCheckInEnabled)) {
      delete changes.rapidCheckInEnabled;
    } else {
      changes.rapidCheckInEnabled = value;
    }
    this.userChanges$.next(changes);
  }

  public clearTamper(value: boolean) {
    const changes = this.userChanges;
    if (value) {
      changes.clearTamper = value;
    } else {
      delete changes.clearTamper;
    }
    this.userChanges$.next(changes);
  }

  public updatePulseOut(value: boolean) {
    const changes = this.userChanges;
    if ((value && this.rr4.pulseOut) || (!value && !this.rr4.pulseOut)) {
      delete changes.pulseOut;
    } else {
      changes.pulseOut = value;
    }
    this.userChanges$.next(changes);
  }

  public updateMultiplier(value: number, port: 1 | 2) {
    const { meter, key } = this.getCurrentMeter(port);
    const changes = this.userChanges;
    if (meter.multiplier === value) {
      delete changes[key].multiplier;
    } else {
      changes[key].multiplier = value;
    }
    this.userChanges$.next(changes);
  }

  public updateIMR(value: number, port: 1 | 2) {
    const { meter, key } = this.getCurrentMeter(port);
    const changes = this.userChanges;
    if (meter.imr === value) {
      delete changes[key].imr;
    } else {
      changes[key].imr = value;
    }
    this.userChanges$.next(changes);
  }

  public updateUtilityTypeId(value: UtilityTypeIds, port: 1 | 2) {
    const { meter, key } = this.getCurrentMeter(port);
    const changes = this.userChanges;
    const uoms = UomTypeHelper.GetUomForUtilityTypeId(value);
    if (uoms && uoms[0]) {
      this.updateUomTypeId(uoms[0].id, port);
    }
    if (meter.utilityTypeId === value) {
      delete changes[key].utilityTypeId;
    } else {
      changes[key].utilityTypeId = value;
    }

    this.userChanges$.next(changes);
  }

  public updateUomTypeId(value: UomTypeIds, port: 1 | 2) {
    const { meter, key } = this.getCurrentMeter(port);
    const changes = this.userChanges;
    if (meter.uomTypeId === value) {
      delete changes[key].uomTypeId;
    } else {
      changes[key].uomTypeId = value;
    }
    this.userChanges$.next(changes);
  }

  public updateConfigType(value: MeterConfig, port: 1 | 2) {
    const { meter, key } = this.getCurrentMeter(port);
    const changes = this.userChanges;
    if (meter.configType === value) {
      delete changes[key].configType;
    } else {
      changes[key].configType = value;
    }
    this.userChanges$.next(changes);
  }

  public resetPulseCount(value: boolean, port: 1 | 2) {
    const { key } = this.getCurrentMeter(port);
    const changes = this.userChanges;
    if (!value) {
      delete changes[key].resetPulseCount;
    } else {
      changes[key].resetPulseCount = value;
    }
    this.userChanges$.next(changes);
  }

  public applyConfiguration(changes: IRRUserChanges) {
    if (!changes) {
      changes = { meter1: {}, meter2: {} };
    }
    if (!changes.hasOwnProperty('meter1')) {
      changes.meter1 = {};
    }
    if (!changes.hasOwnProperty('meter2')) {
      changes.meter2 = {};
    }
    this.userChanges$.next(changes);
  }

  public getConfiguration(): IRRUserChanges {
    return {
      meter1: {
        multiplier: this.rr4.meter1.multiplier,
        imr: this.rr4.meter1.imr,
        utilityTypeId: this.rr4.meter1.utilityTypeId,
        uomTypeId: this.rr4.meter1.uomTypeId,
        configType: this.rr4.meter1.configType,
        ...this.userChanges.meter1,
      },
      meter2: {
        multiplier: this.rr4.meter2.multiplier,
        imr: this.rr4.meter2.imr,
        utilityTypeId: this.rr4.meter2.utilityTypeId,
        uomTypeId: this.rr4.meter2.uomTypeId,
        configType: this.rr4.meter2.configType,
        ...this.userChanges.meter2,
      },
      pulseOut: this.userChanges.hasOwnProperty('pulseOut') ? this.userChanges.pulseOut : this.rr4.pulseOut,
      rapidCheckInEnabled: this.userChanges.hasOwnProperty('rapidCheckInEnabled') ?
        this.userChanges.rapidCheckInEnabled : this.rr4.rapidCheckInEnabled,
      lcdAlwaysOn: this.userChanges.hasOwnProperty('lcdAlwaysOn')
        ? this.userChanges.lcdAlwaysOn : this.rr4.lcdAlwaysOn,
    };
  }

  public needsCloudSync(): boolean {
    const hasUserChanges = !this.checkChangesAreEmpty(this.userChanges);
    return this.isTRDevice() && hasUserChanges && !!this.programmedUnit;
  }

  public setProgrammedUnit(unit: Unit) {
    this.programmedUnit = unit;
    const baseSN = ConversionUtils.GetBaseRRSerial(this.serialNumber);
    const meters: [Meter, Meter] = [null, null];
    unit.meters.forEach((m) => {
      if (m.device && (m.device.id === baseSN + 1)) {
        meters[0] = m;
      } else if (m.device && (m.device.id === baseSN + 2)) {
        meters[1] = m;
      }
    });
    this.programmedMeters = meters;
    this.populateChangesFromProgrammedMeters(meters);
  }

  private populateChangesFromProgrammedMeters(meters: [Meter, Meter]): void {
    const [meter1, meter2] = meters;
    if (!meter1 && !meter2) { return; }
    this.updateLCDAlwaysOn(meter1.meterDisplayAlwaysOn);
    this.updatePulseOut(meter1.meterPulseOut || false);
    this.updateConfigType(meter1.meterPortType || MeterConfig.PULSE_IN, 1);
    this.updateMultiplier(meter1.multiplier, 1);
    this.updateUtilityTypeId(meter1.utilityTypeId, 1);
    this.updateIMR(meter1.imr, 1);
    this.updateUomTypeId(meter1.uomTypeId, 1);

    if (!meter2) {
      this.updateConfigType(meter1.meterPulseOut ? MeterConfig.PULSE_OUT : MeterConfig.PORT_DISABLED, 2);
    } else {
      this.updateConfigType(meter2.meterPortType || MeterConfig.PULSE_IN, 2);
      this.updateMultiplier(meter2.multiplier, 2);
      this.updateUtilityTypeId(meter2.utilityTypeId, 2);
      if (meter2.meterPortType !== MeterConfig.ENCODER_IN) {
        this.updateIMR(meter2.imr, 2);
      }
      this.updateUomTypeId(meter2.uomTypeId, 2);
    }
  }

  public getAvailableUtilities(port: 1 | 2) {
    let otherSelected: UtilityTypeIds;
    if (port === 2) {
      otherSelected = this.meter1Info ? this.meter1Info.utilityTypeId : null;
    } else {
      otherSelected = this.meter2Info && this.meter2Info.configType !== MeterConfig.PORT_DISABLED
        && this.meter2Info.configType !== MeterConfig.PULSE_OUT ?
        this.meter2Info.utilityTypeId : null;
    }
    const othersOnUnit = this.programmedUnit ?
      this.getOtherMetersOnUnit(this.programmedUnit).map((m) => m.utilityTypeId) : [];

    return UtilityTypesOrderedList.filter(
      (u) => u.id !== otherSelected && u.id !== UtilityTypeIds.RUN_TIME && !othersOnUnit.includes(u.id));
  }

  private getOtherMetersOnUnit(unit: Unit) {
    return unit.meters.filter((m) => {
      if (m && m.device && ConversionUtils.GetBaseRRSerial(m.device.id) === this.serialNumber) {
        return false;
      } else {
        return true;
      }
    });
  }

  // The serialized msg with RR4Info needs the RRMeterInfo to be fully populated, whether it is changing or not
  private populateMeterInfoFromCurrent(current: RRMeter, toSet: RRMeterInfo): void {
    toSet.configType = current.configType;
    toSet.highVoltMode = current.highVoltMode;
    toSet.utilityTypeId = current.utilityTypeId;
    toSet.uomTypeId = current.uomTypeId;
    toSet.resetPulseCount = false;
    toSet.rawMultiplier = current.getRawMultiplier();
    toSet.imr = current.imr;
  }

  // applies user changes to an RR4Info obj
  // Should happen right before sending a msg
  private populateInfoFromChanges(rr4Info: RR4Info, changes: IRRUserChanges): void {
    if (!Object.keys(changes).length) { return; }
    if (changes.hasOwnProperty('meter1')) {
      if (this.rr4 && this.rr4.meter1) {
        this.populateMeterInfoFromCurrent(this.rr4.meter1, rr4Info.meter1);
      }
      this.populateMeterInfoFromChanges(rr4Info.meter1, changes.meter1);
      if (rr4Info.meter1.resetPulseCount) {
        rr4Info.key ^= METER_1_PULSE_RESET_KEY;
      }
    }
    if (changes.hasOwnProperty('meter2')) {
      if (this.rr4 && this.rr4.meter2) {
        this.populateMeterInfoFromCurrent(this.rr4.meter2, rr4Info.meter2);
      }
      this.populateMeterInfoFromChanges(rr4Info.meter2, changes.meter2);
      if (rr4Info.meter2.resetPulseCount) {
        rr4Info.key ^= METER_2_PULSE_RESET_KEY;
      }
    }
    if (changes.hasOwnProperty('rapidCheckInEnabled')) {
      if (changes.rapidCheckInEnabled) {
        rr4Info.startRapidCheckIn = true;
      } else {
        rr4Info.stopRapidCheckIn = true;
      }
    }
    if (changes.hasOwnProperty('clearTamper')) {
      rr4Info.clearTamperAlert = changes.clearTamper;
    }
    if (changes.hasOwnProperty('pulseOut')) {
      rr4Info.meter1.setInfo = true;
      rr4Info.meter2.setInfo = true;
      rr4Info.pulseOut = changes.pulseOut;
      if (!changes.pulseOut && !changes.meter2.utilityTypeId) {
        rr4Info.meter2.configType = MeterConfig.PORT_DISABLED;
      }
    }
    if (changes.hasOwnProperty('lcdAlwaysOn')) {
      rr4Info.setLCDAlwaysOnState = true;
      rr4Info.lcdAlwaysOn = changes.lcdAlwaysOn;
    }

    if (changes.hasOwnProperty('showTamperAlerts')) {
      rr4Info.setTamperAlertState = true;
      rr4Info.tamperAlertEnableState = changes.showTamperAlerts;
    }
  }

  // applies user changes to an RRMeterInfo obj
  // the RRMeterInfo obj should already have been populated by the current configurations on the device
  // by calling `populateMeterInfoFromCurrent()`
  private populateMeterInfoFromChanges(meter: RRMeterInfo, changes: IUserChangeableMeterInfo) {
    if (!Object.keys(changes).length) { return; }
    meter.setInfo = true;
    if (changes.hasOwnProperty('multiplier')) {
      meter.rawMultiplier = Utils.ComputeRawMultiplier(changes.multiplier) || 1;
    }
    if (changes.hasOwnProperty('imr')) {
      meter.imr = changes.imr || 0;
    }
    if (changes.hasOwnProperty('utilityTypeId')) {
      meter.utilityTypeId = changes.utilityTypeId;
    }
    if (changes.hasOwnProperty('uomTypeId')) {
      meter.uomTypeId = changes.uomTypeId;
    }
    if (changes.hasOwnProperty('configType')) {
      meter.configType = changes.configType;
    }
    if (changes.hasOwnProperty('resetPulseCount')) {
      meter.resetPulseCount = changes.resetPulseCount;
    }
  }

  private getCurrentMeter(port: 1 | 2): { meter: RRMeter, key: string } {
    if (port === 2) {
      return { meter: this.rr4.meter2, key: 'meter2' };
    } else {
      return { meter: this.rr4.meter1, key: 'meter1' };
    }
  }

  private normalizeRSSI(rssi: number): string {
    if (rssi > 168) {
      return 'Excellent';
    } else if (rssi === 0) {
      return '-';
    } else if (rssi < 85) {
      return 'Poor';
    } else {
      return 'Good';
    }
  }

  private normalizeSignalStrength(signalStrength: number) {
    switch (signalStrength) {
      case 4:
        return 'Excellent';
      case 3:
      case 2:
        return 'Good';
      case 1:
        return 'Poor';
      default:
        return '-';
    }
  }

  // combines the latest RRMeter from the device and the latest user changes into what should be shown in the view
  private rrMeterToInfo(device: RR4, meter: RRMeter, userChanges: IUserChangeableMeterInfo): IDirectConnectMeterInfo {
    if (!meter) { return; }
    const utilityTypeId = userChanges.utilityTypeId ?
      userChanges.utilityTypeId : (meter.utilityTypeId || UtilityTypeIds.ALL_WATER);
    const uomType = userChanges.uomTypeId ? UomTypes[userChanges.uomTypeId] :
      (UomTypes[meter.uomTypeId] || UomTypeHelper.GetUomForUtilityTypeId(utilityTypeId)[0]);
    const read = (meter.getCurrentRead() * (meter.multiplier || 1)) + (meter.imr || 0);
    const info: IDirectConnectMeterInfo = {
      resetPulseCount: userChanges.resetPulseCount || false,
      multiplier: userChanges.multiplier ? userChanges.multiplier : (meter.multiplier || 1),
      imr: userChanges.hasOwnProperty('imr') ? userChanges.imr : (meter.imr || 0),
      utilityTypeId,
      utilityTypeName: UtilityTypes[utilityTypeId].name,
      utilityTypeIcon: UtilityTypes[utilityTypeId].iconName,
      utilityTypeColor: this.getUtilityTypeColor(utilityTypeId),
      uomTypeId: uomType.id,
      uomTypeName: uomType.name,
      configType: userChanges.hasOwnProperty('configType') ? userChanges.configType : meter.configType,
      configTypeStr: MeterConfigLabels[userChanges.configType || meter.configType],
      read: read.toLocaleString('en-GB') + ' ' + (uomType ? uomType.name : ''),
      isMeterOk: meter === device.meter1 ? device.meter1Health : device.meter2Health,
      encodedSerialNumber: meter.configType === MeterConfig.ENCODER_IN ? meter.meterSerialNumber : null,
      encodedProtocolStr: meter.encoderProtocol ? EncodedProtocolLabels[meter.encoderProtocol] : null,
    };
    return info;
  }

  private rrToDeviceInfo(device: RR4, userChanges: IRRUserChanges): IRR4DeviceInfo {
    if (!device) { return {}; }
    const read = RR4Read.Create(this.serialNumber, device, {
      firstHopId: null,
      firstHopRssi: null,
      lastHopRssi: null,
      isProgrammed: true,
    })[0];
    const info = {
      lcdAlwaysOn: userChanges.hasOwnProperty('lcdAlwaysOn') ? userChanges.lcdAlwaysOn : device.lcdAlwaysOn || false,
      showTamperAlerts: userChanges.hasOwnProperty('showTamperAlerts') ? userChanges.showTamperAlerts : device.tamperAlertEnabled || false,
      rapidCheckInEnabled: userChanges.hasOwnProperty('rapidCheckInEnabled') ?
        userChanges.rapidCheckInEnabled : device.rapidCheckInEnabled || false,
      pulseOut: userChanges.hasOwnProperty('pulseOut') ? userChanges.pulseOut : device.pulseOut || false,
      clearTamper: userChanges.hasOwnProperty('clearTamper') ? userChanges.clearTamper : false,
      firmwareStr: `v${device.firmwareVersionMajor}.${device.firmwareVersionMinor}`,
      hardwareStr: `v${device.hardwareVersionMajor}.${device.hardwareVersionMinor}`,
      batteryLevelStr: Lorax.FormatBatteryYearsRemaining(read.batteryLevelYearsRemaining),
      rfLinkQualityStr: this.normalizeSignalStrength(device.rfSignal),
    };
    return info;
  }

  private getUtilityTypeColor(utilityType: UtilityTypeIds): string {
    switch (utilityType) {
      case UtilityTypeIds.ALL_WATER:
      case UtilityTypeIds.COLD_WATER:
      case UtilityTypeIds.COMMERCIAL_WATER:
        return '#3e89bf';
      case UtilityTypeIds.ELECTRIC:
        return '#cebf10';
      case UtilityTypeIds.GAS:
        return '#7bc147';
      case UtilityTypeIds.THERMAL_USAGE:
      case UtilityTypeIds.HOT_WATER:
        return '#e3515f';
      case UtilityTypeIds.RUN_TIME:
        return '#a043cf';
      default:
        return '#c4c4c4';
    }
  }

  private checkChangesAreEmpty(changes: IRRUserChanges) {
    if (!changes) { return true; }
    const keys = Object.keys(changes);
    if (keys.length > 2) {
      return false;
    } else if (keys.includes('meter1') && keys.includes('meter2')) {
      const meter1Keys = Object.keys(changes.meter1);
      const meter2Keys = Object.keys(changes.meter2);
      if (!meter1Keys.length && !meter2Keys.length) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }

  }

  private getOverallStatus(rr4: RR4): { isOk: boolean, description: string, label: string } {
    const status = {
      isOk: true,
      label: '',
      description: '',
    };
    if (this.isTRDevice()) {
      status.isOk = rr4.gatewayReplied && this.linkQualityStr !== 'Poor';
      status.label = this.linkQualityStr === 'Poor' ? 'Poor Transceiver Signal' :
        rr4.gatewayReplied ? 'All Good' : 'Not Communicating With Gateway';
      status.description = rr4.gatewayReplied ?
        `This device has a${this.linkQualityStr === 'Excellent' ? 'n' : ''} ${this.linkQualityStr} connection to the Gateway.` :
        `This Remote Reader has not received a response from any nearby Gateways. This could mean that this device is either not programmed to a Unit yet or it is not within range of a Gateway.`;
    }

    if (status.isOk) {
      const meter1 = { type: rr4.meter1.configType, isOk: rr4.meter1Health, protocol: rr4.meter1.encoderProtocol };
      const meter2 = { type: rr4.meter2.configType, isOk: rr4.meter2Health, protocol: rr4.meter2.encoderProtocol };
      const { isOk, label, description } = this.getMeterOverallStatus(meter1, meter2);
      if (isOk) {
        status.description += ' ' + description;
      } else {
        status.isOk = false;
        status.label = label;
        status.description = description;
      }
    }

    return status;
  }

  private getMeterOverallStatus(
    meter1: { type: MeterConfig, isOk: boolean, protocol: EncoderProtocol },
    meter2: { type: MeterConfig, isOk: boolean, protocol: EncoderProtocol }): { isOk: boolean, label: string, description: string } {
    if (meter2.type === MeterConfig.PORT_DISABLED || meter2.type === MeterConfig.PULSE_OUT) {
      if (meter1.isOk) {
        // 1 Meter Configured - Healthy
        return meter1.type === MeterConfig.PULSE_IN
          ? {
            isOk: true,
            label: 'Usage Detected',
            description: 'Pulses have been detected within the last 24 hours.',
          } : {
            isOk: true,
            label: 'Communicating With Meter',
            description: `The Remote Reader can successfully communicate with the connected ${EncodedProtocolLabels[meter1.protocol]} meter.`,
          };
      } else {
        // 1 Meter Configured - NOT Healthy
        return meter1.type === MeterConfig.PULSE_IN
          ? {
            isOk: false,
            label: 'No Usage Detected',
            description: 'No pulses detected within the last 24 hours.',
          } : {
            isOk: false,
            label: 'Check Wiring',
            description: 'The Remote Reader could not communicate with the connected meter. Refer to the "Wiring Guide" here or contact us at support@nextcenturymeters.com for assistance.',
          };
      }
    } else {
      if (meter1.isOk && meter2.isOk) {
        if (meter1.type === MeterConfig.PULSE_IN && meter2.type === MeterConfig.PULSE_IN) {
          // Both Meters - Healthy - PULSE
          return {
            isOk: true,
            label: 'Meters Detected',
            description: 'Pulses have been detected within the last 24 hours on both meters.',
          };
        } else if (meter1.type === MeterConfig.ENCODER_IN && meter2.type === MeterConfig.ENCODER_IN) {
          // Both Meters - Healthy - ENCODED
          const protocols: string[] = [];
          if (EncodedProtocolLabels[meter1.protocol]) { protocols.push(EncodedProtocolLabels[meter1.protocol]); }
          if (EncodedProtocolLabels[meter2.protocol]) { protocols.push(EncodedProtocolLabels[meter2.protocol]); }
          return {
            isOk: true,
            label: 'Communicating With Meters',
            description: 'The Remote Reader can successfully communicate with both connected meters' + protocols.length ? ` (${protocols.join(' and ')})` : '',
          };
        } else {
          // Both Meters - Healthy - PULSE AND ENCODED
          return {
            isOk: true,
            label: 'Meters Detected',
            description: 'The Remote Reader has successfully detected both connected meters.',
          };
        }
      } else if (!meter1.isOk && meter2.isOk) {
        // Meter 1 - NOT Healthy
        return meter1.type === MeterConfig.PULSE_IN
          ? {
            isOk: false,
            label: 'No Usage Detected',
            description: 'No pulses detected within the last 24 hours on Meter 1.',
          } : {
            isOk: false,
            label: 'Check Wiring',
            description: 'The Remote Reader could not communicate with the Meter 1. Refer to the "Wiring Guide" here or contact us at support@nextcenturymeters.com for assistance.',
          };
      } else if (meter1.isOk && !meter2.isOk) {
        // Meter 2 - NOT Healthy
        return meter2.type === MeterConfig.PULSE_IN
          ? {
            isOk: false,
            label: 'No Usage Detected',
            description: 'No pulses detected within the last 24 hours on Meter 2.',
          } : {
            isOk: false,
            label: 'Check Wiring',
            description: 'The Remote Reader could not communicate with Meter 2. Refer to the "Wiring Guide" here or contact us at support@nextcenturymeters.com for assistance.',
          };
      } else {
        if (meter1.type === MeterConfig.PULSE_IN && meter2.type === MeterConfig.PULSE_IN) {
          // Both Meters - NOT Healthy - PULSE
          return {
            isOk: false,
            label: 'Meters Not Detected',
            description: 'No pulses have been detected within the last 24 hours on both meters.',
          };
        } else if (meter1.type === MeterConfig.ENCODER_IN && meter2.type === MeterConfig.ENCODER_IN) {
          // Both Meters - NOT Healthy - ENCODED
          return {
            isOk: true,
            label: 'Check Wiring',
            description: 'The Remote Reader could not communicate with either meter. Refer to the "Wiring Guide" here or contact us at support@nextcenturymeters.com for assistance.',
          };
        } else {
          // Both Meters - NOT Healthy - PULSE AND ENCODED
          return {
            isOk: true,
            label: 'Check Wiring',
            description: 'The Remote Reader has not detected either meters.',
          };
        }
      }
    }
  }

  private getFlagValuesFromRR4(rr4: RR4): number {
    let flags = 0;
    flags = BitwiseHelper.SetBits(flags, 0, 3, rr4.batteryLevel);
    flags = BitwiseHelper.SetBits(flags, 3, 1, rr4.freeze ? 1 : 0);
    flags = BitwiseHelper.SetBits(flags, 4, 1, rr4.meter1Leak ? 1 : 0);
    flags = BitwiseHelper.SetBits(flags, 5, 1, rr4.meter2Leak ? 1 : 0);
    flags = BitwiseHelper.SetBits(flags, 6, 1, rr4.meter1Regression ? 1 : 0);
    flags = BitwiseHelper.SetBits(flags, 7, 1, rr4.meter2Regression ? 1 : 0);
    flags = BitwiseHelper.SetBits(flags, 8, 3, rr4.meter1Bounce);
    flags = BitwiseHelper.SetBits(flags, 11, 3, rr4.meter2Bounce);
    flags = BitwiseHelper.SetBits(flags, 14, 1, rr4.lowBattery ? 1 : 0);
    flags = BitwiseHelper.SetBits(flags, 15, 1, rr4.tamperAlert ? 1 : 0);
    return flags;
  }

  public isTRDevice() {
    if (!this.serialNumber) { return false; }
    const deviceType = DeviceTypeHelper.GetDeviceTypeBySerialNumber(this.serialNumber);
    if (deviceType && deviceType.id === DeviceTypeIds.RR4_TR) {
      return true;
    } else {
      return false;
    }
  }

  public getMetersForCloudSync(): [Meter, Meter] {
    const config: IRRUserChanges = this.getConfiguration();
    const m1 = new Meter(this.programmedMeters ? this.programmedMeters[0] : null);
    m1.imr = config.meter1.imr;
    m1.utilityTypeId = config.meter1.utilityTypeId;
    m1.uomTypeId = config.meter1.uomTypeId;
    m1.multiplier = config.meter1.multiplier;
    m1.meterPortType = config.meter1.configType;
    m1.meterPulseOut = config.pulseOut;
    m1.meterDisplayAlwaysOn = config.lcdAlwaysOn;
    const m1DeviceId = ConversionUtils.GetBaseRRSerial(this.serialNumber) + 1;
    m1.device = m1.device && m1.device.id && m1.device.id === m1DeviceId ? m1.device : new Device({ id: m1DeviceId });
    let m2: Meter = null;
    if (!config.pulseOut &&
      config.meter2 &&
      (config.meter2.configType === MeterConfig.PULSE_IN || config.meter2.configType === MeterConfig.ENCODER_IN)) {
      m2 = new Meter(this.programmedMeters ? this.programmedMeters[1] : null);
      m2.imr = config.meter2.imr;
      m2.utilityTypeId = config.meter2.utilityTypeId;
      m2.uomTypeId = config.meter2.uomTypeId;
      m2.multiplier = config.meter2.multiplier;
      m2.meterPortType = config.meter2.configType;
      m2.meterPulseOut = false;
      m2.meterDisplayAlwaysOn = config.lcdAlwaysOn;
      const m2DeviceId = ConversionUtils.GetBaseRRSerial(this.serialNumber) + 2;
      m2.device = m2.device && m2.device.id && m2.device.id === m2DeviceId ? m2.device : new Device({ id: m2DeviceId });
    }
    return [m1, m2];
  }
}
