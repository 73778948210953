import { Directive } from '@angular/core';
import { AbstractControl, Validator, NG_VALIDATORS } from '@angular/forms';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[validUsername][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: UsernameValidator, multi: true },
  ],
})
export class UsernameValidator implements Validator {
    public static Validate(c: AbstractControl) {
        if (c.value && c.value.length < 3) {
            return {
                usernameShort: true,
            };
        } else if (c.value && c.value.includes('@')) {
            return {
                hasAtSign: true,
            }
        } else {
            return null;
        }
    }
  public validate(c: AbstractControl) {
    return UsernameValidator.Validate(c);
  }
}
