import { ConversionUtils, DeviceTypeHelper, DeviceTypeIds, Lorax, TR4E, TR4EInfo, TR4EInfoMessageType, TR4ERead, TR4ERfMode, Unit } from '@ncss/models';

import { ByteList } from 'byte-list';
import { isEqual, round } from 'lodash';
import { BehaviorSubject } from 'rxjs';

import { DirectConnectDeviceStatus } from '../baseDirectConnectDevice';
import { DirectConnect301 } from '../directConnect301/directConnect301';
import { EndDeviceMessage } from '../directConnect301/messages/endDeviceMessage';
import { InfoMessage } from '../directConnect301/messages/infoMessage';
import { EndDeviceFormState, IUserChanges } from '../remoteReader/remoteReader';

export interface ITR4EUserChanges extends IUserChanges {
  legacyMode?: boolean;
}

export class DirectConnectTR4E {
  public static useMetric = false;

  public static create(bleDevice: any, manufacturingData: { serialNumber: number, bytelist?: ByteList }, isLimited = false) {
    const deviceType = DeviceTypeHelper.GetDeviceTypeBySerialNumber(bleDevice.id);
    if (!deviceType || deviceType.id !== DeviceTypeIds.TR4_E) {
      return null;
    }

    const device = new DirectConnectTR4E(bleDevice.msg);
    return {
      status: DirectConnectDeviceStatus.ADVERTISING,
      serialNumber: bleDevice.msg.srcAddr,
      bleSignalStrength: null,
      bleDevice,
      device,
      subscriptions: [],
    };
  }

  public serialNumber: number;
  public serialNumberStr: string;
  public deviceName: string;
  public deviceModel: string;
  public programmedUnit: Unit;
  public pairedProgrammer: DirectConnect301;

  public get firmwareVersionStr$() { return this._firmwareVersionStr$.asObservable(); }
  public get hardwareVersionStr$() { return this._hardwareVersionStr$.asObservable(); }
  public get temperatureStr$() { return this._temperatureStr$.asObservable(); }
  public get linkQualityStr$() { return this._linkQualityStr$.asObservable(); }
  public get gatewayReplied$() { return this._gatewayReplied$.asObservable(); }
  public get rapidCheckInEnabled() { return this._rapidCheckInEnabled$.value; }
  public get rapidCheckInEnabled$() { return this._rapidCheckInEnabled$.asObservable(); }
  public get useLegacyMode() { return this._legacyMode$.value; }
  public get useLegacyMode$() { return this._legacyMode$.asObservable(); }
  public get userChanges() { return this._userChanges$.value; }
  public get userChanges$() { return this._userChanges$.asObservable(); }
  public get lastMsgAt$() { return this._lastMsgAt$.asObservable(); }
  public get state() { return this._state$.value; }
  public get state$() { return this._state$.asObservable(); }
  public get overallStatus() { return this._overallStatus$.value; }
  public get overallStatus$() { return this._overallStatus$.asObservable(); }
  public get read() { return this._read$.value; }
  public get read$() { return this._read$.asObservable(); }
  public get meterHealth() { return this._meterHealth$.value; }
  public get meterHealth$() { return this._meterHealth$.asObservable(); }
  public get meterSerial() { return this._meterSerial$.value; }
  public get meterSerial$() { return this._meterSerial$.asObservable(); }
  public get factorySleep() { return this._factorySleep; }
  public set factorySleep(val) { this._factorySleep = val; }

  private _temperature: number;
  private _linkQuality: number;

  private _firmwareVersionStr$ = new BehaviorSubject<string>('');
  private _hardwareVersionStr$ = new BehaviorSubject<string>('');
  private _temperatureStr$ = new BehaviorSubject<string>('');
  private _linkQualityStr$ = new BehaviorSubject<string>('');
  private _gatewayReplied$ = new BehaviorSubject<boolean>(false);
  private _rapidCheckInEnabled$ = new BehaviorSubject<boolean>(false);
  private _resetPulseCount$ = new BehaviorSubject<boolean>(false);
  private _legacyMode$ = new BehaviorSubject<boolean>(false);
  private _read$ = new BehaviorSubject<number>(null);
  private _meterHealth$ = new BehaviorSubject<boolean>(false);
  private _meterSerial$ = new BehaviorSubject<number>(null);
  private _userChanges$ = new BehaviorSubject<ITR4EUserChanges>({});
  private _tr4e: TR4E;
  private _lastMsg: EndDeviceMessage;
  private _lastMsgAt$ = new BehaviorSubject<Date>(null);
  private _state$ = new BehaviorSubject<EndDeviceFormState>(EndDeviceFormState.PRISTINE);
  private _overallStatus$ = new BehaviorSubject<{ label: string, description: string, isOk: boolean }>(null);
  private _factorySleep = false;

  private _retryTimeout;

  constructor(msg?: EndDeviceMessage) {
    if (msg) {
      this.updateFromMessage(msg);
    }

    this._userChanges$.subscribe((changes) => {
      let pristine = true;

      if (changes.hasOwnProperty('rapidCheckInEnabled')) {
        pristine = false;
      }

      if (changes.hasOwnProperty('legacyMode')) {
        pristine = false;
      }

      if (pristine) {
        this._state$.next(EndDeviceFormState.PRISTINE);
      } else if (this.state === EndDeviceFormState.PRISTINE) {
        this._state$.next(EndDeviceFormState.DIRTY);
      }
    });
  }

  public markFormAsDirty() {
    this._state$.next(EndDeviceFormState.DIRTY);
  }

  public applyChanges() {
    if (!this.pairedProgrammer) { return; }
    const msg = new InfoMessage(this.serialNumber, TR4EInfoMessageType.DC301);
    msg.info = msg.info as TR4EInfo;
    msg.info.userMsgResponse = true;
    msg.info.serialNumber = this.serialNumber;
    msg.info.checkInInterval = this._tr4e.checkInInterval;
    if (this.userChanges.hasOwnProperty('rapidCheckInEnabled') && this.userChanges.rapidCheckInEnabled) {
      msg.info.startRapidCheckIn = this.userChanges.rapidCheckInEnabled;
    } else if (this.userChanges.hasOwnProperty('rapidCheckInEnabled') && !this.userChanges.rapidCheckInEnabled) {
      msg.info.stopRapidCheckIn = !this.userChanges.rapidCheckInEnabled;
    }

    if (this.userChanges.hasOwnProperty('legacyMode')) {
      msg.info.setRFMode(this.userChanges.legacyMode ? TR4ERfMode.LEGACY : TR4ERfMode.HIGH_POWER);
    }

    if (this.factorySleep) {
      msg.info.enterFactorySleep = true;
    }
    this._state$.next(EndDeviceFormState.APPLYING_CHANGES);
    setTimeout(() => {
      console.log('%c TX TR4-E', 'color: blue', msg);
      this.pairedProgrammer.sendInfoMessage(msg);
      this._retryTimeout = setTimeout(() => {
        console.log('%c TX TR4-E (1st Retry)', 'color: blue', msg);
        msg.frameId = null;
        this.pairedProgrammer.sendInfoMessage(msg);
        this._retryTimeout = setTimeout(() => {
          console.log('%c TX TR4-E (2nd Retry)', 'color: blue', msg);
          msg.frameId = null;
          this.pairedProgrammer.sendInfoMessage(msg);
        }, 10 * 1000);
      }, 10 * 1000);
    }, 300);
  }

  public changesSuccessfullyApplied(msg: EndDeviceMessage) {
    if (this.userChanges.hasOwnProperty('rapidCheckInEnabled') && msg.device.rapidCheckInEnabled !== this.userChanges.rapidCheckInEnabled) {
      return;
    } else if (this.userChanges.hasOwnProperty('legacyMode') && msg.device) {
      const isLegacy = (msg.device as TR4E).rfMode === 0;
      if ((isLegacy && !this.userChanges.legacyMode) || (!isLegacy && this.userChanges.legacyMode)) {
        return;
      }
    }
    if (this._retryTimeout) {
      clearTimeout(this._retryTimeout);
      this._retryTimeout = undefined;
    }
    this.updateFromMessage(msg);
    this._state$.next(EndDeviceFormState.CHANGES_APPLIED);
  }

  public updateFromMessage(msg: EndDeviceMessage) {
    this._lastMsg = msg;
    this._tr4e = msg.device as TR4E;
    this.updateDeviceInfo(msg);
    this.updateOverallStatus(msg);
    this._lastMsgAt$.next(new Date());
  }

  public dropUserChanges() {
    this._userChanges$.next({});
    this.updateFromMessage(this._lastMsg);
  }

  public setRapidCheckInEnabled(rapidCheckIn: boolean) {
    const changes = { ...this.userChanges };
    if (rapidCheckIn !== this._tr4e.rapidCheckInEnabled) {
      changes.rapidCheckInEnabled = rapidCheckIn;
    } else {
      delete changes.rapidCheckInEnabled;
    }

    this._userChanges$.next(changes);
    this._rapidCheckInEnabled$.next(rapidCheckIn);
  }

  public setUseLegacyMode(legacyMode: boolean) {
    const changes = { ...this.userChanges };
    if (legacyMode && this._tr4e.rfMode !== 0) {
      changes.legacyMode = legacyMode;
    } else if (!legacyMode && this._tr4e.rfMode === 0) {
      changes.legacyMode = legacyMode;
    } else {
      delete changes.legacyMode;
    }

    this._userChanges$.next(changes);
    this._legacyMode$.next(legacyMode);
  }

  public setProgrammedUnit(unit: Unit) {
    if (unit && unit._id) {
      this.programmedUnit = unit;
    }
  }

  private updateDeviceInfo(msg: EndDeviceMessage) {
    this.serialNumber = msg.srcAddr;
    this.serialNumberStr = ConversionUtils.ConvertSerialNumberToString(this.serialNumber);
    const deviceType = DeviceTypeHelper.GetDeviceTypeBySerialNumber(this.serialNumber);
    this.deviceName = deviceType ? deviceType.name : '';
    this.deviceModel = deviceType ? deviceType.model : '';

    if (!msg.device) {
      // clear everything
      this._temperature = null;
      this._temperatureStr$.next('');
      this._linkQuality = msg.firstHopRssi;
      this._linkQualityStr$.next(Lorax.LinkQualityToSignalStrengthMessage(msg.firstHopRssi));
      this._gatewayReplied$.next(false);
      this._rapidCheckInEnabled$.next(false);
      this._resetPulseCount$.next(false);
      this._legacyMode$.next(false);
      this._read$.next(null);
      this._meterHealth$.next(false);
      this._meterSerial$.next(null);
      return;
    }
    msg.device = msg.device ? msg.device as TR4E : null;
    const read = TR4ERead.Create(msg.srcAddr, msg.device, {
      firstHopId: msg.firstHopAddr,
      firstHopRssi: msg.firstHopRssi,
      lastHopRssi: msg.dstAddr,
      isProgrammed: true,
    })[0];

    if (this._temperature !== msg.device.temperature) {
      this._temperature = msg.device.temperature;
      this._temperatureStr$.next(DirectConnectTR4E.useMetric ? msg.device.temperature + ' C'
        : round(ConversionUtils.ConvertCelsiusToFahrenheit(msg.device.temperature)) + ' F');
    }

    if (this._linkQuality !== msg.firstHopRssi) {
      this._linkQuality = msg.firstHopRssi;
      this._linkQualityStr$.next(Lorax.LinkQualityToSignalStrengthMessage(msg.firstHopRssi));
    }

    if (msg.device && this.meterHealth !== msg.device.meterHealth) {
      this._meterHealth$.next(msg.device.meterHealth);
    }

    if (msg.device.meter && this.read !== read.meterRead()) {
      this._read$.next(read.meterRead());
    }

    if (msg.device.meter && this.meterSerial !== read.meterSerial) {
      this._meterSerial$.next(read.meterSerial);
    }

    if (this._gatewayReplied$.value !== msg.device.gatewayReplied) {
      this._gatewayReplied$.next(msg.device.gatewayReplied);
    }

    if (this.userChanges && this.userChanges.hasOwnProperty('rapidCheckInEnabled')) {
      this._rapidCheckInEnabled$.next(this.userChanges.rapidCheckInEnabled);
    } else {
      this._rapidCheckInEnabled$.next(msg.device.rapidCheckInEnabled);
    }

    if (this.userChanges && this.userChanges.hasOwnProperty('legacyMode')) {
      this._legacyMode$.next(this.userChanges.legacyMode);
    } else {
      this._legacyMode$.next(msg.device.rfMode === 0);
    }

    const firmware = `v${msg.device.firmwareVersionMajor}.${msg.device.firmwareVersionMinor}`;
    if (firmware !== this._firmwareVersionStr$.value) {
      this._firmwareVersionStr$.next(firmware);
    }
    const hardware = `v${msg.device.hardwareVersionMajor}.${msg.device.hardwareVersionMinor}`;
    if (hardware !== this._hardwareVersionStr$.value) {
      this._hardwareVersionStr$.next(hardware);
    }
  }

  private updateOverallStatus(msg: EndDeviceMessage) {
    msg.device = msg.device as TR4E;
    const status = {
      isOk: true,
      label: '',
      description: '',
    };

    if (msg.device) {
      const linkQuality = Lorax.LinkQualityToSignalStrengthMessage(msg.firstHopRssi);
      status.isOk = msg.device.gatewayReplied && linkQuality !== 'Poor';
      status.label = linkQuality === 'Poor'
        ? 'Poor Transceiver Signal'
        : msg.device.gatewayReplied ? 'All Good' : 'Not Communicating With Gateway';
      status.description = msg.device.gatewayReplied
        ? `This device has a${linkQuality === 'Excellent' ? 'n' : ''} ${linkQuality} connection to the Gateway.`
        : 'This Transceiver has not received a response from any nearby Gateways. This could mean that this ' +
        'device is either not programmed to a Unit yet or it is not within range of a Gateway.';
    } else {
      status.isOk = false;
    }

    if (!isEqual(this.overallStatus, status)) {
      this._overallStatus$.next(status);
    }
  }

}
