import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { of, Observable, Subscription, from, combineLatest } from 'rxjs';
import { repeat, delay, concatMap, map, distinctUntilChanged } from 'rxjs/operators';

import { IDirectConnectDevice } from '../../services/direct-connect/baseDirectConnectDevice';

export enum DirectConnectInstructionMode {
  GW301_INSTRUCTION,
  DC301_INSTRUCTION,
  RR301_INSTRUCTION,
  TR401_INSTRUCTION,
  TR401_X_INSTRUCTION,
  RE4_INSTRUCTION,
}

const LimitedUnpairedStream = from([
  DirectConnectInstructionMode.DC301_INSTRUCTION,
  DirectConnectInstructionMode.RR301_INSTRUCTION,
]).pipe(concatMap((val) => of(val).pipe(delay(5000))), repeat());

const LimitedPairedStream = from([
  DirectConnectInstructionMode.RR301_INSTRUCTION,
]);

const DC301UnpairedStream = from([
  DirectConnectInstructionMode.GW301_INSTRUCTION,
  DirectConnectInstructionMode.DC301_INSTRUCTION,
  DirectConnectInstructionMode.RE4_INSTRUCTION,
]).pipe(concatMap((val) => of(val).pipe(delay(5000))), repeat());

const DC301PairedStream = from([
  DirectConnectInstructionMode.RR301_INSTRUCTION,
  DirectConnectInstructionMode.TR401_INSTRUCTION,
  DirectConnectInstructionMode.TR401_X_INSTRUCTION,
]).pipe(concatMap((val) => of(val).pipe(delay(5000))), repeat());


@Component({
  selector: 'app-direct-connect-instructions',
  templateUrl: './direct-connect-instructions.component.html',
  styleUrls: ['./direct-connect-instructions.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1200ms 900ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('700ms', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class DirectConnectInstructionsComponent implements OnInit, OnDestroy {
  public DirectConnectInstructionMode = DirectConnectInstructionMode;

  @Input() public dc301Paired$: Observable<IDirectConnectDevice>;
  @Input() public limitAccess$: Observable<boolean>;
  @Input() public modes: number[];
  @Input() public context: 'connect' | 'program' = 'connect';

  public modeStream$: Observable<DirectConnectInstructionMode>;

  private _subscription: Subscription;

  constructor() { }

  ngOnInit() {
      this._subscription = combineLatest([
        this.dc301Paired$.pipe(map((paired) => !!paired), distinctUntilChanged()),
        this.limitAccess$ ? this.limitAccess$.pipe(distinctUntilChanged()) : of(false),
      ]).subscribe(([paired, limited]) => {
        this.updateModeStream(paired, limited);
      });
  }

  ngOnDestroy() {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }

  updateModeStream(paired: boolean, limited: boolean) {
    if (this.modes) {
      this.modeStream$ = from(this.modes)
        .pipe(concatMap((val) => of(val).pipe(delay(5000))), repeat());
    } else if (paired) {
      this.modeStream$ = limited ? LimitedPairedStream : DC301PairedStream;
    } else {
      this.modeStream$ = limited ? LimitedUnpairedStream : DC301UnpairedStream;
    }
  }
}
