import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { AppSettingsService } from '../../services/app-settings.service';

@Component({
  selector: 'app-modal-pager',
  templateUrl: './modal-pager.component.html',
  styleUrls: ['./modal-pager.component.scss'],
})
export class ModalPagerComponent implements OnInit {
  @Input() public itemIds: string[];
  @Input() public itemName: string;
  @Input() public selectId: string;
  @Input() public isDisabled: boolean = false;
  @Output() public onClickNext: EventEmitter<string> = new EventEmitter<string>();
  @Output() public onClickPrevious: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    public appSettings: AppSettingsService,
    private modalCtrl: ModalController,
  ) {}

  async ngOnInit() {
    await this.modalCtrl.getTop();
  }

  public onNextButtonClick() {
    const itemIds = this.itemIds;
    const selectId = this.selectId;
    const selectIndex = itemIds.indexOf(selectId);
    const nextIndex = (selectIndex + 1 < itemIds.length)
      ? selectIndex + 1 : selectIndex;

    this.onClickNext.emit(itemIds[nextIndex]);
  }

  public onPreviousButtonClick() {
    const itemIds = this.itemIds;
    const selectId = this.selectId;
    const selectIndex = itemIds.indexOf(selectId);
    const previousIndex = (selectIndex - 1 >= 0)
      ? selectIndex - 1 : selectIndex;

    this.onClickPrevious.emit(itemIds[previousIndex]);
  }
}
