import { BleMessage } from './bleMessage';
import { Ack, AckType } from './payloads/incoming/ack';
import { AvailableNetworksType, AvailableNetworks } from './payloads/incoming/availableNetworks';
import { Info, InfoPayloadType } from './payloads/incoming/info';
import { Status, StatusType } from './payloads/incoming/status';
import { SyncProgress, SyncProgressType } from './payloads/incoming/syncProgress';
import { Payload } from './payloads/payload';

export const BLEIncomingMessageType = 0x0500;

export class BleIncomingMessage extends BleMessage {
  constructor(serialNumber: number) {
    super(serialNumber, BLEIncomingMessageType);
  }
}

Payload.Register(AckType, Ack);
Payload.Register(AvailableNetworksType, AvailableNetworks);
Payload.Register(InfoPayloadType, Info);
Payload.Register(StatusType, Status);
Payload.Register(SyncProgressType, SyncProgress);
