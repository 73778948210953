import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { ModalHeaderComponent } from '../../components/modal-header/modal-header.component';
import { DirectivesModule } from '../../directives/directives.module';


@NgModule({
  declarations: [
      ModalHeaderComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    DirectivesModule,
  ],
  providers: [

  ],
  exports: [
    ModalHeaderComponent,
  ],
})
export class ModalHeaderModule {}
