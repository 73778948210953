import * as _ from 'lodash';

const PROBLEM_REQUESTS = 'problemRequests';

export class DiagnosticDB {

  private nextId = 1;
  private db;
  private primaryKeys: { [collectionName: string]: string } = {
    [PROBLEM_REQUESTS]: 'url',
  };

  public getID() {
    return this.nextId++;
  }

  public init(): Promise<void> {
    return new Promise((resolve, reject) => {
      const request = window.indexedDB.open('diagnostic-db', 2); // IMPORTANT: If you add a new collection increment this version number
      request.onerror = (event) => {
        console.error(event);
        reject(event);
      };
      request.onsuccess = (event: any) => {
        this.db = event.target.result;
        resolve();
      };
      request.onupgradeneeded = (event: any) => {
        this.db = event.target.result;

        // objectStoreNames is of type 'DOMStringList' which has a '.contains' method
        // See https://developer.mozilla.org/en-US/docs/Web/API/DOMStringList for more info
        if (!this.db.objectStoreNames.contains(PROBLEM_REQUESTS)) {
          this.db.createObjectStore(PROBLEM_REQUESTS, { keyPath: this.primaryKeys[PROBLEM_REQUESTS] });
        }
      };
    });
  }

  public getProblemRequests(url?): Promise<any> {
    return this.get(PROBLEM_REQUESTS, url).then((doc) => doc);
  }

  public async setProblemRequest(requestInfo): Promise<any> {
    let req = await this.getProblemRequests(requestInfo.url);
    const occurrence = {
      date: requestInfo.date,
      type: requestInfo.type,
      connectionType: requestInfo.connectionType,
      downlinkMax: requestInfo.downlinkMax,
    };
    if (!req) {
      req = {
        url: requestInfo.url,
        occurrences: [occurrence],
        count: 1,
      };
    } else {
      req.count = ++req.count;
      if (req.occurrences.length >= 50) {
        req.occurrences.shift();
      }
      req.occurrences.push(occurrence);
    }
    return this.put(PROBLEM_REQUESTS, req);
  }

  public clearProblemRequests(): Promise<any> {
    return this.clear(PROBLEM_REQUESTS);
  }

  private async get(collection: string, id?: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (!this.db) { await this.init(); }
      const store = id
        ? this.db.transaction(collection).objectStore(collection).get(id)
        : this.db.transaction(collection).objectStore(collection).getAll();
      store.onerror = (event) => {
        reject(event);
      };
      store.onsuccess = (event) => {
        resolve(event.target.result);
      };
    });
  }

  private async put(collection: string, object, key?: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      if (!collection || !object) {
        resolve();
      } else {
        if (!this.db) { await this.init(); }
        const store = this.db.transaction(collection, 'readwrite').objectStore(collection);
        let req;
        if (key) {
          req = store.put(object, key);
        } else {
          if (!this.primaryKeys[collection]) {
            reject(new Error(`Collection "${collection}" not found in keyPaths`));
          } else if (!object[this.primaryKeys[collection]]) {
            reject(new Error(`Object does not contain a value at keyPath: ${this.primaryKeys[collection]}`));
          }
          req = store.put(object);
        }
        req.onerror = (event) => reject(event);
        req.onsuccess = () => resolve();
      }
    });
  }

  private async clear(collection: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      if (!collection) {
        resolve();
      } else {
        if (!this.db) { await this.init(); }
        const store = this.db.transaction(collection, 'readwrite').objectStore(collection);
        const req = store.clear();
        req.onerror = (event) => reject(event);
        req.onsuccess = () => resolve();
      }
    });
  }
}
