import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Plugins, CameraResultType, CameraOptions as CapacitorCameraOptions, CameraSource } from '@capacitor/core';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { ActionSheetController, AlertController } from '@ionic/angular';

import { AppSettingsService, permissionType } from './app-settings.service';
import { IDocumentUpload, DocumentUploadService } from './document-upload.service';
import { MobilePropertyService } from './mobile-property.service';
import { ToastService } from './toast.service';

const IMG_HEADER = 'data:image/jpeg;base64,';

export enum CameraResultStatus {
  CANCELED,
  DELETE,
  CAMERA_PICTURE,
  UPLOADED_PICTURE,
  ERROR,
}

export interface ICameraResult {
  status: CameraResultStatus;
  base64Img: string;
}

@Injectable({
  providedIn: 'root',
})
export class CameraService {

  private static actionsheet;

  constructor(
    private appSettings: AppSettingsService,
    private camera: Camera,
    private actionSheetCtrl: ActionSheetController,
    private http: HttpClient,
    private documentUploadService: DocumentUploadService,
    private toast: ToastService,
    private alertCtrl: AlertController,
    private mobilePropertyService: MobilePropertyService,
  ) { }

  public getPicture(deleteText?: string): Promise<ICameraResult> {
    return new Promise(async (res) => {
      CameraService.actionsheet = await this.actionSheetCtrl.create({
        buttons: await this.buttons(res, deleteText),
      });

      CameraService.actionsheet.present();
    });
  }

  public async takePictureWithCamera(): Promise<ICameraResult> {
    return new Promise(async (res) => {
      this.takePicture(res);
    });
  }

  public getFileFromDataURI(dataURI: string, filename: string) {
    if (dataURI === '') { return; }
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURI.split(',')[1]);
    } else {
      byteString = unescape(dataURI.split(',')[1]);
    }

    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const file: any = new Blob([ia], { type: mimeString });
    file.name = filename;
    return file;
  }

  public uploadPropertyDoc(propertyId, file, title, loader): void {
    this.http.post(`${this.appSettings.appSettings.backEnd}/PropertyUploader/${propertyId}/Documents/${file.name}`,
      { filename: file.name, type: file.type }, { responseType: 'text' }).subscribe(
        (url) => {
          loader.dismiss();
          const upload: IDocumentUpload = {
            id: propertyId,
            file,
            onSuccess: () => {
              return new Promise((resolve) => {
                this.mobilePropertyService.updateDoc(propertyId, { fileType: file.type, title })
                  .subscribe(
                    () => {
                      this.showToast(`File "${title}" finished uploading!`);
                      resolve();
                    },
                    () => {
                      this.showAlert(`File "${title}" has failed to upload`, 'Upload Failed');
                      resolve();
                    },
                  );
              });
            },
            signedUrl: url,
          };
          this.documentUploadService.enqueueUpload(upload);
        },
        () => {
          loader.dismiss();
          this.showAlert(`File "${title}" has failed to upload`, 'Upload Failed');
        },
      );
  }

  public uploadGatewayDoc(propertyId, gatewayId, file, title, coords, loader): void {
    this.http.post(`${this.appSettings.appSettings.backEnd}/PropertyUploader/${propertyId}/Documents/${file.name}`,
      { filename: file.name, type: file.type }, { responseType: 'text' }).subscribe(
        (url) => {
          if (loader) {
            loader.dismiss();
          }
          const upload: IDocumentUpload = {
            signedUrl: url,
            id: gatewayId,
            file,
            onSuccess: () => {
              return new Promise((resolve) => {
                this.mobilePropertyService.updateGatewayDoc(propertyId, gatewayId, { fileType: file.type, title, coords })
                  .subscribe(
                    () => {
                      this.showToast(`File "${title}" finished uploading!`);
                      resolve();
                    },
                    () => {
                      this.showAlert(`File "${title}" has failed to upload`, 'Upload Failed');
                      resolve();
                    },
                  );
              });
            },
          };
          this.documentUploadService.enqueueUpload(upload);
        },
        () => {
          if (loader) {
            loader.dismiss();
          }
          this.showAlert(`File "${title}" has failed to upload`, 'Upload Failed');
        },
      );
  }

  public uploadRepeaterDoc(propertyId, repeaterId, file, title, coords, loader) {
    this.http.post(`${this.appSettings.appSettings.backEnd}/PropertyUploader/${propertyId}/Documents/${file.name}`,
      { filename: file.name, type: file.type }, { responseType: 'text' }).subscribe(
        (url) => {
          if (loader) {
            loader.dismiss();
          }
          const upload: IDocumentUpload = {
            signedUrl: url,
            id: repeaterId,
            file,
            onSuccess: () => {
              return new Promise((resolve) => {
                this.mobilePropertyService.updateRepeaterDoc(propertyId, repeaterId, { fileType: file.type, title, coords })
                  .subscribe(
                    () => {
                      this.showToast(`File "${title}" finished uploading!`);
                      resolve();
                    },
                    () => {
                      this.showAlert(`File "${title}" has failed to upload`, 'Upload Failed');
                      resolve();
                    },
                  );
              });
            },
          };
          this.documentUploadService.enqueueUpload(upload);
        },
        () => {
          if (loader) {
            loader.dismiss();
          }
          this.showAlert(`File "${title}" has failed to upload`, 'Upload Failed');
        },
      );
  }

  private showToast(message: string) {
    this.toast.queueToast(message);
  }

  private showAlert(message: string, header: string) {
    this.alertCtrl.create({
      message,
      header,
      buttons: ['Ok'],
    }).then((a) => a.present());
  }

  private async takePicture(resolution: any) {
    if (await this.checkCameraPermission()) {
      try {
      let image = '';
      if (this.appSettings.isBrowser) {
        const options: CapacitorCameraOptions = {
          height: 1000,
          width: 1000,
          resultType: CameraResultType.DataUrl,
          source: CameraSource.Camera,
        };
        const data = await Plugins.Camera.getPhoto(options);
        image = data.dataUrl as string;
      } else {

        const options: CameraOptions = {
          // quality: 25, // We used to do this but opted to use the targetWidth and TargetHeight instead.
          targetWidth: 1000,
          targetHeight: 1000,
          destinationType: this.camera.DestinationType.DATA_URL,
          encodingType: this.camera.EncodingType.JPEG,
          saveToPhotoAlbum: false,
          sourceType: this.camera.PictureSourceType.CAMERA,
          allowEdit: false,
          correctOrientation: true,
        };
        image = IMG_HEADER + await this.camera.getPicture(options);
      }
      resolution({status: CameraResultStatus.CAMERA_PICTURE, base64Img: image});
    } catch {
      resolution({ status: CameraResultStatus.ERROR, base64Img: null });
    }
    }
  }

  public async checkStoragePermission() {
    return new Promise(async (resolve, reject) => {
      const res = await this.appSettings.needPermission(permissionType.Storage);
      resolve(res);
    });
  }

  public async checkCameraPermission() {
    return new Promise(async (resolve, reject) => {
      const res = await this.appSettings.needPermission(permissionType.Camera);
      resolve(res);
    });
  }

  private async uploadPicture(resolution: any) {
    if ((await this.checkStoragePermission())) {
      const options: CameraOptions = {
        // quality: 50,
        targetWidth: 1000,
        targetHeight: 1000,
        destinationType: this.camera.DestinationType.DATA_URL,
        encodingType: this.camera.EncodingType.JPEG,
        saveToPhotoAlbum: false,
        sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
        allowEdit: false,
        correctOrientation: true,
      };

      this.camera.getPicture(options)
        .then((imgData) => {
          resolution({ status: CameraResultStatus.UPLOADED_PICTURE, base64Img: IMG_HEADER + imgData });
        }, (err) => {
          resolution({ status: CameraResultStatus.ERROR, base64Img: null });
        });
    }
  }

  private async buttons(resolution: any, deleteText?: string): Promise<{ text: string, role: string, handler: any }[]> {
    const settings = await this.appSettings.getAppSettings();
    const buttons = [];
    // Camera Button
    buttons.push({
      text: this.accessText('Camera', settings.camera.available, settings.camera.enabled),
      cssClass: settings.camera.available && settings.camera.enabled ? '' : 'disabled-action-button',
      handler: async () => {
        this.takePicture(resolution);
      },
    });

    // Upload Button
    buttons.push({
      text: this.accessText('Upload Photo', settings.storage.available, settings.storage.enabled),
      cssClass: settings.storage.available && settings.storage.enabled ? '' : 'disabled-action-button',
      handler: async () => {
        this.uploadPicture(resolution);
      },
    });

    // Delete button
    if (deleteText && deleteText.length > 0) {
      buttons.push({
        text: deleteText,
        role: 'destructive',
        handler: () => {
          resolution({ status: CameraResultStatus.DELETE, base64Img: null });
        },
      });
    }

    // Cancel Button
    buttons.push({
      text: 'Cancel',
      role: 'cancel',
      handler: () => {
        resolution({ status: CameraResultStatus.CANCELED, base64Img: null });
      },
    });

    return buttons;
  }

  private accessText(name: string, available: boolean, enabled: boolean): string {
    if (available && enabled) {
      return name;
    } else if (!available) {
      return name + ' (Not Available)';
    } else {
      return name + ' (Disabled)';
    }
  }
}
