import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

export interface IConfirmDeleteOptions {
  match?: string;
  uppercase?: boolean;
}

@Injectable()
export class ConfirmationService {

  constructor(
    private alertCtrl: AlertController,
  ) { }

  public async confirmDelete(header: string, message: string, options: IConfirmDeleteOptions = {}): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const inputs = [];
      if (options.match) {
        inputs.push({
          name: 'input',
          type: 'text',
          placeholder: `Type ${options.match}`,
        });
        message += ` To confirm, please type ${options.match} below and then click "Delete".`;
      }
      this.alertCtrl.create({ header, message, inputs,
        buttons: [{
          text: 'Cancel',
          handler: () => {
            resolve(false);
          },
        }, {
          text: 'Delete',
          handler: options.match ? ({ input }) => {
            resolve(!options.match || input === options.match ||
              (options.uppercase && input.toUpperCase() === options.match.toUpperCase()));
          } : () => { resolve(true); },
        }],
      }).then((alert) => {
        alert.present();
      });
    });
  }

}
