import { ConversionUtils, Property } from '@ncss/models';

import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  ViewChild,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  ControlContainer,
  FormControlDirective,
  FormControl,
} from '@angular/forms';
import { Events, ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';

import { AppSettingsService } from '../../services/app-settings.service';
import { BarcodeScannerService } from '../../services/barcode-scanner.service';
import { MobilePropertyService } from '../../services/mobile-property.service';
import { SerialKeyboardEventType } from './serial-keyboard/serial-keyboard.component';
import { TestConnectModalComponent } from './test-connect-modal/test-connect-modal.component';

export enum SerialNumberInputMode {
  NONE,
  KEYPAD,
  BARCODE,
  RADIO,
}

export type SerialInputContextType = 'equipment' | 'network';


@Component({
  selector: 'serial-number-input',
  templateUrl: 'serial-number-input.component.html',
  styleUrls: ['serial-number-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SerialNumberInputComponent,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SerialNumberInputComponent implements ControlValueAccessor, OnInit, OnDestroy {

  @ViewChild(FormControlDirective) formControlDirective: FormControlDirective;
  @Input() formControl: FormControl;
  @Input() formControlName: string;
  @Input() hideButtons?: boolean;

  // get hold of FormControl instance no matter formControl or formControlName is given.
  // If formControlName is given, then this.controlContainer.control is the parent FormGroup (or FormArray) instance.
  get control() {
    return this.formControl || this.controlContainer.control.get(this.formControlName);
  }

  @Input() outline = false;
  @Input() serialSize: 'small' | 'medium' | 'big' = 'big';
  @Input() context: SerialInputContextType = 'equipment';
  @Input() autofocus = false;
  @Input() autoHideKeyboard = true;
  @Input() property?: Property;
  value: number | null = null;
  serialStr = '';
  touched = false;
  disabled = false;
  isFocused = false;
  pending = false;
  invalid = false;
  valid = false;
  dirty = false;
  barcodeScanAvailable = false;

  private destroyed = new Subject();

  onChange = (value) => { };
  onTouched = () => { };


  constructor(
    private events: Events,
    private controlContainer: ControlContainer,
    private cd: ChangeDetectorRef,
    private settings: AppSettingsService,
    private barcodeScanner: BarcodeScannerService,
    private modalCtl: ModalController,
    public propertyService: MobilePropertyService,
  ) { }

  ngOnInit(): void {
    this.events.subscribe(SerialKeyboardEventType.VALUE_KEY, this.onValueKey.bind(this));
    this.events.subscribe(SerialKeyboardEventType.CMD_KEY, this.onCommandKey.bind(this));
    this.events.subscribe(SerialKeyboardEventType.CLEAR, this.onClearClicked.bind(this));
    this.control.statusChanges.pipe(takeUntil(this.destroyed), debounceTime(100)).subscribe((status) => {
      this.onStatusChange(status);
    });
    this.control.valueChanges.pipe(takeUntil(this.destroyed)).subscribe(() => {
      this.dirty = this.control.dirty;
    });
    if (this.autofocus) {
      setTimeout(() => {
        this.onClick();
      }, 100);
    }
    this.barcodeScanAvailable = AppSettingsService.isFeatureEnabled(this.settings.appSettings.camera) && this.settings.cordovaAvailable;
  }

  ngOnDestroy(): void {
    this.events.unsubscribe(SerialKeyboardEventType.VALUE_KEY, this.onValueKey.bind(this));
    this.events.unsubscribe(SerialKeyboardEventType.CMD_KEY, this.onCommandKey.bind(this));
    this.events.unsubscribe(SerialKeyboardEventType.CLEAR, this.onClearClicked.bind(this));
    if (this.isFocused) {
      this.events.publish(SerialKeyboardEventType.HIDE);
    }
    this.destroyed.next();
    this.destroyed.complete();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: number | null): void {
    this.value = value;
    this.serialStr = this.value ? this.value.toString(16).toUpperCase() : '';
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onClick() {
    if (!this.isFocused && !this.disabled && !this.hideButtons) {
      this.isFocused = true;
      this.events.publish(SerialKeyboardEventType.SHOW, {
        contextType: this.context,
      });
      this.events.publish(SerialKeyboardEventType.SERIAL_PREVIEW, this.serialStr);
    }
  }

  onClickOutside($event) {
    if (this.isFocused) {
      this.events.publish(SerialKeyboardEventType.OUTSIDE_TAP, $event);
    }
  }

  async onBarcodeClick() {
    const serial = await this.barcodeScanner.showBarcodeScanner(true, true);
    if (serial) {
      this.serialStr = typeof serial === 'string' ? serial : ConversionUtils.ConvertSerialNumberToString(serial);
      this.updateValue();
    }
  }

  async onTestConnectClick() {
    console.log('test connect property', this.property || this.propertyService.property);
    const m = await this.modalCtl.create({
      component: TestConnectModalComponent,
      componentProps: {
        property: this.property || this.propertyService.property,
        deviceTypes: 'all',
      },
    });
    await m.present();
    const res = await m.onDidDismiss();
    if (res && res.data) {
      this.serialStr = ConversionUtils.ConvertSerialNumberToString(res.data);
      this.updateValue();
    }
  }

  private onValueKey(key: string) {
    if (this.isFocused) {
      if (this.serialStr.length >= 8) {
        this.serialStr = this.serialStr.substring(0, 8 - key.length);
      }
      this.serialStr += key;
      this.updateValue();
    }
  }

  private onCommandKey(command: string) {
    if (this.isFocused) {
      if (command === 'Close') {
        this.events.publish(SerialKeyboardEventType.HIDE);
        this.isFocused = false;
      } else if (command === 'Backspace' && this.serialStr.length) {
        this.serialStr = this.serialStr.length > 1 ? this.serialStr.substring(0, this.serialStr.length - 1) : '';
        this.updateValue();
      }
    }
  }

  private onClearClicked() {
    if (this.isFocused) {
      this.serialStr = '';
      this.onChange(null);
    }
    this.cd.markForCheck();
  }

  private updateValue() {
    this.onChange(ConversionUtils.ConvertSerialNumberToNumberForFrontEnd(this.serialStr));
    this.events.publish(SerialKeyboardEventType.SERIAL_PREVIEW, this.serialStr);
    this.cd.markForCheck();
  }

  private onStatusChange(status) {
    this.pending = status === 'PENDING';
    this.invalid = status === 'INVALID';
    this.valid = status === 'VALID';

    if (this.valid && this.autoHideKeyboard) {
      this.events.publish(SerialKeyboardEventType.HIDE);
      this.isFocused = false;
    }
    this.cd.markForCheck();
  }

}
