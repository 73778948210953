import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { ModalHeaderModule } from '../../../components/modal-header/modal-header.module';
import { PipesModule } from './../../../pipes/pipes.module';
import { HopsAwayComponent } from './hops-away.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    ModalHeaderModule,
    PipesModule,
  ],
  exports: [ HopsAwayComponent ],
  declarations: [ HopsAwayComponent ],
  providers: [],
})
export class HopsAwayModule { }
