import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

import { INavApp } from '../services/property-nav.service';

@Pipe({
  name: 'navApps',
})
export class NavAppsPipe implements PipeTransform {

  transform(items: INavApp[]) {
    return items.filter(item => item.isAvailable);
  }
}
