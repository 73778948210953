import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuardService } from '../services/guards/auth-guard.service';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', loadChildren: './login/login.module#LoginPageModule' },
  // Home
  {
    path: 'billing-home', loadChildren: './billing/billing-home/billing-home.module#BillingHomePageModule',
    canActivate: [AuthGuardService],
  },
  {
    path: 'property-list', loadChildren: './billing/property-list/property-list.module#PropertyListPageModule',
    canActivate: [AuthGuardService],
  },
  { path: 'settings', loadChildren: './settings/settings.module#SettingsPageModule', canActivate: [AuthGuardService] },
  // Tools
  {
    path: 'barcode-scanner', loadChildren: './tools/barcode-scanner/barcode-scanner.module#BarcodeScannerPageModule',
    canActivate: [AuthGuardService],
  },
  {
    path: 'direct-connect', loadChildren: './tools/direct-connect/direct-connect.module#DirectConnectPageModule',
    canActivate: [AuthGuardService],
  },
  {
    path: 'replace-device', loadChildren: './tools/replace-device/replace-device.module#ReplaceDevicePageModule',
    canActivate: [AuthGuardService],
  },
  {
    path: 'warranty-lookup', loadChildren: './tools/warranty-lookup/warranty-lookup.module#WarrantyLookupPageModule',
    canActivate: [AuthGuardService],
  },
  { path: 'test-connect', loadChildren: './tools/test-connect/test-connect.module#TestConnectPageModule', canActivate: [AuthGuardService] },
  // Property
  { path: 'property/:id', loadChildren: './property/root/root.module#RootPageModule', canActivate: [AuthGuardService] },

  // Perm Links
  { path: 'support', loadChildren: './support/support.module#SupportPageModule', canActivate: [AuthGuardService] },
  { path: 'education', loadChildren: './education/education.module#EducationPageModule', canActivate: [AuthGuardService] },
  {
    path: 'welcome', loadChildren: './welcome/welcome-screen.module#WelcomeScreenModule',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    onSameUrlNavigation: 'reload',
  })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
