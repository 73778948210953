import { Message, MessageProperty } from '@ncss/message';

import { ByteList, DataTypes } from 'byte-list';
import { forEach } from 'lodash';

export const DC301_UPDATE_DATA_SIZE = 256;

export enum DC301UpdateMessageType {
  GROUP_MASK = 0x1230,
  DC_UPDATE_MCU = 0x1231,
  DC_UPDATE_BLE = 0x1232,
}

export class DC301UpdateMessage extends Message {
  @MessageProperty(DataTypes.BYTE)
  public transferId: number;

  @MessageProperty(DataTypes.BYTE)
  public deviceType: number;

  @MessageProperty(DataTypes.BYTE)
  public versionMajor: number;

  @MessageProperty(DataTypes.BYTE)
  public versionMinor: number;

  @MessageProperty(DataTypes.UINT16)
  public totalBlocks: number;

  @MessageProperty(DataTypes.UINT16)
  public blockNumber: number;

  public data: Uint8Array;

  constructor(type: DC301UpdateMessageType) {
    super();
    this.type = type;
  }

  public serialize(): ByteList {
    const bytes = super.serialize() as ByteList;
    if (!this.data) {
      throw new Error('Missing firmware block data');
    } else if (!(this.data instanceof Uint8Array)) {
      throw new Error('Firmware block data must be a Uint8Array');
    } else if (this.data.length !== DC301_UPDATE_DATA_SIZE) {
      throw new Error(`Wrong data size for DC301 (provided: ${this.data.length} expected: ${DC301_UPDATE_DATA_SIZE})`);
    }

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.data.length; ++i) {
      bytes.writeByte(this.data[i]);
    }
    return bytes;
  }

  public deserialize(bytes: ByteList) {
    super.deserialize(bytes);
    this.data = new Uint8Array(DC301_UPDATE_DATA_SIZE);
    for (let i = 0; i < DC301_UPDATE_DATA_SIZE; ++i) {
      this.data[i] = bytes.readByte();
    }
  }

  public toString() {
    let typeStr = '';
    forEach(DC301UpdateMessageType, (val, key) => {
      if (val === this.type) {
        typeStr = key;
      }
    });
    return `DC301UpdateMessage.${typeStr} - Transferring ${this.blockNumber} of ${this.totalBlocks} (${this.versionMajor}.${this.versionMinor})`;
  }
}

