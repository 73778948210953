import { Message } from '@ncss/message';
import { BitwiseHelper } from '@ncss/models';

import { ByteList } from 'byte-list';
import { trim } from 'lodash';

export enum DC301InfoMessageType {
  GROUP_MASK = 0x1220,
  REQUEST_INFO = 0x1221,
  SET_INFO = 0x1222,
}

export class DC301InfoMessage extends Message {

  public deviceName: string;
  public flags: number;

  public set factoryReset(val: boolean) { this.flags = BitwiseHelper.SetBits(this.flags, 0, 1, val ? 1 : 0); }
  public get factoryReset() { return !!BitwiseHelper.GetBits(this.flags, 0, 1); }

  public set partyMode(val: boolean) { this.flags = BitwiseHelper.SetBits(this.flags, 1, 1, val ? 1 : 0); }
  public get partyMode() { return !!BitwiseHelper.GetBits(this.flags, 1, 1); }

  public set setDeviceName(val: boolean) { this.flags = BitwiseHelper.SetBits(this.flags, 2, 1, val ? 1 : 0); }
  public get setDeviceName() { return !!BitwiseHelper.GetBits(this.flags, 2, 1); }

  public set ackDeviceChange(val: boolean) { this.flags = BitwiseHelper.SetBits(this.flags, 3, 1, val ? 1 : 0); }
  public get ackDeviceChange() { return !!BitwiseHelper.GetBits(this.flags, 3, 1); }

  constructor(type: DC301InfoMessageType) {
    super();
    this.type = type;
  }

  public serialize(): ByteList {
    const bytes = super.serialize();
    bytes.writeString((this.deviceName || '').replace(/[\u2018\u2019]/g, '\''), { length: 24 });
    bytes.writeUInt16(this.flags);
    return bytes;
  }

  public deserialize(bytes: ByteList) {
    super.deserialize(bytes);
    this.deviceName = trim(bytes.readString({ length: 24 }), '\0');
    this.flags = bytes.readUInt16();
  }
}
