import {
  ConversionUtils,
  DeviceFamily,
  DeviceTypeHelper,
  DeviceTypeIds,
  ICanProgramResponse,
  ICanProgramUnitInfo,
} from '@ncss/models';

import { HttpErrorResponse } from '@angular/common/http';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as _ from 'lodash';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { MobilePropertyService } from '../services/mobile-property.service';

export function CreateCanProgram(
  propertyService: MobilePropertyService,
  propertyId: string,
  unitInfo?: ICanProgramUnitInfo,
  utilityTypeIdControl?: AbstractControl,
) {
  return (control: AbstractControl): Observable<ValidationErrors> => {
    if (control.errors) {
      return of(control.errors);
    }
    if (utilityTypeIdControl) {
      unitInfo = { ...unitInfo, utilityTypeId: +utilityTypeIdControl.value };
    }
    const id = ConversionUtils.ConvertSerialNumberToNumberForFrontEnd(control.value);
    const deviceType = DeviceTypeHelper.GetDeviceTypeBySerialNumber(id);
    if (!deviceType) {
      return of(null);
    }
    if (deviceType.family === DeviceFamily.REMOTE_READER) {
      const baseId = ConversionUtils.GetBaseRRSerial(id);
      const portOneResponse = propertyService.canProgramDevice(propertyId, baseId + 1, unitInfo).pipe(
        map<ICanProgramResponse, ValidationErrors>((response) => {
          return getErrorsFromCanProgramResponse((baseId + 1).toString(16).toUpperCase(), response, control.errors);
        }),
      );
      const portTwoResponse = propertyService.canProgramDevice(propertyId, baseId + 2, unitInfo).pipe(
        map<ICanProgramResponse, ValidationErrors>((response) => {
          return getErrorsFromCanProgramResponse((baseId + 2).toString(16).toUpperCase(), response, control.errors);
        }),
      );
      return combineLatest([portOneResponse, portTwoResponse]).pipe(
        map(([portOneErrors, portTwoErrors]) => {
          if ((portOneErrors && portOneErrors.alreadyProgrammed) && !portTwoErrors) {
            return {
              alreadyProgrammed: portOneErrors.alreadyProgrammed + ' Meters connected to the same Remote Reader must be programmed to the same unit.',
            };
          } else if ((portTwoErrors && portTwoErrors.alreadyProgrammed) && !portOneErrors) {
            return {
              alreadyProgrammed: portTwoErrors.alreadyProgrammed + ' Meters connected to the same Remote Reader must be programmed to the same unit.',
            };
          } else if (portOneErrors || portTwoErrors) {
            return portOneErrors ? portOneErrors : portTwoErrors;
          } else {
            return null;
          }
        }),
      );
    } else {
      return propertyService.canProgramDevice(propertyId, id, unitInfo).pipe(
        catchError((e) => {
          if ((e as HttpErrorResponse).status === 403) {
            return of({
              canProgram: false,
              permissionError: true,
            });
          } else {
            return of({canProgram: false});
          }
        }),
        map<ICanProgramResponse, ValidationErrors>((response) => {
          return getErrorsFromCanProgramResponse(control.value ? control.value.toString(16).toUpperCase() : null, response, control.errors);
        }),
      );
    }
  };
}

export function ForSerialNumberLength(): ValidatorFn {
  return (c: AbstractControl) => {
    if (!c || (!c.value && c.value !== 0)) {
      return null;
    } else {
      let requiredLength = 8;
      if (DeviceTypeHelper.GetIsThirdParty(c.value)) {
        requiredLength = 7;
      }
      if (c.value.toString(16).length >= requiredLength) {
        return null;
      }
      return { invalidSerialNumberLength: true };
    }
  };
}

function controlShouldNotRunValidation(c: AbstractControl) {
  let requiredLength = 8;
  if (DeviceTypeHelper.GetIsThirdParty(c.value)) {
    requiredLength = 7;
  }
  return !c || (!c.value && c.value !== 0 || c.value.toString(16).length < requiredLength);
}

export function ForMeterDevice(): ValidatorFn {
  const acceptedDeviceFamilies = [
    DeviceFamily.TRANSCEIVER,
    DeviceFamily.TRANSMITTER_3RD_PARTY,
    DeviceFamily.UNKNOWN_3RD_PARTY,
    DeviceFamily.REMOTE_READER,
    DeviceFamily.INTEGRATED_METER,
  ];
  return (c: AbstractControl) => {
    if (controlShouldNotRunValidation(c)) {
      return null;
    }
    if (typeof c.value !== 'number') {
      c.setValue(ConversionUtils.ConvertSerialNumberToNumberForFrontEnd(c.value));
      return null;
    }
    const deviceType = DeviceTypeHelper.GetDeviceTypeBySerialNumber(c.value);
    if (!deviceType) {
      return { invalidSerialNumber: 'Invalid serial number for a meter' };
    }
    if (deviceType.id === DeviceTypeIds.RR4 || deviceType.id === DeviceTypeIds.REMOTE_READER) {
      return {
        invalidSerialNumber: 'This remote reader does not have a transceiver and cannot be programmed to a unit.',
      };
    }
    if (acceptedDeviceFamilies.indexOf(deviceType.family) === -1) {
      const invalidSerialNumber = deviceType.family === DeviceFamily.MANUAL_READER ?
        'Manual Read/Import Read serial number is invalid here because neither subscription is active.' :
        'Invalid serial number for a meter';
      // Recognized device but not one that can be programmed to a meter.
      return { invalidSerialNumber };
    } else {
      return null;
    }
  };
}

export function ForManualReadMeter(): ValidatorFn {
  return (c: AbstractControl) => {
    const acceptedDeviceFamilies = [
      DeviceFamily.MANUAL_READER,
      DeviceFamily.TRANSCEIVER,
      DeviceFamily.TRANSMITTER_3RD_PARTY,
      DeviceFamily.UNKNOWN_3RD_PARTY,
      DeviceFamily.REMOTE_READER,
      DeviceFamily.INTEGRATED_METER,
    ];
    if (controlShouldNotRunValidation(c)) {
      return null;
    }
    const deviceType = DeviceTypeHelper.GetDeviceTypeBySerialNumber(c.value);
    if (deviceType &&
      acceptedDeviceFamilies.indexOf(deviceType.family) === -1) {
      // Recognized device but not one that can be programmed to a meter.
      return {
        serialErrorMessage: 'Invalid serial number for a meter',
      };
    } else {
      return null;
    }
  };
}

export function ForNetworkDevice(): ValidatorFn {
  const ValidFamilies = [
    DeviceFamily.GATEWAY,
    DeviceFamily.REPEATER,
    DeviceFamily.REPEATER_3RD_PARTY,
    DeviceFamily.UNKNOWN_3RD_PARTY,
  ];
  return (c: AbstractControl) => {
    if (controlShouldNotRunValidation(c)) {
      return null;
    }
    if (typeof c.value !== 'number') {
      c.setValue(ConversionUtils.ConvertSerialNumberToNumberForFrontEnd(c.value));
      return null;
    }
    const deviceType = DeviceTypeHelper.GetDeviceTypeBySerialNumber(c.value);
    if (!deviceType) {
      return { invalidSerialNumber: 'Invalid serial number for a network device' };
    }
    if (deviceType && !ValidFamilies.includes(deviceType.family)) {
      // Recognized device but not a gateway.
      return {
        nonNetworkDevice: `${c.value.toString(16).toUpperCase()} is not a valid Network Device serial number.`,
      };
    }
    return null;
  };
}

export function ForGateway(): ValidatorFn {
  return (c: AbstractControl) => {
    if (controlShouldNotRunValidation(c)) {
      return null;
    }
    if (typeof c.value !== 'number') {
      c.setValue(ConversionUtils.ConvertSerialNumberToNumberForFrontEnd(c.value));
      return null;
    }
    const deviceType = DeviceTypeHelper.GetDeviceTypeBySerialNumber(c.value);
    if (!deviceType) {
      return { invalidSerialNumber: 'Invalid serial number for a Gateway' };
    }
    if (deviceType && deviceType.family !== DeviceFamily.GATEWAY) {
      // Recognized device but not a gateway.
      return {
        nonGatewayDevice: `${c.value.toString(16).toUpperCase()} is not a valid Gateway serial number.`,
      };
    }
    return null;
  };
}

export function ForRepeater(): ValidatorFn {
  const ValidFamilies = [DeviceFamily.REPEATER, DeviceFamily.REPEATER_3RD_PARTY, DeviceFamily.UNKNOWN_3RD_PARTY];
  return (c: AbstractControl) => {
    if (controlShouldNotRunValidation(c)) {
      return null;
    }
    if (typeof c.value !== 'number') {
      c.setValue(ConversionUtils.ConvertSerialNumberToNumberForFrontEnd(c.value));
      return null;
    }
    const deviceType = DeviceTypeHelper.GetDeviceTypeBySerialNumber(c.value);
    if (!deviceType) {
      return { invalidSerialNumber: 'Invalid serial number for a Repeater device' };
    }
    if (deviceType && !ValidFamilies.includes(deviceType.family)) {
      // Recognized device but not a repeater.
      return {
        nonGatewayDevice: `${c.value.toString(16).toUpperCase()} is not a valid Gateway serial number.`,
      };
    }
    return null;
  };
}

export function ForLeakSensor(): ValidatorFn {
  return (control: AbstractControl) => {
    if (controlShouldNotRunValidation(control)) {
      return null;
    }
    const id = ConversionUtils.ConvertSerialNumberToNumber(control.value);
    const family = DeviceTypeHelper.GetFamilyBySerialNumber(id);
    if (family === DeviceFamily.LEAK_SENSOR) {
      return null;
    } else {
      return { invalidSerialNumber: `${control.value.toString(16).toUpperCase()} is not a valid Leak Sensor serial number.` };
    }
  };
}

export function ForAnyDevice(): ValidatorFn {
  return (c: AbstractControl) => {
    if (controlShouldNotRunValidation(c)) {
      return null;
    }
    if (typeof c.value !== 'number') {
      c.setValue(ConversionUtils.ConvertSerialNumberToNumberForFrontEnd(c.value));
      return null;
    }
    const deviceType = DeviceTypeHelper.GetDeviceTypeBySerialNumber(c.value);
    if (!deviceType) {
      return { invalidSerialNumber: 'Unrecognized serial number' };
    }
    if (deviceType.id === DeviceTypeIds.RR4 || deviceType.id === DeviceTypeIds.REMOTE_READER) {
      return {
        nonTRDevice: `${c.value.toString(16).toUpperCase()} does not have a built in Transceiver and cannot be programmed.`,
      };
    }
    return null;
  };
}

export function ForDeviceFamilies(acceptedDeviceFamilies: DeviceFamily[], errorMessage?: string) {
  return (c: AbstractControl) => {
    if (controlShouldNotRunValidation(c)) {
      return null;
    }
    if (typeof c.value !== 'number') {
      c.setValue(ConversionUtils.ConvertSerialNumberToNumberForFrontEnd(c.value));
      return null;
    }
    const deviceType = DeviceTypeHelper.GetDeviceTypeBySerialNumber(c.value);
    if (!deviceType) {
      return { invalidSerialNumber: errorMessage };
    }
    if (deviceType && !acceptedDeviceFamilies.includes(deviceType.family)) {
      if (deviceType.id === DeviceTypeIds.RR4 || deviceType.id === DeviceTypeIds.REMOTE_READER) {
        return {
          nonTRDevice: errorMessage,
        };
      }
      // Recognized device but not a repeater.
      return {
        invalidDeviceFamily: errorMessage,
      };
    }
    return null;
  };
}


function getErrorsFromCanProgramResponse(
  value: any,
  response: ICanProgramResponse & { permissionError?: boolean },
  currentErrors: ValidationErrors | null): ValidationErrors {
  if (currentErrors) {
    return currentErrors;
  }
  if (response.canProgram) {
    return null;
  }
  if (response.permissionError) {
    return {
      accessDenied: 'Unable to program device: Access Denied',
    };
  }
  if (response.thirdPartyError) {
    const device = DeviceTypeHelper.GetFamilyBySerialNumber(value);
    if (device && (device === DeviceFamily.UNKNOWN_3RD_PARTY ||
      device === DeviceFamily.TRANSMITTER_3RD_PARTY)) {
      return {
        thirdPartyDevice: 'Unable to program third party devices',
      };
    }
  }
  if (response.alreadyProgrammedTo) {
    return {
      alreadyProgrammed: `"${value}" is already programmed to ${response.alreadyProgrammedTo.unit ? (response.alreadyProgrammedTo.unit.name + ' on') : ''} ${response.alreadyProgrammedTo.property ? response.alreadyProgrammedTo.property.name : 'another property.'}.`,
    };
  }
  if (response.deviceLimitReached) {
    return {
      deviceLimitReached: 'This property has reached its device limit.',
    };
  }
  if (!response.deviceManufactured) {
    return {
      notManufactured: `"${value}" has not been manufactured`,
    };
  }
  return {
    invalidSerialNumber: `Invalid serial number`,
  };
}
