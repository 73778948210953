import {
  DemandUsage,
  DeviceLocation, DeviceTypeHelper, DeviceTypeIds, DeviceWarrantyInfo, IGatewayConnectionStatus, IGatewayInfo,
  IReplaceDeviceResult,
  LeakSensorLocation,
  Meter,
  MeterDeviceLocation,
  MeterWarrantyInfo,
} from '@ncss/models';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AppSettingsService, BackEndHost } from './../app-settings.service';

@Injectable({
  providedIn: 'root',
})
export class MobileDevicesService {

  private rootUrl: BackEndHost;

  constructor(
    private http: HttpClient,
    private appSettings: AppSettingsService,
  ) {
    this.rootUrl = this.appSettings.appSettings.backEnd;
    this.appSettings.appSettings$.pipe(map((s) => s.backEnd), filter((b) => b !== this.rootUrl))
      .subscribe((backend) => {
        this.rootUrl = backend;
      });
  }

  public getDeviceLocation(id: number | string): Observable<DeviceLocation> {
    return this.http.get<DeviceLocation>(
      `${this.rootUrl}/api/Devices/${id}/Location`).pipe(
        map((res) => {
          return this.hydrateDeviceLocation(res);
        }),
      );
  }

  public getGatewayStatus(id: number): Observable<IGatewayConnectionStatus> {
    return this.http.get(`${this.rootUrl}/api/Gateways/${id}/ComputeGatewayStatus`);
  }

  public getGatewayCacheInfo(id: number): Observable<IGatewayInfo> {
    return this.http.get(`${this.rootUrl}/api/Gateways/${id}/GatewayCacheInfo`);
  }

  public loadReadHistory(id: number) {
    return this.http.get(`${this.rootUrl}/api/DailyReads/${id}?` +
      `from=${moment().subtract(1, 'week').toISOString()}&to=${moment().add(1, 'day').toISOString()}`);
  }

  public getGatewaySyncHistory(gatewayId: number) {
    const url = `${this.rootUrl}/api/DailyReads/${gatewayId}?` +
      `from=${moment().subtract(3, 'd').toISOString()}&to=${moment().add(1, 'd').toISOString()}`;
    return this.http.get(url);
  }

  public async getDemandUsageRangeForMeter(meter: Meter, from: Date, to: Date): Promise<DemandUsage[] | null> {
    if (meter.device && DeviceTypeHelper.GetIsEmbeddedTransceiver(meter.device.id)) {
      try {
        const du: DemandUsage[] =
          await this.http.get<DemandUsage[]>(`${this.rootUrl}/api/Devices/${meter.device.id}/DemandUsage?from=${from.toISOString()}&to=${to.toISOString()}`)
            .toPromise()
            .then((doc) => doc ? doc.map(doc => new DemandUsage(doc)) : null);
        return du;
      } catch (e) {
        console.log('Error getting demand usage', e);
        return null;
      }
    } else {
      return null;
    }
  }

  public async replaceDevice(oldSerialNumber: number, newSerialNumber: number, imr: number): Promise<IReplaceDeviceResult> {
    try {
      const res: IReplaceDeviceResult = await this.http.put<IReplaceDeviceResult>(
        `${this.rootUrl}/api/ReplaceDevice`,
        { oldSerialNumber, newSerialNumber, imr }).toPromise();
      if (res && res.location) {
        res.location = this.hydrateDeviceLocation(res.location);
      }
      return res;
    } catch (e) {
      return { success: false, msg: 'Failed to replace device' };
    }
  }

  public getDeviceWarrantyInfo(deviceId: number | string): Observable<DeviceWarrantyInfo> {
    if (typeof deviceId === 'string') {
      deviceId = parseInt(deviceId, 16);
    }
    return this.http.get(`${this.rootUrl}/api/warranty/device/${deviceId}`).pipe(map((info) => DeviceWarrantyInfo.FromDTO(info)));
  }

  public getMeterWarrantyInfo(deviceId: number | string): Observable<MeterWarrantyInfo> {
    if (typeof deviceId === 'string') {
      deviceId = parseInt(deviceId, 16);
    }
    return this.http.get(`${this.rootUrl}/api/warranty/meter/${deviceId}`).pipe(map((info) => MeterWarrantyInfo.FromDTO(info)));
  }

  public getDeviceImgStr(deviceTypeId: DeviceTypeIds): string {
    switch (deviceTypeId) {
      case DeviceTypeIds.TR4: {
        return '/assets/img/equipment/TR401-Flat.svg';
      }
      case DeviceTypeIds.TR4_E: {
        return '/assets/img/equipment/TR401-Flat.svg';
      }
      case DeviceTypeIds.TR4_X: {
        return '/assets/img/equipment/TR401-X-Flat.svg';
      }
      case DeviceTypeIds.REPEATER: {
        return '/assets/img/equipment/RE201.svg';
      }
      case DeviceTypeIds.TRANSCEIVER: {
        return '/assets/img/equipment/transceiver.png';
      }
      case DeviceTypeIds.REMOTE_READER: {
        return '/assets/img/equipment/rr301.svg';
      }
      case DeviceTypeIds.REMOTE_READER_TRANSCEIVER: {
        return '/assets/img/equipment/rr301.svg';
      }
      case DeviceTypeIds.UNKNOWN_3RD_PARTY: {
        return '/assets/img/equipment/third-party.png';
      }
      case DeviceTypeIds.TRANSMITTER_3RD_PARTY_UNKNOWN: {
        return '/assets/img/equipment/third-party.png';
      }
      case DeviceTypeIds.WATER_METER: {
        return '/assets/img/equipment/meter/meter-plain-5.png';
      }
      case DeviceTypeIds.PULSE_CONVERTER: {
        return '/assets/img/equipment/transceiver.png';
      }
      case DeviceTypeIds.DIRECT_CONNECT_PROGRAMMER: {
        return '/assets/img/equipment/DC301-Blue.png';
      }
      case DeviceTypeIds.GATEWAY_201: {
        return '/assets/img/equipment/GW201-large.png';
      }
      case DeviceTypeIds.GATEWAY_301: {
        return '/assets/img/equipment/GW301.png';
      }
      default: {
        return '';
      }
    }
  }

  private hydrateDeviceLocation(res) {
    return (res && res.hasOwnProperty('meter')) ? new MeterDeviceLocation(res) : (res && res.hasOwnProperty('leakSensorLocation')) ?
      new LeakSensorLocation(res) : new DeviceLocation(res);
  }

}
