import { NgModule } from '@angular/core';

import { CapitalizePipe } from './capitalize.pipe';
import { CelsiusToFahrenheitPipe } from './celsiusToFahrenheit.pipe';
import { DatePipe } from './date.pipe';
import { DecimalToHexPipe } from './decimalToHex.pipe';
import { DeviceTypePipe } from './deviceType.pipe';
import { FileTypePipe } from './fileType.pipe';
import { FromNowPipe } from './fromNow.pipe';
import { HighlightPipe } from './highlight.pipe';
import { KeysPipe } from './keys.pipe';
import { MiddleDotPipe } from './middleDot.pipe';
import { NavAppsPipe } from './nav-apps.pipe';
import { OrderByPipe } from './orderBy.pipe';
import { PercentagePipe } from './percentage.pipe';
import { PhoneNumberPipe } from './phoneNumber.pipe';
import { RoleTypesPipe } from './roleTypes.pipe';
import { RoundReadPipe } from './round-read.pipe';
import { UomTypePipe } from './uomType.pipe';
import { UtilityTypePipe } from './utilityType.pipe';


@NgModule({
  declarations: [
    NavAppsPipe,
    RoundReadPipe,
    CapitalizePipe,
    DatePipe,
    FromNowPipe,
    DecimalToHexPipe,
    KeysPipe,
    OrderByPipe,
    PercentagePipe,
    RoleTypesPipe,
    CelsiusToFahrenheitPipe,
    MiddleDotPipe,
    HighlightPipe,
    PhoneNumberPipe,
    DeviceTypePipe,
    UtilityTypePipe,
    UomTypePipe,
    FileTypePipe,
  ],
  imports: [],
  exports: [
    NavAppsPipe,
    RoundReadPipe,
    CapitalizePipe,
    DatePipe,
    FromNowPipe,
    DecimalToHexPipe,
    KeysPipe,
    OrderByPipe,
    PercentagePipe,
    RoleTypesPipe,
    CelsiusToFahrenheitPipe,
    MiddleDotPipe,
    HighlightPipe,
    PhoneNumberPipe,
    DeviceTypePipe,
    UtilityTypePipe,
    UomTypePipe,
    FileTypePipe,
  ],
})
export class PipesModule {}
