import {
  CreateUnitInfo,
  IMeterInfoStat,
  IMeterInfoStats,
  SaveMeterInfo,
  SaveUnitInfo,
  Unit,
  UtilityTypeIds,
  IMeterInfo,
  Meter,
  IDocument,
  LeakSensor,
  HydrateAlert,
  Alert,
} from '@ncss/models';

import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import * as _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, take, tap, mapTo } from 'rxjs/operators';

import { AppSettingsService, BackEndHost, IAppSettings } from './app-settings.service';
import { IDocumentUpload, DocumentUploadService } from './document-upload.service';
import { MobilePropertyService } from './mobile-property.service';
import { MobileUserService } from './mobile-user.service';
import { ToastService } from './toast.service';

export interface IUploadIMRImageInfo {
  utilityTypeId: number;
  imr: number;
  file: File;
  coords?: number[];
  meter?: Meter;
}

@Injectable()
export class MobileUnitService {
  public get unit$() { return this._unit$.asObservable(); }
  public get unit() { return this._unit$.value; }

  public get propertyUnits$() { return this._propertyUnits$.asObservable(); }
  public get propertyUnits() { return this._propertyUnits$.value; }

  public get loadingUnit$() { return this._loadingUnit$.asObservable(); }
  public get loadingUnit() { return this._loadingUnit$.value; }

  public get loadingPropertyUnits$() { return this._loadingPropertyUnits$.asObservable(); }
  public get loadingPropertyUnits() { return this._loadingPropertyUnits$.value; }

  public rootUrl: string;

  private subscriptions = [];
  private _unit$ = new BehaviorSubject<Unit>(null);
  private _propertyUnits$ = new BehaviorSubject<Unit[]>([]);
  private _loadingPropertyUnits$ = new BehaviorSubject<boolean>(false);
  private _loadingUnit$ = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private appSettings: AppSettingsService,
    private mobilePropertyService: MobilePropertyService,
    private userService: MobileUserService,
    private toast: ToastService,
    private alertCtrl: AlertController,
    private documentUploadService: DocumentUploadService,
  ) {
    this.subscriptions.push(this.appSettings.appSettings$.pipe(
      filter((settings: IAppSettings) => settings.backEnd !== this.rootUrl),
      map((settings: IAppSettings) => settings.backEnd),
    ).subscribe((backend) => {
      this.rootUrl = backend || BackEndHost.Production;
    }));
    this.subscriptions.push(this.mobilePropertyService.property$.subscribe((p) => {
      this.loadPropertyUnits(p ? p._id : null);
    }));
  }

  public cleanupSubscriptions() {
    _.forEach(this.subscriptions, (s) => s.unsubscribe());
  }

  public clearCurrentUnit() {
    this._unit$.next(null);
  }

  public clearPropertyUnits() {
    this._propertyUnits$.next([]);
  }

  public updateIMR(unitId: string, utilityTypeId: number, newImr: number) {
    const body = {
      imr: newImr,
      imrChangedOn: new Date(),
      imrChangedOnPlatform: 'M',
      imrChangedBy: this.userService.user$.value._id,
    };
    return this.http.put(
      `${this.rootUrl}/api/Units/${unitId}/Meters/${utilityTypeId}`, body);
  }

  public getAlertsForUnit(unitId: string): Promise<Array<Alert>> {
    return this.http.get<Array<Alert>>(`${this.rootUrl}/api/Units/${unitId}/OpenAlerts`)
      .pipe(map((alerts) => alerts.map((a) => HydrateAlert(a)))).toPromise();
  }

  public loadPropertyUnits(propertyId: string) {
    if (propertyId) {
      this.http.get<any[]>(`${this.rootUrl}/api/Properties/${propertyId}/Units`).pipe(
        map((units) => units.map((u) => new Unit(u))),
      ).subscribe((units) => {
        this._propertyUnits$.next(units);
      });
    } else {
      this._propertyUnits$.next([]);
    }
  }

  public loadUnit(unitId: string): void {
    this.findById(unitId).subscribe(unit => {
      this._unit$.next(new Unit(unit));
    });
  }

  public findById(unitId: string): Observable<Unit> {
    return this.http.get(`${this.rootUrl}/api/mobile/${unitId}/get-unit`).pipe(map(unit => new Unit(unit)));
  }

  public findFullUnitById(unitId: string): Observable<Unit> {
    return this.http.get(`${this.rootUrl}/api/Units/${unitId}`).pipe(map(unit => new Unit(unit)));
  }

  public create(unitInfo: CreateUnitInfo | CreateUnitInfo[]): Observable<Unit | Unit[]> {
    return this.http.post(`${this.rootUrl}/api/Units`, unitInfo)
      .pipe(
        map((unit) => _.isArray(unit) ? _.map(unit, (u) => new Unit(u)) : new Unit(unit)),
        tap((data: Unit | Unit[]) => _.isArray(data) ? this.updatePropertyStateForUnit(data[0]) : this.updatePropertyStateForUnit(data)),
      );
  }


  public update(unitId: string, unitInfo: SaveUnitInfo): Observable<Unit | void> {
    return this.http.put(`${this.rootUrl}/api/Units/${unitId}`, unitInfo)
      .pipe(
        map(unit => unit ? new Unit(unit) : null),
        tap((unit: Unit) => this.updatePropertyStateForUnit(unit)),
      );
  }

  public delete(unitId: string, propertyId?: string): void {
    this.http.delete(`${this.rootUrl}/api/Units/${unitId}`)
      .subscribe(() => {
        if (propertyId) {
          this.updatePropertyState(propertyId);
        }
      }, err => {
        console.log(err);
      });
  }

  public setMeters(unitId: string, meters: SaveMeterInfo[]): Observable<Unit | null> {
    return this.http.put(`${this.rootUrl}/api/Units/${unitId}/Meters`, meters)
      .pipe(
        map(unit => unit ? new Unit(unit) : null),
        tap((unit: Unit) => this.updatePropertyStateForUnit(unit)),
      );
  }

  public setLeakSensors(unitId: string, leakSensors: LeakSensor[]): Observable<Unit | null> {
    return this.http.put(`${this.rootUrl}/api/Units/${unitId}/LeakSensors`, leakSensors)
      .pipe(
        map((unit) => unit ? new Unit(unit) : null),
        tap((unit: Unit) => this.updatePropertyStateForUnit(unit)),
      );
  }

  public addMeter(unitId: string, meter: SaveMeterInfo): Observable<any> {
    return this.http.post(`${this.rootUrl}/api/Units/${unitId}/Meters`, meter);
  }

  public removeMeter(unitId: string, utilityTypeId: UtilityTypeIds): Observable<any> {
    return this.http.delete(`${this.rootUrl}/api/Units/${unitId}/Meters/${utilityTypeId}`);
  }

  public updateMeter(unitId: string, meter: Meter) {
    return this.http.put(`${this.rootUrl}/api/Units/${unitId}/Meters/${meter.utilityTypeId}`, meter);
  }

  public updateLeakSensor(unitId: string, leakSensor: LeakSensor) {
    return this.http.put(`${this.rootUrl}/api/Units/${unitId}/LeakSensors/${leakSensor.location}`, leakSensor);
  }

  public removeLatestImrImage(unitId: string, utilityTypeId: UtilityTypeIds): Observable<HttpResponse<any>> {
    return this.http.put(`${this.rootUrl}/api/Units/${unitId}/Meters/${utilityTypeId}/RemoveLatestImrImage`, {}, { observe: 'response' });
  }

  public alignUnitReads(unitId: string): Observable<{ modifiedDevices: { [id: number]: boolean }, count: number }> {
    return this.http.put(`${this.rootUrl}/api/Units/${unitId}/ConvertReads`, {}) as Observable<any>;
  }

  public async uploadLeakSensorPhoto(propertyId: string, unitId: string, leakSensor: LeakSensor, file: File): Promise<void> {
    let signedUrl: string | null = null;
    try {
      const res = await this.http.post<{ signedUrl: string, doc: IDocument }>(`${this.rootUrl}/PropertyUploader/${propertyId}/Units/${unitId}/LeakSensors/${leakSensor.location}/Documents/${file.name}`,
        { filename: file.name, type: file.type }).toPromise();
        signedUrl = res.signedUrl;
        const upload: IDocumentUpload = {
        signedUrl,
        id: `${unitId}_${leakSensor.location}`,
        file: file,
        onSuccess: () => {
          return new Promise((resolve) => {
            const leakSensorToUpdate = new LeakSensor({ ...leakSensor });
            leakSensorToUpdate.photo = res.doc;
            this.updateLeakSensor(unitId, leakSensorToUpdate).subscribe(
              () => {
                this.showToast(`${leakSensorToUpdate.location || ''} Leak Sensor image finished uploading!`);
                resolve();
              },
              () => {
                this.showAlert(`${leakSensorToUpdate.location || ''} Leak Sensor image failed to upload.`, 'Upload Failed');
                resolve();
              },
            );
          });
        },
      };
      this.documentUploadService.enqueueUpload(upload);
    } catch (e) {
      this.showAlert(`${leakSensor.location} Leak Sensor image failed to upload.`, 'Upload Failed');
    }
    return Promise.race([
      signedUrl
        ? this.documentUploadService.uploadFinished$.pipe(
          filter(upload => upload && upload.signedUrl === signedUrl),
          take(1),
          mapTo(undefined)).toPromise()
        : Promise.resolve(),
      new Promise(resolve => setTimeout(() => resolve(undefined), 10 * 1000)),
    ]);
  }

  public async uploadIMRDoc(propertyId: string, unitId: string, info: IUploadIMRImageInfo): Promise<void> {
    let signedUrl: string | null = null;
    try {
      const res = await this.http.post<{ signedUrl: string, doc: IDocument }>(`${this.rootUrl}/PropertyUploader/${propertyId}/Units/${unitId}/Meters/${info.utilityTypeId}/Documents/${info.file.name}`,
        { filename: info.file.name, type: info.file.type }).toPromise();
      signedUrl = res.signedUrl;
      const upload: IDocumentUpload = {
        signedUrl,
        id: `${unitId}_${info.utilityTypeId}`,
        file: info.file,
        onSuccess: () => {
          return new Promise((resolve) => {
            const meter = new Meter({ ...info.meter });
            if (!meter.imrImages) {
              meter.imrImages = [];
            } else if (!_.isArray(meter.imrImages)) {
              const imgs = [];
              for (const img of Object.values(meter.imrImages)) {
                imgs.push(img);
              }
              meter.imrImages = imgs;
            }
            meter.imrImages.push({ ...res.doc, imr: meter.imr });
            this.updateMeter(unitId, meter).subscribe(
              () => {
                this.showToast(`Meter IMR image finished uploading!`);
                resolve();
              },
              () => {
                this.showAlert(`Meter IMR image failed to upload.`, 'Upload Failed');
                resolve();
              },
            );
          });
        },
      };
      this.documentUploadService.enqueueUpload(upload);
    } catch (e) {
      this.showAlert(`Meter IMR image failed to upload.`, 'Upload Failed');
    }
    return Promise.race([
      signedUrl
        ? this.documentUploadService.uploadFinished$.pipe(
          filter(upload => upload && upload.signedUrl === signedUrl),
          take(1),
          mapTo(undefined)).toPromise()
        : Promise.resolve(),
      new Promise(resolve => setTimeout(() => resolve(undefined), 10 * 1000)),
    ]);
  }

  public meterInfoStats(propId: string): Observable<IMeterInfoStats> {
    return this.http.get<IMeterInfoStats>(`${this.rootUrl}/api/mobile/property-meter-stats/${propId}`);
  }

  private showToast(message: string) {
    this.toast.queueToast(message);
  }

  private showAlert(message: string, header: string) {
    this.alertCtrl.create({
      message,
      header,
      buttons: ['Ok'],
    }).then((a) => a.present());
  }

  private updatePropertyStateForUnit(unit: Unit): void {
    if (unit && unit.property) {
      this.updatePropertyState(unit.property.id);
    }
  }

  public updateUnitDescription(unitId: string, description: string, platform: string): Observable<Unit> {
    return this.http.put(`${this.rootUrl}/api/Units/${unitId}/Description`, { description, platform }).pipe(
      map((unit) => unit ? new Unit(unit) : null),
    );
  }

  private updatePropertyState(propertyId?: string): void {
    if (propertyId) {
      this.mobilePropertyService.loadProperty(propertyId);
    }
  }

  public getReadData(unitId, startDate: Date, endDate: Date) {
    return this.http.get(`${this.rootUrl}/api/Units/${unitId}/DailyReads?from=${startDate.toISOString()}` +
      `&to=${endDate.toISOString()}`);
  }

  public applyMeterInfo(propertyId: string, oldMeterInfo: IMeterInfo, newMeterInfo: IMeterInfo, utilityTypeId: UtilityTypeIds)
    : Observable<any> {
    return this.http.post(`${this.rootUrl}/api/Properties/${propertyId}/ApplyMeterInfo${utilityTypeId ? '?utilityTypeId=' + utilityTypeId : ''}`, { oldMeterInfo, newMeterInfo });
  }

  public sortMeterInfoForUnit(meterInfo: IMeterInfoStat[], utilityTypeId: UtilityTypeIds) {
    return _.sortBy(meterInfo, (m: IMeterInfoStat) => {
      return m.utilityTypeId === utilityTypeId ? 0 : 1;
    }, (m: IMeterInfoStat) => {
      return 0 - m.count;
    });
  }
}
