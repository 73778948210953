import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { ImageCropperModule } from '../image-cropper/image-cropper.module';
import { ModalHeaderModule } from '../modal-header/modal-header.module';
import { PhotoViewerComponent } from './photo-viewer.component';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    ModalHeaderModule,
    ImageCropperModule,
  ],
  exports: [PhotoViewerComponent],
  entryComponents: [PhotoViewerComponent],
  declarations: [PhotoViewerComponent],
  providers: [],
})
export class PhotoViewerModule { }
