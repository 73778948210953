import { User } from '@ncss/models';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, Injectable, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { BLE } from '@ionic-native/ble/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { DeviceFeedback } from '@ionic-native/device-feedback/ngx';
import { Device } from '@ionic-native/device/ngx';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
import { Flashlight } from '@ionic-native/flashlight/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { LaunchNavigator } from '@ionic-native/launch-navigator/ngx';
import { NativeAudio } from '@ionic-native/native-audio/ngx';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { Network } from '@ionic-native/network/ngx';
import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx';
import { SecureStorage } from '@ionic-native/secure-storage/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TapticEngine } from '@ionic-native/taptic-engine/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import * as Sentry from '@sentry/browser';

import { DirectConnectModalModule } from '../components/direct-connect-modals/direct-connect-modal.module';
import { DirectConnectPopoverModule } from '../components/direct-connect-popover/direct-connect-popover.module';
import { NumericKeyboardModule } from '../components/numeric-input/numeric-keyboard/numeric-keyboard.module';
import { SerialKeyboardModule } from '../components/serial-number-input/serial-keyboard/serial-keyboard.module';
import { environment } from '../environments/environment';
import { AppSettingsService } from '../services/app-settings.service';
import { BarcodeScannerService } from '../services/barcode-scanner.service';
import { CameraService } from '../services/camera.service';
import { ConfirmationService } from '../services/confirmation.service';
import { ConnectionService } from '../services/connection.service';
import { DirectConnectService } from '../services/direct-connect/direct-connect.service';
import { FeedbackService } from '../services/feedback.service';
import { GpsLocationService } from '../services/gps-location.service';
import { MobilePropertyService } from '../services/mobile-property.service';
import { MobileUnitService } from '../services/mobile-unit.service';
import { MobileUserService } from '../services/mobile-user.service';
import { PropertyNavService } from '../services/property-nav.service';
import { UpdateAccountModule } from './../components/update-account/update-account.module';
import { DocumentUploadService } from './../services/document-upload.service';
import { MobileAuthHttpInterceptor } from './../services/interceptors/auth-http-interceptor';
import { TimeoutInterceptor } from './../services/interceptors/timeout-interceptor';
import { MobileLiveDeviceService } from './../services/test-connect/live-device.service';
import { ToastService } from './../services/toast.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuModule } from './menu/menu.module';
import { UnitDetailsModule } from './property/equipment/unit-details/unit-details.module';

if (environment.production) {
  Sentry.init({
    dsn: 'https://f6b298d2049d4ed8aa29487c6986939f@sentry.io/1471812',
  });
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {

  private userContextId: string;

  constructor(private mobileUserService: MobileUserService, private settings: AppSettingsService) {
    this.mobileUserService.user$.subscribe((user) => this.setUserContext(user as any));
    Sentry.configureScope((scope) => {
      scope.setTag('app-version', this.settings.appVersion);
    });
  }

  public handleError(error) {
    console.error(error);
    Sentry.captureException(error.originalError || error);
  }

  public setUserContext(user?: User) {
    if (!user) {
      this.userContextId = null;
      Sentry.configureScope((scope) => {
        scope.setUser({ id: null });
      });
    } else if (this.userContextId !== user._id) {
      this.userContextId = user._id;
      Sentry.configureScope((scope) => {
        scope.setUser({ id: user._id });
      });
    }
  }
}


@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    MenuModule,
    DirectConnectPopoverModule,
    NumericKeyboardModule,
    SerialKeyboardModule,
    DirectConnectModalModule,
    UnitDetailsModule,

    UpdateAccountModule,
    HttpClientModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: MobileAuthHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
    StatusBar,
    SplashScreen,
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AppSettingsService,
    DocumentUploadService,
    ToastService,
    ConnectionService,
    CameraService,
    Camera,
    TapticEngine,
    DeviceFeedback,
    LaunchNavigator,
    Clipboard,
    Device,
    NativeAudio,
    NativeStorage,
    Diagnostic,
    OpenNativeSettings,
    FingerprintAIO,
    SecureStorage,
    Flashlight,
    BLE,
    Network,
    Geolocation,
    PropertyNavService,
    DirectConnectService,
    BarcodeScannerService,
    ConfirmationService,
    GpsLocationService,
    FeedbackService,
    InAppBrowser,
    BarcodeScanner,
    Keyboard,
    Deeplinks,
    MobileUserService,
    MobilePropertyService,
    MobileUnitService,
    MobileLiveDeviceService,
    MobileUnitService,
  ],
  bootstrap: [
    AppComponent,
  ],
})
export class AppModule { }
