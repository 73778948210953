import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Network } from '@ionic-native/network/ngx';
import { AlertController } from '@ionic/angular';
import * as _ from 'lodash';
import { BehaviorSubject, merge, of, Subject, Observable } from 'rxjs';
import { map, throttleTime } from 'rxjs/operators';

import { DiagnosticDB } from '../app/diagnostic-DB';

const offlineStr = 'Offline';
const onlineStr = 'Online';

const colorRed = '#E3515F';
const colorYellow = '#cebf10';
const colorBlue = '#3E89BF';

export enum ConnectionStatus {
  UNKNOWN,
  OFFLINE,
  SLOW,
  ONLINE,
}

export interface IConnectionInfo {
  status: ConnectionStatus;
  color: string;
  label: string;
}

const ConnectionInfoMap: { [status: string]: IConnectionInfo } = {};
ConnectionInfoMap[ConnectionStatus.UNKNOWN] = {
  status: ConnectionStatus.UNKNOWN,
  color: colorRed,
  label: offlineStr,
};
ConnectionInfoMap[ConnectionStatus.OFFLINE] = {
  status: ConnectionStatus.OFFLINE,
  color: colorRed,
  label: offlineStr,
};
ConnectionInfoMap[ConnectionStatus.SLOW] = {
  status: ConnectionStatus.SLOW,
  color: colorYellow,
  label: onlineStr,
};
ConnectionInfoMap[ConnectionStatus.ONLINE] = {
  status: ConnectionStatus.ONLINE,
  color: colorBlue,
  label: onlineStr,
};

@Injectable()
export class ConnectionService {

  public connection$ = new BehaviorSubject<IConnectionInfo>(ConnectionInfoMap[ConnectionStatus.UNKNOWN]);
  public slowRequest$ = new Subject();
  public timedOutRequest$ = new Subject();
  public onTimeRequest$ = new Subject();

  private _connectionStatus: ConnectionStatus;
  private SLOW_WARN_INTERVAL = 4000;
  private TIMEOUT_INTERVAL = 14000;
  private ONLINE_INTERVAL = 7000;
  private _db: DiagnosticDB;
  private _connectionStatusChange$: Observable<ConnectionStatus>;

  constructor(
    private network: Network,
    private alertCtrl: AlertController,
  ) {
  }

  public init() {
    this._db = new DiagnosticDB();
    console.log(`%c ConnectionService.init()`, 'color: cyan');
    this._connectionStatusChange$ = merge(
      of(navigator.onLine).pipe(map((value) => value ? ConnectionStatus.ONLINE : ConnectionStatus.OFFLINE)),
      this.network.onConnect().pipe(map(() => ConnectionStatus.ONLINE)),
      this.network.onDisconnect().pipe(map(() => ConnectionStatus.OFFLINE)),
    );

    this._connectionStatusChange$.subscribe((online) => {
      this.nextConnectionStatus(online);
    });

    this.slowRequest$.pipe(throttleTime(this.SLOW_WARN_INTERVAL)).subscribe((req: HttpRequest<any>) => {
      const connectionInfo = {
        connectionType: this.network.type,
        downlinkMax: this.network.downlinkMax,
      };
      this.saveProblematicRequest(req, 'slow', connectionInfo);
      this.nextConnectionStatus(ConnectionStatus.SLOW);
    });

    this.timedOutRequest$.pipe(throttleTime(this.TIMEOUT_INTERVAL)).subscribe((req: HttpRequest<any>) => {
      const connectionInfo = {
        connectionType: this.network.type,
        downlinkMax: this.network.downlinkMax,
      };
      this.saveProblematicRequest(req, 'timeout', connectionInfo);
      this.nextConnectionStatus(ConnectionStatus.OFFLINE);
    });

    this.onTimeRequest$.pipe(throttleTime(this.ONLINE_INTERVAL)).subscribe(() => {
      this.nextConnectionStatus(ConnectionStatus.ONLINE);
    });
  }

  private nextConnectionStatus(status: ConnectionStatus) {
    // If we are already offline and are not going back online then we'll just stay offline
    if (this._connectionStatus === ConnectionStatus.OFFLINE && status !== ConnectionStatus.ONLINE) {
      status = ConnectionStatus.OFFLINE;
    }

    if (this._connectionStatus !== ConnectionStatus.SLOW && status === ConnectionStatus.SLOW) {
      this.showSlowAlert();
    }

    if (this._connectionStatus !== ConnectionStatus.OFFLINE && status === ConnectionStatus.OFFLINE) {
      this.showTimeoutAlert();
    }

    this.connection$.next(ConnectionInfoMap[status]);
    this._connectionStatus = status;
  }

  private showSlowAlert() {
    this.alertCtrl.getTop().then((top) => {
      if (!top) {
        this.alertCtrl.create(
          {
            header: 'Poor Internet Connection',
            message: 'We\'ve detected a poor connection to the NextCentury servers. This may result in slowness.',
            buttons: ['OK'],
          }).then((a) => a.present());
      }
    });
  }

  private showTimeoutAlert() {
    this.alertCtrl.getTop().then((top) => {
      if (!top) {
        this.alertCtrl.create(
          {
            header: 'Disconnected',
            message: 'We are unable to connect to the NextCentury servers at this time.',
            buttons: ['OK'],
          }).then((a) => a.present());
      }
    });
  }

  private saveProblematicRequest(
    request: HttpRequest<any>,
    type: 'slow' | 'timeout',
    connectionInfo: { connectionType: string, downlinkMax: string }) {
    const problemRequest = {
      url: request.urlWithParams,
      type,
      date: new Date(),
      connectionType: connectionInfo.connectionType,
      downlinkMax: connectionInfo.downlinkMax,
    };
    this._db.setProblemRequest(problemRequest);
  }
}
