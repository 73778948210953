import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { NumericKeyboardComponent } from './numeric-keyboard.component';

@NgModule({
declarations: [NumericKeyboardComponent],
imports: [IonicModule, CommonModule],
exports: [NumericKeyboardComponent],
})
export class NumericKeyboardModule {}
