import { MeterManufacturer } from '@ncss/models';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppSettingsService, BackEndHost } from './app-settings.service';

@Injectable({
  providedIn: 'root',
})
export class MobileMeterManufacturerService {
  private rootUrl: BackEndHost;

  constructor(
    private http: HttpClient,
    private appSettings: AppSettingsService,
  ) {
    this.rootUrl = this.appSettings.appSettings.backEnd;
  }

  public findAll(options?: { utilityFamily: number }): Observable<MeterManufacturer[]> {
    if (options) {
      let p = new HttpParams();
      p = p.set('utilityFamily', options.utilityFamily + '');
      return this.http.get(`${this.rootUrl}/api/MeterManufacturers`, { params: p })
        .pipe(
          map((manufacturers: any) => manufacturers.map((mm) => new MeterManufacturer(mm))),
        );
    }

    return this.http.get(`${this.rootUrl}/api/MeterManufacturers`)
      .pipe(
        map((manufacturers: any) => manufacturers.map((mm) => new MeterManufacturer(mm))),
      );
  }

  public findById(id: number) {
    return this.http.get(`${this.rootUrl}/api/MeterManufacturers/` + id).toPromise().then(res => {
      return res ? new MeterManufacturer(res) : null;
    });
  }

}
