import {
  ConversionUtils,
  IMeterInfoStats,
  MeterType,
  UtilityFamily,
  UtilityTypeIds,
  UtilityTypes,
  UomTypeIds,
  Unit,
  MeterStatsHelper,
  UomTypeHelper,
} from '@ncss/models';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppSettingsService, BackEndHost } from './app-settings.service';

export interface IMeterTypeSearchOptions {
  utilityFamily?: number;
  manufacturerId?: number;
}

export interface PartialMeterStat {
  meterTypeId?: number;
  meterModelNumber?: string;
  meterManufacturerId?: number;
  meterManufacturerName?: string;
  uomTypeId?: UomTypeIds;
  meterSize?: string;
  multiplier?: number;
}

@Injectable({
  providedIn: 'root',
})
export class MobileMeterTypeService {

  private rootUrl: BackEndHost;

  constructor(
    private http: HttpClient,
    private appSettings: AppSettingsService,
  ) {
    this.rootUrl = this.appSettings.appSettings.backEnd;
  }

  public findById(id: number): Observable<MeterType> {
    return this.http.get(`${this.rootUrl}/api/MeterTypes/${id}`)
      .pipe(
        map((meterType) => new MeterType(meterType)),
      );
  }

  public async getGenericTypeForUtility(utilityFamily: UtilityFamily): Promise<MeterType> {
    const res = await this.search('Generic', { utilityFamily });
    return (res && res[0]) ? res[0] : null;
  }

  public getMeterStatsForPropertyUnits(units: Unit[]): IMeterInfoStats {
    return MeterStatsHelper.computeMeterStats(units);
  }

  public async getMostUsedMeterType(units: Unit[], utilityTypeId: UtilityTypeIds): Promise<PartialMeterStat> {
    const family = UtilityTypes[utilityTypeId] ? UtilityTypes[utilityTypeId].family : null;
    if (!family && family !== 0) { return null; }
    const dict = this.getMeterStatsForPropertyUnits(units);
    let stats = (dict[family] || []).filter((s) => s.utilityTypeId === utilityTypeId);
    stats = stats.length ? stats : (dict[family] || []);
    if (!stats.length) {
      const generic = await this.getGenericTypeForUtility(family);
      return generic ? {
        meterManufacturerId: generic.manufacturerId,
        meterManufacturerName: generic.manufacturerName,
        meterModelNumber: generic.modelNumber,
        uomTypeId: generic.consumptionMeasurementOptions ?
          generic.consumptionMeasurementOptions[0].uom : UomTypeHelper.GetUomForUtilityTypeId(utilityTypeId)[0].id,
        multiplier: generic.consumptionMeasurementOptions ? generic.consumptionMeasurementOptions[0].multiplier : 1,
        meterTypeId: generic._id,
      } : null;
    }
    return _.maxBy(stats, 'count').meterInfo;
  }

  public findByManufacturerId(id: number, utilityFamily?: UtilityFamily): Promise<MeterType[]> {
    let req = this.http.get<MeterType[]>(`${this.rootUrl}/api/MeterTypes/Manufacturer/${id}`)
      .pipe(map((types) => types.map((t) => new MeterType(t))));
    if (utilityFamily || utilityFamily === UtilityFamily.WATER) {
      let p = new HttpParams();
      p = p.set('utilityFamily', utilityFamily.toString());
      req = this.http.get<MeterType[]>(`${this.rootUrl}/api/MeterTypes/Manufacturer/${id}`, { params: p });
    }
    return req.toPromise();
  }

  public findAll(): Promise<MeterType[]> {
    return this.http.get<MeterType[]>(`${this.rootUrl}/api/MeterTypes`)
      .pipe(
        map((types) => types.map((t) => new MeterType(t))),
      ).toPromise();
  }

  public search(query: string, options?: IMeterTypeSearchOptions): Promise<MeterType[]> {
    query = ConversionUtils.ConvertStringToEncoded(query);
    let req = this.http.get(`${this.rootUrl}/api/SearchMeterTypes/${query}`);
    if (options) {
      let p = new HttpParams();
      Object.keys(options).forEach((key) => {
        p = p.set(key, options[key]);
      });
      req = this.http.get(`${this.rootUrl}/api/SearchMeterTypes/${query}`, { params: p });
    }

    return req.pipe(
      map((results: any[]) => _.map(results, (result) => new MeterType(result))),
    ).toPromise();
  }
}
