import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { Events } from '@ionic/angular';
import * as _ from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';

import { NumericKeyboardEventType } from './numeric-keyboard/numeric-keyboard.component';


export enum NumericInputEventType {
  CLEAR = 'CLEAR',
}

export enum NumericInputStatus {
  BLANK,
  INVALID,
  VALID,
}

export interface INumericInputInfo {
  number: number;
  numberString: string;
  status: NumericInputStatus;
}

export type DoneAction = 'done' | 'blur' | 'backdrop';
export type NumericInputValidationType = 'IMR' | 'REMOTE_READER_IMR' | 'MULTIPLIER' | 'IMR_NO_DECIMAL';

@Component({
  selector: 'app-numeric-input',
  templateUrl: 'numeric-input.component.html',
  styleUrls: ['numeric-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumericInputComponent),
      multi: true,
    },
  ],
})
export class NumericInputComponent implements OnInit, OnDestroy, ControlValueAccessor {

  @Input() public id: string;
  @Input() public placeholder = 'Enter Number';
  @Input() public large = false;
  @Input() public disabled = false;
  @Input() public initialValue?: number;
  @Input() public autoFocus = false;
  @Input() public validation: NumericInputValidationType;
  @Input() public justify = 'left';
  @Output() public numericInfo = new BehaviorSubject<INumericInputInfo>(null);
  @Output() public done = new Subject<{ status: DoneAction, value?: number, numberString?: string }>();
  public number = '';
  public status: NumericInputStatus;
  public isFocus: boolean;
  public NumericInputStatus: NumericInputStatus; // For HTML
  private subscriptions = [];
  private propagateChange = (val: any) => { };
  private propagateTouch = () => { };

  constructor(
    private events: Events,
    private cd: ChangeDetectorRef,
    private keyboard: Keyboard,
  ) { }

  public ngOnInit() {
    window.addEventListener('keyboardWillShow', () => {
      this.isFocus = false;
      this.events.publish(NumericKeyboardEventType.HIDE);
      this.done.next();
    });
    this.subscriptions.push(this.events.subscribe(NumericKeyboardEventType.VALUE_KEY, (c: string) => {
      if (!this.isFocus) {
        return;
      }

      if (!this.number) {
        this.number = c;
      } else if (+(this.number + c).replace(/,/g, '') <= 0xFFFFFFFF && (c !== '.' || (c === '.' && this.number.indexOf('.') < 0))) {
        this.number += c;
      }

      this.checkNumber();
    }));

    this.subscriptions.push(this.events.subscribe(NumericKeyboardEventType.CMD_KEY, (cmd) => {
      if (!this.isFocus) {
        return;
      }

      if (cmd === 'Backspace') {
        this.number = this.number ? this.number.substring(0, this.number.length - 1) : '';
        this.checkNumber();
      } else if (cmd === 'Close') {
        this.isFocus = false;
        this.events.publish(NumericKeyboardEventType.HIDE);
        this.done.next({ status: 'done', value: +this.number.replace(/,/g, ''), numberString: this.number.replace(/,/g, '') });
      } else if (cmd === 'Blur') {
        this.isFocus = false;
        this.events.publish(NumericKeyboardEventType.HIDE);
        this.done.next({ status: 'blur', value: +this.number.replace(/,/g, ''), numberString: this.number.replace(/,/g, '') });
      }
    }));

    this.subscriptions.push(this.events.subscribe(NumericInputEventType.CLEAR, () => {
      this.isFocus = false;
      this.number = '';
      this.checkNumber();
    }));
    this.writeValue(this.initialValue);
    if (this.autoFocus) {
      setTimeout(() => this.onClick(), 100);
    }
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((s) => { if (s) { s.unsubscribe(); } });
  }

  public onClick() {
    // We have a timeout to avoid a race condition when switching between two
    // numeric-input fields where the keyboard would not show
    setTimeout(() => {
      this.isFocus = true;
      this.checkNumber();
      this.keyboard.hide();
      this.events.publish(NumericKeyboardEventType.SHOW, this.numericInfo.value);
    });
  }

  public writeValue(val: any) {
    this.number = (val || val === 0) ? val.toString() : '';
    this.checkNumber({ propagateChange: false });
    this.cd.markForCheck();
  }

  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any) {
    this.propagateTouch = fn;
  }

  public setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  public clickOutside($event) {
    if (this.isFocus) {
      this.events.publish(NumericKeyboardEventType.OUTSIDE_TAP, $event);
      if (this.propagateTouch) {
        this.propagateTouch();
      }
      this.cd.markForCheck();
    }
  }

  private checkNumber(options = { propagateChange: true }) {
    if (!this.number) {
      this.status = NumericInputStatus.BLANK;
    } else {
      this.status = NumericInputStatus.VALID;
    }
    this.number = this.number.replace(/,/g, '');

    if (this.validation === 'IMR_NO_DECIMAL' && this.number.includes('.')) {
      this.number = this.number.replace(/\./g, '');
    } else if (this.validation === 'IMR' && this.number.includes('.') && this.number.split('.')[1].length > 2) {
      const numString = this.number.split('.');
      this.number = numString[0] + '.' + numString[1].substr(0, 2);
    } else if (this.validation === 'REMOTE_READER_IMR' && this.number.includes('.') && this.number.split('.')[1].length > 1) {
      const numString = this.number.split('.');
      this.number = numString[0] + '.' + numString[1].substr(0, 1);
    } else if (this.validation === 'MULTIPLIER') {
      let numStr = this.number;
      if (numStr.includes('.')) {
        numStr = _.trimEnd(numStr, '0');
      }
      numStr = _.replace(this.number, /\./gi, '');
      if (numStr.length > 6) {
        this.number = this.number.substr(0, this.number.length - 1);
      }
    }

    if (this.number !== '0' && !_.startsWith(this.number, '0.')) {
      this.number = _.trimStart(this.number, '0');
    }

    const num: number = +this.number;
    this.number = this.addCommas(this.number);

    this.numericInfo.next({
      number: num,
      numberString: this.number,
      status: this.status,
    });
    if (options.propagateChange) {
      this.propagateChange(num);
      document.getElementById('numericKeyboard').innerText = this.number;
    }
  }

  public addCommas(val: string) {
    let newValue = val.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'); // Add commas
    if (newValue.includes('.')) {
      const newValueArray = newValue.split('.');
      newValue = newValueArray[0] + '.' + newValueArray[1].replace(/,/g, ''); // remove commas in decimals
    }
    return newValue;
  }
}
