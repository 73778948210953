import {
  MeterConfig,
  UtilityTypesOrderedList,
  UtilityTypeIds,
  UomTypeHelper,
  DeviceTypeIds,
  UtilityTypes,
} from '@ncss/models';

import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnInit, Input, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { ModalController, IonToggle, AlertController, ActionSheetController, LoadingController } from '@ionic/angular';
import * as _ from 'lodash';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, pairwise, filter, map, distinctUntilChanged, take, timeout, catchError, mapTo } from 'rxjs/operators';

import { RemoteReaderModalTabs } from '../remote-reader-modal/remote-reader-modal.component';
import { MobileDevicesService } from './../../../services/devices/mobile-devices.service';
import { IDirectConnectDevice } from './../../../services/direct-connect/baseDirectConnectDevice';
import { IOpenAlert, MeterConfigLabels } from './../../../services/direct-connect/remoteReader/remoteReader';
import { DirectConnectRR4 } from './../../../services/direct-connect/RR4/RR4';
import { FeedbackService, FeedbackType } from './../../../services/feedback.service';
import { MobileUnitService } from './../../../services/mobile-unit.service';
import { MobileUserService } from './../../../services/mobile-user.service';
import { ToastService } from './../../../services/toast.service';
import { NumericInputComponent } from './../../numeric-input/numeric-input.component';
import { ManageConfigurationsComponent } from './../remote-reader-modal/manage-configurations/manage-configurations.component';
import { QuickConfigurationService } from './../remote-reader-modal/quickConfiguration.service';


const defaultTabs = [
  { name: 'Status', value: RemoteReaderModalTabs.STATUS, icon: 'icon-remote-reader' },
  { name: 'Wiring Guide', value: RemoteReaderModalTabs.WIRING, icon: 'icon-meter' },
];
@Component({
  selector: 'app-rr4-modal',
  templateUrl: './rr4-modal.component.html',
  styleUrls: ['./rr4-modal.component.scss'],
  animations: [
    trigger('fadeInItem', [
      transition(':enter', [
        style({ height: '0px' }),
        animate('200ms ease-out', style({ height: '44px' })),
      ]),
      transition(':leave', [
        animate('200ms ease-out', style({ height: '0px' })),
      ]),
    ]),
  ],
})
export class Rr4ModalComponent implements OnInit, OnDestroy, AfterViewInit {
  public MeterConfig = MeterConfig;
  public RemoteReaderModalTabs = RemoteReaderModalTabs;
  public DeviceTypeIds = DeviceTypeIds;
  public PortConfigurations = [
    { key: MeterConfig.PORT_DISABLED, label: MeterConfigLabels[MeterConfig.PORT_DISABLED] },
    { key: MeterConfig.PULSE_IN, label: MeterConfigLabels[MeterConfig.PULSE_IN] },
    { key: MeterConfig.ENCODER_IN, label: MeterConfigLabels[MeterConfig.ENCODER_IN] },
  ];

  @ViewChild('pulseOutToggle') public pulseOutToggle: IonToggle;
  @ViewChild('lcdAlwaysOnToggle') public lcdAlwaysOnToggle: IonToggle;
  @ViewChild('showTamperToggle') public showTamperToggle: IonToggle;
  @ViewChild('rapidCheckInToggle') public rapidCheckInToggle: IonToggle;
  @ViewChild('resetMeter1PulseCount') public resetPulseToggleOne: IonToggle;
  @ViewChild('resetMeter2PulseCount') public resetPulseToggleTwo: IonToggle;
  @Input() connectedDevice: IDirectConnectDevice;

  public selectedTab = RemoteReaderModalTabs.STATUS;
  public tabs: Array<{
    name: string,
    value: RemoteReaderModalTabs,
    icon: string,
    disabledText?: string,
    disabled$?: Observable<boolean>,
  }> = defaultTabs;
  public rr4: DirectConnectRR4;
  public showMoreStatus = false;
  public showAlerts = false;
  public showMoreMeter1 = false;
  public showMoreMeter2 = false;
  public isManufacturingUser = false;

  public meter1UtilityImage$: Observable<string>;
  public meter2UtilityImage$: Observable<string>;

  private _subscriptions = [];
  private _loadIndicator: HTMLIonLoadingElement;

  constructor(
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private toast: ToastService,
    private haptic: FeedbackService,
    private quickConfigService: QuickConfigurationService,
    private actionSheetCtrl: ActionSheetController,
    private userService: MobileUserService,
    private devicesService: MobileDevicesService,
    private unitService: MobileUnitService,
    private loadCtrl: LoadingController,
  ) { }

  ngOnInit() {
    if (!this.connectedDevice) { return; }
    this.rr4 = this.connectedDevice.device as DirectConnectRR4;
    this.initialize();
  }

  ngAfterViewInit() {
    this.setUpSubscriptions();
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  private initialize() {
    this.tabs = defaultTabs;
    this.meter1UtilityImage$ = this.rr4.meter1Info$.pipe(
      map((info) => info.utilityTypeId),
      distinctUntilChanged(),
      map((id) => `assets/img/equipment/meter/meter-plain-${id || 1}.png`),
    );
    this.meter2UtilityImage$ = this.rr4.meter2Info$.pipe(
      map((info) => info.utilityTypeId),
      distinctUntilChanged(),
      map((id) => `assets/img/equipment/meter/meter-plain-${id || 1}.png`),
    );
    setTimeout(() => {
      if (this.rr4 && !this.rr4.deviceInfo) {
        this.rr4.requestInfo();
      }
    }, 1000);

    this.fetchUnit();
    if (this.rr4 && this.rr4.isTRDevice()) {
      this.tabs = [
        ...this.tabs,
        {
          name: 'Program',
          value: RemoteReaderModalTabs.PROGRAM,
          icon: 'icon-property',
          disabled$: this.rr4.hasUserChanges$,
          disabledText: 'Please save the pending configurations before programming the Remote Reader.',
        },
      ];
    }
  }

  private setUpSubscriptions() {
    this._subscriptions.forEach((s) => s.unsubscribe());
    this._subscriptions = [];
    this._subscriptions.push(
      combineLatest([this.rr4.meter1Info$, this.rr4.meter2Info$]).pipe(debounceTime(50)).subscribe(([meter1, meter2]) => {
        setTimeout(() => {
          if (this.resetPulseToggleOne) {
            this.resetPulseToggleOne.checked = meter1.resetPulseCount || false;
          }
          if (this.resetPulseToggleTwo) {
            this.resetPulseToggleTwo.checked = meter2.resetPulseCount || false;
          }
        }, 0);
      }),
      this.rr4.deviceInfo$.subscribe((deviceInfo) => {
        setTimeout(() => {
          if (this.lcdAlwaysOnToggle) {
            this.lcdAlwaysOnToggle.checked = deviceInfo.lcdAlwaysOn || false;
          }
          if (this.rapidCheckInToggle) {
            this.rapidCheckInToggle.checked = deviceInfo.rapidCheckInEnabled || false;
          }
          if (this.pulseOutToggle) {
            this.pulseOutToggle.checked = deviceInfo.pulseOut || false;
          }
          if (this.showTamperToggle) {
            this.showTamperToggle.checked = deviceInfo.showTamperAlerts || false;
          }
        }, 0);
      }),
      this.userService.user$.subscribe((u) => {
        this.isManufacturingUser = (u && u['manufacturingUser']) || false;
      }),
    );
  }

  public async quickActionsClicked() {
    const configurations = await this.quickConfigService.get('1');
    const sheet = await this.actionSheetCtrl.create({
      header: 'Quick Actions',
      buttons: _.concat(_.orderBy(configurations, ['lastUsedAt', 'createdAt'], ['asc'])
        .map((config) => {
          return {
            text: `Apply "${config.name}"`,
            handler: () => {
              this.rr4.applyConfiguration(config.changes);
              this.quickConfigService.save('1', { ...config, lastUsedAt: new Date() });
            },
          };
        }).slice(0, 5),
        [{
          text: 'Save As New Configuration',
          handler: () => {
            this.getConfigurationNameFromUser().then((name: string) => {
              if (name) {
                this.quickConfigService.save('1', { name, changes: this.rr4.getConfiguration() });
              }
            });
          },
        }],
        configurations.length ? [{
          text: 'Manage Configurations',
          handler: () => this.modalCtrl.create({ component: ManageConfigurationsComponent }).then((modal) => modal.present()),
        }] : [],
        [{
          text: 'Cancel',
          role: 'cancel',
        } as any],
      ),
    });
    await sheet.present();
  }

  public async headerClicked(event) {
    if (event === 'Save') {
      if (this.rr4.isDisconnected && this.rr4.attemptReconnect) {
        const res = await this.rr4.attemptReconnect();
        if (res) {
          this.onReconnected(res);
        }
      } else {
        this.save();
      }
    } else {
      this.modalCtrl.dismiss();
    }
  }

  public trackAlertsBy(alert: IOpenAlert) {
    return alert.typeStr;
  }

  public alertClicked(alert: IOpenAlert) {
    if (alert && alert.typeStr === 'Tamper') {
      this.rr4.clearTamper(!this.rr4.deviceInfo.clearTamper);
    }
  }

  public lcdAlwaysOnClicked(checked: boolean) {
    this.rr4.updateLCDAlwaysOn(checked);
  }

  public showTamperAlertsClicked(checked: boolean) {
    this.rr4.updateShowTamperAlerts(checked);
  }

  public rapidCheckInClicked(checked: boolean) {
    this.rr4.updateRapidCheckInEnabled(checked);
  }

  public resetPulseCountClicked(port: 1 | 2, checked: boolean) {
    this.rr4.resetPulseCount(checked, port);
  }

  public pulseOutClicked(checked: boolean) {
    this.rr4.updatePulseOut(checked);
  }

  public async onConfigTypeClicked(port: 1 | 2) {
    const current = port === 2 ? this.rr4.meter2Info.configType : this.rr4.meter1Info.configType;
    const options = port === 2 ? this.PortConfigurations : this.PortConfigurations.filter((c) => c.key !== MeterConfig.PORT_DISABLED);
    const alert = await this.alertCtrl.create({
      header: 'Select Config Type',
      inputs: options.map((config) => {
        return {
          type: 'radio',
          label: config.label,
          value: config.key,
          checked: config.key === current,
        };
      }) as any,
      buttons: [
        { text: 'Cancel', role: 'cancel' },
        {
          text: 'Ok', handler: (selected) => {
            if (selected !== current) {
              this.rr4.updateConfigType(selected, port);
            }
          },
        },
      ],
    });
    await alert.present();
  }

  public async onUtilityTypeClicked(port: 1 | 2) {
    let selected: UtilityTypeIds;
    if (port === 2) {
      selected = this.rr4.meter2Info ? this.rr4.meter2Info.utilityTypeId : null;
    } else {
      selected = this.rr4.meter1Info ? this.rr4.meter1Info.utilityTypeId : null;
    }
    const availableOptions = this.rr4.getAvailableUtilities(port);
    const alert = await this.alertCtrl.create({
      header: 'Select Utility Type',
      inputs: UtilityTypesOrderedList.map((u) => {
        const notAvailable = (availableOptions.findIndex((opt) => opt.id === u.id) < 0);
        return {
          type: 'radio',
          label: u.name + (notAvailable ? ' (not available)' : ''),
          value: u.id,
          checked: u.id === selected,
          disabled: notAvailable,
        };
      }) as any,
      buttons: [
        { text: 'Cancel', role: 'cancel' },
        {
          text: 'Ok', handler: (selectedUtilityTypeId) => {
            if (selectedUtilityTypeId !== selected) {
              this.rr4.updateUtilityTypeId(selectedUtilityTypeId, port);
            }
          },
        },
      ],
    });
    await alert.present();
  }

  public async onUomTypeClicked(port: 1 | 2) {
    const utilityTypeId = port === 2 ? this.rr4.meter2Info.utilityTypeId : this.rr4.meter1Info.utilityTypeId;
    const selectedUomTypeId = port === 2 ? this.rr4.meter2Info.uomTypeId : this.rr4.meter1Info.uomTypeId;
    const alert = await this.alertCtrl.create({
      header: 'Select Unit of Measure',
      inputs: UomTypeHelper.GetUomForUtilityTypeId(utilityTypeId).map((uom) => {
        return {
          type: 'radio',
          label: uom.name,
          value: uom.id,
          checked: uom.id === selectedUomTypeId,
        };
      }) as any,
      buttons: [
        { text: 'Cancel', role: 'cancel' },
        {
          text: 'Ok', handler: (selected) => {
            if (selected !== selectedUomTypeId) {
              this.rr4.updateUomTypeId(selected, port);
            }
          },
        },
      ],
    });
    await alert.present();
  }

  public async onMultiplierChanged(port: 1 | 2, event, component: NumericInputComponent) {
    if (event && event.value) {
      this.rr4.updateMultiplier(event.value, port);
    } else if (event && event.value === 0) {
      const alert = await this.alertCtrl.create({
        header: 'Invalid Multiplier',
        message: 'Oops, "0" is not a valid multiplier. Please enter a value that is greater than "0".',
        buttons: [
          { text: 'Ok', role: 'cancel' },
        ],
      });
      await alert.present();
      component.writeValue(port === 1 ? this.rr4.meter1Info.multiplier : this.rr4.meter2Info.multiplier);
    }
  }

  public onIMRChanged(port: 1 | 2, event) {
    if (event && (event.value || event.value === 0)) {
      this.rr4.updateIMR(event.value, port);
    }
  }

  public configureMeter2() {
    const utilityType = this.rr4.getAvailableUtilities(2)[0];
    this.rr4.updatePulseOut(false);
    this.rr4.updateUtilityTypeId(utilityType.id, 2);
    this.rr4.updateConfigType(MeterConfig.PULSE_IN, 2);
  }

  public factorySleep() {
    if (this.isManufacturingUser) {
      this.rr4.factorySleep();
    }
  }

  public async disableMeter2Clicked() {
    const alert = await this.alertCtrl.create({
      header: 'Disable Meter',
      message: 'Are you sure you want to disable the Meter 2 port on this Remote Reader?',
      buttons: [
        { text: 'Cancel', role: 'cancel' },
        {
          text: 'Yes', handler: () => {
            this.rr4.updateConfigType(MeterConfig.PORT_DISABLED, 2);
            this.rr4.updateUtilityTypeId(UtilityTypeIds.UNDEFINED, 2);
          },
        },
      ],
    });
    await alert.present();
  }

  public tabSelected(event) {
    this.selectedTab = event;
    setTimeout(() => {
      document.scrollingElement.scrollTo({ top: 0 });
    }, 100);

    // ion toggles are finicky :(
    if (this.selectedTab === RemoteReaderModalTabs.STATUS) {
      setTimeout(() => {
        if (this.pulseOutToggle) {
          this.pulseOutToggle.checked = this.rr4.deviceInfo.pulseOut || false;
        }
        if (this.showTamperToggle) {
          this.showTamperToggle.checked = this.rr4.deviceInfo.showTamperAlerts || false;
        }
        if (this.resetPulseToggleOne) {
          this.resetPulseToggleOne.checked = this.rr4.meter1Info.resetPulseCount || false;
        }
        if (this.resetPulseToggleTwo) {
          this.resetPulseToggleTwo.checked = this.rr4.meter2Info.resetPulseCount || false;
        }
        if (this.lcdAlwaysOnToggle) {
          this.lcdAlwaysOnToggle.checked = this.rr4.deviceInfo.lcdAlwaysOn || false;
        }
        if (this.rapidCheckInToggle) {
          this.rapidCheckInToggle.checked = this.rr4.deviceInfo.rapidCheckInEnabled || false;
        }
      }, 300);
    }
  }

  private getConfigurationNameFromUser(): Promise<string | null> {
    return new Promise((resolve) => {
      this.alertCtrl.create({
        header: 'Name This Configuration',
        message: 'Choose a name to describe this configuration for future use.',
        inputs: [{
          name: 'name',
          type: 'text',
          placeholder: 'Enter Name',
        }],
        buttons: [
          {
            text: 'Cancel',
            handler: () => resolve(null),
          },
          {
            text: 'Save',
            handler: (res) => {
              resolve(res.name);
            },
          },
        ],
      }).then((alert) => alert.present());
    });
  }

  private onReconnected(device: IDirectConnectDevice) {
    const pendingChanges = this.rr4.userChanges$.value;
    this.connectedDevice = device;
    this.rr4 = device.device;
    this.initialize();
    this.setUpSubscriptions();
    this.rr4.userChanges$.next(pendingChanges);
    this.rr4.requestingInfo$.pipe(
      pairwise(),
      filter(([prev, curr]) => prev === true && curr === false),
      take(1),
      timeout(1000),
      catchError((err, caught) => caught.pipe(mapTo('timeout'))),
      debounceTime(100),
    ).subscribe((val) => {
      if (val !== 'timeout') {
        this.save();
      }
    });
    this.rr4.requestInfo();
  }

  private async save(): Promise<void> {
    this._loadIndicator = await this.loadCtrl.create({ message: ' Saving...', animated: true, translucent: true });
    await this._loadIndicator.present();
    if (this.rr4.needsCloudSync()) {
      const { errorMsg, success } = await this.syncMetersToCloud();
      if (success) {
        await this.attemptToApplyChanges();
      } else {
        // failed to sync to cloud, so don't apply changes
        await this.onChangesFailedToApply(errorMsg);
      }
    } else {
      await this.attemptToApplyChanges();
    }
    if (this._loadIndicator) {
      await this._loadIndicator.dismiss();
    }
  }

  private async attemptToApplyChanges() {
    const res = await this.rr4.applyChanges();
    if (res) {
      this.onChangesApplied();
    } else {
      this.onChangesFailedToApply();
    }
  }

  private onChangesApplied() {
    this.toast.queueToast('Changes applied!', { duration: 3000 });
    this.rr4.dropUserChanges();
    this.haptic.HapticFeedback(FeedbackType.BLE_CONNECT);
  }

  private async onChangesFailedToApply(errorMsg?: string) {
    const a = await this.alertCtrl.create({
      message: errorMsg || 'Unable to save configurations at this time.',
      header: 'Uh-oh!',
      buttons: ['Ok'],
    });
    await a.present();
  }

  private async syncMetersToCloud(): Promise<{ success: boolean, errorMsg?: string }> {
    const [oldMeter1, oldMeter2] = this.rr4.programmedMeters || [null, null];
    const [newMeter1, newMeter2] = this.rr4.getMetersForCloudSync();
    const unitId = this.rr4.programmedUnit._id;

    const message = ` Syncing...`;
    this.updateLoadingIndicatorMessage(message);

    if (oldMeter1) {
      try {
        await this.unitService.removeMeter(unitId, oldMeter1.utilityTypeId).toPromise();
      } catch (e) {
        return { success: false };
      }
    }
    if (oldMeter2) {
      try {
        await this.unitService.removeMeter(unitId, oldMeter2.utilityTypeId).toPromise();
      } catch (e) {
        return { success: false };
      }
    }

    if (newMeter1) {
      try {
        await this.unitService.addMeter(unitId, newMeter1).toPromise();
      } catch (e) {
        return {
          success: false,
          errorMsg: e.status === 405 ? `A ${UtilityTypes[newMeter1.utilityTypeId].name} already exists on this unit.` : null,
        };
      }
    }

    if (newMeter2) {
      try {
        await this.unitService.addMeter(unitId, newMeter2).toPromise();
      } catch (e) {
        return {
          success: false,
          errorMsg: e.status === 405 ? `A ${UtilityTypes[newMeter2.utilityTypeId].name} already exists on this unit.` : null,
        };
      }
    }
    this.updateLoadingIndicatorMessage(' Almost there...');
    return { success: true };
  }

  private async fetchUnit() {
    if (!this.rr4) { return; }
    const deviceLocation = await this.devicesService.getDeviceLocation(this.rr4.serialNumber).toPromise();
    if (!deviceLocation || !deviceLocation.unit) { return; }
    const unit = await this.unitService.findFullUnitById(deviceLocation.unit.id).toPromise();
    this.rr4.setProgrammedUnit(unit);
  }

  private updateLoadingIndicatorMessage(message: string) {
    if (this._loadIndicator) {
      this._loadIndicator.message = message;
    }
  }
}
