import { HttpHandler, HttpInterceptor, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, Subscriber } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';

import { MobileUserService } from './../mobile-user.service';

@Injectable()
export class MobileAuthHttpInterceptor implements HttpInterceptor {
  constructor(
    private mobileUserService: MobileUserService,
  ) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.mobileUserService.getValue<string>(this.mobileUserService.token$).pipe(switchMap((token) => {
      req = this.addV2Header(req);
      if (token) {
        req = this.addAuthHeader(req, token as any);
      }
      return next.handle(req).pipe(catchError((err) => {
        if (err instanceof HttpErrorResponse && err.status === 401) {
          return new Observable((observer: Subscriber<HttpEvent<any>>) => {
            if (!token) {
              this.mobileUserService.unauthorizedEventOccurred();
              observer.error(err);
              return;
            }

            this.mobileUserService.attemptTokenRefresh().then((attempt) => {
              if (!attempt.success) {
                this.mobileUserService.unauthorizedEventOccurred();
                observer.error(err);
                return;
              }
              req = this.addAuthHeader(req, attempt.token);
              next.handle(req).subscribe(
                (res) => observer.next(res),
                (error) => observer.error(error),
                () => observer.complete(),
              );
            }).catch((error) => throwError(error));
          });
        } else {
          return throwError(err);
        }
      }));
    }));
  }

  private addAuthHeader(req: HttpRequest<any>, token: string) {
    if (req.url.indexOf('amazon') === -1 && req.url.indexOf('google') === -1) {
      req = req.clone({ headers: req.headers.set('Authorization', token)});
    }
    return req;
  }

  private addV2Header(req: HttpRequest<any>) {
    if (req.url.indexOf('amazon') === -1 && req.url.indexOf('google') === -1) {
      req = req.clone({ headers: req.headers.set('version', '2') });
    }
    return req;
  }
}
