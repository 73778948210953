import { AbstractControl, ValidatorFn } from '@angular/forms';

export function clearErrorOnControl(control: AbstractControl, errorKey: string): void {
  const errors = control.errors;
  if (errors) {
    delete errors[errorKey];
    control.setErrors(Object.keys(errors).length ? errors : null);
  }
}

export function setErrorOnControl(control: AbstractControl, errorKey: string, errorValue: string) {
  control.setErrors({
    ...(control.errors || {}),
    [errorKey]: errorValue,
  });
}

export function CreatePositiveNumberValidator(fieldName: string): ValidatorFn {
  return (c: AbstractControl) => {
    if (c.value && typeof c.value === 'number' && c.value < 0) {
      return {
        invalidNumber: `Cannot have a negative ${fieldName}`,
      };
    } else if (c.value && typeof c.value !== 'number') {
      return {
        invalidNumber: `${c.value} is an invalid ${fieldName}`,
      };
    }

    return null;
  };
}

export function CreateNoEmojiValidator(fieldName: string): ValidatorFn {
  return (c: AbstractControl) => {
    if (c.value) {
      if (/\p{Extended_Pictographic}/u.test(c.value)) {
        return {
          emoji: `${fieldName} contains invalid characters.`,
        };
      }
      return null;
    }
    return null;
  };
}
