import {
  CreatePropertyInfo,
  Gateway,
  IBuilding,
  Property,
  Repeater,
  SaveGatewayInfo,
  SavePropertyInfo,
  SaveRepeaterInfo,
  SavePropertySettingsInfo,
  UtilityTypeIds,
  ICanProgramUnitInfo,
  PropertyTile,
  Unit,
  ICanProgramResponse
} from '@ncss/models';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastController, NavController } from '@ionic/angular';
import * as _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, filter, tap } from 'rxjs/operators';

import { AppSettingsService, BackEndHost } from './app-settings.service';


interface BuildingPatch extends IBuilding {
  oldName?: string;
}

@Injectable()
export class MobilePropertyService {

  public get property() { return this._property$.value; }
  public get property$() { return this._property$.asObservable(); }
  public rootUrl: string;
  private loading = false;

  private _property$ = new BehaviorSubject<Property>(null);

  constructor(
    private http: HttpClient,
    private appSettings: AppSettingsService,
    private toastCtrl: ToastController,
    private navCtrL: NavController,
  ) {
    this.rootUrl = this.appSettings.appSettings.backEnd;
    this.appSettings.appSettings$.pipe(
      map((s) => s.backEnd),
      filter((backend) => backend !== this.rootUrl),
    ).subscribe((b) => this.rootUrl = b || BackEndHost.Production);
  }

  public clearCurrentProperty() {
    this._property$.next(null);
  }

  // Please don't use this pattern, don't have time to fix thi api routes atm but It will be a priority for me next week
  public async toggleCellular(propertyId: string) {
    return this.http.put(`${this.rootUrl}/api/Properties/${propertyId}/ToggleCellular`, {});
  }

  public getUnitsForProperty(propertyId: string): Observable<Unit[]> {
    return this.http.get<Unit[]>(`${this.rootUrl}/api/Properties/${propertyId}/Units`);
  }

  public canProgramDevice(propertyId: string, deviceId: number, unitInfo?: ICanProgramUnitInfo): Observable<any> {
    return this.http.get<ICanProgramResponse>(
      `${this.rootUrl}/api/Properties/${propertyId}/CanProgramDevice/${deviceId}`,
      { params: unitInfo } as any);
  }

  public loadProperty(propertyId: string) {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.findById(propertyId).subscribe((p: Property) => {
      const actualProperty = new Property(p);
      this._property$.next(actualProperty);
      this.loading = false;
    }, (error) => this.loading = false);
  }

  public update(propertyInfo: SavePropertyInfo): Observable<Property> {
    if (propertyInfo && propertyInfo._id) {
      console.log('saving');
      return this.http.put(`${this.rootUrl}/api/Properties/${propertyInfo._id}`, propertyInfo).pipe(map((p) => new Property(p)));
    }
  }

  public updatePropertySettings(id: string, info: SavePropertySettingsInfo): Observable<any> {
    return this.http.put<Property>(`${this.rootUrl}/api/Properties/${id}/Settings`, info);
  }

  public getPropertyDataResolution(id: string):
    Observable<{ dataResolution: { [key in UtilityTypeIds]?: number }, hasIntegratedTransceiver: boolean }> {
    return this.http.get<{ dataResolution: { [key in UtilityTypeIds]?: number }, hasIntegratedTransceiver: boolean }>(`${this.rootUrl}/api/mobile/property-resolution/${id}`);
  }

  public create(newProperty: CreatePropertyInfo): Observable<Property> {
    return this.http.post(`${this.rootUrl}/api/Properties`, newProperty).pipe(map((p) => new Property(p)));
  }

  public findById(propertyId: string): Observable<Property> {
    return this.http.get(`${this.rootUrl}/api/Properties/${propertyId}`).pipe(map((p) => new Property(p)));
  }

  public updateGateway(propertyId: string, info: SaveGatewayInfo) {
    return this.http.put(`${this.rootUrl}/api/Properties/${propertyId}/Gateway`, info).toPromise().then((prop: Property) => {
      if (prop && prop._id && prop._id === this.property._id) {
        const actualProp = new Property(prop);
        this._property$.next(actualProp);
      }
    }).catch((err) => {
      // TODO: maybe toast there was an error.
    });
  }

  public updateRepeater(propertyId: string, info: SaveRepeaterInfo) {
    return this.http.put(`${this.rootUrl}/api/Properties/${propertyId}/Repeaters/${info.id}`, info).pipe(
      map((p) => new Property(p)),
    ).toPromise().then((property) => {
      if (property._id === this._property$.value._id) {
        this._property$.next(property);
      }
    });
  }


  public addRepeater(propertyId: string, info: SaveRepeaterInfo) {
    return this.http.post(`${this.rootUrl}/api/Properties/${propertyId}/Repeaters`, info).subscribe((prop: Property) => {
      if (prop && prop._id && prop._id === this.property._id) {
        const actualProp = new Property(prop);
        this._property$.next(actualProp);
      }
    });
  }

  public patchGatewayOntoProperty(gateway: Gateway) {
    const propertyToUpdate = { ...this.property };
    propertyToUpdate['gateway'] = gateway;
    this._property$.next(propertyToUpdate as Property);
  }

  public patchRepeatersOntoProperty(repeaters: Repeater[]) {
    const propertyToUpdate = { ...this.property };
    propertyToUpdate['repeaters'] = repeaters;
    this._property$.next(propertyToUpdate as Property);
  }

  public setCellular(propertyId: string, cellularEnabled: boolean) {
    return this.http.put(
      `${this.rootUrl}/api/Properties/${propertyId}/ToggleCellular?enabled=${cellularEnabled ? '1' : '0'}`,
      {}).pipe(tap(() => this.loadProperty(propertyId)));
  }

  public findPropertyByGatewayId(gatewayId: number, loadIt = false): Observable<Property> {
    if (loadIt) {
      return this.http.get(`${this.rootUrl}/api/Gateways/${gatewayId}/Property`).pipe(
        map((p) => new Property(p)),
        tap((p) => this._property$.next(p)),
      );
    } else {
      return this.http.get(`${this.rootUrl}/api/Gateways/${gatewayId}/Property`).pipe(map((p) => new Property(p)));
    }
  }

  /**
   * Use this to patch the property after updating it via the API.
   * This avoids making another API call to load the property
   * @param buildings Building name and description object
   * @param add Whether to add or remove the building
   */
  public patchBuildings(buildings: BuildingPatch | BuildingPatch[], method: 'add' | 'edit' | 'delete') {
    buildings = _.isArray(buildings) ? buildings : [buildings];
    const property = { ...this.property };
    if (property && !property.buildings) {
      property['buildings'] = [];
    }
    if (method === 'add') {
      property.buildings.push(...buildings);
      property.buildings.sort((a, b) => {
        return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
      });
    } else if (method === 'delete') {
      _.forEach(buildings, (building) => {
        const idx = property.buildings.findIndex((b) => b.name === building.name);
        if (idx > -1) {
          property.buildings.splice(idx, 1);
        }
      });
    } else if (method === 'edit') {
      _.forEach(buildings, (building) => {
        const idx = property.buildings.findIndex((b) => b.name === building.oldName);
        if (idx > -1) {
          property.buildings[idx].name = building.name;
          property.buildings[idx].description = building.description;
        }
      });
    }
    this._property$.next(property as Property);
  }

  public getRepeaterDependencyInfo(propertyId: string) {
    return this.http.get(`${this.rootUrl}/api/Properties/${propertyId}/RepeaterDependencyInfo`);
  }

  /**
   * Un-programs a single repeater from a property.
   */
  public async removeRepeater(propertyId: string, repeaterId: number) {
    return this.http.delete(`${this.rootUrl}/api/Properties/${propertyId}/Repeaters/${repeaterId}`).toPromise();
  }

  /**
   * Un-programs a gateway from a property
   */
  public async removeGateway(propertyId: string, gatewayId: number) {
    return this.http.delete(`${this.rootUrl}/api/Properties/${propertyId}/Gateway/${gatewayId}`).toPromise();
  }

  public getNetworkInfoForProperty(propertyId: string) { // Observable of type {_id: string, gateway: Gateway, repeaters: Repeater[]}
    return this.http.get(`${this.rootUrl}/api/mobile/${propertyId}/network`);
  }

  public deletePropertyDoc(propertyId: string, filename: string) {
    return this.http.delete(`${this.rootUrl}/api/Properties/${propertyId}/Documents/${filename}`);
  }

  public updateRepeaterDoc(propertyId: string, repeaterId, opts: { fileType, title: string, coords: [number, number] }): Observable<any> {
    return this.http.post(`${this.rootUrl}/api/Properties/${propertyId}/Repeaters/${repeaterId}/Documents`, opts).pipe(
      tap(() => this.loadProperty(propertyId)),
    );
  }

  public updateGatewayDoc(propertyId: string, gatewayId, opts: { fileType, title: string, coords: [number, number] }): Observable<any> {
    return this.http.post(`${this.rootUrl}/api/Properties/${propertyId}/Gateway/${gatewayId}/Documents`, opts).pipe(
      tap(() => this.loadProperty(propertyId)),
    );
  }

  public updateDoc(propertyId, opts: { fileType, title: string }): Observable<any> {
    return this.http.post(`${this.rootUrl}/api/Properties/${propertyId}/Documents`, opts).pipe(
      tap(() => this.loadProperty(propertyId)),
    );
  }

  public isManualReadProperty(propertyId: string): Observable<boolean> {
    return this.http.get(`${this.rootUrl}/api/ManualReads/Properties/${propertyId}/ManualReadProperty`) as Observable<boolean>;
  }

  public deleteProperty(id: string): void {
    this.http.delete(`${this.rootUrl}/api/Properties/${id}`).subscribe(() => {
      this.toastCtrl.create({ message: 'Property Deleted', duration: 2000, color: 'dark' })
        .then((t) => t.present());
      this.navCtrL.navigateRoot('/billing-home', { queryParams: { forceRefresh: true } });
      this._property$.next(null);
    }, (err) => {
      console.log(err);
      this.toastCtrl.create({ message: 'Could not delete property at this time', duration: 2000, color: 'dark' })
        .then((t) => t.present());
    });
  }

  public getPropertyTilesList(lat: number, long: number): Observable<PropertyTile[]> {
    return this.http.get<PropertyTile[]>(`${this.rootUrl}/api/common/property-list/?lat=${lat}&long=${long}`).pipe(
      map((res) => {
        if (res && res.length) {
          res = res.map((p) => PropertyTile.fromDTO(p));
        }
        return res;
      }),
    );
  }

  public getBillingHomeProperties(lat: number, long: number):
    Observable<{ nearbyProperties: PropertyTile[], recentProperties: PropertyTile[] }> {
    return this.http.get<{ nearbyProperties: PropertyTile[], recentProperties: PropertyTile[] }>(`${this.rootUrl}/api/mobile/billing-home/properties?lat=${lat}&long=${long}`).pipe(
      map((res) => {
        if (res.nearbyProperties) {
          res.nearbyProperties = res.nearbyProperties.map((p) => PropertyTile.fromDTO(p));
        }

        if (res.recentProperties) {
          res.recentProperties = res.recentProperties.map((p) => PropertyTile.fromDTO(p));
        }
        return res;
      }),
    );
  }

  public getPropertyFilterCount(params: HttpParams): Observable<{ count: number }> {
    return this.http.get<{ count: number }>(`${this.rootUrl}/api/common/property-filter/get-count`, { params });
  }

  public runPropertyFilter(params: HttpParams): Observable<PropertyTile[]> {
    return this.http.get<PropertyTile[]>(`${this.rootUrl}/api/common/property-filter/run`, { params });
  }

  public getPropertyListNames(): Observable<Array<{ _id: string, name: string }>> {
    return this.http.get<Array<{ _id: string, name: string }>>(`${this.rootUrl}/api/common/property-list/names`);
  }
}
