import { Injectable } from '@angular/core';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import * as _ from 'lodash';

import { IUserChanges } from '../../../services/direct-connect/remoteReader/remoteReader';

export interface IQuickConfiguration {
  name: string;
  changes: IUserChanges;
  createdAt?: Date;
  updatedAt?: Date;
  lastUsedAt?: Date;
}

@Injectable()
export class QuickConfigurationService {

  constructor(private nativeStorage: NativeStorage) { }

  public async save(propertyId: string, config: IQuickConfiguration) {
    const configurations = await this.get(propertyId);
    const index = _.findIndex(configurations, { name: config.name });
    if (index !== -1) {
      configurations[index] = { ...configurations[index], ...config, updatedAt: new Date() };
    } else {
      configurations.push({ name: config.name, changes: config.changes, createdAt: new Date() });
    }
    await this.nativeStorage.setItem(this.getKey(propertyId), configurations);
  }

  public async get(propertyId: string): Promise<IQuickConfiguration[]> {
    let configurations: IQuickConfiguration[] = [];
    try {
      configurations = await this.nativeStorage.getItem(this.getKey(propertyId));
    } catch (e) {
      console.log('Error getting quick configurations', e);
    }
    if (!configurations) {
      configurations = [];
    }
    return configurations;
  }

  public async delete(propertyId: string, name: string) {
    let configurations = await this.get(propertyId);
    configurations = _.filter(configurations, (c) => c.name !== name);
    await this.nativeStorage.setItem(this.getKey(propertyId), configurations);
    return configurations;
  }

  private getKey(propertyId: string) {
    return `${propertyId}:quickConfigurations`;
  }

}
