import { Component, EventEmitter, Input, Output } from '@angular/core';

import { AppSettingsService, permissionType } from '../../services/app-settings.service';

export enum HelperStatus {
  NONE =              0,
  READY =             0,
  OFFLINE =           2,
  ONLINE =            3,
  NO_GATEWAY =        4,
  GATEWAY_OFFLINE =   5,
  PAUSED =            6,
  NO_CAMERA =         7,
  CAMERA_DISABLED =   8,
  NO_BLE =            9,
  BLE_DISABLED =      10,
  BLE_RADIO_OFF =     11,
  GPS_OFF =           12,
  GPS_DISABLED =      13,
  CAMERA_OFF =        14,
}

@Component({
  selector: 'app-connection-helper',
  templateUrl: 'connection-helper.component.html',
  styleUrls: ['connection-helper.component.scss'],
})
export class ConnectionHelperComponent {

  @Input() public status = HelperStatus.READY;
  @Input() public readyText = '';
  @Input() public propertyName = '';
  @Output() public settingsClick = new EventEmitter();

  public statusList = HelperStatus;

  constructor(
    private appSettings: AppSettingsService,
  ) {

  }
  public enableLocation() {
    this.appSettings.needPermission(permissionType.Gps);
  }
  public enableBluetooth() {
    this.appSettings.needPermission(permissionType.Ble);
  }
  public enableCamera() {
    this.appSettings.needPermission(permissionType.Camera);
  }
  public turnOnBle() {
    this.appSettings.turnOnBle();
  }
  public turnOnLocation() {
    this.appSettings.turnOnLocation();
  }
}
