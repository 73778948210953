import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-form-row-wrapper',
  styleUrls: ['./form-row-wrapper.component.scss'],
  templateUrl: './form-row-wrapper.component.html',
})
export class FormRowWrapperComponent implements OnInit {
  @Input() control: AbstractControl;
  @Input() additionalOpenCondition = true;

  errorMessage = '';

  ngOnInit() {
    this.control.statusChanges.subscribe((status) => {
      if (status === 'INVALID') {
        Object.keys(this.control.errors).forEach((key) => {
          if (key !== 'required') {
            if (this.control.errors[key].requiredLength) {
              this.errorMessage = `Cannot be longer than ${this.control.errors[key].requiredLength} characters.`;
              return;
            }
            if (typeof this.control.errors[key] === 'string') {
              this.errorMessage = this.control.errors[key];
            }
            return;
          }
        });
      } else {
        this.errorMessage = '';
      }
    });
  }
}
