import { User, Property, RoleTypeValue } from '@ncss/models';

import { ChangeDetectorRef, Directive, ElementRef, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { skipWhile, filter } from 'rxjs/operators';

import { MobilePropertyService } from './../services/mobile-property.service';
import { MobileUserService } from './../services/mobile-user.service';


@Directive({
  selector: '[hasPermission]',
})
export class PermissionUtilsDirective implements OnInit {
  /**
   * This will be the Role required eg: S,A,D,T,E,B,P,AC,MR
   */
  @Input() public hasPermission;
  /**
   * Use this if you would like to disable the element instead of hiding it. (buttons)
   * */
  @Input() public disable = false;
  /**
   * Use this if you would like to check permissions on a specific billingCompany -> (users page)
  */
  @Input() public set specificBillingCompanyId(val: string) {
    if (val) {
      this._specificIds = val.split(',');
    }
  }
  /**
     * Use this if you would like to check permissions on a specific property
    */
  @Input() public set specificPropertyId(val: string) {
    if (val) {
      this.propertyId = val;
    }
  }

  public get specificBillingCompanyId() {
    return this._specificIds.join(',');
  }
  public get specificPropertyId() {
    return this.propertyId;
  }
  private _specificIds: string[] = [];

  private url;
  private propertyId = null;
  private user = null;

  constructor(
    private router: Router,
    private mobileUserService: MobileUserService,
    private propertyService: MobilePropertyService,
    private elementRef: ElementRef,
    private cd: ChangeDetectorRef,
  ) { }

  public ngOnInit() {
    this.checkPermission();
  }

  private checkPermission() {
    // determine if url puts us in the context of a specific property
    this.url = this.router.url;
    if (!this.propertyId && this.url && this.url.indexOf('/p/') !== -1) {
      const parts = this.url.split('/');
      const pIndex = parts.indexOf('p');
      this.propertyId = parts[pIndex] + '_' + parts[pIndex + 1];
    } else if (!this.propertyId) {
      this.propertyId = null;
    }

    this.mobileUserService.user$.pipe(skipWhile(v => !v), filter((u) => !this.user || (u && u._id !== this.user._id)))
      .subscribe((user: User) => {
        this.userChanged(user);
      });
  }
  /**
   * if ids given, find greatest role from among those given
   * if ids not given, find greatest role from all of this.user.permissions
   */
  private findGreatestRole(ids?: String[]) {
    let role;
    let subIds = null;
    _.forEach(this.user.permissions, (permission) => {
      if (!ids) {
        ids = [permission.id];
      } else {
        subIds = ids;
      }
      role = this.findRole(permission, role, ids);
      if (permission.permissions) {
        _.forEach(permission.permissions, (p) => {
          if (!subIds) {
            subIds = [p.id];
          }
          role = this.findRole(p, role, subIds);
        });
      }
    });
    return role;
  }

  private findRole(permission, role, ids: String[]) {
    if (ids.includes(permission.id)) {
      if (!role) {
        role = permission.role;
      }
      if (RoleTypeValue(permission.role) < RoleTypeValue(role)) {
        role = permission.role;
      }
    }
    return role;
  }

  private userChanged(user: User) {
    this.user = user;
    if (this._specificIds.length) {
      // if in a billing company context, only look at the user's role for that billing company
      let myPermission = null;
      let permissionGranted = false;
      _.forEach(this._specificIds, (specificId) => {
        myPermission = user.permissions.find((permission) => {
          return permission.id === specificId;
        });
        if (myPermission && RoleTypeValue(myPermission.role) <= RoleTypeValue(this.hasPermission)) { // permission granted
          permissionGranted = true;
          return false; // lodash forEach() break
        } else { // permission denied
          return; // lodash forEach() continue
        }
      });
      if (permissionGranted) {
        this.disable ? this.elementRef.nativeElement.disabled = false : this.elementRef.nativeElement.style.display = '';
      } else {
        this.disable ? this.elementRef.nativeElement.disabled = true : this.elementRef.nativeElement.style.display = 'none';
      }
      this.cd.markForCheck();
    } else if (this.propertyId) {
      // If in a property context (and not a specific billing company context),
      // search for highest role among the property, its mc, and its bc
      this.propertyService.findById(this.propertyId).subscribe((property: Property) => {
        if (!property) {
          return;
        }
        const role = this.findGreatestRole([property._id, property.managementCompany.id, property.billingCompany.id]);
        if (role && RoleTypeValue(role) <= RoleTypeValue(this.hasPermission)) { // permission granted
          this.disable ? this.elementRef.nativeElement.disabled = false : this.elementRef.nativeElement.style.display = '';
        } else { // permission denied
          this.disable ? this.elementRef.nativeElement.disabled = true : this.elementRef.nativeElement.style.display = 'none';
        }
        this.cd.markForCheck();
      });
    } else {
      // else outside any specific context. search for highest role in all user permissions
      const role = this.findGreatestRole();
      if (role && RoleTypeValue(role) <= RoleTypeValue(this.hasPermission)) { // permission granted
        this.disable ? this.elementRef.nativeElement.disabled = false : this.elementRef.nativeElement.style.display = '';
      } else { // permission denied
        this.disable ? this.elementRef.nativeElement.disabled = true : this.elementRef.nativeElement.style.display = 'none';
      }
      this.cd.markForCheck();
    }
  }

}
