import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { AnimatedContainersModule } from '../../components/animated-containers/animated-containers.module';
import { DirectivesModule } from '../../directives/directives.module';
import { GrowContainerModule } from '../animations/grow-container/growContainer.component';
import { FormRowWrapperModule } from '../form-row-wrapper/form-row-wrapper.module';
import { SerialNumberInputModule } from '../serial-number-input/serial-number-input.module';
import { CustomInputPopoverComponent } from './custom-input-popover.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    SerialNumberInputModule,
    DirectivesModule,
    AnimatedContainersModule,
    GrowContainerModule,
    ReactiveFormsModule,
    FormsModule,
    FormRowWrapperModule,
  ],
  entryComponents: [
    CustomInputPopoverComponent,
  ],
  exports: [
    CustomInputPopoverComponent,
  ],
  declarations: [
    CustomInputPopoverComponent,
  ],
  providers: [],
})
export class CustomInputPopoverModule { }
