import { IGeoLocation, UserLocationInfo } from '@ncss/models';

import { Injectable } from '@angular/core';
import { Geolocation, Geoposition } from '@ionic-native/geolocation/ngx';
import * as moment from 'moment-timezone';

import { AppSettingsService, IAppSettings, permissionType } from './app-settings.service';
import { MobileUserService } from './mobile-user.service';

@Injectable({
  providedIn: 'root',
})
export class GpsLocationService {

  public lastLocation: IGeoLocation;
  private gpsEnabled: boolean;

  constructor(
    private geolocation: Geolocation,
    private appSettings: AppSettingsService,
    private mobileUserService: MobileUserService,
  ) { }

  public init() {
    this.getCurrentLocation(true);
    this.appSettings.appSettings$.subscribe((appSettings: IAppSettings) => {
      if (appSettings.gps.enabled && !appSettings.slides.initial) {
        this.gpsEnabled = true;
      } else {
        this.gpsEnabled = false;
      }
    });
  }

  public async checkGpsPermission() {
    return new Promise(async (resolve, reject) => {
      const res = await this.appSettings.needPermission(permissionType.Gps);
      resolve(res);
    });
  }

  public async getCurrentLocation(checkPermission = true): Promise<IGeoLocation> {
    if ((checkPermission && !(await this.checkGpsPermission()) ||
    (!checkPermission && !this.gpsEnabled))) {
      return Promise.resolve(null);
    }
    if (this.isPositionRecent()) {
      return this.lastLocation;
    }
    return this.geolocation.getCurrentPosition({ timeout: 15000, enableHighAccuracy: true, maximumAge: 30000 })
      .then((position: Geoposition) => {
        if (position) {
          return this.setGeoLocation(position);
        } else {
          return this.lastLocation;
        }
      }).catch((err) => {
        console.log('Could Not Get Geolocation Coords, Returning Previous Location If One Available', err);
          return this.lastLocation ? this.lastLocation : null;
    });
  }

  private isPositionRecent(debounceSeconds: number = 5): boolean {
    if (this.lastLocation && moment(this.lastLocation.timeStamp) > moment(new Date()).subtract(debounceSeconds, 'seconds')) {
      return true;
    }
    return false;
  }

  private setGeoLocation(position: Geoposition): IGeoLocation {
    let geoLocation: IGeoLocation = null;

    if (!position || !position.coords) {
      this.lastLocation = null;
      return geoLocation;
    }
    geoLocation = {
      valid: true,
      timeStamp: new Date(position.timestamp),
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
      accuracy: position.coords.accuracy,
      altitude: position.coords.altitude || null,
      altitudeAccuracy: position.coords.altitudeAccuracy || null,
    };
    if (position.coords.altitude) {
      geoLocation.altitude = position.coords.altitude;
      geoLocation.altitudeAccuracy = position.coords.altitudeAccuracy;
    }
    if (this.mobileUserService.user$ && this.mobileUserService.user$.value) {
      this.mobileUserService.updateUserLocation(
        this.mobileUserService.user$.value._id, { location: geoLocation } as UserLocationInfo,
      ).catch(() => console.log('Unable to update user location (mobile gps service)'));
    }
    this.lastLocation = geoLocation;
    return geoLocation;
  }
}
