import { IUomType, IUtilityType, UomTypeHelper, UtilityTypesOrderedList } from '@ncss/models';

import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as _ from 'lodash';

export enum MeterPortTypeIds {
  UNUSED  = 0,
  PULSE   = 1,
  ENCODED = 2,
}

const MeterPortTypes = [
  { name: 'None', id: MeterPortTypeIds.UNUSED }, // Unused
  { name: 'Pulse', id: MeterPortTypeIds.PULSE },
  { name: 'Encoded', id: MeterPortTypeIds.ENCODED },
];

const newUom: IUomType = {
  id: 0,
  name: 'None',
  shortName: 'None',
  alias: null,
  baseVal: null,
  utilityFamily: null,
};

@Component({
  selector: 'app-direct-connect-meter',
  templateUrl: './direct-connect-meter.component.html',
  styleUrls: ['./direct-connect-meter.component.scss'],
})
export class DirectConnectMeterComponent implements OnInit {

  @Input() public meterForm: FormGroup;

  public MeterPortTypes = MeterPortTypes;
  public MeterPorTypeIds = MeterPortTypeIds;
  public UtilityTypes: IUtilityType[] = [...UtilityTypesOrderedList];
  public UomTypes: IUomType[] = [];

  constructor() {

  }

  ngOnInit() {
    const newUtility: IUtilityType = {
      id: 0,
      name: 'None',
      family: null,
      alias: null,
      color: null,
      colorHex: null,
      iconName: null,
      defaultUomId: null,
    };
    this.UtilityTypes.unshift(newUtility);
    this.getUomTypes();
  }

  public getUomTypes() {
    if (!this.meterForm || !this.meterForm.controls['utilityTypeId'] || !this.meterForm.controls['utilityTypeId'].value) {
      this.UomTypes = [];
    } else {
      this.UomTypes = [...UomTypeHelper.GetUomForUtilityTypeId(this.meterForm.controls['utilityTypeId'].value)];
    }

    this.UomTypes.unshift(newUom);

    // Check that selected Uom Is allowed
    if (this.meterForm.controls['uomId']) {
      const ValidUoms = UomTypeHelper.GetUomForUtilityTypeId(this.meterForm.controls['utilityTypeId'].value);

      if (!_.find(ValidUoms, { 'id': this.meterForm.controls['uomId'].value })) {
        this.meterForm.controls['uomId'].patchValue(0);
      }
    }
  }
}
