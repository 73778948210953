import { DeviceFamily } from '@ncss/models';

import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-reconnect-modal',
  templateUrl: './reconnect-modal.component.html',
  styleUrls: ['./reconnect-modal.component.scss'],
  animations: [
    trigger('fadeInOutX', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale3d(0.1, 0.1, 0.1) translate3d(-450px, 0, 0)' }),
        animate('1000ms cubic-bezier(0.175, 0.885, 0.32, 1)',
          style({ opacity: 1, transform: 'scale3d(1, 1, 1) translate3d(0, 0, 0)' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale3d(1, 1, 1) translate3d(0, 0, 0)' }),
        animate('500ms cubic-bezier(0.175, 0.885, 0.32, 1)',
          style({ opacity: 0, transform: 'scale3d(0.1, 0.1, 0.1) translate3d(500px, 0, 0)' })),
      ]),
    ]),
    trigger('fadeInOutY', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale3d(0.1, 0.1, 0.1) translate3d(0, -450px, 0)' }),
        animate('500ms cubic-bezier(.26,.05,.38,1)',
          style({ opacity: 1, transform: 'scale3d(1, 1, 1) translate3d(0, 0, 0)' })),
      ]),
    ]),
  ],
})
export class ReconnectModalComponent implements OnInit {

  public DeviceFamily = DeviceFamily;

  @Input() public reconnectingText$: Observable<string>;
  @Input() public deviceFamily: DeviceFamily;

  constructor(
    private modalCtrl: ModalController,
  ) { }

  ngOnInit() {
  }

  public onClose() {
    this.modalCtrl.dismiss('cancel');
  }

}
