import { LeakSensorAlert, MeterAlert, Unit } from '@ncss/models';

import { FormGroup, FormControl, AbstractControl, Validators, ValidatorFn } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { uniq } from 'lodash';

import { CameraService } from '../services/camera.service';
import { MobileMeterManufacturerService } from '../services/mobile-meter-manufacturer.service';
import { MobileMeterTypeService } from '../services/mobile-meter-type.service';
import { MobilePropertyService } from '../services/mobile-property.service';
import { MobileUnitService } from '../services/mobile-unit.service';
import { MobileUserService } from '../services/mobile-user.service';
import { setErrorOnControl, clearErrorOnControl, CreateNoEmojiValidator } from './common-validators';
import { LeakSensorsFormArray } from './leak-sensor-form-array';
import { MeterFormArray } from './meter-form-array';

interface UnitFormControls {
  name: FormControl;
  building: FormControl;
  meters: MeterFormArray;
  leakSensors: LeakSensorsFormArray;
  [key: string]: AbstractControl;
}

export class UnitForm extends FormGroup {

  static async Create(
    propertyService: MobilePropertyService,
    meterTypeService: MobileMeterTypeService,
    meterManufacturerService: MobileMeterManufacturerService,
    unitService: MobileUnitService,
    userService: MobileUserService,
    cameraService: CameraService,
    modalCtl: ModalController,
    unit: Unit,
  ): Promise<UnitForm> {
    const alerts = await unitService.getAlertsForUnit(unit._id);
    const meters = await MeterFormArray.Create(
      propertyService,
      meterTypeService,
      meterManufacturerService,
      unitService,
      userService,
      cameraService,
      modalCtl,
      unit,
      alerts.filter((a) => a instanceof MeterAlert) as MeterAlert[],
    );

    const leakSensors = LeakSensorsFormArray.FromUnit(
      propertyService,
      cameraService,
      userService,
      modalCtl,
      unit,
      alerts.filter((a) => a instanceof LeakSensorAlert) as LeakSensorAlert[],
    );

    return new UnitForm(unitService, {
      building: new FormControl(unit ? unit.building : null, [Validators.required]),
      name: new FormControl(unit ? unit.name : null, [Validators.required, Validators.maxLength(30), CreateNoEmojiValidator('Unit Name')]),
      meters,
      leakSensors,
    }, CreateUnitNameValidator(unitService, unit ? unit._id : undefined));
  }

  controls: UnitFormControls;
  buildingOptions: string[] = [];
  private originalValues;

  private constructor(private unitService: MobileUnitService, controls: UnitFormControls, syncValidators: ValidatorFn | ValidatorFn[]) {
    super(controls, syncValidators);
    this.setupListeners();
    this.originalValues = this.value;
  }

  private setupListeners() {
    this.valueChanges.subscribe(() => this.checkForChanges());
    this.buildingOptions = this.getBuildingOptions(this.unitService.propertyUnits);
    this.unitService.propertyUnits$.subscribe((units) => {
      this.buildingOptions = this.getBuildingOptions(units);
    });
  }

  private checkForChanges() {
    const nameOrBuildingChanged = this.originalValues.name !== this.value.name || this.originalValues.building !== this.value.building;
    const metersChanged = this.controls.meters.dirty;
    const leakSensorsChanged = this.controls.leakSensors.dirty;
    if (nameOrBuildingChanged || metersChanged || leakSensorsChanged) {
      this.markAsDirty();
      this.markAsTouched();
    } else {
      this.markAsUntouched();
      this.markAsPristine();
    }
  }

  private getBuildingOptions(units: Unit[]) {
    return uniq(units.map((u) => u.building)).sort();
  }

}

function CreateUnitNameValidator(unitService: MobileUnitService, unitId?: string): ValidatorFn {
  return (unit: UnitForm) => {
    const otherUnit = unitService.propertyUnits.find((u) => {
      return u.name === unit.controls.name.value && u.building === unit.controls.building.value && u._id !== unitId;
    });
    if (otherUnit) {
      const error = `The unit ${otherUnit.name} in building ${otherUnit.building} already exists.`;
      setErrorOnControl(unit.controls.name, 'duplicateUnit', error);
      setErrorOnControl(unit.controls.building, 'duplicateUnit', error);
      return {
        duplicateUnit: error,
      };
    } else {
      clearErrorOnControl(unit.controls.name, 'duplicateUnit');
      clearErrorOnControl(unit.controls.building, 'duplicateUnit');
      return null;
    }
  };
}
