import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Device } from '@ionic-native/device/ngx';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
import { Flashlight } from '@ionic-native/flashlight/ngx';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx';
import { SecureStorage, SecureStorageObject } from '@ionic-native/secure-storage/ngx';
import { AlertController, MenuController, Platform } from '@ionic/angular';
import * as CryptoJS from 'crypto-js';
import * as _ from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import { skip, take } from 'rxjs/operators';

const NATIVE_NAME = 'ncss-native';
const SECURE_NAME = 'ncss-secure';
const OBJ_NAME = 'savedInfo';
const KEY = 'B3AR$*E^T5B@Tt135TARG^1AT1CA';

export enum BiometricType {
  NONE = 0,
  TOUCH_AUTH = 1,
  FACE_AUTH = 2,
}

export enum BackEndHost {
  Production = 'https://api.nextcenturymeters.com', // use for production backend
  socketEndpoint = 'https://api.nextcenturymeters.com',
  // socketEndpoint = 'http://localhost:3000', // use for local backend
  // Production = 'http://localhost:3000',
  Staging = 'https://staging-api.nextcenturymeters.com',
  LS4 = 'https://dev-leak-sensor-webapi.nextcenturymeters.com',
  Sandbox = 'https://sandbox-api.nextcenturymeters.com',
  Internal = 'https://admin.nextcenturymeters.com',
  Manufacturing = 'http://qa.nextcenturymeters.com',
}

export enum DeployChannel {
  Production = 'Production',
  Staging = 'Staging',
}

export enum permissionType {
  Gps = 1,
  Ble = 2,
  Storage = 3,
  Camera = 4,
}

export interface ISlidesEnable {
  initial: boolean;
  testConnect: boolean;
  barcodeScanner: boolean;
  directConnect: boolean;
  replaceDevice: boolean;
  limitedAccess: boolean;
}

export interface IFeature {
  available: boolean;
  osPermission: boolean;
  enabled: boolean;
}

export interface IBiometricFeature extends IFeature {
  type: BiometricType;
}

export interface ISecureSettings {
  password?: string;
}

export interface IAppSettings {
  available?: boolean;
  username?: string;
  secure?: ISecureSettings;
  password?: string;
  savePassword?: boolean;
  defaultNavAppKey?: string;
  biometric?: IBiometricFeature;
  gps?: IFeature;
  camera?: IFeature;
  storage?: IFeature;
  ble?: IFeature;
  bleProximity?: boolean; // Auto Proximity BLE
  flashlight?: IFeature;
  cloudScanner?: IFeature;
  barcodeBeep?: boolean;
  slides?: ISlidesEnable;
  showAdminTools?: boolean;
  backEnd?: BackEndHost;
  socketEndpoint?: BackEndHost;
  deployChannel?: DeployChannel;
  pairedProgrammerBleId?: string;
  pairedProgrammerDeviceId?: number;
  pairedProgrammerDeviceName?: string;
}

export const defaultSettings: IAppSettings = {
  available: false,
  username: '',
  secure: {
    password: '',
  },
  savePassword: false,
  defaultNavAppKey: '',
  biometric: { type: BiometricType.NONE, available: false, osPermission: false, enabled: false },
  gps: { available: false, osPermission: false, enabled: false },
  camera: { available: false, osPermission: false, enabled: false },
  storage: { available: false, osPermission: false, enabled: false },
  ble: { available: false, osPermission: false, enabled: false },
  bleProximity: true,
  flashlight: { available: false, osPermission: false, enabled: false },
  cloudScanner: { available: false, osPermission: false, enabled: false },
  barcodeBeep: true,
  showAdminTools: false,
  slides: { initial: true, testConnect: false, barcodeScanner: false, directConnect: false, replaceDevice: false, limitedAccess: true },
  backEnd: BackEndHost.Production,
  socketEndpoint: BackEndHost.socketEndpoint,
  deployChannel: DeployChannel.Production,
};

@Injectable({
  providedIn: 'root',
})
export class AppSettingsService {

  static isFeatureEnabled(feature: IFeature): boolean {
    return feature.available && feature.osPermission && feature.enabled;
  }

  public get initialized() { return this._initialized$.value; }
  public get initialized$() { return this._initialized$.asObservable(); }
  public get isAndroid() {
    return this.platform.is('android');
  }
  public get isIos() {
    return this.platform.is('ios');
  }
  public appSettings: IAppSettings = _.cloneDeep(defaultSettings);
  public appSettings$: BehaviorSubject<IAppSettings> = new BehaviorSubject(this.appSettings);
  public softwareDownloadProgress$ = new Subject<number>();
  public isNewVersion = false;
  public isBrowser = false;
  public cordovaAvailable = false;
  public bleRadioOn = false;
  public isMenuOpen = false;
  public secureStorageAvailable = false;
  public readonly appVersion = '3.41.14';
  private _initialized$ = new BehaviorSubject<boolean>(false);

  constructor(
    private diagnostic: Diagnostic,
    private device: Device,
    private platform: Platform,
    private fingerprintAIO: FingerprintAIO,
    private flashlight: Flashlight,
    private nativeStorage: NativeStorage,
    private secureStorage: SecureStorage,
    private alertCtrl: AlertController,
    private menuController: MenuController,
    private http: HttpClient,
    private openNativeSettings: OpenNativeSettings,
  ) { }

  public async init() {
    this.checkIsBrowser();
    this.cordovaAvailable = this.platform.is('cordova');
    // this line is important in order to get user location permission at login screen
    this.diagnostic.requestLocationAuthorization();

    // Save changed data
    this.appSettings$.pipe(skip(1)).subscribe(settings => this.writeSettings(settings));

    // Check settings changes on resume
    this.platform.resume.subscribe(() => this.checkFeatureAvailability());

    // Check for BLE radio state change
    this.diagnostic.registerBluetoothStateChangeHandler(() => this.checkFeatureAvailability());

    // Do an initial load of saved data
    await this.readSettings();
    await this.migrateSettings();
    this.checkFeatureAvailability();
    this._initialized$.next(true);
  }

  public update() {
    if (this.initialized) {
      this.checkFeatureAvailability();
    }
  }

  public async checkForAppStoreUpdate(): Promise<string> {
    if (!this.isBrowser) {
      const url = this.platform.is('android') ? '/android' : '/ios';
      // tslint:disable-next-line: max-line-length
      const cloudReportedVersion: any = await this.http.get(`${this.appSettings.backEnd}${url}`).toPromise();
      const app = this.appVersion.split('.');
      const store = cloudReportedVersion.version.split('.');
      let storeUrl;

      if (
        (+store[0] > +app[0]) ||
        (+store[1] > +app[1] && +store[0] >= +app[0]) ||
        (+store[2] > +app[2] && +store[0] >= +app[0] && +store[1] >= +app[1])) {
        storeUrl = this.platform.is('android') ?
          'market://details?id=com.nextcenturymeters.ncssmobile' :
          'itms-apps://itunes.apple.com/app/id994253847';
      }
      return storeUrl;
    }
  }

  public async setDeployChannel(channel: DeployChannel) {
    if (channel !== this.appSettings.deployChannel) {
      this.appSettings.deployChannel = channel;
      this.updateAppSettings();
    }
  }

  public turnOnBle() {
    if (this.isIos) {
      this.openNativeSettings.open('bluetooth');
    } else if (this.isAndroid) {
      try {
        this.diagnostic.setBluetoothState(true);
      } catch {
        this.openNativeSettings.open('bluetooth');
      }
    }
  }

  public turnOnLocation() {
    console.log('gonna turn on location', this.appSettings);
    this.openNativeSettings.open('location');
  }

  public getAppSettings(): Promise<IAppSettings> {
    return new Promise<IAppSettings>((resolve) => {
      this.appSettings$.pipe(take(1)).subscribe((settings) => resolve(settings));
    });
  }

  public updateAppSettings() {
    this.appSettings$.next(this.appSettings);
  }

  public resetSlideSettings() {
    this.appSettings.slides = defaultSettings.slides;
    this.updateAppSettings();

    setTimeout(() => {
      this.init();
    }, 200);
  }

  public resetAllSettings() {
    this.appSettings = _.cloneDeep(defaultSettings);
    this.updateAppSettings();

    setTimeout(() => {
      this.init();
    }, 200);
  }

  public setAppSettings(newSettings: IAppSettings) {
    Object.assign(this.appSettings, newSettings);
    this.updateAppSettings();
  }

  public async enableBiometric(enable: boolean) {
    if (this.appSettings.biometric.available && !this.appSettings.biometric.osPermission) {
      const alert = await this.alertCtrl.create({
        header: 'No Biometric Access!',
        subHeader: 'Please allow Biometric access from your device settings. ' +
          '(You may need to set up touch id or facial recognition for the first time)',
        buttons: [{
          text: 'Cancel',
          role: 'cancel',
        }, {
          text: 'Settings',
          handler: () => {
            this.appSettings.biometric.osPermission = false;
            this.appSettings.biometric.enabled = false;
            this.updateAppSettings();
            this.diagnostic.switchToSettings();
          },
        }],
      });
      alert.present();
    }

    this.appSettings.biometric.enabled = enable;
    this.updateAppSettings();
  }

  // Set alertIfFail to false if connection helper is already going to display a warning message
  public async enableGps(enable: boolean, alertIfFail = true) {
    if (!enable) {
      this.appSettings.gps.enabled = false;
      this.updateAppSettings();
    } else if (enable && !this.isAndroid && !this.isIos) {
      this.appSettings.gps.enabled = true;
      this.updateAppSettings();
    } else {
      let feat = await this.checkForGps();
      feat = { ...feat, enabled: true };
      if (!feat.available) {
        const alert = await this.alertCtrl.create({
          header: 'Location Services Off!',
          message: 'The NextCentury App requires GPS Location to use certain features. Please turn on ' +
            'Location Services in your phone\'s settings',
          buttons: [
            {
              text: 'Cancel',
            },
            {
              text: 'Settings', handler: async () => {
                await this.turnOnLocation();
                this.updateAppSettings();
              },
            },
          ],
        });
        await alert.present();
        this.updateAppSettings();
      } if (!feat.osPermission && alertIfFail) {
        // try to get permission.
        const status = await this.diagnostic.getLocationAuthorizationStatus();
        if (status === this.diagnostic.permissionStatus.GRANTED ||
          status === this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE) {
          this.updateAvailability(this.appSettings.gps, feat);
          this.updateAppSettings();
        } else if (status === this.diagnostic.permissionStatus.NOT_REQUESTED ||
          status === this.diagnostic.permissionStatus.DENIED_ONCE) {
          const answer = await this.diagnostic.requestLocationAuthorization();
          if (answer === this.diagnostic.permissionStatus.GRANTED ||
            answer === this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE) {
            this.appSettings.gps.enabled = true;
          } else {
            this.appSettings.gps.enabled = false;
          }
          this.updateAvailability(this.appSettings.gps, feat);
          this.updateAppSettings();
        } else {
          const alert = await this.alertCtrl.create({
            header: 'No GPS Location Access!',
            message: 'The NextCentury App requires GPS Location to use certain features. Please ensure ' +
              'that Location Services is enabled in your phone\'s settings.',
            buttons: [
              {
                text: 'Cancel', handler: () => {
                  this.appSettings.gps.enabled = false;
                  this.updateAvailability(this.appSettings.gps, feat);
                  this.updateAppSettings();
                },
              },
              {
                text: 'Settings', handler: () => {
                  this.appSettings.gps.enabled = false;
                  this.updateAppSettings();
                  this.diagnostic.switchToSettings();
                },
              },
            ],
          });
          await alert.present();
          this.updateAppSettings();
        }
      } else if (!feat.available && alertIfFail) { // Do have permission, but gps unavailable for some reason.
        const alert = await this.alertCtrl.create({
          header: 'GPS Location Unavailable!',
          message: 'Your device cannot retrieve GPS information currently.',
          buttons: [{
            text: 'Ok', handler: () => {
              this.appSettings.gps.enabled = false;
              this.updateAvailability(this.appSettings.gps, feat);
              this.updateAppSettings();
            },
          }],
        });
        alert.present();
      } else {
        this.appSettings.gps = { enabled: true, available: true, osPermission: true };
        this.updateAvailability(this.appSettings.gps, feat);
        this.updateAppSettings();
      }
    }
  }

  public async needPermission(permission: permissionType) {
    console.log('needPermission', permission);
    let getFeature: Function = null;
    let featureString = null;
    let featureCallback: Function = null;
    switch (permission) {
      case permissionType.Gps:
        getFeature = () => this.appSettings.gps;
        featureString = 'GPS';
        featureCallback = this.enableGps.bind(this);
        break;
      case permissionType.Ble:
        getFeature = () => this.appSettings.ble;
        featureString = 'Bluetooth';
        featureCallback = this.enableBle.bind(this);
        break;
      case permissionType.Camera:
        getFeature = () => this.appSettings.camera;
        featureString = 'Camera';
        featureCallback = this.enableCamera.bind(this);
        break;
      case permissionType.Storage:
        getFeature = () => this.appSettings.storage;
        featureString = 'Storage';
        featureCallback = this.enableStorage.bind(this);
        break;
    }
    let feature = getFeature();
    if (feature.available && feature.osPermission && feature.enabled) {
      return true;
    } else {
      if (!feature.available) {
        if (permission === permissionType.Gps) {
          await featureCallback(true);
        }
        return false;
      } else if (!feature.osPermission) { // no OS permission
        await featureCallback(true);
        feature = getFeature();
        if (feature.enabled) {
          return true;
        } else {
          return false;
        }
      } else { // have OS permission, but turned off on settings page
        return new Promise(async (resolve, reject) => {
          const permissionAlert = await this.alertCtrl.create({
            header: `${featureString} Permission`,
            message: `In order to use this feature, you need to enable the ${featureString} permission`,
            buttons: [{
              text: 'Cancel',
              handler: () => {
                permissionAlert.dismiss().then(() => {
                  resolve(false);
                });
              },
            }, {
              text: `Enable ${featureString}`,
              handler: async () => {
                permissionAlert.dismiss().then(async () => {
                  await featureCallback(true);
                  feature = getFeature();
                  resolve(true);
                });
              },
            }],
          });
          permissionAlert.present();
        });
      }
    }
  }

  public async enableCamera(enable: boolean) {
    if (!enable) {
      this.appSettings.camera.enabled = false;
      this.appSettings.cloudScanner.enabled = false;
      this.appSettings.barcodeBeep = false;
      this.updateAppSettings();
    } else if (this.isBrowser) {
      this.appSettings.camera = { available: true, enabled: true, osPermission: true };
      this.appSettings.cloudScanner.enabled = true;
      this.appSettings.barcodeBeep = true;
      this.updateAppSettings();
    } else {
      try {
        const status = await this.diagnostic.getCameraAuthorizationStatus();
        if (status === this.diagnostic.permissionStatus.GRANTED ||
          status === this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE) {
          this.appSettings.camera = { available: true, enabled: true, osPermission: true };
          this.appSettings.cloudScanner.enabled = true;
          this.appSettings.barcodeBeep = true;
          this.updateAppSettings();
        } else if (status === this.diagnostic.permissionStatus.NOT_REQUESTED ||
          status === this.diagnostic.permissionStatus.DENIED_ONCE) {
          const res = await this.diagnostic.requestCameraAuthorization(false);
          if (res === this.diagnostic.permissionStatus.GRANTED ||
            res === this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE) {
            this.appSettings.camera = { available: true, enabled: true, osPermission: true };
            this.appSettings.cloudScanner.enabled = true;
            this.appSettings.barcodeBeep = true;
            this.updateAppSettings();
          } else {
            this.appSettings.camera.enabled = false;
            this.appSettings.cloudScanner.enabled = false;
            this.appSettings.barcodeBeep = false;
            this.updateAppSettings();
          }
        } else {
          const alert = await this.alertCtrl.create({
            header: 'No Camera Access!',
            subHeader: 'The NextCentury App does not have access to your camera or photos. To enable ' +
              'access, please tap settings and turn on camera access.',
            buttons: [{
              text: 'Cancel',
              role: 'cancel',
              handler: () => {
                this.appSettings.camera.enabled = false;
                this.appSettings.cloudScanner.enabled = false;
                this.appSettings.barcodeBeep = false;
                this.updateAppSettings();
              },
            }, {
              text: 'Settings',
              handler: () => {
                this.appSettings.camera.enabled = false;
                this.appSettings.cloudScanner.enabled = false;
                this.appSettings.barcodeBeep = false;
                this.updateAppSettings();
                this.diagnostic.switchToSettings();
              },
            }],
          });
          alert.present();
        }
      } catch (e) {
        console.log('Error while enabling camera access', e);
        this.appSettings.camera.enabled = false;
        this.appSettings.cloudScanner.enabled = false;
        this.appSettings.barcodeBeep = false;
        this.updateAppSettings();
      }
    }
  }

  public async enableStorage(enable: boolean) {
    if (!enable) {
      this.appSettings.storage.enabled = false;
      this.updateAppSettings();
    } else {
      if (this.platform.is('android')) {
        try {
          const status = await this.diagnostic.getExternalStorageAuthorizationStatus();
          if (status === this.diagnostic.permissionStatus.GRANTED ||
            status === this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE) {
            this.appSettings.storage = { available: true, enabled: true, osPermission: true };
            this.updateAppSettings();
          } else if (status === this.diagnostic.permissionStatus.NOT_REQUESTED ||
            status === this.diagnostic.permissionStatus.DENIED_ONCE) {
            const res = await this.diagnostic.requestExternalStorageAuthorization();
            if (res === this.diagnostic.permissionStatus.GRANTED ||
              res === this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE) {
              this.appSettings.storage = { available: true, enabled: true, osPermission: true };
              this.updateAppSettings();
            } else {
              this.appSettings.storage.enabled = false;
              this.updateAppSettings();
            }
          } else {
            const alert = await this.alertCtrl.create({
              header: 'No Gallery Access!',
              subHeader: 'The NextCentury App does not have access to your gallery. To enable ' +
                'access, please tap settings and turn on file and storage access.',
              buttons: [{
                text: 'Cancel',
                role: 'cancel',
                handler: () => {
                  this.appSettings.storage.enabled = false;
                  this.updateAppSettings();
                },
              }, {
                text: 'Settings',
                handler: () => {
                  this.appSettings.storage.enabled = false;
                  this.updateAppSettings();
                  this.diagnostic.switchToSettings();
                },
              }],
            });
            alert.present();
          }
        } catch (e) {
          console.log('Error while enabling gallery access', e);
          this.appSettings.storage.enabled = false;
          this.updateAppSettings();
        }
      } else if (this.platform.is('ios')) {
        this.appSettings.storage.enabled = true;
        this.updateAppSettings();
      }
    }
  }

  public enableCloudBarcodeScanner(enable: boolean) {
    if (!enable) {
      this.appSettings.cloudScanner.enabled = false;
      this.updateAppSettings();
    } else {
      if (this.appSettings.camera.osPermission) {
        this.appSettings.cloudScanner.enabled = true;
        this.appSettings.camera.enabled = true;
        this.updateAppSettings();
      } else {
        this.enableCamera(true);
      }
    }
  }

  public enableBarcodeBeep(enable: boolean) {
    if (!enable) {
      this.appSettings.barcodeBeep = false;
      this.updateAppSettings();
    } else {
      this.appSettings.barcodeBeep = true;
    }
  }

  public async enableBle(enable: boolean) {
    if (!enable) {
      this.appSettings.ble.enabled = false;
      this.appSettings.bleProximity = false;
      this.updateAppSettings();
    } else {
      let status = await this.diagnostic.getBluetoothState();
      if (status === this.diagnostic.bluetoothState.POWERED_OFF ||
        status === this.diagnostic.bluetoothState.UNKNOWN) {

        // Ble is not Turned on
        const alert = await this.alertCtrl.create({
          header: 'BLE Radio Disabled!',
          subHeader: 'The NextCentury App uses your devices Bluetooth to Direct-Connect ' +
            'to equipment. Please enable your devices Bluetooth radio from device settings.',
          buttons: [{
            text: 'Ok',
            role: 'cancel',
            handler: () => {
              this.appSettings.ble.osPermission = false;
              this.appSettings.ble.enabled = false;
              this.appSettings.bleProximity = false;
              this.updateAppSettings();
            },
          }],
        });
        alert.present();
      } else if (status === this.diagnostic.bluetoothState.UNAUTHORIZED) {
        await this.diagnostic.requestBluetoothAuthorization();
        status = await this.diagnostic.getBluetoothState();
        if (status === this.diagnostic.bluetoothState.UNAUTHORIZED) {
          const alert = await this.alertCtrl.create({
            header: 'No BLE Radio Access!',
            subHeader: 'The NextCentury App uses your devices Bluetooth to Direct-Connect ' +
              'to equipment. Please allow bluetooth access from your device settings.',
            buttons: [{
              text: 'Cancel',
              role: 'cancel',
            }, {
              text: 'Settings',
              handler: () => {
                this.appSettings.ble.osPermission = false;
                this.appSettings.ble.enabled = false;
                this.appSettings.bleProximity = false;
                this.updateAppSettings();
                this.diagnostic.switchToSettings();
              },
            }],
          });
          alert.present();
        }
      } else {
        this.appSettings.ble.osPermission = true;
        this.appSettings.ble.enabled = true;
        this.updateAppSettings();
      }
    }
  }

  public enableBleProximity(enable: boolean) {
    if (!enable) {
      this.appSettings.bleProximity = false;
      this.updateAppSettings();
    } else {
      this.enableBle(true);
    }
  }

  public enableFlashlight(enable: boolean) {
    this.appSettings.flashlight.enabled = enable;
    this.updateAppSettings();
  }

  public enableShowAdminTools(enable: boolean) {
    this.appSettings.showAdminTools = enable;
    this.updateAppSettings();
  }

  public setBackEnd(host: BackEndHost) {
    this.appSettings.backEnd = host;
    const socketURL = host === BackEndHost.Production ? BackEndHost.socketEndpoint : host;
    this.appSettings.socketEndpoint = socketURL;
    this.updateAppSettings();
  }

  public closeMenu() {
    this.menuController.getMenus()
      .then((menus) => {
        _.forEach(menus, (menu) => {
          menu.close();
        });
      });
  }

  public async checkFeatureAvailability() {
    Promise.all([
      this.checkForCamera(),
      this.checkForStorage(),
      this.checkForGps(),
      this.checkForBle(),
      this.checkForBiometric(),
      this.checkForFlashlight(),
    ]).then((res) => {
      this.updateAvailability(this.appSettings.camera, res[0]);
      this.updateAvailability(this.appSettings.cloudScanner, res[0]);
      this.updateAvailability(this.appSettings.storage, res[1]);
      this.updateAvailability(this.appSettings.gps, res[2]);
      this.updateAvailability(this.appSettings.ble, res[3]);
      this.updateAvailability(this.appSettings.biometric, res[4]);
      this.updateAvailability(this.appSettings.flashlight, res[5]);

      this.updateAppSettings();
    });
  }

  private updateAvailability(savedFeature: IFeature, newFeature: IFeature) {
    savedFeature.available = newFeature.available;
    (savedFeature as IBiometricFeature).type = (newFeature as IBiometricFeature).type;
    if (newFeature.osPermission !== savedFeature.osPermission) {
      savedFeature.osPermission = newFeature.osPermission;
      savedFeature.enabled = newFeature.osPermission;
    }
  }

  private async checkForCamera(): Promise<IFeature> {
    const feature: IFeature = { available: false, osPermission: false, enabled: false };
    if (this.isBrowser) {
      feature.available = true;
      feature.osPermission = true;
      feature.enabled = true;
      return feature;
    }
    try {
      feature.available = await this.diagnostic.isCameraPresent();
      const status = await this.diagnostic.getCameraAuthorizationStatus(false);
      feature.osPermission = status === this.diagnostic.permissionStatus.GRANTED ||
        status === this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE;
    } catch (e) { console.log('Error checking for Camera', e); }
    return feature;
  }

  private async checkForStorage(): Promise<IFeature> {
    const feature: IFeature = { available: false, osPermission: false, enabled: false };
    try {
      feature.available = await this.diagnostic.isCameraPresent();
      if (this.isAndroid) {
        const status = await this.diagnostic.getExternalStorageAuthorizationStatus();
        if (status === this.diagnostic.permissionStatus.GRANTED ||
          status === this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE) {
          feature.osPermission = true;
        }
      } else {
        const status = await this.diagnostic.getCameraAuthorizationStatus();
        if (status === this.diagnostic.permissionStatus.GRANTED ||
          status === this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE) {
          feature.osPermission = true;
        }
      }
    } catch (e) { console.log('Error checking for Camera Roll', e); }
    return feature;
  }

  private async checkForGps(): Promise<IFeature> {
    const feature: IFeature = { available: false, osPermission: false, enabled: false };
    try {
      const [status, available] = await Promise.all([
        this.diagnostic.getLocationAuthorizationStatus(),
        this.diagnostic.isLocationEnabled(),
      ]);
      feature.osPermission = (status === this.diagnostic.permissionStatus.GRANTED ||
        status === this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE);
      feature.available = available;
      if (this.isAndroid && (+this.device.version >= 13)) {
        feature.osPermission = available;
        feature.enabled = available;
      }
    } catch (e) {
      console.log('Error checking for GPS', e);
    }
    return feature;
  }

  private async checkForBle(): Promise<IFeature> {
    const feature: IFeature = { available: false, osPermission: false, enabled: false };
    try {
      if (this.isAndroid || this.isIos) {
        const status = await this.diagnostic.getBluetoothState();
        feature.osPermission =
          status &&
          status !== this.diagnostic.bluetoothState.UNKNOWN &&
          status !== this.diagnostic.bluetoothState.UNAUTHORIZED &&
          status !== this.diagnostic.bluetoothState.UNSUPPORTED;
        const turnedOn = status === this.diagnostic.bluetoothState.POWERED_ON;
        if (this.platform.is('android')) {
          feature.available = await this.diagnostic.hasBluetoothLESupport() && turnedOn;
        } else if (this.platform.is('ios')) {
          // All iPhones we support have bluetooth available
          feature.available = true && turnedOn;
        }
      }
    } catch (e) {
      console.log('Error checking for BLE', e);
    }
    return feature;
  }

  private async checkForBiometric(): Promise<IBiometricFeature> {
    const feature: IBiometricFeature = { available: false, osPermission: false, enabled: false, type: BiometricType.NONE };
    try {
      if (!this.isBrowser) {
        const res = await this.fingerprintAIO.isAvailable();
        console.log('%c CHECKING BIOMETRICS', 'color: red', res);
        if (res && res.toLowerCase() !== 'not available') {
          feature.type = res === 'face' ? BiometricType.FACE_AUTH : BiometricType.TOUCH_AUTH;
          feature.available = true;
          feature.osPermission = true;
        }
      }
    } catch (e) {
      console.log('Error checking for Biometrics', e);
      if (!this.isBrowser) {
        feature.available = true;
      }
    }
    return feature;
  }

  private async checkForFlashlight(): Promise<IFeature> {
    const feature: IFeature = { available: false, osPermission: false, enabled: false };
    try {
      const present = await this.flashlight.available();
      if (present) {
        feature.available = true;
        feature.osPermission = true;
      }
    } catch (e) { console.log('Error checking for flashlight'); }
    return feature;
  }

  private checkIsBrowser() {
    this.isBrowser = this.platform.is('desktop') || !this.platform.is('cordova');
    if (this.isBrowser) {
      this.enableCamera(true);
    }
  }

  private async readSettings(): Promise<any> {
    try {
      this.appSettings = (await this.readUnsecureSettings());
    } catch (e) { console.log('Could not read unsecured settings', e); }

    // Ensure that appSettings exists
    this.appSettings = this.appSettings || _.cloneDeep(defaultSettings);

    try {
      this.appSettings.secure = (await this.readSecureSettings()) || {};
    } catch (e) { console.log('Could not read secured settings', e); }

    // Ensure that appSettings.secure exists
    this.appSettings.secure = this.appSettings.secure || {};

    this.mergeDefaults(this.appSettings, _.cloneDeep(defaultSettings));
    this.appSettings.available = true;
    this.updateAppSettings();
  }

  private async writeSettings(info: IAppSettings): Promise<boolean> {
    const unsecuredSuccess = await this.writeUnsecureSettings(_.omit(info, ['secure', 'backEnd', 'socketEndpoint']));
    const securedSuccess = await this.writeSecureSettings(info ? info.secure : defaultSettings.secure);
    return unsecuredSuccess && securedSuccess;
  }

  private readSecureSettings(): Promise<ISecureSettings> {
    let settings: ISecureSettings = defaultSettings.secure;
    if (!this.cordovaAvailable) {
      return Promise.resolve(settings);
    }
    return this.initSecureStorage().then((obj) => {
      return obj.get(OBJ_NAME).then((data) => {
        const bytes = CryptoJS.AES.decrypt((data), KEY);
        settings = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return settings;
      }).catch((e) => {
        console.error('Error reading from secure storage: ', e);
        return settings;
      });
    }).catch((e) => {
      console.error('Error initializing secure storage: ', e);
      return settings;
    });
  }

  private async readUnsecureSettings(): Promise<IAppSettings> {
    return this.nativeStorage.getItem(NATIVE_NAME).then((settings) => _.omit(settings || _.cloneDeep(defaultSettings), ['backEnd', 'socketEndpoint']));
  }

  private async writeSecureSettings(info: ISecureSettings): Promise<boolean> {
    let success = false;
    const secure = info || defaultSettings.secure;
    if (!this.cordovaAvailable) {
      return success;
    }
    try {
      const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(secure), KEY);
      const obj = await this.initSecureStorage();
      await obj.set(OBJ_NAME, encryptedData.toString());
      success = true;
    } catch (e) {
      console.log('Error writing to secure storage: ' + e);
    }
    return success;
  }

  private writeUnsecureSettings(info: IAppSettings): Promise<boolean> {
    return this.nativeStorage.setItem(NATIVE_NAME, info || {}).then(() => {
      return true;
    }).catch((e) => {
      console.log('Error writing to native storage: ' + e);
      return false;
    });
  }

  private async initSecureStorage(): Promise<SecureStorageObject> {
    if (!this.cordovaAvailable) {
      return Promise.resolve(null);
    }

    return this.secureStorage.create(SECURE_NAME)
      .then((obj: SecureStorageObject) => {
        this.secureStorageAvailable = true;
        return obj;
      });
  }

  private async mergeDefaults(userSettings: any, defaults: any) {
    _.forEach(defaults, (defaultsValue, defaultsKey) => {
      if (!_.isObject(defaultsValue) && !userSettings.hasOwnProperty(defaultsKey)) {
        userSettings[defaultsKey] = defaultsValue;
      } else if (_.isObject(defaultsValue)) {
        // Recursion City
        if (!userSettings[defaultsKey]) {
          userSettings[defaultsKey] = {};
        }
        this.mergeDefaults(userSettings[defaultsKey], defaultsValue);
      }
    });
  }

  private async migrateSettings(): Promise<any> {
    let complete = false;
    try {
      const item = await this.nativeStorage.getItem('migrationComplete');
      complete = item && item.complete;
    } catch (e) {
      console.log('Could not load migrationComplete key from nativeStorage');
    }
    if (!complete) {
      let settings: IAppSettings = _.cloneDeep(defaultSettings);
      try {
        const obj = await this.secureStorage.create('ncss-app-info');
        const data = await obj.get('savedInfo');
        const bytes = CryptoJS.AES.decrypt((data), KEY);
        settings = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      } catch (e) {
        console.log('Could not recover settings from secure: ncss-app-info');
      }

      if (settings.hasOwnProperty('username')) { this.appSettings.username = settings.username; }
      if (settings.hasOwnProperty('savePassword')) { this.appSettings.savePassword = settings.savePassword; }
      if (settings.hasOwnProperty('defaultNavAppKey')) { this.appSettings.defaultNavAppKey = settings.defaultNavAppKey; }
      if (settings.hasOwnProperty('slides')) { this.appSettings.slides = settings.slides; }
      if (settings.hasOwnProperty('password')) {
        this.appSettings.secure = this.appSettings.secure || {};
        this.appSettings.secure.password = settings.password;
      }
      try {
        await this.nativeStorage.setItem('migrationComplete', { complete: true });
      } catch (e) {
        console.log('Could not write settings to native storage');
      }
      this.updateAppSettings();
    }
  }
}
