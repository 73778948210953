import { LeakSensor, LeakSensorAlert, Unit } from '@ncss/models';

import { FormGroup, AbstractControl, FormControl, Validators, ValidatorFn, FormArray } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { isEqual } from 'lodash';

import { CameraService } from '../services/camera.service';
import { MobilePropertyService } from '../services/mobile-property.service';
import { MobileUserService } from '../services/mobile-user.service';
import { clearErrorOnControl, CreateNoEmojiValidator, setErrorOnControl } from './common-validators';
import { PhotoControl } from './photo-control';
import { CreateCanProgram, ForLeakSensor, ForSerialNumberLength } from './serial-number-validators';

const DefaultLocationIcon = 'leak-sensor-drawing';

export class LeakSensorsFormArray extends FormArray {
  static FromUnit(
    propertyService: MobilePropertyService,
    cameraService: CameraService,
    userService: MobileUserService,
    modalCtl: ModalController,
    unit: Unit,
    alerts: LeakSensorAlert[],
  ): LeakSensorsFormArray {
    const controls: LeakSensorFormGroup[] = [];
    for (const ls of (unit.leakSensors || [])) {
      controls.push(
        LeakSensorFormGroup.Create(
          propertyService,
          cameraService,
          userService,
          modalCtl,
          unit,
          ls,
          alerts.filter((a) => a.leakSensorLocation === ls.location),
        ),
      );
    }

    return new LeakSensorsFormArray(
      controls,
      propertyService,
      cameraService,
      userService,
      modalCtl,
      unit,
    );
  }

  controls: LeakSensorFormGroup[];

  private originalValues;

  private constructor(
    controls: LeakSensorFormGroup[],
    private propertyService: MobilePropertyService,
    private cameraService: CameraService,
    private userService: MobileUserService,
    private modalCtl: ModalController,
    private unit: Unit,
  ) {
    super(
      controls,
      [
        CreateDuplicateDeviceIdValidator(),
        CreateDuplicateLocationValidator(),
      ],
    );
    this.originalValues = this.value;
    this.setupListeners();
  }

  removeLeakSensor(location: string) {
    const index = this.controls.findIndex((c) => c.controls.location.value === location);
    this.removeAt(index);
    this.updateValueAndValidity();
  }

  addNewLeakSensor(location?: string) {
    const newLeakSensor = LeakSensorFormGroup.Create(
      this.propertyService,
      this.cameraService,
      this.userService,
      this.modalCtl,
      this.unit,
    );
    if (location) {
      newLeakSensor.controls.location.setValue(location);
    }
    this.push(newLeakSensor);
    this.updateValueAndValidity();
  }

  private setupListeners() {
    this.valueChanges.subscribe(() => {
      this.checkForChanges();
    });
  }

  private checkForChanges() {
    if (isEqual(this.originalValues, this.value)) {
      this.markAsUntouched();
      this.markAsPristine();
    } else {
      this.markAsTouched();
      this.markAsDirty();
    }
  }
}

export class LeakSensorFormGroup extends FormGroup {
  static Create(
    propertyService: MobilePropertyService,
    cameraService: CameraService,
    userService: MobileUserService,
    modalCtl: ModalController,
    unit: Unit,
    leakSensor?: LeakSensor,
    alerts?: LeakSensorAlert[],
  ): LeakSensorFormGroup {
    return new LeakSensorFormGroup(
      propertyService,
      cameraService,
      userService,
      modalCtl,
      unit,
      leakSensor,
      alerts,
    );
  }

  controls: {
    location: AbstractControl;
    serialNumber: AbstractControl;
    description: AbstractControl;
    photo: PhotoControl;
    locationIcon: AbstractControl;
  };

  parent: LeakSensorsFormArray;


  private constructor(
    propertyService: MobilePropertyService,
    cameraService: CameraService,
    userService: MobileUserService,
    modalCtl: ModalController,
    private unit: Unit,
    private leakSensor?: LeakSensor,
    public alerts?: LeakSensorAlert[],
  ) {
    super({
      location: new FormControl(leakSensor ? leakSensor.location : null,
        [Validators.required, Validators.maxLength(30), CreateNoEmojiValidator('Location')]),
      serialNumber: new FormControl(
        leakSensor ? leakSensor.id || null : null,
        [ForSerialNumberLength(), ForLeakSensor()],
        [CreateCanProgram(
          propertyService,
          propertyService.property ? propertyService.property._id : null,
          { unitId: unit._id },
        )],
      ),
      description: new FormControl(leakSensor ? leakSensor.description || null : null, Validators.maxLength(144)),
      photo: PhotoControl.FromLeakSensor(leakSensor, cameraService, modalCtl, userService),
      locationIcon: new FormControl(leakSensor ? leakSensor.locationIcon || DefaultLocationIcon : DefaultLocationIcon),
    });
    this.setupListeners();
    if (!this.controls.location.value) {
      this.controls.location.markAsTouched();
      this.controls.location.markAsDirty();
    }
  }

  originalUnit(): Unit {
    return this.unit;
  }

  originalLeakSensor(): LeakSensor | undefined {
    return this.leakSensor;
  }

  toLeakSensor(): LeakSensor {
    let programmedAt: Date;
    if (this.originalLeakSensor()) {
      if (this.originalLeakSensor().id !== this.controls.serialNumber.value && !!this.controls.serialNumber.value) {
        programmedAt = new Date();
      } else {
        programmedAt = this.originalLeakSensor().programmedAt;
      }
    } else if (this.controls.serialNumber.value) {
      programmedAt = new Date();
    }
    return new LeakSensor({
      ...this.leakSensor,
      location: this.controls.location.value,
      description: this.controls.description.value,
      locationIcon: this.controls.locationIcon.value,
      id: this.controls.serialNumber.value,
      data: (this.leakSensor && this.leakSensor.data && this.leakSensor.id === this.controls.serialNumber.value) ?
        this.leakSensor.data : null,
      programmedAt,
    });
  }

  private setupListeners() {
    this.controls.location.valueChanges.subscribe((location) => {
      const icon = this.inferLocationIcon(location);
      if (this.controls.locationIcon.value !== location) {
        this.controls.locationIcon.setValue(icon);
      }
    });
  }

  private inferLocationIcon(location: string) {
    if (/dish/i.test(location)) {
      return 'dishwasher';
    } else if (/toilet|bath|restroom/i.test(location)) {
      return 'bathroom';
    } else if (/fridge|freezer|refrigerator/i.test(location)) {
      return 'refrigerator';
    } else if (/kitchen|sink/i.test(location)) {
      return 'kitchen';
    } else if (/laundry|washer|wash(er|ing) machine/i.test(location)) {
      return 'laundry';
    } else if (/hvac|A\/C/i.test(location)) {
      return 'hvac';
    } else if (/(water )?heater/i.test(location)) {
      return 'water heater';
    } else {
      return 'leak-sensor-drawing';
    }
  }
}

function CreateDuplicateDeviceIdValidator(): ValidatorFn {
  return (leakSensors: LeakSensorsFormArray) => {
    let errors: { duplicateSerial: string } | null = null;
    for (const leakSensor of leakSensors.controls) {
      const deviceId = leakSensor.controls.serialNumber;
      if (!deviceId || !deviceId.value) { continue; }
      const otherLeakSensorsWithId = leakSensors.controls.filter((l) => l.controls.serialNumber.value === deviceId.value);
      if (otherLeakSensorsWithId.length > 1) {
        const errorMessage = `"${deviceId.value.toString(16).toUpperCase()}" can't be programmed to two locations.`;
        errors = {
          duplicateSerial: errorMessage,
        };
        setErrorOnControl(deviceId, 'duplicateSerial', errorMessage);
      } else {
        clearErrorOnControl(deviceId, 'duplicateSerial');
      }
    }

    return errors;
  };
}

function CreateDuplicateLocationValidator(): ValidatorFn {
  return (leakSensors: LeakSensorsFormArray) => {
    let errors: { duplicateLocation: string } | null = null;
    for (const leakSensor of leakSensors.controls) {
      const location = leakSensor.controls.location;
      if (!location || !location.value) { continue; }
      const otherLeakSensorsWithLocation = leakSensors.controls.filter((l) => l.controls.location.value === location.value);
      if (otherLeakSensorsWithLocation.length > 1) {
        const errorMessage = `The location "${location.value}" needs to be unique. Try adding identifiers like "${location.value} A" and "${location.value} B".`;
        errors = {
          duplicateLocation: errorMessage,
        };
        setErrorOnControl(location, 'duplicateLocation', errorMessage);
      } else {
        clearErrorOnControl(location, 'duplicateLocation');
      }
    }

    return errors;
  };
}
