import { LeakSensor, Lorax, Unit, DeviceTypeHelper, HydrateRead, LS4Read, LeakLevel, TLS4Read } from '@ncss/models';

import { Component, Input, OnChanges, OnInit, Output, EventEmitter } from '@angular/core';
import { PopoverController, ActionSheetController } from '@ionic/angular';
import { ActionSheetButton } from '@ionic/core';
import * as moment from 'moment';
import { debounceTime } from 'rxjs/operators';

import { LeakSensorFormGroup } from '../../../../../angularUtilities/leak-sensor-form-array';
import { CustomInputPopoverComponent, CustomInputPopoverType } from '../../../../../components/custom-input-popover/custom-input-popover.component';
import { DocumentUploadService } from '../../../../../services/document-upload.service';

@Component({
  selector: 'app-leak-sensor-details',
  styleUrls: ['./leak-sensor-details.component.scss'],
  templateUrl: './leak-sensor-details.component.html',
})
export class LeakSensorDetailsComponent implements OnChanges, OnInit {
  @Input() leakSensor: LeakSensorFormGroup;
  @Output() delete = new EventEmitter<string>();

  unitId: string;
  leakSensorStatus: LeakSensorStatus;
  showMoreStatus = false;

  constructor(
    public uploadService: DocumentUploadService,
    private popCtl: PopoverController,
    private actionCtl: ActionSheetController,
  ) { }

  ngOnInit(): void {
    this.unitId = this.leakSensor.originalUnit()._id;
    this.leakSensor.valueChanges.pipe(debounceTime(100)).subscribe(() => {
      this.refreshStatus();
    });
  }

  ngOnChanges(changes) {
    this.refreshStatus();
  }

  async programDeviceClicked(tapEvent?: Event) {
    if (tapEvent) { tapEvent.preventDefault(); }
    const p = await this.popCtl.create({
      component: CustomInputPopoverComponent,
      animated: true,
      backdropDismiss: true,
      cssClass: 'custom-popover',
      componentProps: {
        header: tapEvent ? 'Edit Serial Number' : 'Program Device',
        type: CustomInputPopoverType.SERIAL_INPUT_POPOVER,
        submitLabel: 'Program',
        initialValue: tapEvent ? this.leakSensor.controls.serialNumber.value : null,
        syncValidators: this.leakSensor.controls.serialNumber.validator,
        asyncValidators: this.leakSensor.controls.serialNumber.asyncValidator,
      },
    });
    await p.present();
    const res = await p.onDidDismiss();
    if (res.data) {
      this.leakSensor.controls.serialNumber.setValue(res.data.value);
    }
  }

  async quickActionsClicked() {
    const buttons: ActionSheetButton[] = [];
    if (this.leakSensor.controls.location.value) {
      buttons.push({
        text: `Delete ${this.leakSensor.controls.location.value} Leak Sensor`,
        icon: 'icon-trash-2',
        handler: () => {
          this.delete.next(this.leakSensor.controls.location.value);
        },
      });
    }
    if (this.leakSensor.controls.serialNumber.value && this.leakSensor.controls.serialNumber.valid) {
      buttons.unshift({
        text: `Replace ${this.leakSensor.controls.serialNumber.value.toString(16).toUpperCase()}`,
        icon: 'icon-leak-sensor',
        handler: () => {
          const oldId = this.leakSensor.controls.serialNumber.value;
          this.leakSensor.controls.serialNumber.setValue(null);
          this.programDeviceClicked().then(() => {
            if (!this.leakSensor.controls.serialNumber.value) {
              this.leakSensor.controls.serialNumber.setValue(oldId);
            }
          });
          return true;
        },
      });
    }
    const a = await this.actionCtl.create({
      header: 'Quick Actions',
      buttons,
    });
    await a.present();
  }

  private refreshStatus() {
    const unit = this.leakSensor.originalUnit();
    unit.leakSensors = this.leakSensor.parent.controls.map((leakSensorForm) => leakSensorForm.toLeakSensor());
    this.leakSensorStatus = getLeakSensorStatus(
      this.leakSensor.toLeakSensor(),
      unit,
    );
  }
}


interface LeakSensorStatus {
  status: string;
  color: string;
  icon: string;
  iconColor: string;
  lastCheckInStr?: string;
  stats: Array<{ icon: string, label: string, value: string, iconClass: string }>;
  isProgrammed: boolean;
}

function getLeakSensorStatus(leakSensor: LeakSensor, unit: Unit): LeakSensorStatus {
  let status = '';
  let color = '';
  let icon = '';
  let iconColor = '';
  let lastCheckInStr;
  const stats: Array<{ icon: string, label: string, value: string, iconClass: string }> = [];
  const isProgrammed = !!leakSensor && !!leakSensor.id;
  const isTLS4: boolean = leakSensor.data instanceof TLS4Read;
  if (unit && leakSensor) {
    const loraxStatus = Lorax.getLeakSensorStatusFromUnit(unit, leakSensor.location);
    const lastCheckIn = Lorax.getLastCheckInFromLeakSensor(leakSensor);
    const hydratedRead = isTLS4 ? HydrateRead(leakSensor.data) as TLS4Read : HydrateRead(leakSensor.data) as LS4Read | null;
    const signalStrength = Lorax.getLinkQualityFromLeakSensor(leakSensor);
    let signalIcon = 'icon-check';
    let signalIconColor = 'color-green';
    if (signalStrength.error) {
      signalIcon = 'icon-minus';
      signalIconColor = 'color-gray-light';
    }
    switch (signalStrength.result.humanized) {
      case 'Excellent':
      case 'Good':
        signalIcon = 'icon-check';
        signalIconColor = 'color-green';
        break;
      case 'Poor':
        signalIcon = 'icon-x';
        signalIconColor = 'color-red';
        break;
      default:
        break;
    }
    stats.push({
      icon: signalIcon,
      label: 'Signal Strength',
      value: signalStrength.result.humanized,
      iconClass: signalIconColor,
    });
    stats.push({
      icon: 'icon-thermometer',
      label: 'Temperature',
      value: Lorax.getTemperatureFromLeakSensor(leakSensor).result,
      iconClass: 'color-gray-light',
    });
    stats.push({
      icon: 'icon-battery',
      label: 'Battery',
      value: Lorax.getBatteryFromLeakSensor(leakSensor).result.humanized,
      iconClass: 'color-gray-light',
    });
    stats.push({
      icon: 'icon-water',
      label: 'Leak Type',
      value: Lorax.getLeakMonitoringType(leakSensor).result.leakMonitoringType,
      iconClass: 'color-gray-light',
    });
    stats.push({
      icon: 'icon-power-loss',
      label: 'Cable Connected',
      value: hydratedRead ? hydratedRead.leakCableDetected ? 'Yes' : 'No' : '-',
      iconClass: 'color-gray-light',
    });
    if (hydratedRead instanceof TLS4Read) {
      stats.push({
        icon: 'icon-trending-up',
        label: 'Estimated Usage',
        value: hydratedRead.getEstimatedConsumption(0),
        iconClass: 'color-gray-light',
      })
    }
    if (!isProgrammed) {
      status = 'Device Not Programmed';
      color = '#999999';
      icon = 'icon-minus';
      iconColor = '#999999';
    } else if (loraxStatus.error) {
      status = loraxStatus.result.errorString || '';
      color = '#999999';
      icon = 'icon-minus';
      iconColor = '#999999';
    } else {
      if (loraxStatus.result.leakLevel === LeakLevel.LEAK) {
        status = isTLS4 ? 'Leak Detected' : 'Water Detected';
        color = '#e3515f';
        icon = 'icon-alert-water-triangle';
        iconColor = '#e3515f';
      } else if (!loraxStatus.result.cableDetected) {
        status = 'Cable Not Detected';
        color = '#999999';
        icon = 'icon-x';
        iconColor = '#e3515f';
      } else if (!loraxStatus.result.online) {
        status = 'Offline';
        color = '#999999';
        icon = 'icon-x';
        iconColor = '#e3515f';
      } else {
        status = isTLS4 ? 'No Leak Detected' : 'No Water Detected';
        color = '#3E89BF';
        icon = 'icon-check';
        iconColor = '#7bc147';
      }
    }

    lastCheckInStr = !lastCheckIn.error ? 'Last Checked In ' + moment.duration(moment(lastCheckIn.result).diff(new Date())).humanize(true) : '';

    if (isProgrammed) {
      stats.push({
        label: 'Model',
        icon: 'icon-leak-sensor',
        value: DeviceTypeHelper.GetModelBySerialNumber(leakSensor.id) || 'Unknown',
        iconClass: 'color-gray-light',
      });
    }
  }

  return {
    status,
    color,
    icon,
    iconColor,
    lastCheckInStr,
    stats,
    isProgrammed,
  };
}
