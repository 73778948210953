import { PipeLogic } from '@ncss/models';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'capitalize' })
export class CapitalizePipe implements PipeTransform {
  public transform(value, args?) {
    return PipeLogic.CapitalizeLogic(value, args);
  }
}
