import { MessageService } from '@ncss/message';
import { DeviceTypeIds } from '@ncss/models';

import { ByteList } from 'byte-list';
import { Observable, Subject, Subscription, BehaviorSubject } from 'rxjs';

import { NcssEncryptPlugin } from './NcssEncryptPlugin.service';


const NordicId = 0x0059;
const DC301_MANUFACTURER_DATA_SIZE = 45;
const GW301_MANUFACTURER_DATA_SIZE = 19;
const RR4_MANUFACTURER_DATA_SIZE = 11;
const RE4_MANUFACTURER_DATA_SIZE = 10;
const GW4_MANUFACTURER_DATA_SIZE = 10;
export const NCSS_CONNECT_SERVICE_UUID = '8667000A-AB40-461B-AC61-BAFA8D71BB86';
const NCSS_CONNECT_CHARACTERISTIC_NOTIFY = '86670003-AB40-461B-AC61-BAFA8D71BB86';
const NCSS_CONNECT_CHARACTERISTIC_WRITE = '86670002-AB40-461B-AC61-BAFA8D71BB86';

export const NCSS_DIRECT_CONNECT_SERVICE_UUID = '6E40000A-B5A3-F393-E0A9-E50E24DCCA9E';
export const NCSS_DIRECT_CONNECT_CHARACTERISTIC_NOTIFY = '6E400003-B5A3-F393-E0A9-E50E24DCCA9E';
export const NCSS_DIRECT_CONNECT_CHARACTERISTIC_WRITE = '6E400002-B5A3-F393-E0A9-E50E24DCCA9E';

export const NCSS_BLE_WALK_BY_SERVICE_UUID = '6E400A00-B5A3-F393-E0A9-E50E24DCCA9E';
export const NCSS_BLE_WALK_BY_RX_ID = '6E400A01-B5A3-F393-E0A9-E50E24DCCA9E';
export const NCSS_BLE_WALK_BY_TX_ID = '6E400A02-B5A3-F393-E0A9-E50E24DCCA9E';

const RSSI_MAX = -55;
const RSSI_MIN = -120;


export enum DirectConnectDeviceStatus {
  UNKNOWN,
  ADVERTISING,
  CONNECTING,
  CONNECTED,
}

export interface IDirectConnectDevice {
  serialNumber: number;
  status: DirectConnectDeviceStatus;
  bleSignalStrength: number;
  bleDevice: any;
  device?: any;
  scanTimestamp?: Date;
  popoverTimestamp?: Date;
  subscriptions: Array<Subscription>;
  timers: Array<any>;
  isEndDevice?: boolean;
  keepModalOpenOnDisconnect?: boolean;
}

export interface ISetupByteHandlerResult {
  txBytes: Subject<ByteList>;
  rxBytes: Subscription;
}

export class DirectConnectBaseDevice {

  public constructor() {

  }

  static getManufacturerData(bleDevice: any): { serialNumber: number, byteList: ByteList } {
    let byteList: ByteList | null = null;
    if (!bleDevice || !bleDevice.advertising) {
      return null;
    }

    if (bleDevice.advertising.kCBAdvDataManufacturerData) {
      byteList = new ByteList(bleDevice.advertising.kCBAdvDataManufacturerData);
    } else if (bleDevice.advertising instanceof ArrayBuffer) {
      byteList = new ByteList(bleDevice.advertising);
    }

    const serialNumber = this.checkValidManufacturerData(byteList);

    if (!byteList || !serialNumber) {
      return null;
    }

    return (byteList && serialNumber) ? { serialNumber, byteList } : null;
  }

  static rssiToSignalStrength(val: number): number {
    if (val > RSSI_MAX) {
      return 100;
    } else if (val < RSSI_MIN) {
      return 0;
    }

    const percent = (val + (RSSI_MIN * -1)) / ((RSSI_MAX + (RSSI_MIN * -1)) - (RSSI_MIN + (RSSI_MIN * -1))) * 100;
    return +(percent.toFixed(0));
  }

  static checkValidManufacturerData(byteList: ByteList): number {
    if (!byteList) {
      return null;
    }
    const dc301SN = this.checkDC301ManufacturerData(byteList);
    if (dc301SN) {
      return dc301SN;
    }
    byteList.index = 0;
    const rr4SN = this.checkDeviceManufacturerData(byteList, RR4_MANUFACTURER_DATA_SIZE, [0xEA000000, 0xE1000000]);
    if (rr4SN) {
      return rr4SN;
    }

    byteList.index = 0;
    const re4SN = this.checkDeviceManufacturerData(byteList, RE4_MANUFACTURER_DATA_SIZE, [0xCA100000], 0xFFF00000);
    if (re4SN) {
      return re4SN;
    }

    byteList.index = 0;
    const gw4SN = this.checkDeviceManufacturerData(byteList, GW4_MANUFACTURER_DATA_SIZE, [0xDA200000], 0xFFF00000);
    if (gw4SN) {
      return gw4SN;
    }
    return null;
  }

  static checkDeviceManufacturerData(
    byteList: ByteList,
    expectedDataSize: number,
    expectedMasks: number[],
    maskBy = 0xFF000000): number | null {
    if (!byteList) { return null; }
    let deviceId = null;
    let indexAfterDeviceId = 0;
    this.stripOffPadding(byteList, expectedDataSize);
    for (let i = 0; i <= byteList.length - expectedDataSize; i++) {
      byteList.index = i;
      const nordicBytes = byteList.readUInt16();
      const deviceIdBytes = byteList.readUInt32();
      const mask = (deviceIdBytes & maskBy) >>> 0;
      if (nordicBytes === NordicId && expectedMasks.includes(mask)) {
        deviceId = deviceIdBytes;
        indexAfterDeviceId = byteList.index;
      }
    }
    byteList.index = indexAfterDeviceId;
    return deviceId;
  }

  private static stripOffPadding(bytes: ByteList, expectedDataSize: number): void {
    let zeroCount = 0;
    let trimRightCount = -1;
    for (let i = 0; i < bytes.length; i++) {
      const byte = bytes.readByte();
      if (byte === 0) {
        zeroCount += 1;
      } else {
        zeroCount = 0;
      }
      if (zeroCount >= expectedDataSize) {
        trimRightCount = bytes.length - (bytes.index - Math.floor(expectedDataSize / 2));
        break;
      }
    }
    if (trimRightCount !== -1) {
      bytes.trimRight(bytes.length - bytes.index);
    }
    bytes.index = 0;
  }

  static checkDC301ManufacturerData(byteList: ByteList): number | null {
    if (!byteList) {
      return null;
    }
    let indexOfSN = -1;
    if (byteList.length > 60) {
      for (let i = 0; i < byteList.length - 6; i++) {
        byteList.index = i;
        const nordic = byteList.readUInt16();
        const id = byteList.readUInt32();
        const mask = (id & 0xFF000000) >>> 0;
        if (nordic === NordicId && mask === 0xF1000000) {
          indexOfSN = i + 2;
          break;
        }
      }
    }
    if (indexOfSN !== -1) {
      byteList.index = indexOfSN;
    } else {
      if (byteList.length !== GW301_MANUFACTURER_DATA_SIZE &&
        byteList.length !== DC301_MANUFACTURER_DATA_SIZE) {
        return null;
      }
      if (byteList.readUInt16() !== NordicId) {
        return null;
      }
    }
    return byteList.readUInt32();
  }

  public serialNumber: number;
  public EncryptPlugin: NcssEncryptPlugin;
  public modelId: DeviceTypeIds;
  public hardwareVersion: { major: number, minor: number };
  public firmwareVersion: { major: number, minor: number };
  public directConnectVersion: { major: number, minor: number };
  public messageService: MessageService = new MessageService();
  public txBytes: Subject<ByteList> = new Subject<ByteList>();
  public uuid: { service: string, write: string, read: string } = {
    service: NCSS_CONNECT_SERVICE_UUID,
    write: NCSS_CONNECT_CHARACTERISTIC_WRITE,
    read: NCSS_CONNECT_CHARACTERISTIC_NOTIFY,
  };
  public signalStrengthStr$ = new BehaviorSubject<string>('');
  public signalStrengthColor$ = new BehaviorSubject<string>('');
  public isDisconnected = false;

  public setupByteHandlers(notifyEvent: Observable<any>): ISetupByteHandlerResult {
    // Bytes Received
    const subscription = notifyEvent.subscribe((buffer) => {
      const bytes = new ByteList(buffer);
      try {
        this.messageService.deserialize(bytes, (data, frameId) => {
          return this.EncryptPlugin.decrypt(data, this.serialNumber, frameId);
        });
      } catch (err) {
        console.error('Error Deserializing Msg', err);
      }
    }, (err) => {
      console.log('%c NOTIFY ERR', 'color: red', err);
    }, () => {
      console.log('%c NOTIFY COMPLETE', 'color: red');
    });

    const res = { txBytes: this.txBytes, rxBytes: subscription };
    return res;
  }

  public updateSignalStrength(signalStrength: number) {
    const obj = signalStrength >= 60
      ? { value: 'Good', color: '#7BC147' } : (signalStrength >= 30 ? { value: 'Fair', color: '#CEBF10' } : { value: 'Poor', color: '#E3515F' });
    this.signalStrengthStr$.next(`${obj.value} ${signalStrength}%`);
    this.signalStrengthColor$.next(obj.color);
  }

  public requestInfo() { }

}
