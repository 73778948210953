import {
  DeviceTypeHelper,
  DeviceTypeIds,
  BitwiseHelper,
  ConversionUtils,
  DeviceTypes,
  DeviceFamily,
  GW4Read,
  INetworkInterface,
  IWirelessNetworkInterface,
} from '@ncss/models';

import { ByteList } from 'byte-list';
import { Observable, timer, BehaviorSubject, merge, Subject, combineLatest, interval, from, of } from 'rxjs';
import {
  mapTo,
  take,
  filter,
  takeWhile,
  map,
  distinctUntilChanged,
  first,
  shareReplay,
  switchMap,
  concatMap,
  delay,
  repeat,
} from 'rxjs/operators';

import { BackEndHost } from './../../app-settings.service';
import {
  DirectConnectBaseDevice,
  DirectConnectDeviceStatus,
  IDirectConnectDevice,
  NCSS_BLE_WALK_BY_SERVICE_UUID,
  NCSS_BLE_WALK_BY_TX_ID,
  NCSS_BLE_WALK_BY_RX_ID,
} from './../baseDirectConnectDevice';
import { BleIncomingMessage, BLEIncomingMessageType } from './messages/ble/bleIncomingMessage';
import { BleOutgoingMessage } from './messages/ble/bleOutgoingMessage';
import { Ack } from './messages/ble/payloads/incoming/ack';
import { AvailableNetworks, WifiNetwork } from './messages/ble/payloads/incoming/availableNetworks';
import { Info, DeviceStat } from './messages/ble/payloads/incoming/info';
import { Status, StatusCode } from './messages/ble/payloads/incoming/status';
import { SyncProgress } from './messages/ble/payloads/incoming/syncProgress';
import { ChangeHost } from './messages/ble/payloads/outgoing/changeHost';
import { ChangeNetwork, InterfaceType } from './messages/ble/payloads/outgoing/changeNetwork';
import { ChangeWirelessNetwork } from './messages/ble/payloads/outgoing/changeWirelessNetwork';
import { CheckForUpdate } from './messages/ble/payloads/outgoing/checkForUpdate';
import { Command, CommandCode } from './messages/ble/payloads/outgoing/command';

const TIME_BETWEEN_RETRIES = 2000;

const BLUE_LED = '#15C2D8';
const YELLOW_LED = '#EADF59';
const RED_LED = '#E3515F';
const GREEN_LED = '#83EFA3';
const PURPLE_LED = '#A043CF';
const PINK_LED = '#E45898';
const ORANGE_LED = '#F1AC3A';
const OFF_LED = 'transparent';

export class DirectConnectGW4 extends DirectConnectBaseDevice {
  static useMetric = false;
  static create(bleDevice, manufacturingData: { serialNumber: number, byteList: ByteList }, isLimited = false): IDirectConnectDevice {
    if (!manufacturingData || !manufacturingData.serialNumber || isLimited) {
      return null;
    }

    const deviceType = DeviceTypeHelper.GetDeviceTypeBySerialNumber(manufacturingData.serialNumber);
    if (deviceType && (deviceType.id === DeviceTypeIds.GW4 || deviceType.id === DeviceTypeIds.GW4_LITE)) {
      const gw4 = new DirectConnectGW4();
      gw4.serialNumber = manufacturingData.serialNumber;
      gw4.serialNumberStr = manufacturingData.serialNumber.toString(16).toUpperCase();
      gw4.deviceModel = deviceType.model;
      gw4.deviceName = deviceType.name;
      gw4.modelId = deviceType.id;
      if (bleDevice.rssi) {
        gw4.updateSignalStrength(DirectConnectBaseDevice.rssiToSignalStrength(bleDevice.rssi));
      }
      gw4.addAdvertisingData(manufacturingData.byteList);
      const directConnectDevice: IDirectConnectDevice = {
        status: DirectConnectDeviceStatus.ADVERTISING,
        serialNumber: manufacturingData.serialNumber,
        bleSignalStrength: bleDevice.rssi ? DirectConnectBaseDevice.rssiToSignalStrength(bleDevice.rssi) : null,
        bleDevice,
        device: gw4,
        subscriptions: [],
        timers: [],
        keepModalOpenOnDisconnect: false,
      };
      return directConnectDevice;
    }
    return null;
  }

  private onMessage$ = new Subject<BleIncomingMessage>();
  private advertisingData$ = new BehaviorSubject<{ temperature: number, powerPinState: boolean }>(null);

  public lastMsgAt$: Observable<Date> = this.onMessage$.pipe(mapTo(new Date()));
  private availableNetworksPayload$: Observable<AvailableNetworks> = this.onMessage$.pipe(
    filter((msg) => msg && msg.payload instanceof AvailableNetworks),
    map((msg) => msg.payload as AvailableNetworks),
    shareReplay(1),
  );
  private infoPayload$: Observable<Info> = this.onMessage$.pipe(
    filter((msg) => msg && msg.payload instanceof Info),
    map((msg) => msg.payload as Info),
    shareReplay(1),
  );
  private read$: Observable<GW4Read> = this.infoPayload$.pipe(
    map((info) => info.read),
  );
  public availableNetworks$: Observable<WifiNetwork[]> = this.availableNetworksPayload$.pipe(
    map((payload) => payload.networks),
  );
  public lastSyncedAt$: Observable<Date> = this.read$.pipe(map((r) => r.lastReadsSyncedUpAt));
  private equipmentCheckInInfo$: Observable<DeviceStat[]> = this.infoPayload$.pipe(map((info) => info.deviceStats));
  public remoteTarget$: Observable<{ host: string, port: number }> = this.infoPayload$.pipe(
    map((info) => info.remoteTarget),
  );
  public powerLoss$ = merge(
    this.read$.pipe(map((r) => r.gatewayPowerLoss())),
    this.advertisingData$.pipe(map((d) => !d.powerPinState)),
  );
  public temperature$ = this.advertisingData$.pipe(map((d) => d.temperature));
  public temperatureStr$ = this.temperature$.pipe(
    map((temp) => {
      return DirectConnectGW4.useMetric ? `${temp.toFixed(1)} C` : `${ConversionUtils.ConvertCelsiusToFahrenheit(temp).toFixed(1)} F`;
    }),
    distinctUntilChanged(),
  );
  public repeaterCount$ = this.equipmentCheckInInfo$.pipe(
    map((totals) => this.sumUpDevices(totals, true)),
  );
  public endDeviceCount$ = this.equipmentCheckInInfo$.pipe(
    map((totals) => this.sumUpDevices(totals)),
  );
  public cloudConnected$: Observable<boolean> = this.read$.pipe(
    map((r) => r.cloudConnected()),
    distinctUntilChanged(),
  );

  public cellularEnabled$: Observable<boolean> = this.read$.pipe(
    map((r) => r.cellularEnabled()),
    distinctUntilChanged(),
  );
  public cellSignalStrength$: Observable<string> = this.read$.pipe(
    map((r) => this.cellSignalToString(r.flags.cellularSignal())),
    distinctUntilChanged(),
  );
  public forcingCellular$: Observable<boolean> = this.read$.pipe(
    map((r) => r.forcingCellular()),
    distinctUntilChanged(),
  );

  public usingCell$: Observable<boolean> = this.read$.pipe(map((r) => r.flags.pppInUse()), distinctUntilChanged());
  public usingEth$: Observable<boolean> = this.read$.pipe(map((r) => r.flags.ethInUse()), distinctUntilChanged());
  public usingWlan$: Observable<boolean> = this.read$.pipe(map((r) => r.flags.wlanInUse()), distinctUntilChanged());

  public cellReachable$: Observable<boolean> = this.read$.pipe(map((r) => r.flags.pppCloudReachable()), distinctUntilChanged());
  public ethReachable$: Observable<boolean> = this.read$.pipe(map((r) => r.flags.ethCloudReachable()), distinctUntilChanged());
  public wlanReachable$: Observable<boolean> = this.read$.pipe(map((r) => r.flags.wlanCloudReachable()), distinctUntilChanged());

  public wlanInterface$: Observable<IWirelessNetworkInterface> = this.read$.pipe(
    map((r) => r.wlan),
  );
  public ethInterface$: Observable<INetworkInterface> = this.read$.pipe(
    map((r) => r.eth),
  );
  public cellInterface$: Observable<INetworkInterface> = this.read$.pipe(
    map((r) => r.ppp),
  );
  public isPartying$ = this.read$.pipe(map((r) => r ? r.partyModeEnabled() : false), distinctUntilChanged());

  public powerLedColor$: Observable<string> = combineLatest([
    this.cloudConnected$,
    this.powerLoss$,
    this.isPartying$,
  ]).pipe(
    switchMap(([connected, powerLoss, party]) => {
      if (powerLoss) {
        return this.getSolidColorObs(RED_LED);
      } else if (party) {
        return this.getPartyColorObs();
      } else if (connected) {
        return this.getSolidColorObs(BLUE_LED);
      } else {
        return this.getSolidColorObs(GREEN_LED);
      }
    }),
  );

  public isProgrammed$ = this.read$.pipe(map((r) => r.programmedToProperty()));
  public programmedLedColor$: Observable<string> = combineLatest([
    this.isProgrammed$.pipe(distinctUntilChanged()),
    this.isPartying$.pipe(distinctUntilChanged()),
  ]).pipe(
    switchMap(([programmed, party]) => {
      if (party) {
        return this.getPartyColorObs();
      } else {
        return this.getBlinkingColorObs(programmed ? GREEN_LED : YELLOW_LED, 600);
      }
    }),
  );

  public rfLedColor$: Observable<string> = this.isPartying$.pipe(
    switchMap((party) => {
      return party ? this.getPartyColorObs() : this.getSolidColorObs(OFF_LED);
    }),
  );

  public cellLedColor$: Observable<string> = combineLatest([
    this.cellReachable$,
    this.cellularEnabled$,
    this.read$.pipe(map((r) => r ? r.flags.cellularSignal() : 0), distinctUntilChanged()),
    this.isPartying$,
  ]).pipe(
    switchMap(([cellReachable, enabled, signal, party]) => {
      if (party) {
        return this.getPartyColorObs();
      }
      const color = !cellReachable ? OFF_LED : signal >= 3 ? GREEN_LED : signal >= 2 ? YELLOW_LED : signal >= 1 ? RED_LED : OFF_LED;
      if (enabled) {
        return this.getBlinkingColorObs(color, 1500);
      } else {
        return this.getSolidColorObs(color);
      }
    }),
  );

  // scan response data
  public uuid = {
    service: NCSS_BLE_WALK_BY_SERVICE_UUID,
    write: NCSS_BLE_WALK_BY_TX_ID,
    read: NCSS_BLE_WALK_BY_RX_ID,
  };
  public serialNumber: number;
  public serialNumberStr: string;
  public deviceModel: string;
  public deviceName: string;

  public syncProgress$: Observable<string> = this.onMessage$.pipe(
    filter((msg) => msg && msg.payload instanceof Status),
    map((msg) => msg.payload as Status),
    map((payload) => {
      if (payload.code === StatusCode.SYNC_PROGRESS) {
        return `${(payload as SyncProgress).progress}%`;
      } else {
        return '';
      }
    }),
  );

  constructor() {
    super();
    this.messageService.registerMessageGroup({
      mask: BLEIncomingMessageType,
      messageClass: BleIncomingMessage,
      handler: this.onIncomingMessage.bind(this),
    });
    this.messageService.numberOfTxFlagBytes = 14;
  }

  private onIncomingMessage(msg: BleIncomingMessage) {
    this.onMessage$.next(msg);
  }

  public async sync(): Promise<boolean> {
    const msg = new BleOutgoingMessage(this.serialNumber);
    msg.payload = new Command(CommandCode.FORCE_SYNC);
    const syncStartedResponse = await this.sendMessage<BleIncomingMessage>(msg);
    if (
      !syncStartedResponse ||
      (syncStartedResponse &&
        syncStartedResponse.payload instanceof Status &&
        syncStartedResponse.payload.code === StatusCode.SYNC_FAILED)
    ) {
      return false;
    } else {
      const response = await this.waitForFilteredResponse(
        (m) =>
          m.payload instanceof Status &&
          (m.payload.code === StatusCode.SYNC_STOPPED || m.payload.code === StatusCode.SYNC_FAILED),
        60 * 1000,
      ).toPromise();

      return (response && response.payload instanceof Status && response.payload.code === StatusCode.SYNC_STOPPED);
    }
  }

  public async heartbeat(): Promise<boolean> {
    const msg = new BleOutgoingMessage(this.serialNumber);
    msg.payload = new Command(CommandCode.HEART_BEAT);
    const res = await this.sendMessage(msg);
    return !!res;
  }

  public party(enabled: boolean): void {
    const msg = new BleOutgoingMessage(this.serialNumber);
    msg.payload = new Command(enabled ? CommandCode.START_THE_PARTY : CommandCode.STOP_THE_PARTY);
    this.sendMessage(msg);
  }

  public async setRemoteServer(host: string, port: number): Promise<boolean> {
    const msg = new BleOutgoingMessage(this.serialNumber);
    msg.payload = new ChangeHost(host, port);
    const response = await this.sendMessage(msg);
    return !!response;
  }

  public async changeWifiNetwork(ssid: string, pass: string): Promise<boolean> {
    const msg = new BleOutgoingMessage(this.serialNumber);
    const payload = new ChangeWirelessNetwork();
    payload.useDHCP = true;
    payload.ssid = ssid;
    payload.password = pass;
    msg.payload = payload;
    const ack = await this.sendMessage(msg);
    if (ack) {
      const applied = await this.waitForFilteredResponse(
        (m) => m.payload instanceof Info && m.payload.read && m.payload.read.wlan.connectedSSID === ssid,
        60 * 1000,
      ).toPromise();
      if (applied) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public async forgetWifiNetwork(): Promise<boolean> {
    const msg = new BleOutgoingMessage(this.serialNumber);
    const payload = new Command(CommandCode.FORGET_WIFI_NETWORK);
    msg.payload = payload;
    const ack = await this.sendMessage(msg);
    if (ack) {
      const applied = await this.waitForFilteredResponse(
        (m) => m.payload instanceof Info && m.payload.requestId === msg.payload.id,
        60 * 1000,
      ).toPromise();
      return !!applied;
    } else {
      return false;
    }
  }

  public async changeNetwork(interfaceType: InterfaceType, netInterface: IWirelessNetworkInterface | INetworkInterface) {
    const msg = new BleOutgoingMessage(this.serialNumber);
    const payload = new ChangeNetwork(interfaceType);
    payload.useDHCP = netInterface.dhcp;
    if (!payload.useDHCP) {
      payload.gateway = netInterface.gateway;
      payload.ipAddress = netInterface.address;
      payload.networkMask = netInterface.netmask;
    }
    msg.payload = payload;
    const ack = await this.sendMessage(msg, {
      waitForResponse: true,
      timeBetweenRetries: 10 * 1000,
      timeout: 30 * 1000,
    });
    if (ack) {
      const applied = await this.waitForFilteredResponse(
        (m) => m.payload instanceof Info && m.payload.requestId === msg.payload.id,
        60 * 1000,
      ).toPromise();
      return !!applied;
    } else {
      return false;
    }

  }

  public async setCellularEnabled(enabled: boolean): Promise<boolean> {
    const msg = new BleOutgoingMessage(this.serialNumber);
    msg.payload = new Command(enabled ? CommandCode.CELLULAR_ENABLE : CommandCode.CELLULAR_DISABLE);
    const response = await this.sendMessage(msg);
    return !!response;
  }

  public async setForceCellular(force: boolean): Promise<boolean> {
    const msg = new BleOutgoingMessage(this.serialNumber);
    msg.payload = new Command(force ? CommandCode.CELLULAR_FORCE_ENABLE : CommandCode.CELLULAR_FORCE_DISABLE);
    const ack = await this.sendMessage(msg);
    if (ack) {
      const wasSuccessful = (m: BleIncomingMessage) => {
        if (force) {
          return m.payload instanceof Info && m.payload.read && m.payload.read.forcingCellular();
        } else {
          return m.payload instanceof Info && m.payload.read && !m.payload.read.forcingCellular();
        }
      };
      const response = await this.waitForFilteredResponse(
        wasSuccessful,
        20 * 1000,
      ).toPromise();
      return !!response;
    } else {
      return false;
    }
  }

  public async requestInfo() {
    const msg = new BleOutgoingMessage(this.serialNumber);
    msg.payload = new Command(CommandCode.REQUEST_INFO);
    const res = await this.sendMessage(msg);
    return !!res;
  }

  public async checkForUpdate(host: string = BackEndHost.Production) {
    const msg = new BleOutgoingMessage(this.serialNumber);
    msg.payload = new CheckForUpdate(host);
    const res = await this.sendMessage(msg);
    return !!res;
  }

  public addAdvertisingData(bytes: ByteList): void {
    const temperature = bytes.readInt8();
    const flags = bytes.readByte();
    const powerPinState = BitwiseHelper.getValueFromFlags(flags, 0);
    this.advertisingData$.next({ temperature, powerPinState });
  }

  private sendMessage<T extends BleIncomingMessage>(
    outgoingMsg: BleOutgoingMessage,
    opts: {
      waitForResponse: boolean,
      filterFn?: (m: BleIncomingMessage) => boolean,
      timeout?: number,
      timeBetweenRetries?: number,
    } = { waitForResponse: true, timeBetweenRetries: TIME_BETWEEN_RETRIES, timeout: 8 * 1000 },
  ): Promise<T | null | void> {
    if (opts.waitForResponse) {
      const response = opts.filterFn ?
        this.waitForFilteredResponse<T>(opts.filterFn, opts.timeout) :
        this.waitForResponse<T>(outgoingMsg, opts.timeout);
      const sendAttempts = timer(0, opts.timeBetweenRetries).pipe(take(2), mapTo(null));
      const retryStream = opts.timeBetweenRetries ?
        merge(response, sendAttempts).pipe(
          takeWhile((res) => !res),
        ) : response;
      retryStream.subscribe((sendAttempt) => {
        if (sendAttempt === null) {
          this.messageService.serialize(outgoingMsg, (data, frameId) => {
            return this.EncryptPlugin.encrypt(data, this.serialNumber, frameId);
          }).then((data) => {
            this.txBytes.next(data);
          });
        }
      });
      return response.toPromise();
    } else {
      return this.messageService.serialize(outgoingMsg, (data, frameId) => {
        return this.EncryptPlugin.encrypt(data, this.serialNumber, frameId);
      }).then((data) => {
        this.txBytes.next(data);
      });
    }
  }

  private waitForFilteredResponse<T extends BleIncomingMessage>(
    filterFn: (msg: BleIncomingMessage) => boolean, timeoutMS: number): Observable<T | null> {
    return merge(
      this.onMessage$.pipe(
        filter(filterFn),
      ),
      timer(timeoutMS).pipe(mapTo(null)),
    ).pipe(first());
  }

  private waitForResponse<T extends BleIncomingMessage>(outgoing: BleOutgoingMessage, timeoutMs: number): Observable<T | null> {
    return merge(
      this.onMessage$.pipe(
        filter((incoming) => incoming && incoming.payload instanceof Ack && incoming.payload.requestId === outgoing.payload.id),
      ),
      timer(timeoutMs).pipe(mapTo(null)),
    ).pipe(first());
  }

  private cellSignalToString(signal: number): string {
    switch (signal) {
      case 0:
        return 'Unavailable';
      case 1:
        return 'Poor';
      case 2:
        return 'Good';
      case 3:
        return 'Excellent';
      default:
        return 'Unknown';
    }
  }

  private sumUpDevices(deviceStats: DeviceStat[], repeaters = false): number {
    const families = repeaters ? [DeviceFamily.REPEATER, DeviceFamily.REPEATER_3RD_PARTY] :
      [DeviceFamily.TRANSCEIVER, DeviceFamily.REMOTE_READER, DeviceFamily.TRANSMITTER_3RD_PARTY];
    let sum = 0;
    for (const t of deviceStats) {
      const family: DeviceFamily = DeviceTypes[t.deviceType] ? DeviceTypes[t.deviceType].family : null;
      if (families.includes(family)) {
        sum += t.count;
      }
    }
    return sum;
  }

  private getPartyColorObs() {
    const intervalPeriod = Math.floor((Math.random() * 200) + 200);
    const ledPattern = this.randomLedPattern();
    return from(ledPattern).pipe(
      concatMap((color) => of(color).pipe(delay(intervalPeriod))),
      repeat(),
    );
  }

  private getBlinkingColorObs(color: string, period?: number) {
    const intervalPeriod = period || Math.floor((Math.random() * 400) + 400);
    return interval(intervalPeriod).pipe(
      map((val, i) => i % 2 === 0 ? true : false),
    ).pipe(
      map((blinkOff) => {
        return blinkOff ? OFF_LED : color;
      }),
    );
  }

  private getSolidColorObs(color: string) {
    return from([color]).pipe(
      concatMap((c) => of(c).pipe(delay(1000))),
      repeat(),
    );
  }

  private randomLedPattern(): string[] {
    const leds = [BLUE_LED, YELLOW_LED, RED_LED, GREEN_LED, PURPLE_LED, PINK_LED, ORANGE_LED];
    const iterations = Math.floor(Math.random() * 5);
    for (let i = 0; i < iterations; i++) {
      const l = leds.shift();
      leds.push(l);
    }
    const returnVal = [];
    for (const l of leds) {
      returnVal.push(l);
      returnVal.push(OFF_LED);
    }
    return returnVal;
  }
}
