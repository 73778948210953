import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { DirectivesModule } from '../../directives/directives.module';
import { GrowContainerModule } from '../animations/grow-container/growContainer.component';
import { ModalHeaderModule } from '../modal-header/modal-header.module';
import { NumericInputModule } from '../numeric-input/numeric-input.module';
import { SelectorModalModule } from '../selector-modal/selector-modal.module';
import { Gw4ModalComponent } from './../../components/direct-connect-modals/gw4-modal/gw4-modal.component';
import { PipesModule } from './../../pipes/pipes.module';
import { ConnectionHelperModule } from './../connection-helper/connection-helper.module';
import { DirectConnect301ModalComponent } from './dc301-modal/dc301-modal.component';
import { DirectConnectModalTabsComponent } from './direct-connect-modal-tabs/direct-connect-modal-tabs.component';
import { Gateway301ModalComponent } from './gateway301-modal/gateway301-modal.component';
import { ProgramDeviceComponent } from './program-device/program-device.component';
import { Re4ModalComponent } from './re4-modal/re4-modal.component';
import { ReconnectModalComponent } from './reconnect-modal/reconnect-modal.component';
import { DirectConnectMeterComponent } from './remote-reader-modal/direct-connect-meter/direct-connect-meter.component';
import { MagnetInstructionComponent } from './remote-reader-modal/magnet-instruction/magnet-instruction.component';
import { ManageConfigurationsComponent } from './remote-reader-modal/manage-configurations/manage-configurations.component';
import { QuickConfigurationService } from './remote-reader-modal/quickConfiguration.service';
import { RemoteReaderModalComponent } from './remote-reader-modal/remote-reader-modal.component';
import { StatusMeterComponent } from './remote-reader-modal/status-meter/status-meter.component';
import { Rr4ModalComponent } from './rr4-modal/rr4-modal.component';
import { Tr4eModalComponent } from './tr4-e-modal/tr4-e-modal.component';
import { Tr401ModalComponent } from './tr401-modal/tr401-modal.component';
import { WiringGuideComponent } from './wiring-guide/wiring-guide.component';


@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    ModalHeaderModule,
    ReactiveFormsModule,
    FormsModule,
    DirectivesModule,
    PipesModule,
    NumericInputModule,
    GrowContainerModule,
    ConnectionHelperModule,
    SelectorModalModule,
  ],
  entryComponents: [
    Gw4ModalComponent,
    ReconnectModalComponent,
    Rr4ModalComponent,
    Re4ModalComponent,
    Tr401ModalComponent,
    RemoteReaderModalComponent,
    DirectConnect301ModalComponent,
    Gateway301ModalComponent,
    DirectConnectModalTabsComponent,
    MagnetInstructionComponent,
    ManageConfigurationsComponent,
    WiringGuideComponent,
    ProgramDeviceComponent,
    Tr4eModalComponent,
  ],
  exports: [
    ProgramDeviceComponent,
    RemoteReaderModalComponent,
    DirectConnect301ModalComponent,
    Gateway301ModalComponent,
    DirectConnectModalTabsComponent,
  ],
  declarations: [
    Gw4ModalComponent,
    ProgramDeviceComponent,
    ReconnectModalComponent,
    Rr4ModalComponent,
    WiringGuideComponent,
    RemoteReaderModalComponent,
    Tr401ModalComponent,
    Re4ModalComponent,
    DirectConnect301ModalComponent,
    Gateway301ModalComponent,
    DirectConnectModalTabsComponent,
    DirectConnectMeterComponent,
    StatusMeterComponent,
    MagnetInstructionComponent,
    ManageConfigurationsComponent,
    Tr4eModalComponent,
  ],
  providers: [
    QuickConfigurationService,
  ],
})
export class DirectConnectModalModule { }
