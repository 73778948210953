import { ByteList } from 'byte-list';
import * as moment from 'moment';

import { GatewayMessage } from './gatewayMessage';

export interface INetworkInterface {
  state: number;
  address: string;
  netmask: string;
  family: string;
  mac: string;
  gateway: string;
  isDefault: boolean;
}
export interface IWirelessNetworkInterface extends INetworkInterface {
  connectedSSID: string;
}
export interface IDeviceCheckInInfo {
  deviceType: number;
  totalCount: number;
  checkedInCount: number;
}
export interface IGatewayCPUInfo {
  user: number;
  sys: number;
  idle: number;
}

export enum GatewayInfoMessageTypes {
  GROUP_MASK = 0x0500,

  // Gateway --> Mobile
  INFO = 0x0501,
  INFO_V1 = 0x0502,

  // Gateway <-- Mobile
  SET_CELLULAR_ENABLED = 0x05A1,
  SET_CELLULAR_DISABLED = 0x05A2,
  ENABLE_PARTY_MODE = 0x05A3,
  DISABLE_PARTY_MODE = 0x05A4,
}

export class GatewayInfoMessage extends GatewayMessage {

  public remoteHost: string;
  public remotePort: number;
  public softwareVersion: string;
  public osVersion: string;
  public mcuVersion: string;
  public coordinatorVersion: string;
  public repeaterVersion: string;
  public transceiverVersion: string;
  public flags: number;
  public wifiSSID: string;
  public wifiPassHash: string;
  public wifiSecurityType: string;
  public pin: string;
  public deploymentGroup: number;
  public defaultWDTCountdown: number;
  public resetCount: number;
  public eth: INetworkInterface;
  public wlan: IWirelessNetworkInterface;
  public cloudNetworkStatusFlags: number;
  public cloudNetworkStatusError: string;
  public cellularFlags: number;
  public cellularSignalQuality: number;
  public cellularRegisteredNetwork: string;
  public cellularError: string;
  public osUpTime: number;
  public appUpTime: number;
  public cpuUsage: IGatewayCPUInfo;
  public memoryUsage: number;
  public lastSyncedAt: Date;
  public checkInThresholdHours: number;
  public deviceCheckInInfo: IDeviceCheckInInfo[];

  constructor(type?: GatewayInfoMessageTypes) {
    super();
    this.type = type;
  }

  public deserialize(byteList: ByteList) {
    // super.deserialize(byteList);
    this.requestId = byteList.readByte();
    this.serialNumber = byteList.readUInt32();
    if (this.type === GatewayInfoMessageTypes.INFO) {
      this.deserializeGatewayInfo(byteList);
      this.cpuUsage = {
        user: 0,
        sys: 0,
        idle: 0,
      };
    } else if (this.type === GatewayInfoMessageTypes.INFO_V1) {
      this.deserializeGatewayInfo(byteList);
      this.cpuUsage = {
        user: byteList.readByte(),
        sys: byteList.readByte(),
        idle: byteList.readByte(),
      };
    }
  }

  private deserializeGatewayInfo(byteList: ByteList) {
    this.serialNumber = byteList.readUInt32();
    this.remoteHost = byteList.readString();
    this.remotePort = byteList.readUInt16();
    this.softwareVersion = byteList.readString();
    this.osVersion = byteList.readString();
    this.mcuVersion = byteList.readString();
    this.coordinatorVersion = byteList.readString();
    this.repeaterVersion = byteList.readString();
    this.transceiverVersion = byteList.readString();
    this.flags = byteList.readUInt32();
    this.wifiSSID = byteList.readString();
    this.wifiPassHash = byteList.readString();
    this.wifiSecurityType = byteList.readString();
    this.pin = byteList.readString();
    this.deploymentGroup = byteList.readByte();
    this.defaultWDTCountdown = byteList.readUInt16();
    this.resetCount = byteList.readUInt16();
    this.eth = {
      state: byteList.readByte(),
      address: byteList.readString(),
      netmask: byteList.readString(),
      family: byteList.readString(),
      mac: byteList.readString(),
      gateway: byteList.readString(),
      isDefault: byteList.readBool(),
    };
    this.wlan = {
      state: byteList.readByte(),
      address: byteList.readString(),
      netmask: byteList.readString(),
      family: byteList.readString(),
      mac: byteList.readString(),
      gateway: byteList.readString(),
      isDefault: byteList.readBool(),
      connectedSSID: byteList.readString(),
    };
    this.cloudNetworkStatusFlags = byteList.readByte();
    this.cloudNetworkStatusError = byteList.readString();
    this.cellularFlags = byteList.readByte();
    this.cellularSignalQuality = byteList.readByte();
    this.cellularRegisteredNetwork = byteList.readString();
    this.cellularError = byteList.readString();
    this.osUpTime = byteList.readUInt32();
    this.appUpTime = byteList.readUInt32();
    this.memoryUsage = byteList.readByte();
    const secondsAgo = byteList.readUInt32();
    if (secondsAgo !== 0xFFFF) {
      this.lastSyncedAt = moment().subtract(secondsAgo, 's').toDate();
    }
    this.checkInThresholdHours = byteList.readByte();
    const count = byteList.readByte();
    this.deviceCheckInInfo = [];
    for (let i = 0; i < count; ++i) {
      this.deviceCheckInInfo.push({
        deviceType: byteList.readByte(),
        totalCount: byteList.readUInt16(),
        checkedInCount: byteList.readUInt16(),
      });
    }
  }

}
