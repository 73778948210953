import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { ModalHeaderModule } from './../modal-header/modal-header.module';
import { UpdateAccountComponent } from './update-account.component';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    ModalHeaderModule,
    CommonModule,
  ],
  declarations: [UpdateAccountComponent],
  entryComponents: [
    UpdateAccountComponent,
  ],
})
export class UpdateAccountModule { }
