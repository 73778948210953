import {
  Unit,
  UtilityTypeIds,
  MeterConfig,
  UomTypeIds,
  UomTypeHelper,
  UtilityTypes,
  UtilityTypesOrderedList,
  UomTypes,
  ConversionUtils,
  Meter,
  DeviceTypeHelper,
  Device,
  PortTypeIds,
  MeterType,
  UtilityFamily,
} from '@ncss/models';

import { transition, trigger, style, animate } from '@angular/animations';
import { Component, OnInit, Input } from '@angular/core';
import { Validators } from '@angular/forms';
import { ModalController, AlertController, PopoverController, LoadingController } from '@ionic/angular';
import { AlertInput } from '@ionic/core';
import { uniqBy as _uniqBy, groupBy as _groupBy, isEqual as _isEqual } from 'lodash';

import { CreatePositiveNumberValidator } from '../../../angularUtilities/common-validators';
import { ConnectionService, ConnectionStatus } from './../../../services/connection.service';
import { IUserChangeableMeterInfo, MeterConfigLabels } from './../../../services/direct-connect/remoteReader/remoteReader';
import { DirectConnectRR4 } from './../../../services/direct-connect/RR4/RR4';
import { DirectConnectTR4 } from './../../../services/direct-connect/tr4/tr4';
import { MobileMeterTypeService } from './../../../services/mobile-meter-type.service';
import { MobilePropertyService } from './../../../services/mobile-property.service';
import { MobileUnitService } from './../../../services/mobile-unit.service';
import { ToastService } from './../../../services/toast.service';
import { HelperStatus } from './../../connection-helper/connection-helper.component';
import { CustomInputPopoverComponent, CustomInputPopoverType } from './../../custom-input-popover/custom-input-popover.component';
import { SelectorModalComponent } from './../../selector-modal/selector-modal.component';


@Component({
  selector: 'app-program-device',
  templateUrl: './program-device.component.html',
  styleUrls: ['./program-device.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ height: '0px', opacity: 0 }),
        animate('250ms ease-out', style({ height: '45px', opacity: 1 })),
      ]),
      transition(':leave', [
        animate('250ms ease-out', style({ height: '0px', opacity: 0 })),
      ]),
    ]),
    trigger('checkmark', [
      transition(':enter', [
        style({ transform: 'scale(0)', opacity: 0 }),
        animate('350ms ease-out', style({ transform: 'scale(1)', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ transform: 'scale(1)', opacity: 1 }),
        animate('250ms ease-out', style({ transform: 'scale(0)', opacity: 0 })),
      ]),
    ]),
  ],
})
export class ProgramDeviceComponent implements OnInit {

  public ConnectionStatus = ConnectionStatus;
  public HelperStatus = HelperStatus;
  public UtilityTypes = UtilityTypes;
  public UtilityTypeIds = UtilityTypeIds;
  public UomTypes = UomTypes;
  public MeterConfigLabels = MeterConfigLabels;

  @Input() public unit: Unit;
  @Input() public device: DirectConnectRR4 | DirectConnectTR4;

  public selectedProperty: { id: string, name: string };
  public selectedBuilding: string;
  public selectedUnit: { id: string, name: string };
  public meterConfigurationOne: IUserChangeableMeterInfo;
  public meterConfigurationTwo: IUserChangeableMeterInfo;
  public canEditMeterConfigs = false; // false for RRs and true for TR4s
  public port2DisabledText: string;
  public canSave = false;
  public deviceAlreadyProgrammedText: { building: string, unit: string, property: string, device: string };

  public buildingOptions: string[] = [];
  public unitOptions: { id: string, name: string }[] = [];

  private unitGroups: { [building: string]: Unit[] } = {};

  private initialConfigOne: IUserChangeableMeterInfo;
  private initialConfigTwo: IUserChangeableMeterInfo;

  private _loader: HTMLIonLoadingElement;


  constructor(
    private modalCtrl: ModalController,
    private propertyService: MobilePropertyService,
    private alertCtrl: AlertController,
    public connectionService: ConnectionService,
    private popCtrl: PopoverController,
    private unitService: MobileUnitService,
    private meterTypeService: MobileMeterTypeService,
    private toast: ToastService,
    private loadCtrl: LoadingController,
  ) { }

  ngOnInit() {
    if (this.isValidUnit(this.unit)) {
      this.showDeviceAlreadyProgrammedText();
    }
    if (this.device instanceof DirectConnectRR4) {
      this.initializeMeterConfigurationsForRR4(this.device);
    } else if (this.device) {
      const m = this.getMeterFromUnit(this.device.serialNumber);
      this.initializeMeterConfigurationsForTR4(this.device, m);
      this.canEditMeterConfigs = true;
    }
    this.updateCanSave();
  }

  async openPropertySelector() {
    const m = await this.modalCtrl.create({
      component: SelectorModalComponent,
    });
    await m.present();
    const { data } = await m.onDidDismiss();
    if (data && data._id && data.name) {
      await this.setSelectedProperty(data._id, data.name);
    }
  }

  async openBuildingSelector() {
    const options = this.buildingOptions.map((b) => {
      return {
        label: b,
        name: b,
        value: b,
        type: 'radio',
        checked: this.selectedBuilding === b,
        handler: () => {
          this.setSelectedBuilding(b);
        },
      } as AlertInput;
    });
    await this.openSelector(options, 'Select Building');
  }

  async openUnitSelector() {
    const options = this.unitOptions.map((u) => {
      return {
        label: u.name,
        name: u.name,
        value: u.id,
        type: 'radio',
        checked: this.selectedUnit && this.selectedUnit.id === u.id,
        handler: () => {
          this.setSelectedUnit(u.id, u.name);
        },
      } as AlertInput;
    });
    await this.openSelector(options, 'Select Unit');
  }

  async openUtilitySelector(port: 1 | 2) {
    if (!this.canEditMeterConfigs) { return; }
    const configuration = port === 2 ? (this.meterConfigurationTwo || {}) : (this.meterConfigurationOne || {});
    const options = UtilityTypesOrderedList.map((u) => {
      return {
        label: u.name,
        name: u.name,
        value: u.id,
        type: 'radio',
        checked: configuration.utilityTypeId === u.id,
        handler: () => {
          this.setSelectedUtility(port, u.id);
        },
      } as AlertInput;
    });
    await this.openSelector(options, 'Select Utility');
  }

  async openMultiplierInput(port: 1 | 2) {
    if (!this.canEditMeterConfigs) { return; }
    const configuration = port === 2 ? (this.meterConfigurationTwo || {}) : (this.meterConfigurationOne || {});
    const pop = await this.popCtrl.create({
      component: CustomInputPopoverComponent,
      animated: true,
      backdropDismiss: true,
      cssClass: 'custom-popover',
      componentProps: {
        type: CustomInputPopoverType.NUMERIC_INPUT_POPOVER,
        header: 'Multiplier',
        placeholder: 'Enter Multiplier',
        submitLabel: 'Ok',
        initialValue: configuration.multiplier,
        syncValidators: [Validators.required, CreatePositiveNumberValidator('Multiplier')],
      },
    });
    await pop.present();
    const { data } = await pop.onDidDismiss();
    if (data.value && data.value !== configuration.multiplier) {
      this.setSelectedMultiplier(port, data.value);
    }
  }

  async openConfigTypeSelector(port: 1 | 2) {
    if (!this.canEditMeterConfigs || (this.device instanceof DirectConnectTR4)) { return; }
    const configuration = port === 2 ? (this.meterConfigurationTwo || {}) : (this.meterConfigurationOne || {});
    const configs = port === 2 ? [MeterConfig.PULSE_IN, MeterConfig.ENCODER_IN, MeterConfig.PULSE_OUT, MeterConfig.PORT_DISABLED] :
      [MeterConfig.PULSE_IN, MeterConfig.ENCODER_IN];
    const options = configs.map((config) => {
      return {
        label: MeterConfigLabels[config],
        name: MeterConfigLabels[config],
        value: config,
        type: 'radio',
        checked: configuration.configType === config,
        handler: () => {
          this.setSelectedConfigType(port, config);
        },
      } as AlertInput;
    });
    await this.openSelector(options, 'Select Signal Type');
  }

  async openUomSelector(port: 1 | 2) {
    if (!this.canEditMeterConfigs) { return; }
    const configuration = port === 2 ? (this.meterConfigurationTwo || {}) : (this.meterConfigurationOne || {});
    const options = UomTypeHelper.GetUomForUtilityTypeId(configuration.utilityTypeId).map((u) => {
      return {
        label: u.name,
        name: u.name,
        value: u.id,
        type: 'radio',
        checked: configuration.uomTypeId === u.id,
        handler: () => {
          this.setSelectedUom(port, u.id);
        },
      } as AlertInput;
    });
    await this.openSelector(options, 'SelectUnitOfMeasure');
  }

  async saveClicked() {
    if (!this.selectedUnit || !this.selectedUnit.id) { return; }
    this._loader = await this.loadCtrl.create({message: 'Saving changes...'});
    await this._loader.present();
    if (this.isValidUnit(this.unit)) {  // was programmed to a different unit
      const ids = this.device instanceof DirectConnectRR4 ?
        this.getRRSerialsFromBase(this.device.serialNumber) : [this.device.serialNumber];
      this._loader.message = `Removing device from ${this.unit.property.name} - ${this.unit.building} - ${this.unit.name}`;
      const utilities = this.getUtilitiesOnUnitFromDevices(this.unit, ids);
      const promises: Array<Promise<void>> = [];
      for (const u of utilities) {
        promises.push(this.unitService.removeMeter(this.unit._id, u).toPromise());
      }
      await Promise.all(promises);
    }

    if (this.device instanceof DirectConnectRR4) {
      const ids = this.getRRSerialsFromBase(this.device.serialNumber);
      const meter1 = await this.getMeterFromUserChangeableInfo(this.meterConfigurationOne, ids[0]);
      const meter2 = await this.getMeterFromUserChangeableInfo(this.meterConfigurationTwo, ids[1]);
      this.populateMeterWithDCRR4Info(meter1, this.device);
      this.populateMeterWithDCRR4Info(meter2, this.device);
      this._loader.message = `Saving Meter 1 to ${this.selectedProperty.name} - ${this.selectedBuilding} - ${this.selectedUnit.name}...`;
      await this.saveMeterToSelectedUnit(meter1);
      this._loader.message = `Saving Meter 2 to ${this.selectedProperty.name} - ${this.selectedBuilding} - ${this.selectedUnit.name}...`;
      await this.saveMeterToSelectedUnit(meter2);
    } else {
      const meter = await this.getMeterFromUserChangeableInfo(this.meterConfigurationOne, this.device.serialNumber);
      this._loader.message = `Saving meter to ${this.selectedProperty.name} - ${this.selectedBuilding} - ${this.selectedUnit.name}...`;
      await this.saveMeterToSelectedUnit(meter);
    }
    await this._loader.dismiss();
    this._loader = null;
  }

  private showDeviceAlreadyProgrammedText() {
    if (this.isValidUnit(this.unit)) {
      this.deviceAlreadyProgrammedText = {
        device: this.device.serialNumberStr ? this.device.serialNumberStr.toUpperCase() : 'This device',
        unit: this.unit.name,
        property: this.unit.property.name,
        building: this.unit.building,
      };
    }
  }

  public clearDeviceAlreadyProgrammedText() {
    this.deviceAlreadyProgrammedText = null;
  }

  private async saveMeterToSelectedUnit(meter: Meter) {
    if (!meter || !this.selectedUnit.id || !meter.utilityTypeId) { return; }
    try {
      const res = await this.unitService.addMeter(this.selectedUnit.id, meter).toPromise();
      if (res) {
        this.toast.queueToast(`Device programmed to ${this.selectedUnit.name}`);
        this.unit = new Unit(res);
        this.showDeviceAlreadyProgrammedText();
        this.device.setProgrammedUnit(this.unit);
      }
    } catch (e) {
      const errorMsg = e.status === 405 ?
        `A ${UtilityTypes[meter.utilityTypeId].name} meter already exists on ${this.selectedUnit.name}.` :
        `Unable to save at this time.`;
      const a = await this.alertCtrl.create({
        message: errorMsg,
        buttons: ['Ok'],
        header: 'Uh-oh!',
      });
      await a.present();
    }
  }

  private async openSelector(inputs: AlertInput[], header: string) {
    const a = await this.alertCtrl.create({
      header,
      inputs,
      buttons: ['Ok'],
    });
    await a.present();
  }

  private initializeMeterConfigurationsForTR4(tr4: DirectConnectTR4, meter: Meter) {
    if (meter) {
      this.setSelectedUtility(1, meter.utilityTypeId);
      this.setSelectedUom(1, meter.uomTypeId);
      this.setSelectedMultiplier(1, meter.multiplier);
      this.setSelectedConfigType(1, tr4.configType);
      this.setSelectedImr(1, meter.imr);
    } else {
      this.setSelectedConfigType(1, tr4.configType);
    }
    this.meterConfigurationTwo = null;
  }

  private initializeMeterConfigurationsForRR4(rr4: DirectConnectRR4) {
    if (rr4 && rr4.meter1Info) {
      this.setSelectedUtility(1, rr4.meter1Info.utilityTypeId);
      this.setSelectedUom(1, rr4.meter1Info.uomTypeId);
      this.setSelectedMultiplier(1, rr4.meter1Info.multiplier);
      this.setSelectedConfigType(1, rr4.meter1Info.configType);
      this.setSelectedImr(1, rr4.meter1Info.imr);
    } else {
      this.meterConfigurationOne = {};
    }

    if (rr4 && rr4.meter2Info &&
      (rr4.meter2Info.configType !== MeterConfig.PORT_DISABLED && rr4.meter2Info.configType !== MeterConfig.PULSE_OUT)) {
      this.setSelectedUtility(2, rr4.meter2Info.utilityTypeId);
      this.setSelectedUom(2, rr4.meter2Info.uomTypeId);
      this.setSelectedMultiplier(2, rr4.meter2Info.multiplier);
      this.setSelectedConfigType(2, rr4.meter2Info.configType);
      this.setSelectedImr(2, rr4.meter2Info.imr);
    } else if (rr4 && rr4.meter2Info) {
      this.setSelectedConfigType(2, rr4.meter2Info.configType);
      this.port2DisabledText = rr4.meter2Info.configTypeStr;
    }

    this.initialConfigOne = { ...this.meterConfigurationOne };
    this.initialConfigTwo = { ...this.meterConfigurationTwo };
  }

  private async setSelectedProperty(id: string, name: string) {
    this.selectedBuilding = null;
    this.selectedUnit = null;
    this.selectedProperty = { id, name };
    this._loader = await this.loadCtrl.create({message: `Loading units for ${this.selectedProperty.name}...`});
    await this._loader.present();
    await this.fetchPropertyUnits(this.selectedProperty.id);
    this.updateCanSave();
    await this._loader.dismiss();
    this._loader = null;
  }

  private setSelectedBuilding(building: string) {
    this.selectedUnit = null;
    this.selectedBuilding = building;
    this.updateUnitOptions(this.selectedBuilding);
    this.updateCanSave();
  }

  private setSelectedUnit(id: string, name: string) {
    this.selectedUnit = { id, name };
    this.updateCanSave();
  }

  private setSelectedUtility(port: 1 | 2, utilityTypeId: UtilityTypeIds) {
    const oldUtility = port === 2 ? (this.meterConfigurationTwo || {}).utilityTypeId : (this.meterConfigurationOne || {}).utilityTypeId;
    const oldFamily = UtilityTypes[oldUtility] ? UtilityTypes[oldUtility].family : null;
    const newFamily = UtilityTypes[utilityTypeId] ? UtilityTypes[utilityTypeId].family : null;
    if (port === 2) {
      this.meterConfigurationTwo = {
        ...this.meterConfigurationTwo,
        utilityTypeId,
      };
    } else {
      this.meterConfigurationOne = {
        ...this.meterConfigurationOne,
        utilityTypeId,
      };
    }
    if (oldFamily !== null && oldFamily !== newFamily) {
      this.setSelectedUom(port, UomTypeHelper.GetUomForUtilityFamily(newFamily)[0].id);
    }
    this.updateCanSave();
  }

  private setSelectedMultiplier(port: 1 | 2, multiplier: number) {
    if (port === 2) {
      this.meterConfigurationTwo = {
        ...this.meterConfigurationTwo,
        multiplier,
      };
    } else {
      this.meterConfigurationOne = {
        ...this.meterConfigurationOne,
        multiplier,
      };
    }
    this.updateCanSave();
  }

  private setSelectedUom(port: 1 | 2, uomTypeId: UomTypeIds) {
    if (port === 2) {
      this.meterConfigurationTwo = {
        ...this.meterConfigurationTwo,
        uomTypeId,
      };
    } else {
      this.meterConfigurationOne = {
        ...this.meterConfigurationOne,
        uomTypeId,
      };
    }
    this.updateCanSave();
  }

  private setSelectedConfigType(port: 1 | 2, configType: MeterConfig) {
    if (port === 2) {
      this.meterConfigurationTwo = {
        ...this.meterConfigurationTwo,
        configType,
      };
    } else {
      this.meterConfigurationOne = {
        ...this.meterConfigurationOne,
        configType,
      };
    }
    this.updateCanSave();
  }

  private setSelectedImr(port: 1 | 2, imr: number) {
    if (port === 2) {
      this.meterConfigurationTwo = {
        ...this.meterConfigurationTwo,
        imr,
      };
    } else {
      this.meterConfigurationOne = {
        ...this.meterConfigurationOne,
        imr,
      };
    }
    this.updateCanSave();
  }

  private isValidUnit(unit: Unit): boolean {
    if (unit && unit._id && unit.name && unit.property && unit.property.id && unit.building) {
      return true;
    } else {
      return false;
    }
  }

  private getRRSerialsFromBase(deviceId: number): number[] {
    const base = ConversionUtils.GetBaseRRSerial(deviceId);
    return [base + 1, base + 2];
  }

  private getMeterFromUnit(deviceId: number): Meter {
    if (!this.unit || !this.unit.meters) { return null; }
    const meter = this.unit.meters.find((m) => m.device && m.device.id === deviceId);
    return meter;
  }

  private getUtilitiesOnUnitFromDevices(unit: Unit, deviceIds: number[]): UtilityTypeIds[] {
    const utilities: UtilityTypeIds[] = [];
    deviceIds.forEach((id) => {
      const m = this.getMeterFromUnit(id);
      if (m) {
        utilities.push(m.utilityTypeId);
      }
    });
    return utilities;
  }

  private async fetchPropertyUnits(propertyId: string) {
    const res: Unit[] = (await this.propertyService.getUnitsForProperty(propertyId).toPromise()) || [];
    this.buildingOptions = _uniqBy(res, 'building').map((u) => u.building);
    this.unitGroups = _groupBy(res, 'building');
  }

  private updateUnitOptions(building: string) {
    this.unitOptions = (this.unitGroups[building] || []).map((u) => {
      return {
        id: u._id,
        name: u.name,
      };
    });
  }

  private updateCanSave() {
    this.canSave = !!(this.selectedUnit && this.selectedUnit.id) && (this.selectedUnit.id !== (this.unit ? this.unit._id : undefined));
    if (!this.canSave || !this.canEditMeterConfigs) { return; }
    this.canSave = this.hasChanges();
    if (!this.canSave) { return; }
    this.canSave = this.meterConfigurationsOneValid() && this.meterConfigurationsTwoValid();
  }

  private hasChanges() {
    const configOneHasChanges = !_isEqual(this.meterConfigurationOne, this.initialConfigOne);
    const configTwoHasChanges = !_isEqual(this.meterConfigurationTwo, this.initialConfigTwo);
    const originalUnitId = this.unit ? this.unit._id : undefined;
    return (
      (configOneHasChanges) ||
      (configTwoHasChanges) ||
      (this.selectedUnit.id !== originalUnitId));
  }

  private meterConfigurationsOneValid(): boolean {
    return !!(
      this.meterConfigurationOne.configType &&
      this.meterConfigurationOne.multiplier &&
      this.meterConfigurationOne.utilityTypeId &&
      this.meterConfigurationOne.uomTypeId);
  }

  private meterConfigurationsTwoValid(): boolean {
    if (this.device && this.device instanceof DirectConnectRR4) {
      return !!(
        this.meterConfigurationTwo &&
        this.meterConfigurationTwo.configType &&
        this.meterConfigurationTwo.multiplier &&
        this.meterConfigurationTwo.utilityTypeId &&
        this.meterConfigurationTwo.uomTypeId);
    } else {
      return true;
    }
  }

  private async getGenericMeterTypeForUtilityFamily(utilityFamily: UtilityFamily): Promise<MeterType> {
    const res = await this.meterTypeService.search('Generic', {utilityFamily});
    return res && res[0] ? res[0] : null;
  }

  private async getMeterFromUserChangeableInfo(info: IUserChangeableMeterInfo, deviceId: number): Promise<Meter> {
    if (!info || !info.utilityTypeId || !deviceId ||
      info.configType === MeterConfig.PORT_DISABLED || info.configType === MeterConfig.PULSE_OUT) {
      return null;
    }
    const meterType = await this.getGenericMeterTypeForUtilityFamily(UtilityTypes[info.utilityTypeId].family);
    const m = new Meter();
    m.uomTypeId = info.uomTypeId,
    m.imr = info.imr,
    m.utilityTypeId = info.utilityTypeId,
    m.multiplier = info.multiplier,
    m.device = new Device({
      id: deviceId,
      deviceTypeId: DeviceTypeHelper.GetDeviceTypeBySerialNumber(deviceId).id,
    });
    m.meterModelNumber = 'Generic';
    m.meterManufacturerName = 'Generic';
    m.meterPortType = info.configType === MeterConfig.ENCODER_IN ? PortTypeIds.ENCODED_UNKNOWN : PortTypeIds.PULSE;
    if (meterType) {
      m.meterManufacturerId = meterType.manufacturerId;
      m.meterManufacturerName = meterType.manufacturerName;
      m.meterModelNumber = meterType.modelNumber;
      m.meterTypeId = meterType._id;
    }
    return m;
  }

  private populateMeterWithDCRR4Info(meter: Meter, rr4: DirectConnectRR4) {
    if (!meter || !rr4) { return; }
    meter.meterDisplayAlwaysOn = rr4.deviceInfo.lcdAlwaysOn || false;
    meter.meterPulseOut = rr4.deviceInfo.pulseOut;
  }
}
