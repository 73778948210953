import { Message } from '@ncss/message';
import { RE4 } from '@ncss/models';

import { ByteList } from 'byte-list';

export enum RE4BLEMessageType {
  GROUP_MASK = 0x2040,
  DEVICE_MSG = 0x2041,
}

export class RE4BLEDeviceMessage extends Message {
  public re4: RE4;

  constructor() {
    super();
    this.type = RE4BLEMessageType.DEVICE_MSG;
  }

  serialize(): ByteList {
    const bytes = super.serialize();
    this.re4 = this.re4 || new RE4();
    this.re4.serialize(bytes);
    return bytes;
  }

  deserialize(bytes: ByteList): void {
    super.deserialize(bytes);
    this.re4 = new RE4();
    this.re4.deserialize(bytes);
  }
}
