import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[app-click-outside]',
})
export class ClickOutsideDirective {

  @Input() clickOutsideEn = true;
  @Output() clickOutside = new EventEmitter<MouseEvent>();

  constructor(private elementRef: ElementRef) { }

  @HostListener('document:click', ['$event'])
  @HostListener('document:tap', ['$event'])
  public onDocumentClick(event: MouseEvent): void {
    if (!this.clickOutsideEn) {
      return;
    }

    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.clickOutside.emit(event);
    }
  }
}
