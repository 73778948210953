import {
  MeterConfig,
  RR3,
  EncoderProtocol,
  DeviceTypeHelper,
  UtilityTypeIds,
  ConversionUtils,
  UtilityTypes,
  UomTypes,
  UomTypeIds,
  DeviceTypeIds,
  UomTypeHelper,
  DeviceBatteryLevels,
  UtilityFamily,
  UtilityTypesOrderedList,
  Unit,
  Meter,
  Device,
  RRMeter,
} from '@ncss/models';

import { ByteList } from 'byte-list';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';

import { DirectConnectDeviceStatus } from '../baseDirectConnectDevice';
import { EndDeviceMessage } from '../directConnect301/messages/endDeviceMessage';

export const MeterConfigLabels: { [configType: number]: string } = {};
MeterConfigLabels[MeterConfig.PORT_DISABLED] = 'Disabled';
MeterConfigLabels[MeterConfig.PULSE_IN] = 'Pulse';
MeterConfigLabels[MeterConfig.ENCODER_IN] = 'Encoded';
MeterConfigLabels[MeterConfig.PULSE_OUT] = 'Pulse Out';

export const EncodedProtocolLabels: { [protocol: number]: string } = {};
EncodedProtocolLabels[EncoderProtocol.AMCO] = 'AMCO';
EncodedProtocolLabels[EncoderProtocol.NEPTUNE] = 'Neptune';
EncodedProtocolLabels[EncoderProtocol.SENSUS] = 'Sensus';
EncodedProtocolLabels[EncoderProtocol.ECO] = 'GWF ECO';


export interface IUserChanges {
  rapidCheckInEnabled?: boolean;
  hasOwnProperty?: any;
}

export interface IRRUserChanges extends IUserChanges {
  clearTamper?: boolean;
  pulseOut?: boolean;
  meter1?: IUserChangeableMeterInfo;
  meter2?: IUserChangeableMeterInfo;
  lcdAlwaysOn?: boolean;
  showTamperAlerts?: boolean;
}

export interface IOpenAlert {
  typeStr: string;
  iconStr: string;
}

export interface IUserChangeableMeterInfo {
  multiplier?: number;
  imr?: number;
  utilityTypeId?: number;
  uomTypeId?: number;
  configType?: MeterConfig;
  resetPulseCount?: boolean;
  hasOwnProperty?: any;
}

export interface IDirectConnectMeterInfo extends IUserChangeableMeterInfo {
  read?: string;
  pulseCount?: number;
  utilityTypeName?: string;
  utilityTypeIcon?: string;
  utilityTypeColor?: string;
  uomTypeName?: string;
  configTypeStr?: string;
  isMeterOk?: boolean;
  encodedSerialNumber?: number;
  encodedProtocolStr?: string;
  resetPulseCount?: boolean;
}

export enum EndDeviceFormState {
  PRISTINE,
  DIRTY,
  APPLYING_CHANGES,
  WAITING_CONFIRMATION,
  CHANGES_APPLIED,
}

export class DirectConnectRemoteReader {

  public static useMetric = false;

  public static create(bleDevice: any, manufacturingData: { serialNumber: number, byteList: ByteList }, isLimited = false) {
    const deviceType = DeviceTypeHelper.GetDeviceTypeBySerialNumber(bleDevice.id);
    if (!deviceType || (deviceType.id !== DeviceTypeIds.REMOTE_READER && deviceType.id !== DeviceTypeIds.REMOTE_READER_TRANSCEIVER)) {
      return null;
    }
    // limited access users can't connect to TR devices.
    if (isLimited && deviceType.id !== DeviceTypeIds.REMOTE_READER) {
      return null;
    }
    const device = new DirectConnectRemoteReader(bleDevice.msg);
    return {
      status: DirectConnectDeviceStatus.ADVERTISING,
      serialNumber: bleDevice.msg.srcAddr,
      bleSignalStrength: null,
      bleDevice: bleDevice,
      device,
      subscriptions: [],
    };
  }

  public serialNumber: number;
  public serialNumberStr: string;
  public deviceName: string;
  public deviceModel: string;
  public programmedUnit: Unit;
  public programmedMeters: [Meter, Meter];

  public get firmwareVersionStr$() { return this._firmwareVersionStr$.asObservable(); }
  public get hardwareVersionStr$() { return this._hardwareVersionStr$.asObservable(); }
  public get batteryLevelStr$() { return this._batteryLevelStr$.asObservable(); }
  public get temperatureStr$() { return this._temperatureStr$.asObservable(); }
  public get linkQualityStr$() { return this._linkQualityStr$.asObservable(); }
  public get gatewayReplied$() { return this._gatewayReplied$.asObservable(); }
  public get pulseOutEnabled() { return this._pulseOutEnabled$.value; }
  public get pulseOutEnabled$() { return this._pulseOutEnabled$.asObservable(); }
  public get rapidCheckInEnabled() { return this._rapidCheckInEnabled$.value; }
  public get rapidCheckInEnabled$() { return this._rapidCheckInEnabled$.asObservable(); }
  public get lcdAlwaysOn() { return this._lcdAlwaysOn$.value; }
  public get lcdAlwaysOn$() { return this._lcdAlwaysOn$.asObservable(); }
  public get clearTamper() { return this._clearTamper$.value; }
  public get clearTamper$() { return this._clearTamper$.asObservable(); }
  public get meter1Info() { return this._meter1Info$.value; }
  public get meter1Info$() { return this._meter1Info$.asObservable(); }
  public get meter2Info() { return this._meter2Info$.value; }
  public get meter2Info$() { return this._meter2Info$.asObservable(); }
  public get userChanges() { return this._userChanges$.value; }
  public get userChanges$() { return this._userChanges$.asObservable(); }
  public get hasUserChangedMeter1() { return this._hasUserChangedMeter1$.value; }
  public get hasUserChangedMeter1$() { return this._hasUserChangedMeter1$.asObservable(); }
  public get hasUserChangedMeter2() { return this._hasUserChangedMeter2$.value; }
  public get hasUserChangedMeter2$() { return this._hasUserChangedMeter2$.asObservable(); }
  public get lastMsgAt$() { return this._lastMsgAt$.asObservable(); }
  public get state() { return this._state$.value; }
  public get state$() { return this._state$.asObservable(); }
  public get openAlerts() { return this._openAlerts$.value; }
  public get openAlerts$() { return this._openAlerts$.asObservable(); }
  public get overallStatus() { return this._overallStatus$.value; }
  public get overallStatus$() { return this._overallStatus$.asObservable(); }

  public get factorySleep() { return this._factorySleep; }
  public set factorySleep(val) { this._factorySleep = val; }

  public get factoryReset() { return this._factoryReset; }
  public set factoryReset(val: boolean) { this._factoryReset = val; }

  private _batteryLevel: number;
  private _temperature: number;
  private _linkQuality: number;

  private _firmwareVersionStr$ = new BehaviorSubject<string>('');
  private _hardwareVersionStr$ = new BehaviorSubject<string>('');
  private _batteryLevelStr$ = new BehaviorSubject<string>('');
  private _temperatureStr$ = new BehaviorSubject<string>('');
  private _linkQualityStr$ = new BehaviorSubject<string>('');
  private _gatewayReplied$ = new BehaviorSubject<boolean>(false);
  private _pulseOutEnabled$ = new BehaviorSubject<boolean>(false);
  private _rapidCheckInEnabled$ = new BehaviorSubject<boolean>(false);
  private _lcdAlwaysOn$ = new BehaviorSubject<boolean>(false);
  private _clearTamper$ = new BehaviorSubject<boolean>(false);
  private _meter1Info$ = new BehaviorSubject<IDirectConnectMeterInfo>(null);
  private _meter2Info$ = new BehaviorSubject<IDirectConnectMeterInfo>(null);

  private _userChanges$ = new BehaviorSubject<IRRUserChanges>({ meter1: {}, meter2: {} });
  private _hasUserChangedMeter1$ = new BehaviorSubject<boolean>(false);
  private _hasUserChangedMeter2$ = new BehaviorSubject<boolean>(false);
  private _rr301: RR3;
  private _lastMsg: EndDeviceMessage;
  private _lastMsgAt$ = new BehaviorSubject<Date>(null);
  private _state$ = new BehaviorSubject<EndDeviceFormState>(EndDeviceFormState.PRISTINE);
  private _openAlerts$ = new BehaviorSubject<IOpenAlert[]>([]);
  private _overallStatus$ = new BehaviorSubject<{ label: string, description: string, isOk: boolean }>(null);
  private _factorySleep = false;
  private _factoryReset = false;

  constructor(msg?: EndDeviceMessage) {
    if (msg) {
      this.updateFromMessage(msg);
    }
    this._userChanges$.subscribe((userChanges: IRRUserChanges) => {
      let pristine = true;

      if (!_.isEmpty(userChanges.meter1)) {
        pristine = false;
        this.emitUserChangedMeter1(true);
      } else {
        this.emitUserChangedMeter1(false);
      }
      if (!_.isEmpty(userChanges.meter2)) {
        pristine = false;
        this.emitUserChangedMeter2(true);
      } else {
        this.emitUserChangedMeter2(false);
      }
      if (userChanges.hasOwnProperty('pulseOut')) {
        pristine = false;
      }
      if (userChanges.hasOwnProperty('rapidCheckInEnabled')) {
        pristine = false;
      }
      if (userChanges.hasOwnProperty('lcdAlwaysOn')) {
        pristine = false;
      }
      if (userChanges.hasOwnProperty('clearTamper')) {
        pristine = false;
      }

      if (pristine) {
        this._state$.next(EndDeviceFormState.PRISTINE);
      } else if (this.state === EndDeviceFormState.PRISTINE) {
        this._state$.next(EndDeviceFormState.DIRTY);
      }
    });
  }

  public updateFromMessage(msg: EndDeviceMessage) {
    msg.device = msg.device as RR3;
    this._lastMsg = msg;
    this._rr301 = msg.device;
    this.updateDeviceInfo(msg);
    this.updateMeterInfo(this._meter1Info$, msg.device.meter1, msg.device.meter1Health, this.userChanges ? this.userChanges.meter1 : null);
    this.updateMeterInfo(this._meter2Info$, msg.device.meter2, msg.device.meter2Health, this.userChanges ? this.userChanges.meter2 : null);
    this.updateOpenAlerts(msg);
    this.updateOverallStatus(msg);
    this._lastMsgAt$.next(new Date());
  }

  public setFormToDirty() {
    this._state$.next(EndDeviceFormState.DIRTY);
  }

  public applyChanges() {
    this._state$.next(EndDeviceFormState.APPLYING_CHANGES);
  }

  public changesBeingApplied() {
    this._state$.next(EndDeviceFormState.WAITING_CONFIRMATION);
  }

  public changesSuccessfullyApplied(msg: EndDeviceMessage) {
    this.updateFromMessage(msg);
    this._state$.next(EndDeviceFormState.CHANGES_APPLIED);
    this._userChanges$.next({ meter1: {}, meter2: {} });
    this._meter1Info$.next({ ...this.meter1Info, resetPulseCount: false });
    this._meter2Info$.next({ ...this.meter2Info, resetPulseCount: false });
  }

  public configureMeter(
    meterNumber: 1 | 2,
    configType: MeterConfig,
    utilityTypeId: UtilityTypeIds,
    uomTypeId: UomTypeIds,
    multiplier: number,
    imr: number,
  ) {
    const meter = meterNumber === 1 ? this.userChanges.meter1 : this.userChanges.meter2;
    meter.configType = configType;
    meter.utilityTypeId = utilityTypeId;
    meter.uomTypeId = uomTypeId;
    meter.multiplier = multiplier;
    meter.imr = imr;
    const { info$, info } = this.getInfoFromMeterNumber(meterNumber);
    info$.next({
      ...info,
      ...this.getUtilityTypeInfo(utilityTypeId),
      ...this.getReadInfo(info.pulseCount, multiplier, imr, uomTypeId, configType),
    });
  }

  public dropUserChanges() {
    this._userChanges$.next({ meter1: {}, meter2: {} });
    this.updateFromMessage(this._lastMsg);
  }

  public setUtilityTypeId(meterNumber: 1 | 2, utilityTypeId: UtilityTypeIds) {
    this.updateUserChanges(meterNumber, 'utilityTypeId', utilityTypeId);
    const { info$, info } = this.getInfoFromMeterNumber(meterNumber);
    info$.next({ ...info, ...this.getUtilityTypeInfo(utilityTypeId) });
    // explicitly check if it's water because that is 0
    if (UtilityTypes[utilityTypeId].family || UtilityTypes[utilityTypeId].family === UtilityFamily.WATER) {
      const uomTypes = UomTypeHelper.GetUomForUtilityFamily(UtilityTypes[utilityTypeId].family);
      const index = _.findIndex(uomTypes, { id: info.uomTypeId });
      if (index === -1) {
        this.setUomTypeId(meterNumber, uomTypes[0] ? uomTypes[0].id : null);
      }
    }
  }

  public setUomTypeId(meterNumber: 1 | 2, uomTypeId: UomTypeIds) {
    this.updateUserChanges(meterNumber, 'uomTypeId', uomTypeId);
    const { info$, info } = this.getInfoFromMeterNumber(meterNumber);
    info$.next({ ...info, ...this.getReadInfo(info.pulseCount, info.multiplier, info.imr, uomTypeId, info.configType) });
  }

  public setConfigType(meterNumber: 1 | 2, configType: MeterConfig) {
    this.updateUserChanges(meterNumber, 'configType', configType);
    const { info$, info } = this.getInfoFromMeterNumber(meterNumber);
    info$.next({ ...info, ...this.getReadInfo(info.pulseCount, info.multiplier, info.imr, info.uomTypeId, configType) });
    if (configType === MeterConfig.ENCODER_IN) {
      this.setPulseOut(false);
    }
  }

  public setMultiplier(meterNumber: 1 | 2, multiplier: number) {
    this.updateUserChanges(meterNumber, 'multiplier', multiplier);
    const { info$, info } = this.getInfoFromMeterNumber(meterNumber);
    info$.next({ ...info, ...this.getReadInfo(info.pulseCount, multiplier, info.imr, info.uomTypeId, info.configType) });
  }

  public setIMR(meterNumber: 1 | 2, imr: number) {
    this.updateUserChanges(meterNumber, 'imr', imr);
    const { info$, info } = this.getInfoFromMeterNumber(meterNumber);
    info$.next({ ...info, ...this.getReadInfo(info.pulseCount, info.multiplier, imr, info.uomTypeId, info.configType) });
  }

  public setPulseOut(pulseOut: boolean) {
    const obj = { ...this.userChanges };
    if (pulseOut !== this._rr301.pulseOut) {
      obj.pulseOut = pulseOut;
    } else {
      delete obj.pulseOut;
    }
    this._userChanges$.next(obj);
    this._pulseOutEnabled$.next(pulseOut);
  }

  public setRapidCheckInEnabled(rapidCheckInEnabled: boolean) {
    const obj = { ...this.userChanges };
    if (rapidCheckInEnabled !== this._rr301.rapidCheckInEnabled) {
      obj.rapidCheckInEnabled = rapidCheckInEnabled;
    } else {
      delete obj.rapidCheckInEnabled;
    }
    this._userChanges$.next(obj);
    this._rapidCheckInEnabled$.next(rapidCheckInEnabled);
  }

  public setLCDAlwaysOn(lcdAlwaysOn: boolean) {
    const obj = { ...this.userChanges };
    if (lcdAlwaysOn !== this._rr301.lcdAlwaysOn) {
      obj.lcdAlwaysOn = lcdAlwaysOn;
    } else {
      delete obj.lcdAlwaysOn;
    }
    this._userChanges$.next(obj);
    this._lcdAlwaysOn$.next(lcdAlwaysOn);
  }

  public setClearTamper(value: boolean) {
    const obj = { ...this.userChanges };
    if (value) {
      obj.clearTamper = true;
    } else {
      delete obj.clearTamper;
    }
    this._userChanges$.next(obj);
    this._clearTamper$.next(value);
  }

  public resetPulseCount(meterNumber: 1 | 2, toggleValue, clearUserChanges = false) {
    if (clearUserChanges) {
      const obj = { ...this.userChanges };
      if (meterNumber === 1 && obj.meter1) {
        delete obj.meter1.resetPulseCount;
        this._userChanges$.next(obj);
      } else if (meterNumber === 2 && this.userChanges.meter2) {
        delete obj.meter2.resetPulseCount;
        this._userChanges$.next(obj);
      }
    } else {
      this.updateUserChanges(meterNumber, 'resetPulseCount', toggleValue);
    }
    const { info$, info } = this.getInfoFromMeterNumber(meterNumber);
    info$.next({ ...info, resetPulseCount: toggleValue });
  }

  public getConfiguration(): IRRUserChanges {
    return {
      meter1: {
        multiplier: this._rr301.meter1.multiplier,
        imr: this._rr301.meter1.imr,
        utilityTypeId: this._rr301.meter1.utilityTypeId,
        uomTypeId: this._rr301.meter1.uomTypeId,
        configType: this._rr301.meter1.configType,
        ...this.userChanges.meter1,
      },
      meter2: {
        multiplier: this._rr301.meter2.multiplier,
        imr: this._rr301.meter2.imr,
        utilityTypeId: this._rr301.meter2.utilityTypeId,
        uomTypeId: this._rr301.meter2.uomTypeId,
        configType: this._rr301.meter2.configType,
        ...this.userChanges.meter2,
      },
      pulseOut: this.userChanges.hasOwnProperty('pulseOut') ? this.userChanges.pulseOut : this._rr301.pulseOut,
      rapidCheckInEnabled: this.userChanges.hasOwnProperty('rapidCheckInEnabled')
        ? this.userChanges.rapidCheckInEnabled
        : this._rr301.rapidCheckInEnabled,
      lcdAlwaysOn: this.userChanges.hasOwnProperty('lcdAlwaysOn')
        ? this.userChanges.lcdAlwaysOn
        : this._rr301.lcdAlwaysOn,
    };
  }

  public applyConfiguration(changes: IRRUserChanges) {
    this._userChanges$.next(changes);
    this.applyConfigurationForMeter(1, changes.meter1);
    this.applyConfigurationForMeter(2, changes.meter2);
    this._pulseOutEnabled$.next(changes.pulseOut);
    this._rapidCheckInEnabled$.next(changes.rapidCheckInEnabled);
    this._lcdAlwaysOn$.next(changes.lcdAlwaysOn);
  }

  public needsCloudSync(): boolean {
    return this.isTRDevice() && (!!this.programmedUnit);
  }

  public setProgrammedUnit(unit: Unit) {
    this.programmedUnit = unit;
    const baseSN = ConversionUtils.GetBaseRRSerial(this.serialNumber);
    const meters: [Meter, Meter] = [null, null];
    unit.meters.forEach((m) => {
      if (m.device && (m.device.id === baseSN + 1)) {
        meters[0] = m;
      } else if (m.device && (m.device.id === baseSN + 2)) {
        meters[1] = m;
      }
    });
    this.programmedMeters = meters;
    this.populateChangesFromProgrammedMeters(meters);
  }

  private populateChangesFromProgrammedMeters(meters: [Meter, Meter]): void {
    const [meter1, meter2] = meters;
    if (!meter1 && !meter2) { return; }
    this.setLCDAlwaysOn(meter1.meterDisplayAlwaysOn);
    this.setPulseOut(meter1.meterPulseOut || false);
    this.setConfigType(1, meter1.meterPortType || MeterConfig.PULSE_IN);
    this.setMultiplier(1, meter1.multiplier);
    this.setUtilityTypeId(1, meter1.utilityTypeId);
    if (meter1.meterPortType !== MeterConfig.ENCODER_IN) {
      this.setIMR(1, meter1.imr);
    }
    this.setUomTypeId(1, meter1.uomTypeId);

    if (!meter2) {
      this.setConfigType(2, meter1.meterPulseOut ? MeterConfig.PULSE_OUT : MeterConfig.PORT_DISABLED);
    } else {
      this.setConfigType(2, meter2.meterPortType || MeterConfig.PULSE_IN);
      this.setMultiplier(2, meter2.multiplier);
      this.setUtilityTypeId(2, meter2.utilityTypeId);
      if (meter2.meterPortType !== MeterConfig.ENCODER_IN) {
        this.setIMR(2, meter2.imr);
      }
      this.setUomTypeId(2, meter2.uomTypeId);
    }
  }

  public getMetersForCloudSync(): [Meter, Meter] {
    const config: IRRUserChanges = this.getConfiguration();
    const m1 = new Meter(this.programmedMeters ? this.programmedMeters[0] : null);
    m1.imr = config.meter1.imr;
    m1.utilityTypeId = config.meter1.utilityTypeId;
    m1.uomTypeId = config.meter1.uomTypeId;
    m1.multiplier = config.meter1.multiplier;
    m1.meterPortType = config.meter1.configType;
    m1.meterPulseOut = config.pulseOut;
    m1.meterDisplayAlwaysOn = config.lcdAlwaysOn;
    const m1DeviceId = ConversionUtils.GetBaseRRSerial(this.serialNumber) + 1;
    m1.device = m1.device && m1.device.id && m1.device.id === m1DeviceId ? m1.device : new Device({ id: m1DeviceId });
    let m2: Meter = null;
    if (!config.pulseOut &&
      config.meter2 &&
      (config.meter2.configType === MeterConfig.PULSE_IN || config.meter2.configType === MeterConfig.ENCODER_IN)) {
      m2 = new Meter(this.programmedMeters ? this.programmedMeters[1] : null);
      m2.imr = config.meter2.imr;
      m2.utilityTypeId = config.meter2.utilityTypeId;
      m2.uomTypeId = config.meter2.uomTypeId;
      m2.multiplier = config.meter2.multiplier;
      m2.meterPortType = config.meter2.configType;
      m2.meterPulseOut = false;
      m2.meterDisplayAlwaysOn = config.lcdAlwaysOn;
      const m2DeviceId = ConversionUtils.GetBaseRRSerial(this.serialNumber) + 2;
      m2.device = m2.device && m2.device.id && m2.device.id === m2DeviceId ? m2.device : new Device({ id: m2DeviceId });
    }
    return [m1, m2];
  }

  public getAvailableUtilities(port: 1 | 2) {
    let otherSelected: UtilityTypeIds;
    if (port === 2) {
      otherSelected = this.meter1Info ? this.meter1Info.utilityTypeId : null;
    } else {
      otherSelected = this.meter2Info && this.meter2Info.configType !== MeterConfig.PORT_DISABLED ?
        this.meter2Info.utilityTypeId : null;
    }
    const othersOnUnit = this.programmedUnit ?
      this.getOtherMetersOnUnit(this.programmedUnit).map((m) => m.utilityTypeId) : [];

    return UtilityTypesOrderedList.filter(
      (u) => u.id !== otherSelected && u.id !== UtilityTypeIds.RUN_TIME && !othersOnUnit.includes(u.id));
  }

  private getOtherMetersOnUnit(unit: Unit) {
    return unit.meters.filter((m) => {
      if (m && m.device && ConversionUtils.GetBaseRRSerial(m.device.id) === this.serialNumber) {
        return false;
      } else {
        return true;
      }
    });
  }

  private isTRDevice() {
    if (!this.serialNumber) { return false; }
    const deviceType = DeviceTypeHelper.GetDeviceTypeBySerialNumber(this.serialNumber);
    if (deviceType && deviceType.id === DeviceTypeIds.REMOTE_READER_TRANSCEIVER) {
      return true;
    } else {
      return false;
    }
  }

  private applyConfigurationForMeter(meterNumber: 1 | 2, changes: IUserChangeableMeterInfo) {
    const { info$, info } = this.getInfoFromMeterNumber(meterNumber);
    info$.next({
      ...info,
      ...this.getReadInfo(
        info.pulseCount,
        changes.hasOwnProperty('multiplier') ? changes.multiplier : info.multiplier,
        changes.hasOwnProperty('imr') ? changes.imr : info.imr,
        changes.hasOwnProperty('uomTypeId') ? changes.uomTypeId : info.uomTypeId,
        changes.hasOwnProperty('configType') ? changes.configType : info.configType,
      ),
      ...this.getUtilityTypeInfo(changes.hasOwnProperty('utilityTypeId') ? changes.utilityTypeId : info.utilityTypeId),
    });
  }

  private getInfoFromMeterNumber(meterNumber: 1 | 2): { info: IDirectConnectMeterInfo, info$: BehaviorSubject<IDirectConnectMeterInfo> } {
    return meterNumber === 1 ? { info: this.meter1Info, info$: this._meter1Info$ } : { info: this.meter2Info, info$: this._meter2Info$ };
  }

  private updateDeviceInfo(msg: EndDeviceMessage) {
    this.serialNumber = msg.srcAddr;
    this.serialNumberStr = ConversionUtils.ConvertSerialNumberToString(msg.srcAddr);
    const deviceType = DeviceTypeHelper.GetDeviceTypeBySerialNumber(this.serialNumber);
    this.deviceName = deviceType ? deviceType.name : '';
    this.deviceModel = deviceType ? deviceType.model : '';
    msg.device = msg.device as RR3;
    if (this._batteryLevel !== msg.device.batteryLevel) {
      this._batteryLevel = msg.device.batteryLevel;
      this._batteryLevelStr$.next(DeviceBatteryLevels[msg.device.batteryLevel]);
    }
    if (this._temperature !== msg.device.temperature) {
      this._temperature = msg.device.temperature;
      this._temperatureStr$.next(DirectConnectRemoteReader.useMetric
        ? msg.device.temperature + ' C'
        : _.round(ConversionUtils.ConvertCelsiusToFahrenheit(msg.device.temperature)) + ' F');
    }
    if (this._linkQuality !== msg.firstHopRssi) {
      this._linkQuality = msg.firstHopRssi;
      this._linkQualityStr$.next(this.normalizeSignalStrength(msg.firstHopRssi));
    }
    if (this._gatewayReplied$.value !== msg.device.gatewayReplied) {
      this._gatewayReplied$.next(msg.device.gatewayReplied);
    }

    if (this.userChanges && this.userChanges.hasOwnProperty('pulseOut')) {
      this._pulseOutEnabled$.next(this.userChanges.pulseOut);
    } else {
      if (this._pulseOutEnabled$.value !== msg.device.pulseOut) {
        this._pulseOutEnabled$.next(msg.device.pulseOut);
      }
    }
    if (this.userChanges && this.userChanges.hasOwnProperty('rapidCheckInEnabled')) {
      this._rapidCheckInEnabled$.next(this.userChanges.rapidCheckInEnabled);
    } else {
      this._rapidCheckInEnabled$.next(msg.device.rapidCheckInEnabled);
    }
    if (this.userChanges && this.userChanges.hasOwnProperty('lcdAlwaysOn')) {
      this._lcdAlwaysOn$.next(this.userChanges.lcdAlwaysOn);
    } else {
      this._lcdAlwaysOn$.next(msg.device.lcdAlwaysOn);
    }
    const firmware = `v${msg.device.firmwareVersionMajor}.${msg.device.firmwareVersionMinor}`;
    if (firmware !== this._firmwareVersionStr$.value) {
      this._firmwareVersionStr$.next(firmware);
    }
    const hardware = `v${msg.device.hardwareVersionMajor}.${msg.device.hardwareVersionMinor}`;
    if (hardware !== this._hardwareVersionStr$.value) {
      this._hardwareVersionStr$.next(hardware);
    }
  }

  private updateOpenAlerts(msg: EndDeviceMessage) {
    const alerts: IOpenAlert[] = [];
    if (msg.device && msg.device instanceof RR3) {
      if (msg.device.tamperAlert) {
        alerts.push({ typeStr: 'Tamper', iconStr: 'icon-alert-triangle' });
      }
      if (msg.device.freeze) {
        alerts.push({ typeStr: 'Freeze', iconStr: 'icon-freeze' });
      }
      if (msg.device.lowBattery) {
        alerts.push({ typeStr: 'Low Battery', iconStr: 'icon-battery-low' });
      }
      // Make sure that the meters are configured
      if (msg.device.meter1Leak) {
        alerts.push({ typeStr: 'Leak - Meter 1', iconStr: 'icon-leak-B' });
      }
      if (msg.device.meter2Leak) {
        alerts.push({ typeStr: 'Leak - Meter 2', iconStr: 'icon-leak-B' });
      }
      // Make sure that the meters are configured
      if (msg.device.meter1BounceDetected) {
        alerts.push({ typeStr: 'Faulty Reed Switch - Meter 1', iconStr: 'icon-meter-off' });
      }
      if (msg.device.meter2BounceDetected) {
        alerts.push({ typeStr: 'Faulty Reed Switch - Meter 2', iconStr: 'icon-meter-off' });
      }
      // Make sure that the meters are configured
      if (msg.device.meter1Regression) {
        alerts.push({ typeStr: 'Meter Regression - Meter 1', iconStr: 'icon-meter-off' });
      }
      if (msg.device.meter2Regression) {
        alerts.push({ typeStr: 'Meter Regression - Meter 2', iconStr: 'icon-meter-off' });
      }
    }
    this._openAlerts$.next(alerts);
  }

  private updateOverallStatus(msg: EndDeviceMessage) {
    msg.device = msg.device as RR3;
    const status = {
      isOk: true,
      label: '',
      description: '',
    };
    const deviceType = DeviceTypeHelper.GetDeviceTypeBySerialNumber(msg.srcAddr);
    if (deviceType && deviceType.id === DeviceTypeIds.REMOTE_READER_TRANSCEIVER) {
      const linkQuality = this.normalizeSignalStrength(msg.firstHopRssi);
      status.isOk = msg.device.gatewayReplied && linkQuality !== 'Poor';
      status.label = linkQuality === 'Poor'
        ? 'Poor Transceiver Signal'
        : msg.device.gatewayReplied ? 'All Good' : 'Not Communicating With Gateway';
      status.description = msg.device.gatewayReplied
        ? `This device has a${linkQuality === 'Excellent' ? 'n' : ''} ${linkQuality} connection to the Gateway.`
        : 'This Remote Reader has not received a response from any nearby Gateways. This could mean that this ' +
        'device is either not programmed to a Unit yet or it is not within range of a Gateway.';
    }
    if (status.isOk) {
      const { isOk, label, description } = this.getMeterDetectedStr({
        type: msg.device.meter1.configType,
        isOk: msg.device.meter1Health,
        protocol: msg.device.meter1.encoderProtocol,
      }, {
        type: msg.device.meter2.configType,
        isOk: msg.device.meter2Health,
        protocol: msg.device.meter2.encoderProtocol,
      });
      if (isOk) {
        status.description += ' ' + description;
      } else {
        status.isOk = false;
        status.label = label;
        status.description = description;
      }
    }
    if (!_.isEqual(this.overallStatus, status)) {
      this._overallStatus$.next(status);
    }
  }

  private getMeterDetectedStr(
    meter1: { type: MeterConfig, isOk: boolean, protocol?: EncoderProtocol },
    meter2: { type?: MeterConfig, isOk?: boolean, protocol?: EncoderProtocol },
  ): { isOk: boolean, label: string, description: string } {
    if (meter2.type === MeterConfig.PORT_DISABLED || meter2.type === MeterConfig.PULSE_OUT) {
      if (meter1.isOk) {
        // 1 Meter Configured - Healthy
        return meter1.type === MeterConfig.PULSE_IN
          ? {
            isOk: true,
            label: 'Usage Detected',
            description: 'Pulses have been detected within the last 24 hours.',
          } : {
            isOk: true,
            label: 'Communicating With Meter',
            description: `The Remote Reader can successfully communicate with the connected ${EncodedProtocolLabels[meter1.protocol]} meter.`,
          };
      } else {
        // 1 Meter Configured - NOT Healthy
        return meter1.type === MeterConfig.PULSE_IN
          ? {
            isOk: false,
            label: 'No Usage Detected',
            description: 'No pulses detected within the last 24 hours.',
          } : {
            isOk: false,
            label: 'Check Wiring',
            description: 'The Remote Reader could not communicate with the connected meter. Refer to the "Wiring Guide" here or contact us at support@nextcenturymeters.com for assistance.',
          };
      }
    } else {
      if (meter1.isOk && meter2.isOk) {
        if (meter1.type === MeterConfig.PULSE_IN && meter2.type === MeterConfig.PULSE_IN) {
          // Both Meters - Healthy - PULSE
          return {
            isOk: true,
            label: 'Meters Detected',
            description: 'Pulses have been detected within the last 24 hours on both meters.',
          };
        } else if (meter1.type === MeterConfig.ENCODER_IN && meter2.type === MeterConfig.ENCODER_IN) {
          // Both Meters - Healthy - ENCODED
          const protocols: string[] = [];
          if (EncodedProtocolLabels[meter1.protocol]) { protocols.push(EncodedProtocolLabels[meter1.protocol]); }
          if (EncodedProtocolLabels[meter2.protocol]) { protocols.push(EncodedProtocolLabels[meter2.protocol]); }
          return {
            isOk: true,
            label: 'Communicating With Meters',
            description: 'The Remote Reader can successfully communicate with both connected meters' + protocols.length ? ` (${protocols.join(' and ')})` : '',
          };
        } else {
          // Both Meters - Healthy - PULSE AND ENCODED
          return {
            isOk: true,
            label: 'Meters Detected',
            description: 'The Remote Reader has successfully detected both connected meters.',
          };
        }
      } else if (!meter1.isOk && meter2.isOk) {
        // Meter 1 - NOT Healthy
        return meter1.type === MeterConfig.PULSE_IN
          ? {
            isOk: false,
            label: 'No Usage Detected',
            description: 'No pulses detected within the last 24 hours on Meter 1.',
          } : {
            isOk: false,
            label: 'Check Wiring',
            description: 'The Remote Reader could not communicate with the Meter 1. Refer to the "Wiring Guide" here or contact us at support@nextcenturymeters.com for assistance.',
          };
      } else if (meter1.isOk && !meter2.isOk) {
        // Meter 2 - NOT Healthy
        return meter2.type === MeterConfig.PULSE_IN
          ? {
            isOk: false,
            label: 'No Usage Detected',
            description: 'No pulses detected within the last 24 hours on Meter 2.',
          } : {
            isOk: false,
            label: 'Check Wiring',
            description: 'The Remote Reader could not communicate with Meter 2. Refer to the "Wiring Guide" here or contact us at support@nextcenturymeters.com for assistance.',
          };
      } else {
        if (meter1.type === MeterConfig.PULSE_IN && meter2.type === MeterConfig.PULSE_IN) {
          // Both Meters - NOT Healthy - PULSE
          return {
            isOk: false,
            label: 'Meters Not Detected',
            description: 'No pulses have been detected within the last 24 hours on both meters.',
          };
        } else if (meter1.type === MeterConfig.ENCODER_IN && meter2.type === MeterConfig.ENCODER_IN) {
          // Both Meters - NOT Healthy - ENCODED
          return {
            isOk: true,
            label: 'Check Wiring',
            description: 'The Remote Reader could not communicate with either meter. Refer to the "Wiring Guide" here or contact us at support@nextcenturymeters.com for assistance.',
          };
        } else {
          // Both Meters - NOT Healthy - PULSE AND ENCODED
          return {
            isOk: true,
            label: 'Check Wiring',
            description: 'The Remote Reader has not detected either meters.',
          };
        }
      }
    }
  }

  private updateMeterInfo(
    bs: BehaviorSubject<IDirectConnectMeterInfo>,
    meter: RRMeter,
    isMeterOk: boolean,
    userChanges?: IDirectConnectMeterInfo,
  ) {
    bs.next({
      ...bs.value,
      ...this.getReadInfo(
        meter.getCurrentRead(),
        userChanges && userChanges.multiplier ? userChanges.multiplier : meter.multiplier,
        userChanges && (userChanges.imr || userChanges.imr === 0) ? userChanges.imr : meter.imr,
        userChanges && userChanges.uomTypeId ? userChanges.uomTypeId : meter.uomTypeId,
        userChanges && (userChanges.configType || userChanges.configType === 0) ? userChanges.configType : meter.configType,
      ),
      ...this.getUtilityTypeInfo(userChanges && userChanges.utilityTypeId ? userChanges.utilityTypeId : meter.utilityTypeId),
      encodedSerialNumber: meter.meterSerialNumber,
      encodedProtocolStr: meter.encoderProtocol ? EncodedProtocolLabels[meter.encoderProtocol] : '',
      isMeterOk,
    });
  }

  private getUtilityTypeInfo(utilityTypeId: UtilityTypeIds): IDirectConnectMeterInfo {
    const utilityType = UtilityTypes[utilityTypeId];
    return {
      utilityTypeId,
      utilityTypeIcon: utilityType ? utilityType.iconName : '',
      utilityTypeName: utilityType ? utilityType.name : '',
      utilityTypeColor: utilityType ? utilityType.colorHex : '',
    };
  }

  private getReadInfo(
    pulseCount: number,
    multiplier: number,
    imr: number,
    uomTypeId: UomTypeIds,
    configType: MeterConfig,
  ): IDirectConnectMeterInfo {
    const uomType = UomTypes[uomTypeId];
    const read = ((pulseCount || 0) * (multiplier || 1)) + (imr || 0);
    return {
      read: read.toLocaleString('en-GB') + ' ' + (uomType ? uomType.name : ''),
      pulseCount,
      multiplier,
      imr,
      uomTypeId,
      uomTypeName: uomType ? uomType.name : '',
      configType: configType,
      configTypeStr: MeterConfigLabels[configType],
    };
  }

  private isMeterValueDifferent(meterNumber: 1 | 2, key: string, value: any) {
    const meterNumberKey = meterNumber === 1 ? 'meter1' : 'meter2';
    return !this._rr301 || this._rr301[meterNumberKey][key] !== value;
  }

  private updateUserChanges(meterNumber: 1 | 2, key: string, value: any) {
    const meterNumberKey = meterNumber === 1 ? 'meter1' : 'meter2';
    const obj = { ...this.userChanges };
    if (this.isMeterValueDifferent(meterNumber, key, value)) {
      obj[meterNumberKey][key] = value;
    } else {
      delete obj[meterNumberKey][key];
    }
    this._userChanges$.next(obj);
  }

  private emitUserChangedMeter1(val: boolean) {
    if (this.hasUserChangedMeter1 !== val) {
      this._hasUserChangedMeter1$.next(val);
    }
  }

  private emitUserChangedMeter2(val: boolean) {
    if (this.hasUserChangedMeter2 !== val) {
      this._hasUserChangedMeter2$.next(val);
    }
  }

  private normalizeSignalStrength(rssi: number): string {
    if (rssi > 168) {
      return 'Excellent';
    } else if (rssi < 85) {
      return 'Poor';
    } else {
      return 'Good';
    }
  }

}
