import { Message, MessageProperty } from '@ncss/message';

import { DataTypes } from 'byte-list';
import { forEach } from 'lodash';

export enum UpdateErrorCode {
  UPDATE_ERROR_NONE = 0,
  UPDATE_ERROR_DEVICE_TYPE = 1,
  UPDATE_ERROR_SIZE = 2,
  UPDATE_ERROR_INFO_CHANGED = 3,
  UPDATE_ERROR_WRITE_FLASH = 4,
  UPDATE_ERROR_TAG = 5,
  UPDATE_ERROR_SAME_VERSION = 6,
}

export enum DC301UpdateStatusMessageType {
  GROUP_MASK = 0x1240,
  DC_UPDATE_STATUS = 0x1241,
}

export class DC301UpdateStatusMessage extends Message {
  @MessageProperty(DataTypes.UINT8)
  public versionMajor: number;

  @MessageProperty(DataTypes.UINT8)
  public versionMinor: number;

  @MessageProperty(DataTypes.UINT8)
  public transferId: number;

  @MessageProperty(DataTypes.UINT16)
  public blockNumber: number;

  @MessageProperty(DataTypes.BYTE)
  public errorCode: UpdateErrorCode;

  public toString() {
    let typeStr = '';
    forEach(DC301UpdateStatusMessageType, (val, key) => {
      if (val === this.type) {
        typeStr = key;
      }
    });
    return `DC301UpdateStatusMessage.${typeStr} Code: ${this.errorCode}`;
  }
}
