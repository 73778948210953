import {
  Meter,
  Unit,
  Lorax,
  DeviceTypeHelper,
  DeviceFamily,
  ConversionUtils,
  MeterConfig,
  SubscriptionType,
  ICanProgramUnitInfo,
  Device,
  UtilityTypeIds,
  MeterAlert,
  UtilityTypes,
  UtilityFamily,
  IDeviceType,
  DeviceTypeIds,
} from '@ncss/models';

import { FormGroup, AbstractControl, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { isEqual } from 'lodash';
import { BehaviorSubject } from 'rxjs';

import { CameraService } from '../services/camera.service';
import { MobileMeterManufacturerService } from '../services/mobile-meter-manufacturer.service';
import { MobileMeterTypeService } from '../services/mobile-meter-type.service';
import { MobilePropertyService } from '../services/mobile-property.service';
import { MobileUnitService } from '../services/mobile-unit.service';
import { MobileUserService } from '../services/mobile-user.service';
import { clearErrorOnControl, CreatePositiveNumberValidator, setErrorOnControl } from './common-validators';
import { MeterFormArray } from './meter-form-array';
import { MeterTypeFormGroup } from './meter-type-form';
import { PhotoControl } from './photo-control';
import { ForMeterDevice, CreateCanProgram, ForManualReadMeter, ForSerialNumberLength } from './serial-number-validators';

interface MeterFormControls {
  deviceId: FormControl;
  utilityTypeId: FormControl;
  imr: FormControl;
  imrPhoto: PhotoControl;
  meterSerial: FormControl;
  customId: FormControl;
  meterType: MeterTypeFormGroup;
  configType: FormControl;
  portNumber: FormControl;
  pulseOutOnPortTwo: FormControl;
  [key: string]: AbstractControl;
}

export class MeterFormGroup extends FormGroup {

  public static validateIMR(imr: number): number {
    const isNotNumber = typeof imr !== 'number';
    if (isNotNumber) {
      return null;
    }
    if (imr === 0) {
      return 0;
    }
    return imr;
  }

  static async Create(
    propertyService: MobilePropertyService,
    meterTypeService: MobileMeterTypeService,
    meterManufacturerService: MobileMeterManufacturerService,
    unitService: MobileUnitService,
    userService: MobileUserService,
    cameraService: CameraService,
    modalCtl: ModalController,
    unit: Unit,
    meter: Meter,
    alerts?: MeterAlert[],
  ): Promise<MeterFormGroup> {
    const meterType = await MeterTypeFormGroup.Create(meterTypeService, meterManufacturerService, unitService, meter);
    let portNumber = null;
    if (
      meter && meter.device && meter.device.id &&
      DeviceTypeHelper.GetFamilyBySerialNumber(meter.device.id) === DeviceFamily.REMOTE_READER) {
      portNumber = meter.device.id - ConversionUtils.GetBaseRRSerial(meter.device.id);
    }
    const manualReadActive =
      unit.subscriptions && unit.subscriptions[SubscriptionType.MANUAL_READS] && unit.subscriptions[SubscriptionType.MANUAL_READS].active;
    const utilityTypeId = new FormControl(meter ? meter.utilityTypeId || null : null, [Validators.required]);
    const unitInfo: ICanProgramUnitInfo = { unitId: unit._id, utilityTypeId: meter.utilityTypeId };
    const deviceId = new FormControl(
      meter && meter.device ? meter.device.id : null,
      [ForSerialNumberLength(), manualReadActive ? ForManualReadMeter() : ForMeterDevice()],
      [CreateCanProgram(
        propertyService,
        unit.property ? unit.property.id : null,
        unitInfo,
        utilityTypeId,
      )],
    );
    const isEncoded = Lorax.isMeterEncoded(meter);
    const configType = new FormControl(
      isEncoded ? MeterConfig.ENCODER_IN : (meter && meter.meterPortType !== null && meter.meterPortType !== undefined)
       ? meter.meterPortType :  MeterConfig.PULSE_IN,
    );
    const meterSerialNumber = Lorax.getMeterSerialFromMeter(meter);
    const group = new MeterFormGroup(
      meter,
      unit,
      alerts || [],
      {
        deviceId,
        utilityTypeId,
        imr: new FormControl(meter ? this.validateIMR(meter.imr) : null, CreatePositiveNumberValidator('IMR')),
        imrPhoto: PhotoControl.FromMeter(meter, cameraService, modalCtl, userService, unit._id, unit.property.id),
        meterSerial: new FormControl(meterSerialNumber.error ? null : (meterSerialNumber.result.serial || null)),
        customId: new FormControl(meter ? meter.customId || null : null),
        meterType,
        configType,
        portNumber: new FormControl(portNumber),
        pulseOutOnPortTwo: new FormControl(meter ? !!meter.meterPulseOut : false),
        highConsumptionThreshold: new FormControl(meter.highConsumptionThreshold && meter.utilityTypeId && UtilityTypes[meter.utilityTypeId].family === UtilityFamily.WATER
        ? meter.highConsumptionThreshold + ' ' + (meter.uomType ? meter.uomType.name : '') : '-'),
      },
      meterTypeService,
    );
    return group;
  }

  controls: MeterFormControls;
  parent: MeterFormArray;
  deviceType$ = new BehaviorSubject<IDeviceType>(null);
  private originalValues;

  private constructor(
    private meter: Meter,
    private unit: Unit,
    public alerts: MeterAlert[],
    controls: MeterFormControls,
    public meterTypeService: MobileMeterTypeService,
  ) {
    super(controls, [SpecificUtilityDeviceValidator()]);
    this.originalValues = this.value;
    this.setupListeners();
  }

  async setUtilityType(utilityTypeId: UtilityTypeIds): Promise<void> {
    this.controls.utilityTypeId.setValue(utilityTypeId);
    await this.controls.meterType.utilityTypeIdChanged(utilityTypeId);
    if (this.controls.deviceId.value && !this.controls.deviceId.invalid) {
      await new Promise<void>((resolve, reject) => {
        setTimeout(() => {
          this.controls.deviceId.updateValueAndValidity();
          resolve();
        }, 400);
      });
    }
  }

  originalMeter(): Meter {
    return this.meter;
  }

  originalUnit(): Unit {
    return this.unit;
  }

  toMeter(): Meter {
    return new Meter({
      ...this.meter,
      device: new Device({
        ...this.meter.device,
        id: this.controls.deviceId.value,
        data: (this.meter.device && this.meter.device.data && this.meter.device.data.deviceId === this.controls.deviceId.value) ?
          this.meter.device.data : null,
      }),
      utilityTypeId: this.controls.utilityTypeId.value,
      imr: MeterFormGroup.validateIMR(this.controls.imr.value),
      meterSerialNumber: this.controls.meterSerial.value,
      customId: this.controls.customId.value,
      meterTypeId: this.controls.meterType.controls.meterTypeId.value,
      meterManufacturerId: this.controls.meterType.controls.meterManufacturerId.value,
      meterManufacturerName: this.controls.meterType.controls.meterManufacturerName.value,
      meterModelNumber: this.controls.meterType.controls.meterModelNumber.value,
      uomTypeId: this.controls.meterType.controls.uom.value,
      meterSize: this.controls.meterType.controls.meterSize.value,
      multiplier: this.controls.meterType.controls.multiplier.value,
      meterPortType: this.controls.configType.value,
      pulseOut: this.controls.pulseOutOnPortTwo.value,
      highConsumptionThreshold: null,
    });
  }

  private setupListeners() {
    this.onDeviceIdChange(this.controls.deviceId.value);
    this.onConfigTypeChange(this.controls.configType.value);
    this.onPortChange(this.controls.portNumber.value);
    this.onMeterSerialChange(this.controls.meterSerial.value);

    this.controls.deviceId.valueChanges.subscribe((value) => {
      this.onDeviceIdChange(value);
    });

    this.controls.portNumber.valueChanges.subscribe((value) => {
      this.onPortChange(value);
    });

    this.controls.configType.valueChanges.subscribe((value) => {
      this.onConfigTypeChange(value);
    });

    this.controls.meterSerial.valueChanges.subscribe((value) => {
      this.onMeterSerialChange(value);
    });

    this.valueChanges.subscribe(() => {
      this.checkForChanges();
    });

    if (this.isDeviceFamily(DeviceFamily.MANUAL_READER)) {
      this.controls.deviceId.disable();
    }
  }

  private checkForChanges() {
    for (const key of Object.keys(this.controls)) {
      if (!isEqual(this.originalValues[key], this.controls[key].value)) {
        this.controls[key].markAsDirty();
        this.controls[key].markAsTouched();
      } else {
        this.controls[key].markAsPristine();
        this.controls[key].markAsUntouched();
      }
    }
  }

  private onPortChange(value: number) {
    const id = ConversionUtils.ConvertSerialNumberToNumberForFrontEnd(this.controls.deviceId.value);
    if (this.isDeviceFamily(DeviceFamily.REMOTE_READER)) {
      const baseId = ConversionUtils.GetBaseRRSerial(id);
      const port = id - baseId;
      if ((value === 1 || value === 2) && port !== value) {
        this.controls.deviceId.setValue(baseId + value);
      }

      if (value !== 1) {
        this.controls.pulseOutOnPortTwo.setValue(false);
        this.controls.pulseOutOnPortTwo.disable();
      } else if (this.controls.configType.value !== MeterConfig.ENCODER_IN) {
        this.controls.pulseOutOnPortTwo.enable();
      }
    }
  }

  private onConfigTypeChange(value: MeterConfig) {
    if (value === MeterConfig.ENCODER_IN) {
      this.controls.imr.disable();
      this.controls.pulseOutOnPortTwo.setValue(false);
      this.controls.pulseOutOnPortTwo.disable();
    } else if (!this.isDeviceFamily(DeviceFamily.INTEGRATED_METER) && !this.isEmbeddedTransceiver()) {
      this.controls.imr.enable();
      if (this.isDeviceFamily(DeviceFamily.REMOTE_READER) && this.controls.portNumber.value === 1) {
        this.controls.pulseOutOnPortTwo.enable();
      }
    }
  }

  private async onDeviceIdChange(value: number) {
    const id = ConversionUtils.ConvertSerialNumberToNumberForFrontEnd(value);
    this.deviceType$.next(DeviceTypeHelper.GetDeviceTypeBySerialNumber(id));
    this.disableAllFields();
    if (this.isDeviceFamily(DeviceFamily.REMOTE_READER)) {
      this.enableRRFields();
      const baseId = ConversionUtils.GetBaseRRSerial(id);
      const port = id - baseId;
      if (port !== 1) {
        this.controls.pulseOutOnPortTwo.disable();
      }
      if ((port === 1 || port === 2) && this.controls.portNumber.value !== port) {
        this.controls.portNumber.setValue(port, { emitEvent: true });
      } else if (port === 0) {
        const inferredPort = this.inferPortForRR(baseId);
        this.controls.deviceId.setValue(baseId + inferredPort);
      }
      return;
    }
    if (this.isDeviceFamily(DeviceFamily.MANUAL_READER)) {
      this.enableManualReadFields();
      return;
    }
    if (this.isEmbeddedTransceiver() || this.isDeviceFamily(DeviceFamily.INTEGRATED_METER)) {
      this.controls.customId.enable();
      if (this.deviceType$.value.manufacturer && this.deviceType$.value.manufacturer.meterTypeId) {
        this.controls.meterType.enable();
        this.controls.meterType.patchWithMeterType(this.deviceType$.value.manufacturer.meterTypeId).then(() => {
          this.controls.meterType.disable();
        });
      } else {
        this.controls.meterType.enable();
        this.controls.meterType.utilityTypeIdChanged(this.controls.utilityTypeId.value).then(() => {
          this.controls.meterType.disable();
        });
      }
      this.updateValueAndValidity();
      return;
    }

    this.enableTRFields();
    this.patchValue({
      portNumber: null,
      pulseOutOnPortTwo: false,
    });
    this.updateValueAndValidity();
  }

  private onMeterSerialChange(meterSerial: string) {
    const utilityTypeId = this.controls.utilityTypeId.value;
    const meterSerialNum = ConversionUtils.ConvertSerialNumberToNumberForFrontEnd(meterSerial);
    const shouldMoveToDeviceId = (DeviceTypeHelper.GetIsEmbeddedTransceiver(meterSerialNum) && utilityTypeId === UtilityTypeIds.ELECTRIC) ||
      (DeviceTypeHelper.GetFamilyBySerialNumber(meterSerialNum) === DeviceFamily.INTEGRATED_METER &&
      DeviceTypeHelper.GetIdBySerialNumber(meterSerialNum) !== DeviceTypeIds.NR4);
    if (shouldMoveToDeviceId) {
      this.controls.deviceId.setValue(meterSerial);
      this.controls.meterSerial.setValue(null);
    }
  }

  private inferPortForRR(baseId: number): number {
    const rrForms = this.parent.getMetersWithRemoteReader(baseId);
    const otherRR = rrForms.find((meter) => {
      return meter.controls.utilityTypeId.value !== this.controls.utilityTypeId.value;
    });
    if (otherRR) {
      const otherPort = otherRR.controls.portNumber.value;
      return otherPort === 2 ? 1 : 2;
    } else {
      return 1;
    }
  }

  public isDeviceFamily(family: DeviceFamily): boolean {
    const id = ConversionUtils.ConvertSerialNumberToNumberForFrontEnd(this.controls.deviceId.value);
    return DeviceTypeHelper.GetFamilyBySerialNumber(id) === family;
  }

  public isEmbeddedTransceiver(): boolean {
    const id = ConversionUtils.ConvertSerialNumberToNumberForFrontEnd(this.controls.deviceId.value);
    return DeviceTypeHelper.GetIsEmbeddedTransceiver(id);
  }

  private disableAllFields() {
    this.controls.meterSerial.disable({ emitEvent: false });
    this.controls.imr.disable({ emitEvent: false });
    this.controls.configType.disable({ emitEvent: false });
    this.controls.portNumber.disable({ emitEvent: false });
    this.controls.pulseOutOnPortTwo.disable({ emitEvent: false });
    this.controls.meterType.disable({ emitEvent: false });
    this.controls.customId.disable({ emitEvent: false });
  }

  private enableRRFields() {
    this.controls.meterSerial.enable({ emitEvent: false });
    this.controls.configType.enable({ emitEvent: false });
    this.controls.portNumber.enable({ emitEvent: false });
    this.controls.pulseOutOnPortTwo.enable({ emitEvent: false });
    this.controls.meterType.enable({ emitEvent: false });
    this.controls.customId.enable({ emitEvent: false });
    if (this.controls.configType.value !== MeterConfig.ENCODER_IN) {
      this.controls.imr.enable({ emitEvent: false });
    }
  }

  private enableTRFields() {
    this.controls.meterSerial.enable({ emitEvent: false });
    this.controls.meterType.enable({ emitEvent: false });
    this.controls.customId.enable({ emitEvent: false });
    if (this.originalValues.configType !== MeterConfig.ENCODER_IN) {
      this.controls.imr.enable({ emitEvent: false });
    }
  }

  private enableManualReadFields() {
    this.controls.meterSerial.enable({ emitEvent: false });
    this.controls.meterType.enable({ emitEvent: false });
    this.controls.customId.enable({ emitEvent: false });
  }

}

function SpecificUtilityDeviceValidator(): ValidatorFn {
  return (form: MeterFormGroup) => {
    const deviceIdControl = form.controls.deviceId;
    const utilityControl = form.controls.utilityTypeId;
    if (!deviceIdControl || !deviceIdControl.value) {
      return null;
    }
    if (typeof deviceIdControl.value !== 'number') {
      deviceIdControl.setValue(ConversionUtils.ConvertSerialNumberToNumberForFrontEnd(deviceIdControl.value));
      return null;
    }
    if (DeviceTypeHelper.GetIsEmbeddedTransceiver(deviceIdControl.value)) {
      const family = UtilityTypes[utilityControl.value] ? UtilityTypes[utilityControl.value].family : null;
      if (family !== UtilityFamily.ELECTRIC) {
        setErrorOnControl(deviceIdControl, 'invalidUtility', 'This device is only compatible with Electric meters');
        return {
          invalidUtility: 'This device is only compatible with Electric meters',
        };
      }
    } else if (DeviceTypeHelper.GetFamilyBySerialNumber(deviceIdControl.value) === DeviceFamily.INTEGRATED_METER) {
      const family = UtilityTypes[utilityControl.value] ? UtilityTypes[utilityControl.value].family : null;
      if (family !== UtilityFamily.WATER) {
        setErrorOnControl(deviceIdControl, 'invalidUtility', 'This device is only compatible with Water utilities');
        return {
          invalidUtility: 'This device is only compatible with Water utilities',
        };
      }
    }
    clearErrorOnControl(deviceIdControl, 'invalidUtility');
    return null;
  };
}
