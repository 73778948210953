import { CloudScannerMessage, CloudScannerMessageType, SessionDeviceType } from '@ncss/models';

import { EventEmitter, Injectable } from '@angular/core';
import * as io from 'socket.io-client';

import { AppSettingsService } from './app-settings.service';

@Injectable({
  providedIn: 'root',
})
export class MobileCloudScannerService {

  /**
   * Mobile Use
   */
  public onStartScanning = new EventEmitter();

  /**
   * Mobile Use
   */
  public onStopScanning = new EventEmitter();

  /**
   * Website Use
   */
  public onScanningCanceled = new EventEmitter<string>();

  /**
   * Website Use
   */
  public onScanResponse = new EventEmitter();

  private socket;
  private io;
  private token: string;
  private namespace = '/root';

  constructor(private appSettings: AppSettingsService) {
    this.io = io;
    this.appSettings.appSettings$.subscribe((settings) => {
      if (!this.token) {
        this.token = localStorage.getItem('token');
      }
      if (this.socket) {
        this.socket.disconnect();
      }
      this.socket = this.io(settings.socketEndpoint + this.namespace, {
        transports: ['websocket'],
        query: {
          token: this.token,
          deviceType: SessionDeviceType.MOBILE,
        },
      });

      this.socket.on('message', this.onMessage.bind(this));
    });
  }

  /**
   * Website Use
   */
  public startScanning() {
    this.sendMessage(new CloudScannerMessage({ type: CloudScannerMessageType.START_SCANNER }));
  }

  /**
   * Website Use
   */
  public stopScanning() {
    this.sendMessage(new CloudScannerMessage({ type: CloudScannerMessageType.STOP_SCANNER }));
  }

  /**
   * Mobile Use
   */
  public cancelScanning(cancelDescription?: string) {
    const msg = new CloudScannerMessage({ type: CloudScannerMessageType.SCANNING_CANCELED });
    msg.cancelDescription = cancelDescription || null;
    this.sendMessage(msg);
  }

  /**
   * Mobile Use
   */
  public scanned(serialNumber: number) {
    const msg = new CloudScannerMessage({ type: CloudScannerMessageType.SCAN_RESPONSE });
    msg.serialNumber = serialNumber;
    this.sendMessage(msg);
  }

  private onMessage(msg: CloudScannerMessage) {
    if (msg.type === CloudScannerMessageType.START_SCANNER) {
      this.onStartScanning.emit();
    } else if (msg.type === CloudScannerMessageType.STOP_SCANNER) {
      this.onStopScanning.emit();
    } else if (msg.type === CloudScannerMessageType.SCAN_RESPONSE) {
      this.onScanResponse.emit(msg.serialNumber);
    } else if (msg.type === CloudScannerMessageType.SCANNING_CANCELED) {
      this.onScanningCanceled.emit(msg.cancelDescription);
    }
  }

  private sendMessage(msg: CloudScannerMessage) {
    this.socket.emit('message', msg);
  }

}
