import { Message } from '@ncss/message';
import { BitwiseHelper } from '@ncss/models';

import { ByteList } from 'byte-list';

export enum RE4RfDebugMsgType {
  RE4_RF_DEBUG_MSG = 0x20A0,
}

export enum RfDebugMsgType {
  ConnectDebugRxMsg = 0xC0,
  ConnectDebugTxMsg = 0xC1,
  ConnectDebugTxBusy = 0xC2,
  ConnectDebugDupMsg = 0xC3,
  ConnectDebugDupRouteDiscMsg = 0xC4,
  ConnectDebugInvalidDstMsg = 0xC5,
  ConnectDebugRxUserMsg = 0xC6,
  ConnectDebugTxRetryMsg = 0xC7,
  ConnectDebugMaxHop = 0xC8,
  ConnectDebugDropV1Msg = 0xC9,
}

export class RfDebugMessage extends Message {
  debugMsgType: RfDebugMsgType; // BYTE
  msgSize: number; // UINT16
  reserved: number; // BYTE
  id: number; // BYTE
  control: number; // UINT16
  srcAddr: number; // UINT32
  srcAddrMAC: number; // UINT32
  dstAddr: number; // UINT32
  dstAddrMAC: number; // UINT32
  firstHopAddr: number; // UINT32
  firstHopRssi: number; // BYTE
  msgType: number; // UINT16

  serialize(): ByteList {
    const bytes = super.serialize() as ByteList;
    bytes.writeByte(this.debugMsgType);
    bytes.writeUInt16(this.msgSize);
    bytes.writeByte(this.reserved);
    bytes.writeByte(this.id);
    bytes.writeUInt16(this.control);
    bytes.writeUInt32(this.srcAddr);
    bytes.writeUInt32(this.srcAddrMAC);
    bytes.writeUInt32(this.dstAddr);
    bytes.writeUInt32(this.dstAddrMAC);
    bytes.writeUInt32(this.firstHopAddr);
    bytes.writeByte(this.firstHopRssi);
    bytes.writeUInt16(this.msgType);
    return bytes;
  }

  deserialize(bytes: ByteList) {
    super.deserialize(bytes);
    this.debugMsgType = bytes.readByte();
    this.msgSize = bytes.readUInt16();
    this.reserved = bytes.readByte();
    this.id = bytes.readByte();
    this.control = bytes.readUInt16();
    this.srcAddr = bytes.readUInt32();
    this.srcAddrMAC = bytes.readUInt32();
    this.dstAddr = bytes.readUInt32();
    this.dstAddrMAC = bytes.readUInt32();
    this.firstHopAddr = bytes.readUInt32();
    this.firstHopRssi = bytes.readByte();
    this.msgType = bytes.readUInt16();
  }

  toString(): string {
    let string = `NCSS RfConnect Debug ${this.debugMsgTypeToString()}: ` +
      `DstAddr=${this.dstAddr.toString(16).toUpperCase()} DstAddrMAC=${this.dstAddrMAC.toString(16).toUpperCase()} ` +
      `SrcAddr=${this.srcAddr.toString(16).toUpperCase()} SrcAddrMAC=${this.srcAddrMAC.toString(16).toUpperCase()} ` +
      `FirstHopAddr=${this.firstHopAddr.toString(16).toUpperCase()} FirstHopRssi=${this.firstHopRssi} `;

    const frameType = BitwiseHelper.GetBits(this.control, 0, 3);
    if (frameType === 0) {
      const v2Frame = BitwiseHelper.GetBits(this.control, 4, 2) === 1;
      if ((v2Frame && this.debugMsgType === RfDebugMsgType.ConnectDebugRxUserMsg) || !v2Frame) {
        string += `Msg Type ${this.msgType.toString(16).toUpperCase()}`;
      }
    } else if (frameType === 1) {
      string += 'ROUTE REQ MSG';
    } else if (frameType === 2) {
      string += 'ROUTE RES MSG';
    } else {
      string += 'ACK MSG';
    }
    return string;
  }

  private debugMsgTypeToString(): string {
    switch (this.debugMsgType) {
      case RfDebugMsgType.ConnectDebugRxMsg:
        return 'RX MSG';
      case RfDebugMsgType.ConnectDebugTxMsg:
        return 'TX MSG';
      case RfDebugMsgType.ConnectDebugTxBusy:
        return 'TX BUSY MSG';
      case RfDebugMsgType.ConnectDebugDupMsg:
        return 'DUPLICATE MSG';
      case RfDebugMsgType.ConnectDebugDupRouteDiscMsg:
        return 'DUPLICATE ROUTE MSG';
      case RfDebugMsgType.ConnectDebugInvalidDstMsg:
        return 'INVALID DST MSG';
      case RfDebugMsgType.ConnectDebugRxUserMsg:
        return 'RX USER MSG';
      case RfDebugMsgType.ConnectDebugTxRetryMsg:
        return 'TX RETRY MSG';
      case RfDebugMsgType.ConnectDebugMaxHop:
        return 'MAX HOP MSG';
      case RfDebugMsgType.ConnectDebugDropV1Msg:
        return 'DROP V1 MSG';
      default:
        return (this.debugMsgType as number).toString(16).toUpperCase();
    }
  }
}
