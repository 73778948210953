import { Message } from '@ncss/message';

import { ByteList } from 'byte-list';

import { Payload } from './payloads/payload';

export class BleMessage extends Message {

  serialNumber = 0;
  payload?: Payload;

  constructor(serialNumber: number, type: number) {
    super();
    this.type = type;
    this.serialNumber = serialNumber;
  }

  serialize(): ByteList {
    const bytes = super.serialize();
    bytes.writeUInt32(this.serialNumber);
    if (!this.payload) {
      throw new Error('Payload not populated');
    }
    bytes.concat(this.payload ? this.payload.serialize() : null);
    return bytes;
  }

  deserialize(bytes: ByteList): void {
    super.deserialize(bytes);
    this.serialNumber = bytes.readUInt32();
    const type = bytes.peekByte();
    this.payload = Payload.Create(type);
    if (!this.payload) {
      throw new Error('Could not find payload type for: ' + type);
    }
    this.payload.deserialize(bytes);
  }

  toString() {
    return `BLE: ${this.serialNumber} - ${this.type} ${this.payload ? this.payload.type : 'unknown payload'}`;
  }

}
