import { Message } from '@ncss/message';
import { RR4Info, RR4BLEInfoMessageType } from '@ncss/models';

import { ByteList } from 'byte-list';

export class RR4BLEInfoMessage extends Message {

  public rr4Info: RR4Info;

  constructor(type: RR4BLEInfoMessageType, serialNumber: number) {
    super();
    this.type = type || RR4BLEInfoMessageType.REQUEST_INFO;
    this.rr4Info = new RR4Info(serialNumber);
  }

  public serialize(): ByteList {
    const bytes = super.serialize();
    this.rr4Info.serialize(bytes);
    return bytes;
  }

  public deserialize(bytes: ByteList) {
    super.deserialize(bytes);
    this.rr4Info = new RR4Info(0);
    this.rr4Info.deserialize(bytes);
  }
}
