import { Address } from '@ncss/models';

import { Injectable } from '@angular/core';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { LaunchNavigator, LaunchNavigatorOptions } from '@ionic-native/launch-navigator/ngx';
import { ActionSheetController, AlertController, Platform } from '@ionic/angular';
import * as _ from 'lodash';

import { AppSettingsService } from './app-settings.service';

export interface INavApp {
  key: string;
  name: string;
  isAvailable: boolean;
  isDefault: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class PropertyNavService {

  public navAppCount = 0;
  public navApps: INavApp[] = [
    { key: 'apple_maps', name: 'Apple Maps', isAvailable: false, isDefault: false },
    { key: 'google_maps', name: 'Google Maps', isAvailable: false, isDefault: false },
    { key: 'bing_maps', name: 'Bing Maps', isAvailable: false, isDefault: false },
    { key: 'waze', name: 'Waze Navigation', isAvailable: false, isDefault: false },
    { key: 'tomtom', name: 'Tom Tom Navigation', isAvailable: false, isDefault: false },
    { key: 'city_mapper', name: 'City Mapper', isAvailable: false, isDefault: false },
    { key: 'transit_app', name: 'Transit App', isAvailable: false, isDefault: false },
    { key: 'maps_me', name: 'Maps Me Navigation', isAvailable: false, isDefault: false },
    { key: 'uber', name: 'Uber', isAvailable: false, isDefault: false },
    { key: 'lyft', name: 'Lyft', isAvailable: false, isDefault: false },
  ];

  constructor(
    private launchNav: LaunchNavigator,
    private actionSheetCtrl: ActionSheetController,
    private appSettings: AppSettingsService,
    private alertCtrl: AlertController,
    private clipboard: Clipboard,
    private platform: Platform,
    // private webClipboard: ClipboardService,
  ) { }

  public init() {
    this.getNavApps();

    this.platform.resume.subscribe((res) => {
      this.getNavApps();
    });
  }

  public async getNavApps() {
    const settings = await this.appSettings.getAppSettings();

    return this.launchNav.availableApps()
      .then((apps: string[] = []) => {

        this.navAppCount = 0;
        let firstAvailalbe = null;

        this.navApps.forEach((navApp) => {
          navApp.isAvailable = apps[navApp.key] ? apps[navApp.key] : false;
          navApp.isDefault = settings.defaultNavAppKey === navApp.key ? true : false;
          this.navAppCount++;

          // Set default automatically
          if (!firstAvailalbe && navApp.isAvailable) {
            firstAvailalbe = navApp.key;
          }

          // Remove default if app no longer avialalbe
          if (navApp.isDefault && !navApp.isAvailable) {
            navApp.isDefault = false;
            this.appSettings.setAppSettings({ defaultNavAppKey: null });
          }
        });

        // Set default nav app if none is selected
        if (!this.appSettings.appSettings.defaultNavAppKey) {
          this.appSettings.setAppSettings({ defaultNavAppKey: firstAvailalbe });
          const app = _.find(this.navApps, o => o.key === firstAvailalbe);
          if (app) {
            app.isDefault = true;
          }
        }

      }, () => { });
  }

  public async navToProperty(address: Address, name: string) {
    if (!address || !address.fullAddress) {
      const alert = await this.alertCtrl.create({
        header: 'No Address Available',
        subHeader: `An address has not been provided for ${name}.  To add an address tap "Details" then "Edit" this property.`,
        buttons: ['Ok'],
      });
      alert.present();
    } else {
      this.getNavApps().then(() => {
        Promise.all([
          this.pickNavApp(),
          Promise.resolve(address),
        ]).then((res) => {
          if (res[0] === 'cancel') {

          } else if (res[0] === 'copy_address') {
            this.clipboard.copy((res[1]) ? res[1].fullAddress : address.fullAddress);
            // this.webClipboard.copyFromContent( (res[1]) ? res[1].fullAddress : property.address.fullAddress );
          } else if (res[0] === 'googleMapsWeb') {
            window.open('https://www.google.com/maps?saddr=My+Location&daddr=' + address.fullAddress);
          } else {
            this.openNavApp(res[0], (res[1]) ? res[1].fullAddress : address.fullAddress, name);
          }
        });
      });
    }
  }

  private pickNavApp(): Promise<string> {
    return new Promise(async (resolve) => {
      // Setup Buttons
      const buttons = this.navApps.filter(app => app.isAvailable).map((x) => {
        const buttonName = x.isDefault ? x.name + ' (Default)' : x.name;
        return {
          text: buttonName, key: x.key, icon: '', isDefault: x.isDefault, role: undefined,
          handler: () => resolve(x.key), cssClass: undefined,
        };
      });

      // move default to top of list
      const defaultIndex = _.findIndex(buttons, ['isDefault', true]);
      if (defaultIndex > -1) {
        buttons.splice(0, 0, buttons.splice(defaultIndex, 1)[0]);
      }

      // Add browser navigation links
      if (this.appSettings.isBrowser) {
        buttons.push({
          text: 'Google Maps (Web)', key: undefined, icon: 'icon-map', role: undefined, isDefault: false,
          handler: () => resolve('googleMapsWeb'),
          cssClass: undefined,
        });
      }

      // Add Copy and Cancel Buttons
      buttons.push({
        text: 'Copy Address', key: undefined, icon: 'icon-copy', role: undefined, isDefault: false,
        handler: () => resolve('copy_address'),
        cssClass: undefined,
      });
      buttons.push({
        text: 'Cancel', icon: 'icon-x', key: undefined, role: 'cancel', isDefault: false,
        handler: () => resolve('cancel'), cssClass: undefined,
      });

      const actionSheet = await this.actionSheetCtrl.create({
        header: 'Pick Navigation App',
        buttons: buttons,
      });
      actionSheet.present();
    });
  }

  private openNavApp(appName: string, address: string, propertyName?: string) {
    const options: LaunchNavigatorOptions = {
      app: appName,
      destinationName: _.capitalize(propertyName ? propertyName : 'Property'),
      startName: 'Your Location',
    };

    this.launchNav.navigate(address, options)
      .then(() => {
        // Start Navigation
      }, async () => {
        const alert = await this.alertCtrl.create({
          header: 'Unable to Open App',
          subHeader: 'We were unable to start the 3rd party navigation app. If the issue continues, ' +
            'please try re-installing the 3rd party navigation app.',
          buttons: ['Ok'],
        });
        alert.present();
      });
  }
}
