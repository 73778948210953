import { ByteList } from 'byte-list';

import { ChangeNetwork } from './changeNetwork';

export const ChangeWirelessNetworkType = 0xA3;

export class ChangeWirelessNetwork extends ChangeNetwork {

  type = ChangeWirelessNetworkType;
  ssid?: string;
  password?: string;

  constructor() {
    super('wlan');
  }

  serialize(): ByteList {
    const bytes = super.serialize();
    bytes.writeString(this.ssid);
    bytes.writeString(this.password);
    return bytes;
  }

  deserialize(bytes: ByteList) {
    super.deserialize(bytes);
    this.ssid = bytes.readString();
    this.password = bytes.readString();
  }

}
