import { GW4Read, BitwiseHelper, ReadFromBytes } from '@ncss/models';

import { ByteList } from 'byte-list';

import { Ack } from './ack';

export const InfoPayloadType = 0x02;

export interface DeviceStat {
  deviceType: number;
  count: number;
}

export class Info extends Ack {

  type = InfoPayloadType;
  read?: GW4Read;
  deviceStats: DeviceStat[] = [];
  remoteTarget?: { port: number, host: string };

  constructor(read?: GW4Read, deviceStats?: DeviceStat[], remoteTarget?: { port: number, host: string }) {
    super();
    this.read = read;
    this.deviceStats = deviceStats || [];
    this.remoteTarget = remoteTarget;
  }

  serialize(): ByteList {
    const bytes = super.serialize();
    bytes.writeByte(this.deviceStats.length);
    for (const stat of this.deviceStats) {
      bytes.writeByte(stat.deviceType);
      bytes.writeInt16(stat.count);
    }
    const remoteTargetPopulated = !!this.remoteTarget && !!this.remoteTarget.host && !!this.remoteTarget.port;
    let populated = BitwiseHelper.SetBits(0, 0, 1, this.read ? 1 : 0);
    populated = BitwiseHelper.SetBits(populated, 1, 1, remoteTargetPopulated ? 1 : 0);
    bytes.writeByte(populated);
    if (this.read) {
      bytes.concat(this.read.toBytes());
    }
    if (this.remoteTarget) {
      bytes.writeString(this.remoteTarget.host);
      bytes.writeUInt16(this.remoteTarget.port);
    }
    return bytes;
  }

  deserialize(bytes: ByteList): void {
    super.deserialize(bytes);
    this.deviceStats = [];
    const count = bytes.readByte();
    for (let i = 0; i < count; i++) {
      const stat: DeviceStat = { deviceType: 0, count: 0 };
      stat.deviceType = bytes.readByte();
      stat.count = bytes.readUInt16();
      this.deviceStats.push(stat);
    }
    const populated = bytes.readByte();
    if (BitwiseHelper.GetBits(populated, 0, 1)) {
      const read = ReadFromBytes(bytes);
      if (read instanceof GW4Read) {
        this.read = read;
      }
    }
    if (BitwiseHelper.GetBits(populated, 1, 1)) {
      const host = bytes.readString();
      const port = bytes.readUInt16();
      this.remoteTarget = { host, port };
    }
  }

}
