import { Component, ElementRef, OnDestroy } from '@angular/core';
import { OnInit } from '@angular/core/src/metadata/lifecycle_hooks';
import { Events } from '@ionic/angular';

import { INumericInputInfo } from '../numeric-input.component';

export enum NumericKeyboardStatus {
  HIDDEN,
  SHOWN,
}

export enum NumericKeyboardEventType {
  SHOW = 'SHOW_N', // <--- IN
  HIDE = 'HIDE_N', // <--- IN
  OUTSIDE_TAP = 'OUTSIDE-TAP_N', // <--- IN
  VALUE_KEY = 'VALUE_KEY_N', // ---> OUT
  CMD_KEY = 'CMD_KEY_N', // ---> OUT
}

@Component({
  selector: 'app-numeric-keyboard',
  templateUrl: 'numeric-keyboard.component.html',
  styleUrls: ['numeric-keyboard.component.scss'],
})
export class NumericKeyboardComponent implements OnInit, OnDestroy {

  public status: NumericKeyboardStatus = NumericKeyboardStatus.HIDDEN;
  public KeyboardStatus = NumericKeyboardStatus; // For HTML
  public visible;
  public input = '';  // used for text field in the titleBar section on HTML

  private subscriptions = [];
  private keydownListener = null;
  constructor(
    private events: Events,
    private elementRef: ElementRef,
  ) { }

  ngOnInit() {
    this.subscriptions.push(this.events.subscribe(NumericKeyboardEventType.SHOW, (numericInfo: INumericInputInfo) => {
      this.show(numericInfo);
    }));

    this.subscriptions.push(this.events.subscribe(NumericKeyboardEventType.HIDE, () => {
      this.hide();
    }));

    this.subscriptions.push(this.events.subscribe(NumericKeyboardEventType.OUTSIDE_TAP, ($event) => {
      if (!this.elementRef.nativeElement.contains($event.target)) {
        this.onCmd('Blur');
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  public onValue(c: string, fromKeyboard?: boolean) {
    if (fromKeyboard && c.length > 1) {
      this.onCmd(c);
    } else {
      c = c.toUpperCase().replace(/([^0-9.])/gi, '');
      if (c) {
        // The order of these next two lines matters, in the publish we might update the keyboard value
        this.input += c;
        this.events.publish(NumericKeyboardEventType.VALUE_KEY, c);
      }
    }
  }

  public onCmd(val: string) {
    this.events.publish(NumericKeyboardEventType.CMD_KEY, val);
    if (val === 'Backspace') {
      this.input = this.input.slice(0, this.input.length - 1);
    }
  }

  private show(numericInfo: INumericInputInfo) {
    if (this.status !== NumericKeyboardStatus.SHOWN) {
      this.input = numericInfo.numberString || '';
      this.status = NumericKeyboardStatus.SHOWN;
    }

    if (!this.keydownListener) {
      document.addEventListener('keydown', this.keydownListener = this.onKeyboardValue.bind(this));
    }
  }

  private hide() {
    this.status = NumericKeyboardStatus.HIDDEN;
    this.input = '';

    if (this.keydownListener) {
      document.removeEventListener('keydown', this.keydownListener);
      this.keydownListener = null;
    }
  }

  private onKeyboardValue(val) {
    this.onValue(val.key, true);
  }
}
