import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'roundCurrentRead',
})
export class RoundReadPipe implements PipeTransform {
  transform(value: number, multiplier?: number, isOverlay?: boolean) {
    let readStr = value.toString();
    const parts = readStr.split('.');

    // if multiplier has decimal
    if (multiplier && multiplier % 1 !== 0) {
      readStr = parts[0] + (parts[1] && +parts[1].substr(0, 1) ? '.' + parts[1].substr(0, 1) : '');
    } else {
      readStr = parts[0];
    }

    if (isOverlay && readStr.replace('.', '').length > 8) {
      readStr = readStr.includes('.') ? readStr.substr(readStr.length - 9) : readStr.substr(readStr.length - 8);
    }

    return +readStr;
  }
}
