import { PipeLogic } from '@ncss/models';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'fromNow'})
export class FromNowPipe implements PipeTransform {

  public transform(value: any, args?: any): any {
    return PipeLogic.FromNow(value, args);
  }
}
