import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { filter, timeout } from 'rxjs/operators';

import { ToastService } from './toast.service';

export interface IDocumentUpload {
  signedUrl: string;  // the aws url to make the put request to
  file: File;
  onSuccess?: () => Promise<void> | void;
  onError?: () => Promise<void> | void;
  id: string;
  tries?: number;
}

@Injectable({
  providedIn: 'root',
})
export class DocumentUploadService {

  public uploadsInProgressDictionary: { [docId: string]: IDocumentUpload } = {};

  public uploadsInProgressCount$ = new BehaviorSubject<number>(0);
  public uploadQueued$ = new BehaviorSubject<IDocumentUpload>(null);
  public uploadFinished$ = new BehaviorSubject<IDocumentUpload>(null);

  private _queue: IDocumentUpload[] = [];
  private _processing = false;

  constructor(
    private http: HttpClient,
    private toast: ToastService,
    private alertCtrl: AlertController,
  ) {
    this.uploadQueued$.pipe(filter((u) => !!u)).subscribe(() => {
      const currentCount = (this.uploadsInProgressCount$.value || 0) + 1;
      this.uploadsInProgressCount$.next((currentCount < 0 ? 0 : currentCount));
    });
    this.uploadFinished$.pipe(filter((u) => !!u)).subscribe(() => {
      const currentCount = (this.uploadsInProgressCount$.value || 0) - 1;
      this.uploadsInProgressCount$.next((currentCount < 0 ? 0 : currentCount));
    });
    setInterval(() => {
      this.processQueue();
    }, 1000);
  }

  public enqueueUpload(upload: IDocumentUpload) {
    if (!upload || !upload.id) { return; }
    console.log('queued:', upload);
    this.uploadsInProgressDictionary[upload.id] = upload;
    this._queue.push(upload);
    this.uploadQueued$.next(upload);
    this.processQueue();
  }

  private async processQueue() {
    if (this._processing || !this._queue.length) { return; }
    let currentUpload = this._queue.shift();
    this._processing = true;
    while (currentUpload) {
      await this.processUpload(currentUpload);
      currentUpload = this._queue.shift();
    }
    this._processing = false;
  }

  private async processUpload(upload: IDocumentUpload): Promise<void> {
    return new Promise((resolve) => {
      if (!upload.id || !upload.signedUrl) { resolve(); }
      this.http.put(upload.signedUrl, upload.file, {
        headers: {
          'Content-Type': upload.file.type, responseType: 'text',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
         },
      }).pipe(
        timeout(60000),
      ).subscribe(
        (res) => {
          this.onUploadSuccess(upload);
          resolve();
        },
        (err) => {
          this.onUploadFail(upload);
          resolve();
        },
      );
    });
  }

  private async onUploadSuccess(upload: IDocumentUpload) {
    if (!upload || !upload.id) { return; }
    if (this.uploadsInProgressDictionary[upload.id] && !(_.find(this._queue, (u) => u.id === upload.id))) {
      delete this.uploadsInProgressDictionary[upload.id];
    }
    if (upload.onSuccess) {
      await upload.onSuccess();
    } else {
      await this.defaultOnSuccess(upload);
    }
    this.uploadFinished$.next(upload);
    this.processQueue();
  }

  private async onUploadFail(upload: IDocumentUpload) {
    if (!upload || !upload.id) { return; }
    if (this.uploadsInProgressDictionary[upload.id] && !(_.find(this._queue, (u) => u.id === upload.id))) {
      delete this.uploadsInProgressDictionary[upload.id];
    }
    if (upload.tries && upload.tries >= 3) {
      if (upload.onError) {
        await upload.onError();
      } else {
        await this.defaultOnFail(upload);
      }
      this.uploadFinished$.next(upload);
      this.processQueue();
    } else {
      upload.tries = upload.tries ? upload.tries + 1 : 1;
      this.uploadFinished$.next(upload);
      this.enqueueUpload(upload);
    }
  }

  private defaultOnSuccess(upload: IDocumentUpload) {
    this.toast.queueToast(`File "${upload.file.name}" finished uploading!`);
  }

  private async defaultOnFail(upload: IDocumentUpload) {
    const a = await this.alertCtrl.create({
      message: `File "${upload.file.name}" failed to upload`,
      header: 'Upload Failed',
      buttons: ['Ok'],
    });
    a.present();
  }

}
