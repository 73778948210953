import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'app-chevron',
  templateUrl: './chevron.component.html',
  styleUrls: ['./chevron.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChevronComponent implements OnInit {

  @Input() public open = false;

  constructor() { }

  public ngOnInit() { }

}
