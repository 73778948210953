
import { PipeLogic } from '@ncss/models';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'Date'})
export class DatePipe implements PipeTransform {
  public transform(value: any, args?: any): any {
    return PipeLogic.FormatDate(value, args);
  }
}
