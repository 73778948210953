import { User, Unit, CommentLocationType } from '@ncss/models';

import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

import { MobileCommentService } from '../../services/mobile-comment.service';
import { MobileUserService } from '../../services/mobile-user.service';
import { MobileUnitService } from './../../services/mobile-unit.service';
import { ToastService } from './../../services/toast.service';

@Component({
  selector: 'app-unit-notes',
  templateUrl: './unit-notes.component.html',
  styleUrls: ['./unit-notes.component.scss'],
})
export class UnitNotesComponent implements OnInit {

  @Input() public unitId: string;

  public get unit() { return this._unit$.value; }
  public get unit$() { return this._unit$.asObservable(); }
  public haveChanges = false;
  public haveEditChanges = false;

  public onDismiss = new EventEmitter();
  public params: any = {};
  public onCommentCreate;
  public onCommentUpdate;
  public onCommentDelete;
  public comments$: BehaviorSubject<any> = new BehaviorSubject<any>([]);

  public buildingName: string;
  public unitName: string;
  public description: string;
  public utilityType: string;
  public comment = '';
  public selectedComment;
  public user: User;
  public editCommentFlag = false;

  private tempComment = '';
  private involvedUsers: { [userId: string]: User };
  private _unit$ = new BehaviorSubject<Unit>(null);
  private unsavedDescription: string;

  constructor(
    public mobileUserService: MobileUserService,
    private unitService: MobileUnitService,
    private cd: ChangeDetectorRef,
    private modalCtrl: ModalController,
    private commentService: MobileCommentService,
    private loadingCtrl: LoadingController,
    private toast: ToastService,
  ) { }

  ngOnInit() {
    this._unit$.subscribe((u) => {
      this.updateStats();
    });
    this.mobileUserService.user$.pipe(take(1))
    .subscribe((user) => {
      this.user = user;
    });
    this.fetchUnit(this.unitId);
  }

  public updateStats() {
    if (this.unit) {
      if (this.unit.building) {
        this.buildingName = this.unit.building;
      }
      if (this.unit.name) {
        this.unitName = this.unit.name;
      }
      if (this.unsavedDescription) {
        this.description = this.unsavedDescription;
        this.haveChanges = true;
      } else if (this.unit.descriptions && this.unit.descriptions.length) {
        this.description = this.unit.descriptions[0].description;
        this.haveChanges = false;
      }
      if (this.unit.comments) {
        this.orderComments();
        this.getCommentUserInfo();
      }
    }
  }

  public descriptionInput(e) {
    if (this.unit.descriptions && e.detail.value === this.unit.descriptions[0].description ||
      (!this.unit.descriptions && e.detail.value === '')) {
      this.haveChanges = false;
      return;
    }
    this.description = e.detail.value;
    this.haveChanges = true;
    this.cd.markForCheck();
  }

  public commentInput(e) {
    if (!e.detail.value) {
      this.comment = '';
      return;
    }
    this.comment = e.detail.value;
    this.cd.markForCheck();
  }

  public editCommentInput(e, comment) {
    if (e.detail.value === comment.comment ||
      (!comment.comment && e.detail.value === '')) {
      this.haveEditChanges = false;
      return;
    }

    this.tempComment = e.detail.value;
    this.haveEditChanges = true;
    this.cd.markForCheck();
  }

  public async saveDescription() {
    if (!this.unit || !this.unit._id) {
      this.toast.queueToast('Description couldn\'t be saved at this time.');
      return;
    }
    const load = await this.loadingCtrl.create({ message: 'Saving Description' });
    load.present();
    this.unitService.updateUnitDescription(this.unit._id, this.description, 'M').subscribe((u) => {
      load.dismiss();
      if (u && u._id) {
        this.unsavedDescription = null;
        this._unit$.next(u);
        this.fetchUnit(u._id);
        this.toast.queueToast('Description successfully updated.');
      } else {
        this.toast.queueToast('Description couldn\'t be saved at this time.');
      }
    }, (err) => {
      load.dismiss();
      this.toast.queueToast('Description couldn\'t be saved at this time.');
    });

  }

  public async submitComment(comment) {
    this.comment = '';
    if (this.haveChanges) {
      this.unsavedDescription = this.description;
    }
    try {
      await this.commentService.create(this.unit._id, comment, { location: CommentLocationType.UNIT }, 'M').toPromise();
      this.toast.queueToast('Comment Saved.');
      this.fetchUnit(this.unitId);
    } catch (err) {
      this.toast.queueToast('Couldn\'t save comment at this time.');
    }
  }

  public selectComment(commentId) {
    if (this.editCommentFlag) {
      return;
    }

    if (this.selectedComment === commentId) {
      this.selectedComment = null;
      this.editCommentFlag = false;
    } else {
      this.selectedComment = commentId;
    }
  }

  public async deleteComment(comment) {
    const index = this.unit.comments.indexOf(comment);
    this.unit.comments.splice(index, 1);
    await this.commentService.delete(this.unit._id, comment.id, { location: CommentLocationType.UNIT }).toPromise();
    this.toast.queueToast('Comment Deleted.');
    this.fetchUnit(this.unitId);
  }

  public editComment(comment) {
    this.editCommentFlag = true;
  }

  public cancel() {
    this.editCommentFlag = false;
    this.selectedComment = null;
    this.tempComment = '';
    this.haveEditChanges = false;
  }

  public saveComment(comment) {
    comment.comment = this.tempComment;
    this.commentService.update(
      this.unit._id,
      comment.id,
      comment.comment,
      { location: CommentLocationType.UNIT },
      'M',
    ).subscribe();
    this.selectedComment = null;
    this.editCommentFlag = false;
  }

  private orderComments() {
    this.unit.comments = _.orderBy(this.unit.comments, ['timestamp'], ['desc']);
  }

  private getCommentUserInfo() {
    if (!this.involvedUsers) {
      this.involvedUsers = {};
    }
    this.unit.comments.forEach(async (c) => {
      if (!this.involvedUsers[c.userId]) {
        const user = await this.mobileUserService.findById(c.userId);
        this.involvedUsers[c.userId] = user;
      }
    });
  }

  public headerClicked(e) {
    if (e === 'Close') {
      const comments = [ ...(this.unit.comments || []) ];
      const descriptions = [... (this.unit.descriptions || [])];
      this.modalCtrl.dismiss({
        comments,
        descriptions,
      });
    }
  }

  private fetchUnit(unitId: string) {
    this.unitService.findById(unitId).subscribe((u) => {
      this._unit$.next(u);
    }, (err) => {
      this.toast.queueToast('Couldn\'t load unit notes at this time.');
    });
  }
}
