import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { RefresherComponent } from './refresher.component';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
  ],
  entryComponents: [RefresherComponent],
  exports: [RefresherComponent],
  declarations: [RefresherComponent],
  providers: [],
})
export class RefresherModule { }
