import { PipeLogic } from '@ncss/models';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'middleDot'})
export class MiddleDotPipe implements PipeTransform {
  public transform(value: any, n?: number): any {
    return PipeLogic.MiddleDot(value, n);
  }
}
