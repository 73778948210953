import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { PageFooterComponent } from './page-footer.component';

@NgModule({
  declarations: [
      PageFooterComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
  ],
  providers: [

  ],
  exports: [
    PageFooterComponent,
     ],
})
export class PageFooterModule {}
