import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { DirectConnectInstructionsComponent } from './direct-connect-instructions.component';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
  ],
  declarations: [
    DirectConnectInstructionsComponent,
  ],
  exports: [
    DirectConnectInstructionsComponent,
  ],
  entryComponents: [
    DirectConnectInstructionsComponent,
  ],
})
export class DirectConnectInstructionsModule {}
