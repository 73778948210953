import {
  Unit,
  ConversionUtils,
  DeviceFamily,
  UtilityTypesOrderedList,
  IUtilityType,
  UtilityTypeIds,
  Meter,
  UomTypeHelper,
  IUomType,
  UtilityTypes,
  SubscriptionType,
  MeterAlert,
} from '@ncss/models';

import { FormArray, ValidatorFn } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { isEqual } from 'lodash';

import { CameraService } from '../services/camera.service';
import { MobileMeterManufacturerService } from '../services/mobile-meter-manufacturer.service';
import { MobileMeterTypeService } from '../services/mobile-meter-type.service';
import { MobilePropertyService } from '../services/mobile-property.service';
import { MobileUnitService } from '../services/mobile-unit.service';
import { MobileUserService } from '../services/mobile-user.service';
import { clearErrorOnControl, setErrorOnControl } from './common-validators';
import { MeterFormGroup } from './meter-form';


export class MeterFormArray extends FormArray {
  static async Create(
    propertyService: MobilePropertyService,
    meterTypeService: MobileMeterTypeService,
    meterManufacturerService: MobileMeterManufacturerService,
    unitService: MobileUnitService,
    userService: MobileUserService,
    cameraService: CameraService,
    modalCtl: ModalController,
    unit: Unit,
    alerts: MeterAlert[],
  ): Promise<MeterFormArray> {
    const controls: MeterFormGroup[] = [];
    for (const meter of unit.meters) {
      const c = await MeterFormGroup.Create(
        propertyService,
        meterTypeService,
        meterManufacturerService,
        unitService,
        userService,
        cameraService,
        modalCtl,
        unit,
        meter,
        alerts,
      );
      controls.push(c);
    }
    const form = new MeterFormArray(
      propertyService,
      meterTypeService,
      meterManufacturerService,
      unitService,
      userService,
      cameraService,
      modalCtl,
      unit,
      controls,
    );
    return form;
  }

  controls: MeterFormGroup[];
  availableUtilities: IUtilityType[] = UtilityTypesOrderedList;
  get manualReadActive() {
    return this.unit &&
      this.unit.subscriptions &&
      this.unit.subscriptions[SubscriptionType.MANUAL_READS] &&
      this.unit.subscriptions[SubscriptionType.MANUAL_READS].active;
  }
  private originalValues;

  private constructor(
    private propertyService: MobilePropertyService,
    private meterTypeService: MobileMeterTypeService,
    private meterManufacturerService: MobileMeterManufacturerService,
    private unitService: MobileUnitService,
    private userService: MobileUserService,
    private cameraService: CameraService,
    private modalCtl: ModalController,
    private unit: Unit,
    controls: MeterFormGroup[],
  ) {
    super(controls, [
      CreateDuplicateDeviceIdValidator(),
      CreateDuplicateUtilityTypeValidator(),
    ]);
    this.setupListeners();
    this.originalValues = this.value;
  }

  public async addNewMeter(utilityTypeId: UtilityTypeIds) {
    const uom: IUomType = UomTypeHelper.GetUomForUtilityTypeId(utilityTypeId)[0];
    const c = await MeterFormGroup.Create(
      this.propertyService,
      this.meterTypeService,
      this.meterManufacturerService,
      this.unitService,
      this.userService,
      this.cameraService,
      this.modalCtl,
      this.unit,
      new Meter({
        utilityTypeId,
        uomTypeId: uom ? uom.id : null,
      }),
    );
    this.push(c);
    this.updateAvailableUtilities();
    this.updateValueAndValidity();
  }

  public removeMeter(utilityTypeId: UtilityTypeIds) {
    const index = this.controls.findIndex((c) => c.controls.utilityTypeId.value === utilityTypeId);
    this.removeAt(index);
    this.updateAvailableUtilities();
    this.updateValueAndValidity();
  }

  private setupListeners() {
    this.updateAvailableUtilities();
    this.controls.forEach((formGroup) => {
      formGroup.controls.utilityTypeId.valueChanges.subscribe((value) => {
        this.updateAvailableUtilities();
      });
    });
    this.controls.forEach((formGroup) => {
      formGroup.controls.pulseOutOnPortTwo.valueChanges.subscribe((value) => {
        if (value) {
          this.pulseOutTurnedOn(formGroup);
        }
      });
    });
    this.valueChanges.subscribe(() => {
      this.checkForChanges();
    });
  }

  private pulseOutTurnedOn(meterGroup: MeterFormGroup) {
    const id = ConversionUtils.ConvertSerialNumberToNumberForFrontEnd(meterGroup.controls.deviceId.value);
    const baseId = ConversionUtils.GetBaseRRSerial(id);
    const rrs = this.getMetersWithRemoteReader(baseId);
    for (const meter of rrs) {
      if (meter.controls.portNumber.value !== 1) {
        this.removeMeter(meter.controls.utilityTypeId.value);
      }
    }
  }

  private checkForChanges() {
    if (!isEqual(this.originalValues, this.value)) {
      this.markAsDirty();
      this.markAsTouched();
    } else {
      this.markAsUntouched();
      this.markAsPristine();
    }
  }

  private updateAvailableUtilities() {
    const usedUtilities = this.controls.map((meter) => meter.controls.utilityTypeId.value);
    this.availableUtilities = UtilityTypesOrderedList.filter((u) => !usedUtilities.find((id) => id === u.id));
  }

  public getMetersWithRemoteReader(baseId: number): MeterFormGroup[] {
    return this.controls.filter((group) => {
      if (group.controls.deviceId.value) {
        const id = ConversionUtils.ConvertSerialNumberToNumberForFrontEnd(group.controls.deviceId.value);
        if (group.isDeviceFamily(DeviceFamily.REMOTE_READER) && ConversionUtils.GetBaseRRSerial(id) === baseId) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    });
  }
}

export function CreateDuplicateDeviceIdValidator(): ValidatorFn {
  return (meters: MeterFormArray) => {
    const errors: { duplicateSerial?: string, invalidPort?: string } = {};
    for (const meter of meters.controls) {
      const deviceId = meter.controls.deviceId;
      if (!deviceId || !deviceId.value) { continue; }
      const metersWithDeviceId = meters.controls.filter((m) => m.controls.deviceId.value === deviceId.value);
      if (metersWithDeviceId.length > 1) {
        const deviceIdStr = deviceId.value.toString(16).toUpperCase();
        const meterA = metersWithDeviceId[0];
        const meterB = metersWithDeviceId[1];
        const utilityA = UtilityTypes[meterA.controls.utilityTypeId.value];
        const utilityB = UtilityTypes[meterB.controls.utilityTypeId.value];
        const utilityNameA = utilityA ? utilityA.name : '';
        const utilityNameB = utilityB ? utilityB.name : '';
        let errorMessage = `${deviceIdStr} can't be programmed to two meters.`;
        if (utilityNameA && utilityNameB) {
          errorMessage = `${deviceIdStr} can't be programmed to both the ${utilityA} meter and ${utilityB} meter.`;
        }
        if (metersWithDeviceId[0].isDeviceFamily(DeviceFamily.REMOTE_READER)) {
          const port = deviceId.value - ConversionUtils.GetBaseRRSerial(deviceId.value);
          errorMessage = (utilityNameA && utilityB) ?
            `Port ${port} can't be programmed to both the ${utilityNameA} meter and ${utilityNameB} meter.` :
            `Port ${port} can't be programmed to two meters.`;
        }
        setErrorOnControl(deviceId, 'duplicateSerial', errorMessage);
        errors.duplicateSerial = errorMessage;
      } else if (metersWithDeviceId[0].isDeviceFamily(DeviceFamily.REMOTE_READER)) {
        const port = deviceId.value - ConversionUtils.GetBaseRRSerial(deviceId.value);
        const portCtl = metersWithDeviceId[0].controls.portNumber;
        if (port === 2) {
          const portOneMeter = meters.controls.find((m) => m.controls.deviceId.value === deviceId.value - 1);
          const portError = `Cannot program port 2 before programming port 1.`;
          if (!portOneMeter) {
            setErrorOnControl(deviceId, 'invalidPort', portError);
            setErrorOnControl(portCtl, 'invalidPort', portError);
            errors.invalidPort = portError;
          } else {
            clearErrorOnControl(portCtl, 'invalidPort');
            clearErrorOnControl(deviceId, 'invalidPort');
            clearErrorOnControl(deviceId, 'duplicateSerial');
          }
        }
      } else {
        clearErrorOnControl(deviceId, 'invalidPort');
        clearErrorOnControl(deviceId, 'duplicateSerial');
      }

    }

    if (errors.duplicateSerial || errors.invalidPort) {
      return errors;
    }

    return null;
  };
}

export function CreateDuplicateUtilityTypeValidator(): ValidatorFn {
  return (meters: MeterFormArray) => {
    const errors: { duplicateUtility?: string } = {};
    for (const meter of meters.controls) {
      const utilityTypeControl = meter.controls.utilityTypeId;
      if (!utilityTypeControl.value) { continue; }
      const sameUtility = meters.controls.filter((m) => m.controls.utilityTypeId.value === utilityTypeControl.value);
      if (sameUtility.length > 1) {
        const utilityType: IUtilityType = UtilityTypes[utilityTypeControl.value];
        const errorMessage = `Can't have multiple ${utilityType ? utilityType.name : ''} meters${!utilityType ? ' of the same utility' : ''} on a single unit.`;
        setErrorOnControl(utilityTypeControl, 'duplicateUtility', errorMessage);
        errors.duplicateUtility = errorMessage;
      } else {
        clearErrorOnControl(utilityTypeControl, 'duplicateUtility');
      }
    }
    if (errors.duplicateUtility) {
      return errors;
    }

    return null;
  };
}
