import { ByteList } from 'byte-list';

import { Command, CommandCode } from './command';

const ChangeHostType = 0xA1;

export class ChangeHost extends Command {

  type = ChangeHostType;
  host?: string;
  port?: number;

  constructor(host?: string, port?: number) {
    super(CommandCode.SET_REMOTE_HOST);
    this.host = host;
    this.port = port;
  }

  serialize(): ByteList {
    const bytes = super.serialize();
    bytes.writeString(this.host || '');
    bytes.writeUInt32(this.port || 44444);
    return bytes;
  }

  deserialize(bytes: ByteList): void {
    super.deserialize(bytes);
    this.host = bytes.readString();
    this.port = bytes.readUInt32();
  }

}
