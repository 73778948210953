import { ByteList } from 'byte-list';

import { GatewayMessage } from './gatewayMessage';

export enum NetworkMessageTypes {
  GROUP_MASK = 0x0700,

  // Gateway --> Phone
  CONNECTED_TO_WIFI = 0x0700,
  DISCONNECTED_WIFI = 0x0701,
  ERROR = 0x0702,
  AVAILABLE_NETWORKS = 0x0703,
  CHECK_CELLULAR_CONNECTION = 0x0704,
  DHCP_STATE = 0x0705,
  CONFIGURE_ETH0_ACK = 0x0706,
  CONFIGURE_WLAN0_ACK = 0x0707,
  CONFIGURE_TARGET_SERVER_ACK = 0x0708,

  // Gateway <-- Phone
  CONNECT_TO_WIFI = 0x070A,
  DISCONNECT_WIFI = 0x070B,
  CONFIGURE_ETH0 = 0x070C,
  CONFIGURE_WLAN0 = 0x070D,
  SCAN_NETWORKS = 0x070E,
  REQUEST_DHCP_STATE = 0x070F,
  CONFIGURE_TARGET_SERVER = 0x0710,
}

export enum NetworkErrorTypes {
  CONNECTING_TO_WIFI = 0x01,
  DISCONNECTING_FROM_WIFI = 0x02,
}

export interface IWifiAP {
  ssid?: string;
  securityType?: string;
  rssi?: number;
}

export class NetworkMessage extends GatewayMessage {

  ssid = '';
  password = '';
  securityType = '';

  availableNetworks: IWifiAP[] = [];

  isEthUsingDHCP: boolean;
  ethIpAddress: string;
  ethNetmask: string;
  ethGateway: string;
  isWlanUsingDHCP: boolean;
  wlanIpAddress: string;
  wlanNetmask: string;
  wlanGateway: string;

  // Configure Network Settings
  useDHCP: boolean;
  ipAddress: string;
  netmask: string;
  gateway: string;

  // Configure Remote Server
  host: string;
  port: number;

  // ERROR
  errorType = -1;
  message = '';

  constructor(type: NetworkMessageTypes) {
    super();
    this.type = type;
  }

  serialize(): ByteList {
    const byteList = super.serialize();

    if (this.type === NetworkMessageTypes.CONNECT_TO_WIFI) {
      byteList.writeString(this.ssid);
      byteList.writeString(this.password);
      byteList.writeString(this.securityType);
    } else if (this.type === NetworkMessageTypes.CONFIGURE_ETH0 || this.type === NetworkMessageTypes.CONFIGURE_WLAN0) {
      byteList.writeBool(this.useDHCP);
      byteList.writeString(this.ipAddress);
      byteList.writeString(this.netmask);
      byteList.writeString(this.gateway);
    } else if (this.type === NetworkMessageTypes.CONFIGURE_TARGET_SERVER) {
      byteList.writeString(this.host);
      byteList.writeUInt16(this.port);
    }

    return byteList;
  }

  deserialize(byteList: ByteList) {
    super.deserialize(byteList);

    if (this.type === NetworkMessageTypes.ERROR) {
      this.errorType = byteList.readByte();
      this.message = byteList.readString();
    } else if (this.type === NetworkMessageTypes.AVAILABLE_NETWORKS) {
      this.availableNetworks = [];
      const count = byteList.readUInt16();
      for (let i = 0; i < count; ++i) {
        this.availableNetworks.push({
          ssid: byteList.readString(),
          securityType: byteList.readString(),
          rssi: byteList.readByte(),
        });
      }
    } else if (this.type === NetworkMessageTypes.DHCP_STATE) {
      this.isEthUsingDHCP = byteList.readBool();
      this.ethIpAddress = byteList.readString();
      this.ethNetmask = byteList.readString();
      this.ethGateway = byteList.readString();
      this.isWlanUsingDHCP = byteList.readBool();
      this.wlanIpAddress = byteList.readString();
      this.wlanNetmask = byteList.readString();
      this.wlanGateway = byteList.readString();
    }
  }

}
