import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { ClickOutsideDirective } from './click-outside.directive';
import { EmailValidator } from './email.validator';
import { HighlighterDirective } from './highlighter.directive';
import { PermissionUtilsDirective } from './permissionUtils';
import { PhoneValidator } from './phone.validator';
import { UsernameValidator } from './username.validator';

@NgModule({
  declarations: [
      HighlighterDirective,
      ClickOutsideDirective,
      PermissionUtilsDirective,
      EmailValidator,
      PhoneValidator,
      UsernameValidator,
  ],
  imports: [
    CommonModule,
    IonicModule,
  ],
  providers: [

  ],
  exports: [
    HighlighterDirective,
    ClickOutsideDirective,
    PermissionUtilsDirective,
    EmailValidator,
    PhoneValidator,
    UsernameValidator,
  ],
})
export class DirectivesModule {}
