import { trigger, transition, style, animate } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, NgModule, Input } from '@angular/core';

@Component({
  selector: 'app-grow-container',
  template: `
  <div @fade class="outer" *ngIf="open">
    <ng-content></ng-content>
  </div>
`,
  styles: [`
  .outer {
    overflow: hidden;
  }
  `],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({ height: '0' }),
        animate('200ms ease-out', style({ height: '*' })),
      ]),
      transition(':leave', [
        style({ height: '*' }),
        animate('200ms ease-out', style({ height: '0' })),
      ]),
    ]),
  ],
})
export class GrowContainerComponent {
  @Input() open = false;
  constructor() { }
}

@NgModule({
  imports: [CommonModule],
  declarations: [GrowContainerComponent],
  exports: [GrowContainerComponent],
})
export class GrowContainerModule { }
