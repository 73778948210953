import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';

import { ChevronModule } from '../../components/chevron/chevron.module';
import { MenuGroupComponent } from './menu-group/menu-group.component';
import { MenuComponent } from './menu.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule,
    ChevronModule,
    BrowserAnimationsModule,
  ],
  declarations: [MenuComponent, MenuGroupComponent],
  exports: [MenuComponent],
})
export class MenuModule {}
