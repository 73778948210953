import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { AngularCropperjsModule } from 'angular-cropperjs';

import { ImageCropperComponent } from './image-cropper.component';

@NgModule({
  imports: [
    AngularCropperjsModule,
    IonicModule,
  ],
  declarations: [ImageCropperComponent],
  exports: [ImageCropperComponent],
  providers: [],
})
export class ImageCropperModule { }
