import { trigger, transition, style, animate } from '@angular/animations';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

export interface IRefreshEvent {
  event: Event;
  doneRefreshing: () => void;
}
@Component({
  selector: 'app-refresher',
  templateUrl: './refresher.component.html',
  styleUrls: ['./refresher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({ opacity: '0', transform: 'translateY(-25px)' }),
        animate('250ms ease-out', style({ opacity: '1', transform: 'translateY(0px)' })),
      ]),
    ]),
  ],
})
export class RefresherComponent implements OnInit, OnDestroy {

  @Output() public refreshFunction = new EventEmitter<IRefreshEvent>();
  @Input() public pullDownText = 'pull down to refresh';

  public arrowDirection$ = new BehaviorSubject<'up' | 'down' | null>(null);
  public showPrompt$ = new BehaviorSubject<boolean>(false);

  private pullProgress$ = new Subject<0 | 1>();
  private subscriptions: Subscription[] = [];
  private refreshingTimeout;

  ngOnInit() {
    this.subscriptions.push(this.pullProgress$.pipe(distinctUntilChanged()).subscribe((value) => {
      this.arrowDirection$.next(value ? 'up' : 'down');
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  public start(e) {
    this.showPrompt();
    this.arrowDirection$.next('down');
  }

  public refresh(event) {
    this.hidePrompt();
    this.refreshFunction.emit({event, doneRefreshing: () => this.completeRefresh(event)});
    this.refreshingTimeout = setTimeout(() => this.completeRefresh(event), 30000);
  }

  public pull(e) {
    e.target.getProgress().then((r: number) => this.pullProgress$.next(r < 1 ? 0 : 1));
  }

  private completeRefresh(e) {
    clearTimeout(this.refreshingTimeout);
    e.target.disabled = true;
    e.target.complete();
    setTimeout(() => {
      e.target.disabled = false;
    }, 100);
  }

  private showPrompt() {
    this.showPrompt$.next(true);
  }

  private hidePrompt() {
    this.showPrompt$.next(false);
  }

}
