import { PipeLogic } from '@ncss/models';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'roleTypes' })
export class RoleTypesPipe implements PipeTransform  {
  public transform(value, args?) {
    return PipeLogic.RoleType(value, args);
  }
}
