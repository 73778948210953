import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { RouterLinkActive } from '@angular/router';
import * as _ from 'lodash';

import { MobilePropertyService } from './../../../services/mobile-property.service';


export interface IMenuPage {
  title: string;
  url: string;
  icon: string;
}

@Component({
  selector: 'app-menu-group',
  templateUrl: './menu-group.component.html',
  styleUrls: ['./menu-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('open', [
      state('true', style({ height: '*' })),
      state('false', style({ height: '39px' })),
      transition('* => *', animate('250ms ease-out')),
    ]),
  ],
})
export class MenuGroupComponent implements OnInit, OnDestroy {
  @ViewChildren(RouterLinkActive) public routerLinks: QueryList<RouterLinkActive>;
  @Input() public title: string;
  @Input() public icon: string;
  @Input() public open = true;
  @Input() public viewingProperty = false;
  @Input() public pages: IMenuPage[];
  private propertySubscription;

  constructor(
    private propertyService: MobilePropertyService,
  ) { }

  public ngOnInit() {
    this.propertySubscription = this.propertyService.property$.subscribe(() => {
      if (this.routerLinks) {
        _.forEach(this.routerLinks.toArray(), (routerLink) => {
          routerLink.links.notifyOnChanges();
        });
      }
    });
  }

  public ngOnDestroy() {
    this.propertySubscription.unsubscribe();
  }

}
