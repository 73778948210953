import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, interval, TimeoutError, Subject } from 'rxjs';
import { map, catchError, take, timeout, takeUntil } from 'rxjs/operators';

import { ConnectionService } from './../connection.service';


@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {
  public recentlyTimedOut = false;
  private SLOW_REQUEST_TIME = 15000;
  private TIMEOUT_AFTER = 25000;

  constructor(private connectionService: ConnectionService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes('property-uploader-us-west')) { // don't time out s3 uploads
      return next.handle(req);
    }

    const gotResponse = new Subject();
    const warningSubscription = interval(this.SLOW_REQUEST_TIME).pipe(takeUntil(gotResponse), take(1)).subscribe(() => {
      this.connectionService.slowRequest$.next(req);
    });

    return next.handle(req).pipe(
      timeout(this.TIMEOUT_AFTER),
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          gotResponse.next();
          gotResponse.complete();
          warningSubscription.unsubscribe();
        }

        if (err instanceof TimeoutError) {
          gotResponse.next();
          gotResponse.complete();
          warningSubscription.unsubscribe();
          this.connectionService.timedOutRequest$.next(req);
        }
        return throwError(err);
      }),
      map((res) => {
        gotResponse.next();
        gotResponse.complete();
        warningSubscription.unsubscribe();
        this.connectionService.onTimeRequest$.next();
        if (res instanceof HttpResponse) {
          return res.clone();
        }
      }),
    );
  }

}
