import { ICommentOptions, CommentLocationType, BaseModel } from '@ncss/models';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, filter } from 'rxjs/operators';

import { AppSettingsService, BackEndHost } from './app-settings.service';

@Injectable({
  providedIn: 'root',
})
export class MobileCommentService {
  private rootUrl: BackEndHost;

  private endPoints: { [type: string]: (docId: string, subId: string | number, commentId?: string) => string } = {
    BILLING_COMPANY: (docId, subId?, commentId?) => {
      return `${this.rootUrl}/api/BillingCompanies/${docId}/Comments${commentId ? '/' + commentId : ''}`;
    },
    MANAGEMENT_COMPANY: (docId, subId?, commentId?) => {
      return `${this.rootUrl}/api/ManagementCompanies/${docId}/Comments${commentId ? '/' + commentId : ''}`;
    },
    PROPERTY: (docId, subId?, commentId?) => {
      return `${this.rootUrl}/api/Properties/${docId}/Comments${commentId ? '/' + commentId : ''}`;
    },
    UNIT: (docId, subId?, commentId?) => {
      return `${this.rootUrl}/api/Units/${docId}/Comments${commentId ? '/' + commentId : ''}`;
    },
    BUILDING: (docId, subId?, commentId?) => {
      return `${this.rootUrl}/api/Properties/${docId}/Buildings/${subId}/Comments${commentId ? '/' + commentId : ''}`;
    },
    GATEWAY: (docId, subId?, commentId?) => {
      return `${this.rootUrl}/api/Properties/${docId}/Gateway/${subId}/Comments${commentId ? '/' + commentId : ''}`;
    },
    REPEATER: (docId, subId?, commentId?) => {
      return `${this.rootUrl}/api/Properties/${docId}/Repeaters/${subId}/Comments${commentId ? '/' + commentId : ''}`;
    },
    METER: (docId, subId?, commentId?) => {
      return `${this.rootUrl}/api/Units/${docId}/Meters/${subId}/Comments${commentId ? '/' + commentId : ''}`;
    },
  };

  constructor(
    private http: HttpClient,
    private appSettings: AppSettingsService,
  ) {
    this.rootUrl = this.appSettings.appSettings.backEnd;
    this.appSettings.appSettings$.pipe(
      map((s) => s.backEnd),
      filter((backend) => backend !== this.rootUrl),
    ).subscribe((b) => this.rootUrl = b || BackEndHost.Production);
  }

  public create(docId: string, comment: string, options: ICommentOptions = {}, platform = '') {
    this.identifyLocation(docId, options);
    if (!options.location) {
      throw new Error('Unknown Doc Type');
    } else if (!this.endPoints[options.location]) {
      throw new Error('Unknown End Point');
    } else {
      return this.http.post(this.endPoints[options.location](docId, options.subId), { comment, platform });
    }
  }

  public delete(docId: string, commentId: string, options: ICommentOptions = {}) {
    this.identifyLocation(docId, options);
    if (!options.location) {
      throw new Error('Unknown Doc Type');
    } else if (!this.endPoints[options.location]) {
      throw new Error('Unknown End Point');
    } else {
      return this.http.delete(this.endPoints[options.location](docId, options.subId, commentId));
    }
  }

  public update(docId: string, commentId: string, comment: string, options: ICommentOptions = {}, platform = '') {
    this.identifyLocation(docId, options);
    if (!options.location) {
      throw new Error('Unknown Doc Type');
    } else if (!this.endPoints[options.location]) {
      throw new Error('Unknown End Point');
    } else {
      return this.http.put(this.endPoints[options.location](docId, options.subId, commentId), { comment, platform });
    }
  }


  private identifyLocation(docId: string, options: ICommentOptions) {
    if (!options.location) {
      switch (BaseModel.GetDocTypeFromId(docId)) {
        case 'u':
          options.location = CommentLocationType.UNIT;
          break;
        case 'p':
          options.location = CommentLocationType.PROPERTY;
          break;
        case 'mc':
          options.location = CommentLocationType.MANAGEMENT_COMPANY;
          break;
        case 'bc':
          options.location = CommentLocationType.BILLING_COMPANY;
          break;
      }
    }
  }
}
