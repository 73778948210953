import { Message } from '@ncss/message';

import { ByteList } from 'byte-list';


export enum GatewayMessageTypes {
  GROUP_MASK  = 0x0000,

  // Gateway <-- Mobile
  REQUEST_INFO = 0x0001,
  REQUEST_DEVICE_LIST = 0x0002,
  REQUEST_DEVICE_INFO = 0x0003,
  FORCE_SYNC = 0x004,

  // Gateway <--> Mobile
  ACK = 0x0009,

  // Gateway --> Mobile
  RF_TRAFFIC_3RD_PARTY_DEVICE = 0x000A,
  RF_TRAFFIC_PROGRAMMED_DEVICE = 0x000B,
  RF_TRAFFIC_UNPROGRAMMED_DEVICE = 0x000C,
  SYNC_STARTED = 0x0010,
  SYNC_FINISHED = 0x0011,
}

export class GatewayMessage extends Message {

  public requestId;
  public serialNumber: number;

  constructor(type?: GatewayMessageTypes) {
    super();
    this.options = 0x02;
    this.type = type;
  }

  public serialize(): ByteList {
    const byteList = new ByteList();
    byteList.writeByte(this.requestId);
    byteList.writeUInt32(this.serialNumber);
    return byteList;
  }

  public deserialize(byteList: ByteList) {
    this.requestId = byteList.readByte();
    this.serialNumber = byteList.readUInt32();
  }
}
