import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FadeContainerComponent } from './fade-container/fade-container.component';
import { FlipContainerComponent } from './flip-container/flip-container.component';
import { SlideContainerComponent } from './slide-container/slide-container.component';


@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    FadeContainerComponent,
    FlipContainerComponent,
    SlideContainerComponent,
  ],
  declarations: [
    FadeContainerComponent,
    FlipContainerComponent,
    SlideContainerComponent,
  ],
  providers: [],
})
export class AnimatedContainersModule { }
