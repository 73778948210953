import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { DirectivesModule } from '../../directives/directives.module';
import { NumericInputComponent } from './numeric-input.component';

@NgModule({
declarations: [NumericInputComponent],
imports: [
    IonicModule,
    CommonModule,
    DirectivesModule,
],
exports: [NumericInputComponent],
})
export class NumericInputModule {}
