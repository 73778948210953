import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-equipment-tile-stats',
  templateUrl: './equipment-tile-stats.component.html',
  styleUrls: ['./equipment-tile-stats.component.scss'],
})
export class EquipmentTileStatsComponent {

  @Input() public icons: {
    name?: string,
    color?: string,
    value?: number,
    multiple?: {name: string, color: string}[],
    iconUrl?: string;
  }[];
  @Input() public fontSize = '10px';

  constructor() { }


  public getIcon(index: number): { name?: string, color?: string, value?: number, iconUrl?: string } {
    if (this.icons && this.icons[index] && !this.icons[index].multiple) {
      return this.icons[index];
    }

    return null;
  }

}
