import { Alert, UtilityTypes, UomTypes, DailyRead, UtilityTypeIds, HydrateRead, RFMeterRead, MeterRead, DeviceTypeHelper, DeviceTypeIds, NM4IRead } from '@ncss/models';

import { Component, Input, OnChanges, OnInit, SimpleChanges, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import * as c3 from 'c3';
import * as _ from 'lodash';
import * as moment from 'moment';

import { MobileDevicesService } from './../../../../../services/devices/mobile-devices.service';
import { MobileUnitService } from './../../../../../services/mobile-unit.service';


@Component({
  selector: 'app-read-history-chart',
  templateUrl: './read-history-chart.component.html',
  styleUrls: ['./read-history-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReadHistoryChartComponent implements OnInit, OnChanges {

  @Input() public unitId: string;
  @Input() public utilityTypeId: UtilityTypeIds;
  @Input() public startDate = moment().subtract(7, 'days');
  @Input() public endDate = moment();
  @Input() public storageActive = true;

  private _chart: any;
  private _loadingReadData = false;
  private isEmbeddedTransceiver: boolean;

  constructor(
    private cd: ChangeDetectorRef,
    private unitService: MobileUnitService,
    private devicesService: MobileDevicesService,
  ) { }

  public ngOnInit() {
    this.loadReadHistory();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.unitId && changes.unitId.currentValue && changes.unitId.currentValue !== this.unitId) {
      this.unitId = changes.unitId.currentValue;
    }
    if (changes.utilityTypeId && changes.utilityTypeId.currentValue && changes.utilityTypeId.currentValue !== this.utilityTypeId) {
      this.utilityTypeId = changes.utilityTypeId.currentValue;
    }
    this.loadReadHistory();
  }

  private getReadData(): Promise<{ key: Date, value: number }[]> {
    this._loadingReadData = true;
    if (!this.utilityTypeId) { return Promise.resolve([]); }
    const start = moment(this.startDate).toDate();
    const end = moment(this.endDate).toDate();
    return this.unitService.getReadData(this.unitId, start, end).toPromise()
      .then((docs) => {
        docs = _.map(docs, (d) => new DailyRead(d));
        let dataArr: Array<{ key: Date, value: number }> = [];
        _.forEach(docs, (r: any, i) => {
          if (r.utilityTypeId === this.utilityTypeId) {
            _.forEach(r.data, (d, j) => {
              const read = HydrateRead(d);
              if (!(read instanceof MeterRead) || (read instanceof RFMeterRead && read.failedToReadMeter)) {
                return; // Continue: We want to exclude failed reads so the chart doesn't dip down
              }
              dataArr.push({
                key: new Date(read.lastCheckIn),
                value: read.meterRead(),
              });
            });
          }
        });
        dataArr = _.sortBy(dataArr, (d) => d.key);
        return dataArr;
      });
  }

  private async getDemandUsageData(meter, startDate, endDate) {
    const data: Array<{ key: Date, value: number }> = [];
    let demandUsages;
    await this.devicesService.getDemandUsageRangeForMeter(meter, startDate, endDate)
      .then(du => {
        demandUsages = du;
      });
    if (demandUsages) {
      demandUsages.forEach((du) => {
        data.push({
          key: new Date(du.usageTimestamp),
          value: du.usageWatts,
        });
      });
    }
    return data;
  }

  public async loadReadHistory(force = false) {
    if (force || !this._loadingReadData) {
      let demandUsage;
      let meter;
      await this.unitService.findById(this.unitId).toPromise()
        .then(unit => {
          if (
            this.utilityTypeId &&
            unit.meters &&
            unit.meters.length &&
            unit.meters.find(m => m.utilityTypeId === this.utilityTypeId)
          ) {
            meter = unit.meters.find(m => m.utilityTypeId === this.utilityTypeId);
            if (meter.device && DeviceTypeHelper.GetIsEmbeddedTransceiver(meter.device.id)) {
              this.isEmbeddedTransceiver = true;
            }
          }
        });
      if (!this.isEmbeddedTransceiver) {
        this.getReadData()
          .then((readData) => {
            if (meter.device.deviceTypeId === DeviceTypeIds.NM4_I) {
              const decimalPosition = (meter.device.data as NM4IRead).device.meter.decimalPosition || 2;
              this.initChart(this.utilityTypeId || null, readData, null, null, decimalPosition);
            } else {
              this.initChart(this.utilityTypeId || null, readData);
            }
            setTimeout(() => this._loadingReadData = false, 200); // This is to debounce the loading
          });
      } else {
        const start = moment(this.startDate).toDate();
        const end = moment(this.endDate).toDate();
        await this.getDemandUsageData(meter, start, end)
          .then(du => {
            demandUsage = du;
            this.getReadData()
              .then((readData) => {
                this.initChart(this.utilityTypeId || null, readData, null, demandUsage);
                setTimeout(() => this._loadingReadData = false, 200); // This is to debounce the loading
              });
          });
      }
    }
  }

  private initChart(utilityTypeId?: number, data: { key: Date, value: number }[] = [], alerts?: Alert[], demandUsageData?: Array<{ key: Date, value: number }>, NM4IReadDecimalPosition?: number) {
    const { timestamps, reads, usage, maxUsage, demandUsage, duTimestamps, maxDemand } = this.parseChartData(_.orderBy(data, 'key'), _.orderBy(demandUsageData, 'key'));
    let columns = demandUsage.length
      ? [
        ['x'].concat(timestamps as any),
        ['x2'].concat(duTimestamps as any),
        ['Meter Usage'].concat(usage as any),
        ['Meter Read'].concat(reads as any),
        ['Demand Usage'].concat(demandUsage as any),
      ]
      : [
        ['x'].concat(timestamps as any),
        ['Meter Usage'].concat(usage as any),
        ['Meter Read'].concat(reads as any),
      ];
    const labelText = this.storageActive ? 'No Read Data' : 'Read Data Not Available';
    if (!this.storageActive) {
      columns = [];
    }

    if (this._chart && data.length > 0) {
      this._chart.load({
        unload: (timestamps.length && timestamps.length < 1),
        columns: timestamps.length ? columns : [],
      });
      this._chart.data.colors({
        'Meter Read': UtilityTypes[utilityTypeId] ? UtilityTypes[utilityTypeId].colorHex : '',
        'Meter Usage': '#E0E0E0',
      });
      if (timestamps.length) {
        this._chart.axis.max({ y2: maxUsage * 3, y3: maxDemand * 1.2 });
        this._chart.internal.config.axis_x_tick_count = 15;
        this._chart.flush();
      }
    } else {
      this._chart = c3.generate({
        bindto: `#chart`,
        size: {
          height: 250,
        },
        data: {
          types: {
            'Meter Read': 'spline',
            'Meter Usage': 'area-step',
            'Demand Usage': 'line',
          },
          xs: {
            'Meter Read': 'x',
            'Meter Usage': 'x',
            'Demand Usage': 'x2',
          },
          columns: columns,
          colors: {
            'Meter Read': UtilityTypes[utilityTypeId] ? UtilityTypes[utilityTypeId].colorHex : '',
            'Meter Usage': '#E0E0E0',
            'Demand Usage': '#FDD696',
          },
          axes: {
            'Meter Read': 'y',
            'Meter Usage': 'y2',
            'Demand Usage': 'y3',
          },
          empty: { label: { text: labelText } },
        },
        grid: {
          x: {
            lines: _.map(alerts, (a) => {
              return {
                value: new Date(a.openedAt).getTime(),
                class: a.closedAt ? 'closed-alert-line' : 'alert-line',
              };
            }),
          },
        },
        axis: {
          x: {
            type: 'timeseries',
            tick: {
              culling: {
                max: 6,
              },
              rotate: -20,
              fit: true,
              format: (d) => moment(d).format('MMM D - h a'),
            },
          },
          x2: {
            type: 'timeseries',
            tick: {
              culling: {
                max: 6,
              },
              rotate: -20,
              fit: true,
              format: (d) => moment(d).format('MMM D - h a'),
            },
          },
          y: { show: true },
          y2: {
            show: true,
            min: 0,
            max: maxUsage * 3,
          },
          y3: { show: false, min: 0 },
        },
        padding: {
          left: 60,
          right: 40,
        },
        point: {
          show: true,
          r: 1,
          sensitivity: 300,
          focus: {
            expand: {
              r: 4,
            },
          },
        },
        spline: {
          interpolation: {
            type: 'monotone',
          },
        },
        tooltip: {
          format: {
            value: (value, ratio, id, index) => {
              if (data[index] && UomTypes[(data[index] as any).uomTypeId]) {
                if (id === 'Meter Read' && NM4IReadDecimalPosition) {
                  return value.toFixed(NM4IReadDecimalPosition) + ' ' + UomTypes[(data[index] as any).uomTypeId].name;
                } else {
                  return value + ' ' + UomTypes[(data[index] as any).uomTypeId].name;
                }
              } else {
                if (id === 'Meter Read' && NM4IReadDecimalPosition) {
                  return value.toFixed(NM4IReadDecimalPosition);
                } else {
                  return value;
                }
              }
            },
          },
        },
      });
      this.cd.markForCheck();
    }
  }

  private parseChartData(data: { key: Date, value: number }[] = [], demandUsageData?: { key: Date, value: number }[])
    : { timestamps: Date[], reads: number[], usage: number[], maxUsage: number, demandUsage: number[]; duTimestamps: Date[]; maxDemand: number } {
    const timestamps: Date[] = [];
    const duTimestamps: Date[] = [];
    const reads: number[] = [];
    const usage: number[] = [];
    const demandUsage: number[] = [];
    let minRead = 0;
    let maxRead = 0;
    let maxUsage = 0;
    let maxDemand = 0;
    _.forEach(data, (d, i) => {
      timestamps.push(new Date(d.key));
      reads.push(d.value);
      if (i === 0) {
        usage.push(0);
      } else {
        const u = reads[i] - reads[i - 1];
        usage.push(u);
        if (u > maxUsage) {
          maxUsage = u;
        }
      }
      if (d.value < minRead) {
        minRead = d.value;
      }
      if (d.value > maxRead) {
        maxRead = d.value;
      }
    });
    if (demandUsageData) {
      demandUsageData.forEach(d => {
        if (d.value > maxDemand) {
          maxDemand = d.value;
        }
        duTimestamps.push(new Date(d.key));
        demandUsage.push(d.value);
      });
    }

    return { timestamps, reads, usage, maxUsage, demandUsage, duTimestamps, maxDemand };
  }

}
