import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { ToastOptions } from '@ionic/core';

@Injectable()
export class ToastService {
  private _queue: ToastOptions[] = [];
  private _processing = false;

  constructor(private toastCtrl: ToastController) {
    setInterval(() => {
      this.processQueue();
    }, 1000);
  }

  public queueToast(message: string, options: ToastOptions = {}, prioritize = false) {
    const opts = {
      message,
      color: options.color || 'dark',
      duration: options.duration || 1500,
      ...options,
    };
    if (prioritize) {
      this._queue.unshift(opts);
    } else {
      this._queue.push(opts);
    }
    this.processQueue();
  }

  private async processQueue() {
    if (this._processing || !this._queue.length) { return; }
    this._processing = true;
    await this.process(this._queue.pop());
    this._processing = false;
  }

  private async process(opts: ToastOptions) {
    if (!opts) { return; }
    const t = await this.toastCtrl.create(opts);
    await t.present();
    await t.onDidDismiss();
    await this.process(this._queue.pop());
  }
}
