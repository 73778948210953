import { Message } from '@ncss/message';
import { RR301InfoMessageType, RR301Info, BitwiseHelper, TR4Info, TR4InfoMessageType, TR4EInfo, TR4EInfoMessageType } from '@ncss/models';

import { ByteList } from 'byte-list';
import { forEach } from 'lodash';

const MASK = 0xFFF0;

export enum InfoMessageType {
  V1_INFO = 0x1020,
  V2_INFO = 0x1021,
}

export class InfoMessage extends Message {
  public msgType: RR301InfoMessageType | TR4InfoMessageType | TR4EInfoMessageType; // | RR301UpdateBlockMessageType;
  public info: RR301Info | TR4Info | TR4EInfo;

  constructor(serialNumber: number, msgType: RR301InfoMessageType | TR4InfoMessageType | TR4EInfoMessageType) {
    super();
    this.type = this.getInfoMessageTypeForSerialNumber(serialNumber);
    this.msgType = msgType;
    if ((this.msgType & MASK) === RR301InfoMessageType.GROUP_MASK) {
      this.info = new RR301Info(serialNumber);
    } else if ((this.msgType & MASK) === TR4InfoMessageType.GROUP_MASK) {
      this.info = new TR4Info(serialNumber);
    } else if ((this.msgType & MASK) === TR4EInfoMessageType.GROUP_MASK) {
      this.info = new TR4EInfo(serialNumber);
    }
  }

  public serialize(): ByteList {
    const bytes = super.serialize() as ByteList;
    if (this.type === InfoMessageType.V1_INFO) {
      bytes.writeByte(this.msgType);                // msgType
    } else if (this.type === InfoMessageType.V2_INFO) {
      bytes.writeByte(0);                           // Reserved
    }
    bytes.writeByte(this.frameId);                  // id
    bytes.writeUInt16(BitwiseHelper.SetBits(0, 4, 2, this.type === InfoMessageType.V1_INFO ? 0 : 1));  // control
    bytes.writeUInt32(0);                           // srcAddr
    bytes.writeUInt32(0);                           // srcAddrMAC
    bytes.writeUInt32(this.info.serialNumber);      // dstAddr
    bytes.writeUInt32(this.info.serialNumber);      // dstAddrMac
    bytes.writeUInt32(0);                           // firstHopAddr
    bytes.writeByte(0);                             // firstHopRssi
    if (this.type === InfoMessageType.V2_INFO) {
      bytes.writeUInt16(this.msgType);              // msgType
    }

    const startingPoint = bytes.index;
    this.info.serialize(bytes);

    const infoSize = bytes.index - startingPoint;
    const payloadSize = bytes.getLength() + 2;

    const size = this.type === InfoMessageType.V1_INFO ? 200 : 198;
    for (let i = infoSize; i < size; i++) {
      bytes.writeByte(0);
    }

    const payload = new ByteList();
    payload.writeUInt16(payloadSize);
    payload.concat(bytes);
    return payload;
  }

  private getInfoMessageTypeForSerialNumber(serialNumber: number) {
    return (serialNumber >= 0xBA000000 && serialNumber <= 0xBAFFFFFF) || (serialNumber >= 0xCA000000 && serialNumber <= 0xCAFFFFFF)
      ? InfoMessageType.V1_INFO : InfoMessageType.V2_INFO;
  }

  public toString() {
    let typeStr = '';
    if ((this.msgType & MASK) === RR301InfoMessageType.GROUP_MASK) {
      forEach(RR301InfoMessageType, (val, key) => {
        if (val === this.msgType) {
          typeStr = key;
        }
      });
    } else if ((this.msgType & MASK) === TR4InfoMessageType.GROUP_MASK) {
      forEach(TR4InfoMessageType, (val, key) => {
        if (val === this.msgType) {
          typeStr = key;
        }
      });
    } else if ((this.msgType & MASK) === TR4EInfoMessageType.GROUP_MASK) {
      forEach(TR4EInfoMessageType, (val, key) => {
        if (val === this.msgType) {
          typeStr = key;
        }
      });
    }
    const id = this.info && this.info.serialNumber ? this.info.serialNumber.toString(16).toUpperCase() : '';
    let log = '';
    if (this.info) {
      log = `(checkInInterval: ${this.info.checkInInterval} nextCheckIn: ${this.info.nextCheckIn})`;
    }
    return `InfoMessage.${typeStr} ${id} ${log} ${this.info.toString()}`;
  }

}
