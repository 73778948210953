import { NgModule } from '@angular/core';

import { ContentPopoverComponent } from './content-popover/content-popover.component';


@NgModule({
  imports: [],
  exports: [ContentPopoverComponent],
  declarations: [ContentPopoverComponent],
  entryComponents: [ContentPopoverComponent],
  providers: [],
})
export class ComponentsModule { }
