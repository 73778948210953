import { PipeLogic } from '@ncss/models';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'keys' })
export class KeysPipe implements PipeTransform {
  public transform(val, args?: string[]): any {
    return PipeLogic.Keys(val, args);
  }
}
