import {
  BillingCompany,
  Property,
  SubscriptionType,
  IWirelessNetworkInterface,
  INetworkInterface,
  PermissionUtils,
} from '@ncss/models';

import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModalController, LoadingController, AlertController, IonToggle } from '@ionic/angular';
import { isEqual } from 'lodash';
import { BehaviorSubject, interval } from 'rxjs';
import { filter, first } from 'rxjs/operators';

import { BackEndHost } from './../../../services/app-settings.service';
import { BillingCompanyService } from './../../../services/billing-company.service';
import { IDirectConnectDevice } from './../../../services/direct-connect/baseDirectConnectDevice';
import { DirectConnectGW4 } from './../../../services/direct-connect/GW4/GW4';
import { WifiNetwork } from './../../../services/direct-connect/GW4/messages/ble/payloads/incoming/availableNetworks';
import { FeedbackService, FeedbackType } from './../../../services/feedback.service';
import { MobilePropertyService } from './../../../services/mobile-property.service';
import { MobileUserService } from './../../../services/mobile-user.service';
import { ToastService } from './../../../services/toast.service';


export enum GW4ModalTabs {
  STATUS,
  CONFIGURE,
}

@Component({
  selector: 'app-gw4-modal',
  templateUrl: './gw4-modal.component.html',
  styleUrls: ['./gw4-modal.component.scss'],
})
export class Gw4ModalComponent implements OnInit, OnDestroy {
  @Input() public connectedDevice: IDirectConnectDevice;

  public gw4: DirectConnectGW4;
  public tabs = [
    { name: 'Status', value: GW4ModalTabs.STATUS, icon: 'icon-gw301' },
    { name: 'Configure', value: GW4ModalTabs.CONFIGURE, icon: 'icon-settings' },
  ];
  public selectedTab = GW4ModalTabs.STATUS;
  public GW4ModalTabs = GW4ModalTabs;

  public remoteServerForm: FormGroup;
  public wlanForm: FormGroup;
  public ethForm: FormGroup;
  public programmedProperty: Property;
  public loadingWlanSection$ = new BehaviorSubject('Scanning Networks');
  public loadingEthSection$ = new BehaviorSubject('Loading...');
  public togglingCellular$ = new BehaviorSubject(false);

  private _subscriptions = [];
  private programmedBillingCompany: BillingCompany;

  constructor(
    private modalCtrl: ModalController,
    private loadCtl: LoadingController,
    private fb: FormBuilder,
    private alertCtl: AlertController,
    private propertyService: MobilePropertyService,
    public users: MobileUserService,
    private billingCompanyService: BillingCompanyService,
    private toast: ToastService,
    private haptics: FeedbackService,
    private alertCtrl: AlertController,
  ) { }

  ngOnInit() {
    if (!this.connectedDevice) { return; }
    this.gw4 = this.connectedDevice.device as DirectConnectGW4;
    this.initialize();
  }

  private initialize() {
    this.propertyService.findPropertyByGatewayId(this.gw4.serialNumber).toPromise().then((p) => {
      if (p && p.billingCompany && p.billingCompany.id) {
        this.billingCompanyService.findById(p.billingCompany.id).toPromise().then((bc) => {
          this.programmedProperty = p;
          this.programmedBillingCompany = bc;
        });
      }
    }).catch((e) => console.log('Property not found for gateway', e));
    this._subscriptions.forEach((s) => s.unsubscribe());
    this._subscriptions = [];
    this.initForms();
    this._subscriptions.push(
      this.gw4.remoteTarget$.subscribe((target) => this.patchRemoteTarget(target)),
      this.gw4.ethInterface$.pipe(filter((eth) => !(this.loadingEthSection$.value))).subscribe((eth) => this.patchEth(eth)),
      this.gw4.wlanInterface$.pipe(filter((wlan) => !(this.loadingWlanSection$.value))).subscribe((wlan) => this.patchWlan(wlan)),
      interval(45 * 1000).subscribe(() => this.gw4.heartbeat()),
    );
    this.gw4.availableNetworks$.pipe(first()).toPromise().then(() => this.loadingWlanSection$.next(null));
    this.gw4.ethInterface$.pipe(first()).toPromise().then(() => this.loadingEthSection$.next(null));
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((s) => s.unsubscribe());
    this._subscriptions = [];
  }

  private patchWlan(wlan: IWirelessNetworkInterface, force = false) {
    if (this.wlanForm.dirty && !force) { return; }
    this.wlanForm.reset({
      ...wlan,
    });
  }

  private patchEth(eth: INetworkInterface, force = false) {
    if (this.ethForm.dirty && !force) { return; }
    this.ethForm.reset({
      ...eth,
    });
  }

  private patchRemoteTarget(target) {
    if (target && target.host) {
      this.remoteServerForm.controls.host.reset(target.host);
    }
    if (target && target.port) {
      this.remoteServerForm.controls.port.reset(target.port);
    }
  }

  private initForms() {
    this.remoteServerForm = this.fb.group({
      host: ['www.nextcenturymeters.com', Validators.required],
      port: [44444, Validators.required],
    });
    this.wlanForm = this.fb.group({
      address: '',
      dhcp: false,
      gateway: '',
      netmask: '',
      connectedSSID: '',
    });
    this.ethForm = this.fb.group({
      address: '',
      dhcp: false,
      gateway: '',
      netmask: '',
    });
  }

  public async sync() {
    const l = await this.loadCtl.create({ message: 'syncing...', backdropDismiss: true });
    await l.present();
    const subscription = this.gw4.syncProgress$.subscribe((progress) => {
      l.message = 'syncing...' + (progress || '');
    });
    const success = await this.gw4.sync();
    await l.dismiss();
    subscription.unsubscribe();
    this.haptics.HapticFeedback(FeedbackType.DOUBLE_TAP);
    this.toast.queueToast(success ? 'Sync Complete!' : 'Failed to sync');
  }

  public async saveWlanForm() {
    this.loadingWlanSection$.next('Applying changes, this may take a minute...');
    const applied = await this.gw4.changeNetwork('wlan', this.wlanForm.value);
    this.loadingWlanSection$.next(null);
    if (applied) {
      this.toast.queueToast('Successfully updated WiFi settings');
      this.patchWlan(this.wlanForm.value, true);
    } else {
      this.toast.queueToast('Failed to update WiFI settings at this time.');
    }
  }

  public async saveEthForm() {
    this.loadingEthSection$.next('Applying changes, this may take a minute...');
    const applied = await this.gw4.changeNetwork('eth', this.ethForm.value);
    this.loadingEthSection$.next(null);
    if (applied) {
      this.toast.queueToast('Successfully updated Ethernet settings');
      this.patchEth(this.ethForm.value, true);
    } else {
      this.toast.queueToast('Failed to update Ethernet settings at this time.');
    }
  }

  public async forgetWifiNetwork() {
    this.loadingWlanSection$.next('Forgetting WiFi Network...');
    await this.gw4.forgetWifiNetwork();
    this.loadingWlanSection$.next(null);
  }

  public async promptTextInput(currValue: string, header: string) {
    const a = await this.alertCtl.create({
      inputs: [
        {
          value: currValue,
          name: header,
          id: header,
        },
      ],
      header,
      animated: true,
      buttons: [{ text: 'Cancel', role: 'cancel' }, { text: 'Ok', role: 'ok' }],
    });
    await a.present();
    const d = await a.onDidDismiss();
    if (d.role === 'ok' && d.data && d.data.values && d.data.values[header]) {
      return d.data.values[header];
    } else {
      return null;
    }
  }

  public async textInputForForm(form: FormGroup, controlName: string) {
    const res = await this.promptTextInput(form.controls[controlName].value, controlName);
    if (res && res !== form.controls[controlName].value) {
      form.controls[controlName].setValue(res);
      form.controls[controlName].markAsDirty();
    }
  }

  public async checkForUpdate() {
    const applying = await this.gw4.checkForUpdate(
      this.users.user['manufacturingUser'] ? BackEndHost.Manufacturing : BackEndHost.Production,
    );
    this.toast.queueToast(applying ? `Checking for update...` : `Failed to check for update`);
  }

  public async selectNetwork(networks: WifiNetwork[]) {
    const current = networks.find((n) => n.name === this.wlanForm.value.connectedSSID);
    let selected = current;
    const inputs = networks.map((n) => {
      return {
        type: 'radio',
        value: n,
        label: n.name,
        name: n.name,
        checked: selected ? n.name === selected.name : false,
        id: n.name,
        handler: () => { selected = n; },
      };
    });
    const a = await this.alertCtl.create({
      header: 'Select a network',
      inputs: inputs as any,
      buttons: [{ text: 'Cancel', role: 'cancel' }, { text: 'Ok', role: 'ok' }],
    });
    await a.present();
    const res = await a.onDidDismiss();
    if (res && res.role === 'ok' && !isEqual(current, selected)) {
      if (selected.secure) {
        const pass = await this.promptTextInput('', 'Enter Network Password');
        if (pass) {
          this.loadingWlanSection$.next('Connecting to network...');
          const success = await this.gw4.changeWifiNetwork(selected.name, pass);
          if (!success) {
          const errorAlert = await this.alertCtrl.create({
            header: 'Uh Oh',
            message: `Could not connect to ${selected.name}.  Please check that the password is correct and try again.`,
            buttons: ['Ok'],
          });
          errorAlert.present();
          }
          this.loadingWlanSection$.next(null);
          this.toast.queueToast(success ? 'Successfully joined new network' : 'Failed to join network');
        }
      } else {
        this.loadingWlanSection$.next('Connecting to network...');
        const success = await this.gw4.changeWifiNetwork(selected.name, null);
        this.loadingWlanSection$.next(null);
        if (!success) {
          const errorAlert = await this.alertCtrl.create({
            header: 'Uh Oh',
            message: `Could not connect to ${selected.name}.  Please check that the password is correct and try again.`,
            buttons: ['Ok'],
          });
          errorAlert.present();
        }
        this.toast.queueToast(success ? 'Successfully joined new network' : 'Failed to join network');
      }
    }
  }

  public async saveRemoteServerForm() {
    const target: { host: string, port: number } = this.remoteServerForm.value;
    if (target.host && target.port) {
      const l = await this.loadCtl.create({ message: 'saving...', backdropDismiss: true });
      await l.present();
      const success = await this.gw4.setRemoteServer(target.host, target.port);
      await l.dismiss();
      if (success) {
        this.haptics.HapticFeedback(FeedbackType.DOUBLE_TAP);
        this.toast.queueToast('Saved Remote Server!');
      }
    }
  }

  public async headerClicked(event: any): Promise<void> {
    this.modalCtrl.dismiss();
  }

  public async setForceCellular(toggleRef: IonToggle) {
    const val = !toggleRef.checked;
    const res = await this.gw4.setForceCellular(val);
    if (res) {
      this.haptics.HapticFeedback(FeedbackType.DOUBLE_TAP);
      this.toast.queueToast(val ? 'Forcing cellular' : 'No longer forcing cellular');
    } else {
      this.toast.queueToast(val ? 'Failed to force cellular' : 'Failed to stop forcing cellular');
    }
  }

  private async canToggleCellular(): Promise<{ canToggle: boolean; error?: string }> {
    const user = this.users.user;
    if (!user) {
      return { canToggle: false, error: 'Invalid user session' };
    }
    if (!!this.programmedBillingCompany && !!this.programmedProperty) {
      return PermissionUtils.userHasPermissionsOnProperty(
        user,
        SubscriptionType.CELLULAR,
        this.programmedBillingCompany,
        this.programmedProperty,
      ) ? { canToggle: true } : { canToggle: false, error: 'Access Denied' };
    } else {
      const isProgrammed = await this.gw4.isProgrammed$.pipe(first()).toPromise();
      return isProgrammed ? { canToggle: false, error: 'Access Denied' } : { canToggle: true};
    }
  }

  public async setEnableCellular(toggleRef: IonToggle) {
    const val = !toggleRef.checked;
    const { canToggle, error } = await this.canToggleCellular();
    if (!canToggle) {
      this.toast.queueToast(`Unable to toggle cellular at this time${error ? (': ' + error) : ''}`);
      setTimeout(() => {
        toggleRef.checked = !val;
      });
      return;
    }
    this.togglingCellular$.next(true);
    if (val) {
      let cost = 2000;
      if (this.programmedBillingCompany) {
        cost = BillingCompany.getSubscriptionCost(
          SubscriptionType.CELLULAR,
          this.programmedBillingCompany.subscriptionDefaults,
          this.programmedBillingCompany.waivedSubscriptions).cost;
      }
      const a = await this.alertCtl.create({
        header: 'Enable Cellular',
        message: `Are you sure you want to enable cellular data? There is a $${cost / 100} a month subscription fee for this service.`,
        buttons: [{ text: 'Cancel', role: 'cancel' }, { text: 'Enable', role: 'enable' }],
      });
      await a.present();
      const res = await a.onDidDismiss();
      if (res.role !== 'enable') {
        setTimeout(() => {
          toggleRef.checked = false;
        });
        this.togglingCellular$.next(false);
      } else {
        try {
          await this.propertyService.setCellular(this.programmedProperty._id, true).toPromise();
        } catch (e) {
          this.toast.queueToast('Unable to toggle cellular at this time');
          setTimeout(() => {
            toggleRef.checked = false;
          });
          this.togglingCellular$.next(false);
          return;
        }
        await this.gw4.setCellularEnabled(val);
        this.haptics.HapticFeedback(FeedbackType.DOUBLE_TAP);
        this.toast.queueToast('Cellular Enabled');
        this.togglingCellular$.next(false);
      }
    } else {
      try {
        await this.propertyService.setCellular(this.programmedProperty._id, false).toPromise();
      } catch (e) {
        this.toast.queueToast('Unable to toggle cellular at this time');
        setTimeout(() => {
          toggleRef.checked = false;
        });
        this.togglingCellular$.next(false);
        return;
      }
      await this.gw4.setCellularEnabled(val);
      this.haptics.HapticFeedback(FeedbackType.DOUBLE_TAP);
      this.toast.queueToast('Cellular Disabled');
      this.togglingCellular$.next(false);
    }
  }

  public selectedTabChange(tab: GW4ModalTabs) {
    this.selectedTab = tab;
  }

}
