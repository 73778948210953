import { Message } from '@ncss/message';
import { NwkRoute, BitwiseHelper } from '@ncss/models';

import { ByteList } from 'byte-list';

export enum RE4DeviceRouteMsgType {
  RE4_DEVICE_ROUTE_MSG = 0x2090,
}

export class DeviceRouteMessage extends Message {
  count: number; // UINT8
  routes: NwkRoute[]; // 20 MAX
  flags: number; // UINT16

  get tableDropped() {
    return BitwiseHelper.getValueFromFlags(this.flags, 0);
  }

  get currentlySortingTable() {
    return BitwiseHelper.getValueFromFlags(this.flags, 1);
  }

  serialize(): ByteList {
    const bytes = super.serialize() as ByteList;
    this.routes = this.routes || [];
    bytes.writeByte(this.routes.length);
    for (const route of this.routes) {
      route.serialize(bytes);
    }

    return bytes;
  }

  deserialize(bytes: ByteList): void {
    super.deserialize(bytes);
    this.flags = bytes.readUInt16();
    this.count = bytes.readByte();
    this.routes = [];
    for (let i = 0; i < this.count; ++i) {
      const route = new NwkRoute();
      route.deserialize(bytes);
      this.routes.push(route);
    }
  }


}
