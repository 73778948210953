import { PipeLogic } from '@ncss/models';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'uomType' })
export class UomTypePipe implements PipeTransform  {
  public transform(id, args?) {
    return PipeLogic.UomType(id, args);
  }
}
