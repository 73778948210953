import { BitwiseHelper } from '@ncss/models';

import { ByteList } from 'byte-list';

import { Command, CommandCode } from './command';


const ChangeNetworkType = 0xA2;

export type InterfaceType = 'eth' | 'wlan' | 'ppp';

export class ChangeNetwork extends Command {

  type = ChangeNetworkType;
  interfaceType?: InterfaceType;
  useDHCP?: boolean;
  ipAddress?: string;
  networkMask?: string;
  gateway?: string;

  constructor(interfaceType?: InterfaceType) {
    super(CommandCode.CHANGE_NETWORK);
    this.interfaceType = interfaceType;
  }

  serialize(): ByteList {
    const bytes = super.serialize();
    let flags = BitwiseHelper.SetBits(0, 0, 3, this.getInterfaceTypeCode());
    flags = BitwiseHelper.SetBits(flags, 3, 1, this.useDHCP ? 1 : 0);
    bytes.writeByte(flags);
    if (!this.useDHCP) {
      bytes.writeString(this.ipAddress);
      bytes.writeString(this.networkMask);
      bytes.writeString(this.gateway);
    }
    return bytes;
  }

  deserialize(bytes: ByteList) {
    super.deserialize(bytes);
    const flags = bytes.readByte();
    this.interfaceType = this.getInterfaceTypeFromCode(BitwiseHelper.GetBits(flags, 0, 3));
    this.useDHCP = ByteList.GetBit(flags, 3);
    if (!this.useDHCP) {
      this.ipAddress = bytes.readString();
      this.networkMask = bytes.readString();
      this.gateway = bytes.readString();
    }
  }

  private getInterfaceTypeFromCode(code: number) {
    switch (code) {
      case 1:
        return 'eth';
      case 2:
        return 'wlan';
      case 3:
        return 'ppp';
    }
  }

  private getInterfaceTypeCode() {
    switch (this.interfaceType) {
      case 'eth':
        return 1;
      case 'wlan':
        return 2;
      case 'ppp':
        return 3;
      default:
        return 0;
    }
  }


}
