import { TR4, DeviceTypeHelper, DeviceTypeIds, MeterConfig, ConversionUtils, EncoderProtocol, Unit, TR4Read, Lorax } from '@ncss/models';

import { ByteList } from 'byte-list';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';

import { DirectConnectDeviceStatus } from './../baseDirectConnectDevice';
import { EndDeviceMessage } from './../directConnect301/messages/endDeviceMessage';
import { EndDeviceFormState, IOpenAlert, IUserChanges, EncodedProtocolLabels, MeterConfigLabels } from './../remoteReader/remoteReader';

export interface ITR4UserChanges extends IUserChanges {
  configType?: MeterConfig;
  resetPulseCount?: boolean;
  use201Radio?: boolean;
}

export class DirectConnectTR4 {

  public static useMetric = false;

  public static create(bleDevice: any, manufacturingData: { serialNumber: number, byteList: ByteList }, isLimited = false) {
    const deviceType = DeviceTypeHelper.GetDeviceTypeBySerialNumber(bleDevice.id);
    if (!deviceType ||
      (deviceType.id !== DeviceTypeIds.TR4 && deviceType.id !== DeviceTypeIds.TR4_X && deviceType.id !== DeviceTypeIds.TR4_I)) {
      return null;
    }

    const device = new DirectConnectTR4(bleDevice.msg);
    return {
      status: DirectConnectDeviceStatus.ADVERTISING,
      serialNumber: bleDevice.msg.srcAddr,
      bleSignalStrength: null,
      bleDevice: bleDevice,
      device,
      subscriptions: [],
    };
  }

  public serialNumber: number;
  public serialNumberStr: string;
  public deviceName: string;
  public deviceModel: string;
  public programmedUnit: Unit;

  public get firmwareVersionStr$() { return this._firmwareVersionStr$.asObservable(); }
  public get hardwareVersionStr$() { return this._hardwareVersionStr$.asObservable(); }
  public get batteryLevelStr$() { return this._batteryLevelStr$.asObservable(); }
  public get temperatureStr$() { return this._temperatureStr$.asObservable(); }
  public get linkQualityStr$() { return this._linkQualityStr$.asObservable(); }
  public get gatewayReplied$() { return this._gatewayReplied$.asObservable(); }
  public get rapidCheckInEnabled() { return this._rapidCheckInEnabled$.value; }
  public get rapidCheckInEnabled$() { return this._rapidCheckInEnabled$.asObservable(); }
  public get resetPulseCount() { return this._resetPulseCount$.value; }
  public get resetPulseCount$() { return this._resetPulseCount$.asObservable(); }
  public get use201Radio() { return this._use201Radio$.value; }
  public get use201Radio$() { return this._use201Radio$.asObservable(); }
  public get configType() { return this._configType$.value; }
  public get configType$() { return this._configType$.asObservable(); }
  public get configTypeStr() { return this._configTypeStr$.value; }
  public get configTypeStr$() { return this._configTypeStr$.asObservable(); }
  public get userChanges() { return this._userChanges$.value; }
  public get userChanges$() { return this._userChanges$.asObservable(); }
  public get lastMsgAt$() { return this._lastMsgAt$.asObservable(); }
  public get state() { return this._state$.value; }
  public get state$() { return this._state$.asObservable(); }
  public get openAlerts() { return this._openAlerts$.value; }
  public get openAlerts$() { return this._openAlerts$.asObservable(); }
  public get overallStatus() { return this._overallStatus$.value; }
  public get overallStatus$() { return this._overallStatus$.asObservable(); }
  public get pulses() { return this._pulses$.value; }
  public get pulses$() { return this._pulses$.asObservable(); }
  public get meterHealth() { return this._meterHealth$.value; }
  public get meterHealth$() { return this._meterHealth$.asObservable(); }
  public get encodedSerial() { return this._encodedSerial$.value; }
  public get encodedSerial$() { return this._encodedSerial$.asObservable(); }
  public get factorySleep() { return this._factorySleep; }
  public set factorySleep(val) { this._factorySleep = val; }

  private _batteryLevel: number;
  private _temperature: number;
  private _linkQuality: number;

  private _firmwareVersionStr$ = new BehaviorSubject<string>('');
  private _hardwareVersionStr$ = new BehaviorSubject<string>('');
  private _batteryLevelStr$ = new BehaviorSubject<string>('');
  private _temperatureStr$ = new BehaviorSubject<string>('');
  private _linkQualityStr$ = new BehaviorSubject<string>('');
  private _gatewayReplied$ = new BehaviorSubject<boolean>(false);
  private _rapidCheckInEnabled$ = new BehaviorSubject<boolean>(false);
  private _resetPulseCount$ = new BehaviorSubject<boolean>(false);
  private _use201Radio$ = new BehaviorSubject<boolean>(false);
  private _configType$ = new BehaviorSubject<MeterConfig>(null);
  private _configTypeStr$ = new BehaviorSubject<string>('');
  private _pulses$ = new BehaviorSubject<number>(null); // also represents read for encoded
  private _meterHealth$ = new BehaviorSubject<boolean>(false);
  private _encodedSerial$ = new BehaviorSubject<number>(null);
  private _userChanges$ = new BehaviorSubject<ITR4UserChanges>({});
  private _tr4: TR4;
  private _lastMsg: EndDeviceMessage;
  private _lastMsgAt$ = new BehaviorSubject<Date>(null);
  private _state$ = new BehaviorSubject<EndDeviceFormState>(EndDeviceFormState.PRISTINE);
  private _openAlerts$ = new BehaviorSubject<IOpenAlert[]>([]);
  private _overallStatus$ = new BehaviorSubject<{ label: string, description: string, isOk: boolean }>(null);
  private _factorySleep = false;

  constructor(msg?: EndDeviceMessage) {
    if (msg) {
      this.updateFromMessage(msg);
    }

    this._userChanges$.subscribe((changes) => {
      let pristine = true;

      if (changes.hasOwnProperty('rapidCheckInEnabled')) {
        pristine = false;
      }

      if (changes.hasOwnProperty('configType')) {
        pristine = false;
      }

      if (changes.hasOwnProperty('resetPulseCount')) {
        pristine = false;
      }

      if (changes.hasOwnProperty('use201Radio')) {
        pristine = false;
      }

      if (pristine) {
        this._state$.next(EndDeviceFormState.PRISTINE);
      } else if (this.state === EndDeviceFormState.PRISTINE) {
        this._state$.next(EndDeviceFormState.DIRTY);
      }
    });
  }

  public markFormAsDirty() {
    this._state$.next(EndDeviceFormState.DIRTY);
  }

  public applyChanges() {
    this._state$.next(EndDeviceFormState.APPLYING_CHANGES);
  }

  public changesBeingApplied() {
    this._state$.next(EndDeviceFormState.WAITING_CONFIRMATION);
  }

  public changesSuccessfullyApplied(msg: EndDeviceMessage) {
    this.updateFromMessage(msg);
    this._state$.next(EndDeviceFormState.CHANGES_APPLIED);
    this._userChanges$.next({});
  }

  public updateFromMessage(msg: EndDeviceMessage) {
    this._lastMsg = msg;
    this._tr4 = msg.device as TR4;
    this.updateDeviceInfo(msg);
    this.updateOpenAlerts(msg);
    this.updateOverallStatus(msg);
    this._lastMsgAt$.next(new Date());
  }

  public dropUserChanges() {
    this._userChanges$.next({});
    this.updateFromMessage(this._lastMsg);
  }

  public setConfigType(type: MeterConfig) {
    const changes = { ...this.userChanges };
    if (type !== this._tr4.meter.configType) {
      changes.configType = type;
    } else {
      delete changes.configType;
    }
    this._userChanges$.next(changes);
    this._configType$.next(type);
    this._configTypeStr$.next(MeterConfigLabels[this.configType]);
  }

  public setRapidCheckInEnabled(rapidCheckIn: boolean) {
    const changes = { ...this.userChanges };
    if (rapidCheckIn !== this._tr4.rapidCheckInEnabled) {
      changes.rapidCheckInEnabled = rapidCheckIn;
    } else {
      delete changes.rapidCheckInEnabled;
    }

    this._userChanges$.next(changes);
    this._rapidCheckInEnabled$.next(rapidCheckIn);
  }

  public setUse201Radio(use201Radio: boolean) {
    const changes = { ...this.userChanges };
    if (use201Radio !== this._tr4.radio201) {
      changes.use201Radio = use201Radio;
    } else {
      delete changes.use201Radio;
    }
    this._userChanges$.next(changes);
    this._use201Radio$.next(use201Radio);
  }


  public setResetPulseCount(toggleValue: boolean) {
    const changes = { ...this.userChanges };
    if (toggleValue) {
      changes.resetPulseCount = true;
    } else {
      delete changes.resetPulseCount;
    }
    this._userChanges$.next(changes);
    this._resetPulseCount$.next(toggleValue);
  }

  public setProgrammedUnit(unit: Unit) {
    if (unit && unit._id) {
      this.programmedUnit = unit;
    }
  }

  private updateDeviceInfo(msg: EndDeviceMessage) {
    this.serialNumber = msg.srcAddr;
    this.serialNumberStr = ConversionUtils.ConvertSerialNumberToString(this.serialNumber);
    const deviceType = DeviceTypeHelper.GetDeviceTypeBySerialNumber(this.serialNumber);
    this.deviceName = deviceType ? deviceType.name : '';
    this.deviceModel = deviceType ? deviceType.model : '';

    if (!msg.device) {
      // clear everything
      this._batteryLevel = null;
      this._batteryLevelStr$.next('');
      this._temperature = null;
      this._temperatureStr$.next('');
      this._linkQuality = msg.firstHopRssi;
      this._linkQualityStr$.next(Lorax.LinkQualityToSignalStrengthMessage(msg.firstHopRssi));
      this._gatewayReplied$.next(false);
      this._rapidCheckInEnabled$.next(false);
      this._resetPulseCount$.next(false);
      this._use201Radio$.next(false);
      this._configType$.next(null);
      this._configTypeStr$.next('');
      this._pulses$.next(null);
      this._meterHealth$.next(false);
      this._encodedSerial$.next(null);
      return;
    }
    msg.device = msg.device ? msg.device as TR4 : null;
    const tr4Read = TR4Read.Create(msg.srcAddr, msg.device, {
      firstHopId: msg.firstHopAddr,
      firstHopRssi: msg.firstHopRssi,
      lastHopRssi: msg.dstAddr,
      isProgrammed: true,
    })[0];

    if (this._batteryLevel !== msg.device.batteryLevel) {
      this._batteryLevel = msg.device.batteryLevel;
      this._batteryLevelStr$.next(Lorax.FormatBatteryYearsRemaining(tr4Read.batteryLevelYearsRemaining));
    }

    if (this._temperature !== msg.device.temperature) {
      this._temperature = msg.device.temperature;
      this._temperatureStr$.next(DirectConnectTR4.useMetric ? msg.device.temperature + ' C'
        : _.round(ConversionUtils.ConvertCelsiusToFahrenheit(msg.device.temperature)) + ' F');
    }

    if (this._linkQuality !== msg.firstHopRssi) {
      this._linkQuality = msg.firstHopRssi;
      this._linkQualityStr$.next(Lorax.LinkQualityToSignalStrengthMessage(msg.firstHopRssi));
    }

    if (msg.device.meter && this.meterHealth !== msg.device.meterHealth) {
      this._meterHealth$.next(msg.device.meterHealth);
    }

    if (msg.device.meter && this.pulses !== tr4Read.pulseCount) {
      this._pulses$.next(tr4Read.pulseCount);
    }

    if (msg.device.meter && this.encodedSerial !== tr4Read.meterSerial) {
      this._encodedSerial$.next(tr4Read.meterSerial);
    }

    if (this._gatewayReplied$.value !== msg.device.gatewayReplied) {
      this._gatewayReplied$.next(msg.device.gatewayReplied);
    }

    if (this.userChanges && this.userChanges.hasOwnProperty('rapidCheckInEnabled')) {
      this._rapidCheckInEnabled$.next(this.userChanges.rapidCheckInEnabled);
    } else {
      this._rapidCheckInEnabled$.next(msg.device.rapidCheckInEnabled);
    }

    if (this.userChanges && this.userChanges.hasOwnProperty('resetPulseCount')) {
      this._resetPulseCount$.next(this.userChanges.resetPulseCount);
    } else {
      this._resetPulseCount$.next(false);
    }

    if (this.userChanges && this.userChanges.hasOwnProperty('use201Radio')) {
      this._use201Radio$.next(this.userChanges.use201Radio);
    } else {
      this._use201Radio$.next(msg.device.radio201);
    }

    if (this.userChanges && this.userChanges.hasOwnProperty('configType')) {
      this._configType$.next(this.userChanges.configType);
      this._configTypeStr$.next(MeterConfigLabels[this.configType]);
    } else if (msg.device.meter) {
      this._configType$.next(msg.device.meter.configType);
      this._configTypeStr$.next(MeterConfigLabels[this.configType]);
    }

    const firmware = `v${msg.device.firmwareVersionMajor}.${msg.device.firmwareVersionMinor}`;
    if (firmware !== this._firmwareVersionStr$.value) {
      this._firmwareVersionStr$.next(firmware);
    }
    const hardware = `v${msg.device.hardwareVersionMajor}.${msg.device.hardwareVersionMinor}`;
    if (hardware !== this._hardwareVersionStr$.value) {
      this._hardwareVersionStr$.next(hardware);
    }
  }

  private updateOpenAlerts(msg: EndDeviceMessage) {
    const alerts: IOpenAlert[] = [];
    if (msg.device && msg.device instanceof TR4) {
      if (msg.device.tamperPinState) {
        alerts.push({ typeStr: 'Tamper', iconStr: 'icon-alert-triangle' });
      }
      if (msg.device.freeze) {
        alerts.push({ typeStr: 'Freeze', iconStr: 'icon-freeze' });
      }
      if (msg.device.lowBattery) {
        alerts.push({ typeStr: 'Low Battery', iconStr: 'icon-battery-low' });
      }
      if (msg.device.leak) {
        alerts.push({ typeStr: 'Leak', iconStr: 'icon-leak-B' });
      }
      if (msg.device.meterBounce) {
        alerts.push({ typeStr: 'Faulty Reed Switch', iconStr: 'icon-meter-off' });
      }
      if (msg.device.regression) {
        alerts.push({ typeStr: 'Meter Regression', iconStr: 'icon-meter-off' });
      }
    }
    this._openAlerts$.next(alerts);
  }

  private updateOverallStatus(msg: EndDeviceMessage) {
    msg.device = msg.device as TR4;
    const status = {
      isOk: true,
      label: '',
      description: '',
    };

    if (msg.device) {
      const linkQuality = Lorax.LinkQualityToSignalStrengthMessage(msg.firstHopRssi);
      status.isOk = msg.device.gatewayReplied && linkQuality !== 'Poor';
      status.label = linkQuality === 'Poor'
        ? 'Poor Transceiver Signal'
        : msg.device.gatewayReplied ? 'All Good' : 'Not Communicating With Gateway';
      status.description = msg.device.gatewayReplied
        ? `This device has a${linkQuality === 'Excellent' ? 'n' : ''} ${linkQuality} connection to the Gateway.`
        : 'This Transceiver has not received a response from any nearby Gateways. This could mean that this ' +
        'device is either not programmed to a Unit yet or it is not within range of a Gateway.';
    } else {
      status.isOk = false;
    }

    if (status.isOk) {
      const { isOk, label, description } = this.getMeterStatusDescription({
        type: msg.device.meter.configType,
        isOk: msg.device.meterHealth,
        protocol: msg.device.meter.encoderProtocol,
      });

      if (isOk) {
        status.description += ' ' + description;
      } else {
        status.isOk = false;
        status.label = label;
        status.description = description;
      }
    }
    if (!_.isEqual(this.overallStatus, status)) {
      this._overallStatus$.next(status);
    }
  }

  private getMeterStatusDescription(
    meter: { type: MeterConfig, isOk: boolean, protocol?: EncoderProtocol }): { isOk: boolean, label: string, description: string } {
    if (meter.isOk) { // meter is okay
      if (meter.type === MeterConfig.PULSE_IN) {
        return {
          isOk: true,
          label: 'Meter Detected',
          description: 'Pulses have been detected within the last 24 hours on the meter.',
        };
      } else if (meter.type === MeterConfig.ENCODER_IN) {
        const p = EncodedProtocolLabels[meter.protocol] || null;
        return {
          isOk: true,
          label: 'Communicating With Meter',
          description: 'The Transceiver can successfully communicate with the meter' + (p ? ` (${p}).` : '.'),
        };
      } else {
        return {
          isOk: true,
          label: 'Meter Detected',
          description: 'The Transceiver has successfully detected a meter.',
        };
      }
    } else {  // meter is NOT okay
      if (meter.type === MeterConfig.PULSE_IN) {
        return {
          isOk: false,
          label: 'Meters Not Detected',
          description: 'No pulses have been detected within the last 24 hours on the meter.',
        };
      } else if (meter.type === MeterConfig.ENCODER_IN) {
        return {
          isOk: false,
          label: 'Check Wiring',
          description: 'The Transceiver could not communicate with the meter. Refer to the "Wiring Guide" here or contact us at support@nextcenturymeters.com for assistance.',
        };
      } else {
        return {
          isOk: false,
          label: 'Check Wiring',
          description: 'The Transceiver has not detected the meter.',
        };
      }
    }
  }
}
