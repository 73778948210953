import { PipeLogic } from '@ncss/models';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'deviceType' })
export class DeviceTypePipe implements PipeTransform  {
  public transform(id, args?) {
    return PipeLogic.DeviceType(id, args);
  }
}
