import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { GrowContainerModule } from '../animations/grow-container/growContainer.component';
import { FormRowWrapperComponent } from './form-row-wrapper.component';

@NgModule({
  imports: [
  GrowContainerModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
  ],
  declarations: [FormRowWrapperComponent],
  exports: [FormRowWrapperComponent],
})
export class FormRowWrapperModule { }
