import {
  Meter,
  UtilityTypeIds,
  UtilityTypes,
  MeterManufacturer,
  MeterType,
  UomTypeIds,
  UomTypeHelper,
  UomTypes,
  IUomType,
} from '@ncss/models';

import { FormGroup, AbstractControl, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { orderBy } from 'lodash';

import { MobileMeterManufacturerService } from '../services/mobile-meter-manufacturer.service';
import { MobileMeterTypeService, PartialMeterStat } from '../services/mobile-meter-type.service';
import { MobileUnitService } from '../services/mobile-unit.service';
import { CreatePositiveNumberValidator, setErrorOnControl, clearErrorOnControl } from './common-validators';

export class MeterTypeFormGroup extends FormGroup {

  private constructor(
    private meterTypeService: MobileMeterTypeService,
    private meterManufacturerService: MobileMeterManufacturerService,
    private unitService: MobileUnitService,
    meter?: Meter,
  ) {
    super({
      meterTypeId: new FormControl(meter ? meter.meterTypeId || null : null, [Validators.required]),
      meterModelNumber: new FormControl(meter ? meter.meterModelNumber || null : null, [Validators.required]),
      meterManufacturerId: new FormControl(meter ? meter.meterManufacturerId || null : null, [Validators.required]),
      meterManufacturerName: new FormControl(meter ? meter.meterManufacturerName || null : null, [Validators.required]),
      meterSize: new FormControl(meter ? meter.meterSize || null : null),
      uom: new FormControl(meter ? meter.uomTypeId || null : null, [Validators.required]),
      multiplier: new FormControl(meter ? meter.multiplier || 1 : 1, [Validators.required, CreatePositiveNumberValidator('Multiplier')]),
    }, CreateUomTypeIdValidator());
    this.currentUtilityTypeId = meter.utilityTypeId;
    this.originalValues = this.value;
    this.updateMeterTypeStr();
    this.updateUomTypeOptions(this.currentUtilityTypeId);
    this.setupListeners();
  }

  static async Create(
    meterTypeService: MobileMeterTypeService,
    meterManufacturerService: MobileMeterManufacturerService,
    unitService: MobileUnitService,
    meter: Meter,
  ): Promise<MeterTypeFormGroup> {
    let meterStat: PartialMeterStat;
    let uomTypeId = meter.uomTypeId;
    let multiplier = meter.multiplier;
    if (meter && (!meter.meterModelNumber || !meter.meterTypeId)) {
      meterStat = await meterTypeService.getMostUsedMeterType(unitService.propertyUnits, meter.utilityTypeId);

      if (meterStat && meterStat.uomTypeId && !uomTypeId) {
        uomTypeId = meterStat.uomTypeId;
      }

      if (meterStat && meterStat.multiplier && !multiplier) {
        multiplier = meterStat.multiplier;
      }
    }
    if (!uomTypeId) {
      const uoms = UomTypeHelper.GetUomForUtilityTypeId(meter.utilityTypeId);
      uomTypeId = uoms && uoms[0] ? uoms[0].id : undefined;
    }
    const form = new MeterTypeFormGroup(
      meterTypeService,
      meterManufacturerService,
      unitService,
      new Meter({
        ...meter,
        meterModelNumber: meter.meterModelNumber || (meterStat ? meterStat.meterModelNumber : null),
        meterTypeId: meter.meterTypeId || (meterStat ? meterStat.meterTypeId : null),
        meterManufacturerName: meter.meterManufacturerName || (meterStat ? meterStat.meterManufacturerName : null),
        meterManufacturerId: meter.meterManufacturerId || (meterStat ? meterStat.meterManufacturerId : null),
        uomTypeId,
        multiplier,
      }),
    );
    await form.updateMeterManufacturerOptions();
    await form.updateMeterTypeOptions();
    return form;
  }

  controls: {
    meterTypeId: FormControl;
    meterModelNumber: FormControl;
    meterManufacturerId: FormControl;
    meterManufacturerName: FormControl;
    meterSize: FormControl;
    uom: FormControl;
    multiplier: FormControl;
    [key: string]: AbstractControl;
  };
  meterTypeStr: string;
  manufacturerOptions: MeterManufacturer[];
  meterTypeOptions: MeterType[];
  uomTypeOptions: IUomType[];

  private currentUtilityTypeId: UtilityTypeIds;
  private originalValues: {
    meterTypeId: number;
    meterModelNumber: string;
    meterManufacturerId: number;
    meterManufacturerName: string;
    meterSize: string;
    uom: UomTypeIds;
    multiplier: number;
  };

  async patchWithMeterType(meterTypeId: number) {
    const meterType = await this.meterTypeService.findById(meterTypeId).toPromise();
    if (meterType && meterType.manufacturerId) {
      const manufacturer = await this.meterManufacturerService.findById(meterType.manufacturerId);
      if (manufacturer) {
        await this.manufacturerSelected(manufacturer);
        this.meterTypeSelected(meterType);
      }
    }
  }

  meterTypeSelected(meterType: MeterType) {
    const patch = {
      meterModelNumber: null,
      meterTypeId: null,
      meterSize: null,
    };
    if (meterType && meterType.modelNumber) {
      patch['meterModelNumber'] = meterType.modelNumber;
    }
    if (meterType && meterType._id) {
      patch['meterTypeId'] = meterType._id;
    }
    if (meterType && meterType.couplingSpecifications && meterType.couplingSpecifications[0]) {
      patch['meterSize'] = meterType.couplingSpecifications[0].size;
    }
    if (meterType && meterType.consumptionMeasurementOptions && meterType.consumptionMeasurementOptions[0]) {
      patch['uom'] = meterType.consumptionMeasurementOptions[0].uom;
      patch['multiplier'] = meterType.consumptionMeasurementOptions[0].multiplier;
    }

    if (Object.keys(patch).length > 0) {
      this.patchValue(patch, { emitEvent: true });
    }
  }

  async manufacturerSelected(manufacturer: MeterManufacturer) {
    this.patchValue({
      meterManufacturerId: manufacturer._id,
      meterManufacturerName: manufacturer.name,
    }, { emitEvent: true, onlySelf: true });
    await this.updateMeterTypeOptions();
    if (this.controls.meterTypeId.value) {
      const foundMeterType = this.meterTypeOptions.find((m) => m._id === this.controls.meterTypeId.value);
      if (!foundMeterType) {
        const type = this.meterTypeOptions[0];
        const defaultUoms = UomTypeHelper.GetUomForUtilityTypeId(this.currentUtilityTypeId);
        const defaultUomId = defaultUoms && defaultUoms.length ? defaultUoms[0].id : null;
        this.patchValue({
          multiplier: (type && type.consumptionMeasurementOptions && type.consumptionMeasurementOptions[0]) ?
            type.consumptionMeasurementOptions[0].multiplier : 1,
          uom: (type && type.consumptionMeasurementOptions && type.consumptionMeasurementOptions[0]) ?
            type.consumptionMeasurementOptions[0].uom : defaultUomId,
          meterTypeId: type ? type._id : null,
          meterModelNumber: type ? type.modelNumber : null,
          meterSize: (type && type.couplingSpecifications && type.couplingSpecifications[0]) ? type.couplingSpecifications[0].size : null,
        }, { emitEvent: true });
      }
    } else {
      this.patchValue({
        meterTypeId: null,
        meterModelNumber: null,
        meterSize: null,
      }, { emitEvent: true });
    }
  }

  private setupListeners() {
    this.valueChanges.subscribe(() => {
      this.checkForChanges();
      this.updateMeterTypeStr();
    });
  }

  private updateMeterTypeStr() {
    const uomString = UomTypeHelper.GetStringForUomTypeId(this.controls.uom.value);
    if (!uomString) {
      this.meterTypeStr = 'Unknown';
    } else {
      this.meterTypeStr = `${this.controls.meterModelNumber.value || 'Unknown'} (x${this.controls.multiplier.value || 1} ${UomTypes[this.controls.uom.value].name})`;
    }
  }

  private meterTypeToStr(meterType: MeterType) {
    const uomsForFamily = UomTypeHelper.GetUomForUtilityFamily(meterType.utilityFamily);
    const uomForFamily = uomsForFamily && uomsForFamily.length ? uomsForFamily[0].id : null;
    const uom = (meterType.consumptionMeasurementOptions && meterType.consumptionMeasurementOptions[0]) ?
      meterType.consumptionMeasurementOptions[0].uom : uomForFamily;
    const multiplier = (meterType.consumptionMeasurementOptions && meterType.consumptionMeasurementOptions[0]) ?
      meterType.consumptionMeasurementOptions[0].multiplier : 1;
    const uomString = UomTypes[uom] ? UomTypes[uom].name : null;
    if (!uomString) {
      return 'Unknown';
    } else {
      return `${meterType.modelNumber || 'Unknown'} (x${multiplier || 1} ${uomString})`;
    }
  }

  async utilityTypeIdChanged(utility: UtilityTypeIds) {
    this.currentUtilityTypeId = utility;
    this.updateUomTypeOptions(utility);
    await this.updateMeterManufacturerOptions();
    if (this.controls.meterManufacturerId.value) {
      const foundManufacturer = this.manufacturerOptions.find((m) => m._id === this.controls.meterManufacturerId.value);
      if (!foundManufacturer || foundManufacturer.name === 'Generic') {
        await this.patchWithGeneric();
      } else {
        await this.manufacturerSelected(foundManufacturer);
      }
    } else {
      await this.patchWithGeneric();
    }
    await this.updateMeterTypeOptions();
  }

  private updateUomTypeOptions(utility: UtilityTypeIds) {
    this.uomTypeOptions = UomTypeHelper.GetUomForUtilityTypeId(utility);
    if (!this.uomTypeOptions.find((u) => u.id === this.controls.uom.value)) {
      this.controls.uom.setValue(null);
    }
  }

  private checkForChanges() {
    for (const key of Object.keys(this.controls)) {
      if ((this.originalValues[key] || this.controls[key].value) && this.originalValues[key] !== this.controls[key].value) {
        this.controls[key].markAsDirty();
        this.controls[key].markAsTouched();
      } else {
        this.controls[key].markAsPristine();
        this.controls[key].markAsUntouched();
      }
    }
  }

  async updateMeterTypeOptions() {
    const manufacturerId = this.controls.meterManufacturerId.value;
    const utilityFamily = UtilityTypes[this.currentUtilityTypeId].family;
    if (manufacturerId) {
      let meterTypes = orderBy((await this.meterTypeService.findByManufacturerId(manufacturerId, utilityFamily)), 'modelNumber');
      meterTypes = meterTypes.map(m => {
        return {
          ...m,
          formattedStr: this.meterTypeToStr(m),
        };
      });
      this.meterTypeOptions = meterTypes;
    } else {
      this.meterTypeOptions = [];
    }
  }

  async updateMeterManufacturerOptions() {
    const utilityFamily = UtilityTypes[this.currentUtilityTypeId].family;
    this.manufacturerOptions = orderBy((await this.meterManufacturerService.findAll({ utilityFamily }).toPromise() || []), 'name');
  }

  private async patchWithGeneric() {
    const stat: PartialMeterStat =
      await this.meterTypeService.getMostUsedMeterType(this.unitService.propertyUnits, this.currentUtilityTypeId);
    const uomsForUtility = UomTypeHelper.GetUomForUtilityTypeId(this.currentUtilityTypeId);
    const currentUomTypeValid = uomsForUtility.findIndex((u) => u.id === this.controls.uom.value) > -1;
    const defaultUom = (uomsForUtility && uomsForUtility[0]) ? uomsForUtility[0].id : null;
    await this.updateMeterManufacturerOptions();
    this.patchValue({
      meterManufacturerId: stat.meterManufacturerId || null,
      meterManufacturerName: stat.meterManufacturerName || null,
    });
    await this.updateMeterTypeOptions();
    this.patchValue({
      meterSize: stat.meterSize || null,
      meterModelNumber: stat.meterModelNumber || null,
      meterTypeId: stat.meterTypeId || null,
      uom: currentUomTypeValid ? this.controls.uom.value : (stat.uomTypeId || defaultUom),
      multiplier: !this.controls.multiplier.value ? (stat.multiplier || 1) : this.controls.multiplier.value,
    });
  }

}

function CreateUomTypeIdValidator(): ValidatorFn {
  return (c: MeterTypeFormGroup) => {
    if (c.parent instanceof FormGroup && c.parent.controls.utilityTypeId) {
      const utilityControl = c.parent.controls.utilityTypeId;
      const uomControl = c.controls.uom;
      const validUoms = UomTypeHelper.GetUomForUtilityTypeId(utilityControl.value);
      if (validUoms.find((u) => u.id === uomControl.value)) {
        clearErrorOnControl(uomControl, 'invalidUom');
        return null;
      } else if (uomControl.value) {
        setErrorOnControl(
          uomControl,
          'invalidUom',
          `${UomTypes[uomControl.value].name} is an invalid Unit of Measure for ${UtilityTypes[utilityControl.value].name} meters.`,
        );
        return {
          invalidUom: `${UomTypes[uomControl.value].name} is an invalid Unit of Measure for ${UtilityTypes[utilityControl.value].name} meters.`,
        };
      } else {
        clearErrorOnControl(uomControl, 'invalidUom');
        return null;
      }
    }

    return null;
  };
}
