import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { DirectivesModule } from '../../directives/directives.module';
import { DirectConnectInstructionsModule } from '../direct-connect-instructions/direct-connect-instructions.module';
import { ModalHeaderModule } from '../modal-header/modal-header.module';
import { PageFooterModule } from '../page-footer/page-footer.module';
import { SerialNumberInputComponent } from './serial-number-input.component';
import { TestConnectModalComponent } from './test-connect-modal/test-connect-modal.component';

@NgModule({
  declarations: [
    SerialNumberInputComponent,
    TestConnectModalComponent,
  ],
  imports: [
    IonicModule,
    DirectivesModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    ModalHeaderModule,
    DirectConnectInstructionsModule,
    PageFooterModule,
  ],
  exports: [
    SerialNumberInputComponent,
  ],
  entryComponents: [
    SerialNumberInputComponent,
    TestConnectModalComponent,
  ],
})
export class SerialNumberInputModule { }
