import { Injectable } from '@angular/core';
import { DeviceFeedback } from '@ionic-native/device-feedback/ngx';
import { TapticEngine } from '@ionic-native/taptic-engine/ngx';
import { Platform } from '@ionic/angular';

import { AppSettingsService } from './app-settings.service';

export enum FeedbackType {
  BLE_CONNECT,
  BLE_DISCONNECT,
  TOGGLE_PARTY_MODE,
  DOUBLE_TAP,
  REFRESH,
}

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {

  constructor(
    private appSettingsService: AppSettingsService,
    private platform: Platform,
    private tapticEngine: TapticEngine, // For iOs
    private deviceFeedback: DeviceFeedback, // For Android
  ) { }

  public HapticFeedback(type: FeedbackType) {
    if (this.appSettingsService.isBrowser) {
      return;
    } else if (this.platform.is('android')) {
      this.androidFeedback(type);
    } else { // iOS
      this.iOsFeedback(type);
    }
  }

  private androidFeedback(type: FeedbackType) {
    switch (type) {
      case FeedbackType.BLE_CONNECT:
      case FeedbackType.TOGGLE_PARTY_MODE:
      case FeedbackType.DOUBLE_TAP:
      case FeedbackType.REFRESH:
        this.deviceFeedback.haptic(0);
        break;
      case FeedbackType.BLE_DISCONNECT:
        this.deviceFeedback.haptic(3);
        break;
    }
  }

  private iOsFeedback(type: FeedbackType) {
    switch (type) {
      case FeedbackType.BLE_CONNECT:
      case FeedbackType.TOGGLE_PARTY_MODE:
        this.tapticEngine.notification({ type: 'success' });
        break;
      case FeedbackType.BLE_DISCONNECT:
        this.tapticEngine.notification({ type: 'error' });
        break;
      case FeedbackType.DOUBLE_TAP:
      case FeedbackType.REFRESH:
        this.tapticEngine.notification({type: 'warning'});
        break;
    }
  }
}
