import { Message } from '@ncss/message';
import { BitwiseHelper } from '@ncss/models';

import { ByteList } from 'byte-list';

import { DC301Flags } from './dc301Flags';

export enum DC301DeviceMessageType {
  GROUP_MASK = 0x1200,
  DC_SYS_EVENT = 0x1200,
  DC_PWR_EVENT = 0x1201,
  DC_USER_EVENT = 0x1202,
  DC_RESPONSE = 0x1203,
}

export enum BatteryChargeStatusType {
  UNKNOWN = 0,
  BATTERY_MISSING = 1,
  BATTERY_POWERED = 2,
  BATTERY_CHARGE_TRICKLE = 3,
  BATTERY_CHARGING_CC = 4,
  BATTERY_CHARGING_CV = 5,
  BATTERY_CHARGED = 6,
  BATTERY_CHARGE_SUSPEND = 7,
}

export class DC301DeviceMessage extends Message {

  public serialNumber: number;
  public hardwareVersion: number;
  public firmwareVersionMajor: number;
  public firmwareVersionMinor: number;
  public bleFirmwareVersionMajor: number;
  public bleFirmwareVersionMinor: number;
  public rfFirmwareBufferedVersionMajor: number;
  public rfFirmwareBufferedVersionMinor: number;
  public rfFirmwareBufferedVersionTransferId: number;
  public rfFirmwareBufferedVersionBlockNumber: number;
  public deviceName: string;
  public resetCount: number;
  public bleResetCount: number;
  public temperature: number;
  public batteryPercent: number;
  public batteryTemp: number;
  public batteryChargeStatus: BatteryChargeStatusType;
  public rawFlags: number;

  public get hardwareVersionMajor() { return BitwiseHelper.GetBits(this.hardwareVersion, 0, 3); }
  public get hardwareVersionMinor() { return BitwiseHelper.GetBits(this.hardwareVersion, 3, 5); }

  public get flags() {
    if (!this._flags) {
      this._flags = new DC301Flags(this.rawFlags);
    } else {
      this._flags.value = this.rawFlags;
    }
    return this._flags;
  }

  private _flags: DC301Flags;

  public serialize(): ByteList {
    const bytes = super.serialize();
    bytes.writeUInt32(this.serialNumber);
    bytes.writeByte(this.hardwareVersion);
    bytes.writeByte(this.firmwareVersionMajor);
    bytes.writeByte(this.firmwareVersionMinor);
    bytes.writeByte(this.bleFirmwareVersionMajor);
    bytes.writeByte(this.bleFirmwareVersionMinor);
    bytes.writeByte(this.rfFirmwareBufferedVersionMajor);
    bytes.writeByte(this.rfFirmwareBufferedVersionMinor);
    bytes.writeByte(this.rfFirmwareBufferedVersionTransferId);
    bytes.writeUInt16(this.rfFirmwareBufferedVersionBlockNumber);
    bytes.writeString(this.deviceName, { length: 24 });
    bytes.writeByte(this.resetCount);
    bytes.writeByte(this.bleResetCount);
    bytes.writeByte(this.temperature);
    bytes.writeByte(this.batteryPercent);
    bytes.writeByte(this.batteryTemp);
    bytes.writeByte(this.batteryChargeStatus);
    bytes.writeUInt32(this.rawFlags);
    return bytes;
  }

  public deserialize(bytes: ByteList) {
    super.deserialize(bytes);
    this.serialNumber = bytes.readUInt32();
    this.hardwareVersion = bytes.readByte();
    this.firmwareVersionMajor = bytes.readByte();
    this.firmwareVersionMinor = bytes.readByte();
    this.bleFirmwareVersionMajor = bytes.readByte();
    this.bleFirmwareVersionMinor = bytes.readByte();
    this.rfFirmwareBufferedVersionMajor = bytes.readByte();
    this.rfFirmwareBufferedVersionMinor = bytes.readByte();
    this.rfFirmwareBufferedVersionTransferId = bytes.readByte();
    this.rfFirmwareBufferedVersionBlockNumber = bytes.readUInt16();
    this.deviceName = bytes.readString({ length: 24 });
    this.resetCount = bytes.readByte();
    this.bleResetCount = bytes.readByte();
    this.temperature = bytes.readInt8();
    this.batteryPercent = bytes.readByte();
    this.batteryTemp = bytes.readInt8();
    this.batteryChargeStatus = bytes.readByte();
    this.rawFlags = bytes.readUInt32();
  }


}
