import { BitwiseHelper } from '@ncss/models';

import { ByteList } from 'byte-list';

import { Ack } from './ack';

const MAX_ITEMS_IN_NETWORK_LIST = 100;

export enum WifiSignalStrength {
  POOR = 1,
  FAIR = 2,
  EXCELLENT = 3,
}

export interface WifiNetwork {
  name: string;
  signal: WifiSignalStrength;
  secure: boolean;
}

export const AvailableNetworksType = 0x05;

export class AvailableNetworks extends Ack {

  type = AvailableNetworksType;
  networks: WifiNetwork[] = [];

  constructor(requestId: number, networks: WifiNetwork[]) {
    super(requestId);
    this.networks = networks;
  }

  serialize(): ByteList {
    const bytes = super.serialize();
    this.shortenNetworksToMaxSize();
    bytes.writeByte(this.networks.length);
    for (const n of this.networks) {
      let flags = BitwiseHelper.SetBits(0, 0, 2, n.signal);
      flags = BitwiseHelper.SetBits(flags, 2, 1, n.secure ? 1 : 0);
      bytes.writeByte(flags);
      bytes.writeString(n.name);
    }
    return bytes;
  }

  deserialize(bytes: ByteList) {
    super.deserialize(bytes);
    this.networks = [];
    const length = bytes.readByte();
    for (let i = 0; i < length; i++) {
      const flags = bytes.readByte();
      const name = bytes.readString();
      this.networks.push({ signal: BitwiseHelper.GetBits(flags, 0, 2), name, secure: ByteList.GetBit(flags, 2) });
    }
  }

  private shortenNetworksToMaxSize() {
    if (this.networks.length > MAX_ITEMS_IN_NETWORK_LIST) {
      this.networks.splice(MAX_ITEMS_IN_NETWORK_LIST, this.networks.length - MAX_ITEMS_IN_NETWORK_LIST);
    }
  }

}
