import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { DirectConnectPopoverComponent } from './direct-connect-popover.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
  ],
  entryComponents: [
    DirectConnectPopoverComponent,
  ],
  exports: [
    DirectConnectPopoverComponent,
  ],
  declarations: [
    DirectConnectPopoverComponent,
  ],
  providers: [],
})
export class DirectConnectPopoverModule { }
