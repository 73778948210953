import { Unit, UtilityTypeIds } from '@ncss/models';

import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Validators } from '@angular/forms';
import { ModalController, LoadingController, AlertController, PopoverController } from '@ionic/angular';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { LeakSensorFormGroup } from '../../../../angularUtilities/leak-sensor-form-array';
import { MeterFormGroup } from '../../../../angularUtilities/meter-form';
import { UnitForm } from '../../../../angularUtilities/unit-form';
import { CustomInputPopoverComponent, CustomInputPopoverType } from '../../../../components/custom-input-popover/custom-input-popover.component';
import { IRefreshEvent } from '../../../../components/refresher/refresher.component';
import { UnitNotesComponent } from '../../../../components/unit-notes/unit-notes.component';
import { CameraService } from '../../../../services/camera.service';
import { DocumentUploadService } from '../../../../services/document-upload.service';
import { MobileMeterManufacturerService } from '../../../../services/mobile-meter-manufacturer.service';
import { MobileMeterTypeService } from '../../../../services/mobile-meter-type.service';
import { MobilePropertyService } from '../../../../services/mobile-property.service';
import { MobileUnitService } from '../../../../services/mobile-unit.service';
import { MobileUserService } from '../../../../services/mobile-user.service';

@Component({
  selector: 'app-unit-details',
  templateUrl: './unit-details.component.html',
  styleUrls: ['./unit-details.component.scss'],
  animations: [
    trigger('fadeItem', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('350ms ease-out', style({ opacity: '1' })),
      ]),
    ]),
  ],
})
export class UnitDetailsComponent implements OnInit, OnDestroy {
  @Input() unit: Unit;
  @Input() unitIds: string[];
  @Input() startingTab: UtilityTypeIds | 'leakSensors';

  form: UnitForm;
  selectedTab: MeterFormGroup | 'leakSensors';
  refreshing = true;

  private hasSaved = false;
  private destroyed = new Subject();

  constructor(
    private propertyService: MobilePropertyService,
    private unitService: MobileUnitService,
    private meterTypeService: MobileMeterTypeService,
    private meterManufacturerService: MobileMeterManufacturerService,
    private modalCtl: ModalController,
    private loadCtl: LoadingController,
    private alertCtl: AlertController,
    private userService: MobileUserService,
    private cameraService: CameraService,
    private popCtl: PopoverController,
    private uploadService: DocumentUploadService,
  ) { }

  async ngOnInit(): Promise<void> {
    await this.createForm();
    this.viewTab(this.startingTab);
    this.refreshing = false;
    this.uploadService.uploadFinished$.pipe(takeUntil(this.destroyed)).subscribe((u) => {
      if (u && u.id.includes(this.unit._id)) {
        this.refresh();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyed.next();
  }

  async refresh(e?: IRefreshEvent) {
    this.refreshing = true;
    this.unit = await this.unitService.findFullUnitById(this.unit._id).toPromise();
    await this.createForm();
    let currentTab;
    if (this.selectedTab instanceof MeterFormGroup) {
      if (this.unit.meters.find((m) => m.utilityTypeId === currentTab)) {
        currentTab = this.selectedTab.controls.utilityTypeId.value;
      }
    } else if (this.selectedTab === 'leakSensors' && this.form.controls.leakSensors.length) {
      currentTab = this.selectedTab;
    }
    this.viewTab(currentTab || null);
    this.refreshing = false;
    if (e) {
      e.doneRefreshing();
    }
  }

  headerClick(e, opts?: { alignReads?: boolean; }) {
    if (e === 'Close') {
      this.close();
    } else if (e === 'Save') {
      this.save(opts);
    } else if (e === 'Delete') {
      this.deleteUnit();
    }
  }

  pagerClick(e) {
    this.unit._id = e;
    this.refresh();
  }

  async notesClicked() {
    const m = await this.modalCtl.create({
      component: UnitNotesComponent,
      componentProps: {
        unitId: this.unit._id,
      },
      animated: true,
    });
    await m.present();
    await m.onDidDismiss();
  }

  async deleteUnit() {
    if (this.unit && this.unit._id) {
      const confirm = await this.alertCtl.create({
        header: `Delete ${this.unit.name}`,
        message: `Are you sure you want to permanently delete this unit? This will also delete all equipment installed on this unit.`,
        buttons: [{ text: 'Cancel', role: 'cancel' }, { text: 'Yes, I\'m sure', role: 'confirm' }],
      });
      await confirm.present();
      const res = await confirm.onDidDismiss();
      if (res.role === 'confirm') {
        await this.unitService.delete(this.unit._id, this.unit.property.id);
        this.modalCtl.dismiss({ reload: true });
      }
    }
  }

  async addNewMeter(utilityTypeId: UtilityTypeIds) {
    if (utilityTypeId) {
      await this.form.controls.meters.addNewMeter(utilityTypeId);
      this.viewTab(utilityTypeId);
    }
  }

  async addNewLeakSensor() {
    const p = await this.popCtl.create({
      component: CustomInputPopoverComponent,
      animated: true,
      backdropDismiss: true,
      cssClass: 'custom-popover',
      componentProps: {
        type: CustomInputPopoverType.TEXT_INPUT_POPOVER,
        header: 'Leak Sensor Location',
        placeholder: 'Enter Location',
        syncValidators: [Validators.required],
        submitLabel: 'Add Leak Sensor',
      },
    });
    await p.present();
    const res = await p.onDidDismiss();
    if (res.data && res.data.value) {
      this.form.controls.leakSensors.addNewLeakSensor(res.data.value);
      this.viewTab('leakSensors');
    }
  }

  async deleteMeter(utilityTypeId: UtilityTypeIds) {
    this.form.controls.meters.removeMeter(utilityTypeId);
    await this.save();
  }

  async deleteLeakSensor(location: string) {
    this.form.controls.leakSensors.removeLeakSensor(location);
    await this.save();
  }

  viewTab(tab: UtilityTypeIds | 'leakSensors') {
    let newTab: MeterFormGroup | 'leakSensors' = this.form.controls.meters.length ?
      this.form.controls.meters.controls[0] :
      'leakSensors';
    if (tab === 'leakSensors' && this.form.controls.leakSensors.length) {
      newTab = 'leakSensors';
    } else if (tab) {
      const found = this.form.controls.meters.controls.find((m) => m.controls.utilityTypeId.value === tab);
      newTab = found || newTab;
    }

    this.selectedTab = newTab;
  }

  private async save(opts?: { alignReads?: boolean }) {
    if (this.form.valid) {
      const l = await this.loadCtl.create({
        message: 'Saving Unit...',
      });
      await l.present();
      if (this.form.controls.building.dirty || this.form.controls.name.dirty) {
        await this.unitService.update(this.unit._id, {
          name: this.form.value.name,
          building: this.form.value.building,
        }).toPromise();
      }
      if (this.form.controls.meters.dirty) {
        l.message = 'Saving meters...';
        const meters = this.form.controls.meters.controls.map((m) => m.toMeter());
        await this.unitService.setMeters(this.unit._id, meters).toPromise();
      }
      if (opts && opts.alignReads) {
        l.message = 'Transferring Usage History...';
        await this.unitService.alignUnitReads(this.unit._id).toPromise();
      }
      if (this.form.controls.leakSensors.dirty) {
        l.message = 'Saving leak sensors...';
        const leakSensors = this.form.controls.leakSensors.controls.map((leakSensor) => leakSensor.toLeakSensor());
        await this.unitService.setLeakSensors(this.unit._id, leakSensors).toPromise();
      }
      await this.startImageUploads(this.form.controls.meters.controls, this.form.controls.leakSensors.controls);
      await l.dismiss();
      this.hasSaved = true;
      await this.refresh();
    }
  }

  private async startImageUploads(meterForms: MeterFormGroup[], leakSensorForms: LeakSensorFormGroup[]) {
    for (const meterForm of meterForms) {
      const dirty = meterForm.controls.imrPhoto && meterForm.controls.imrPhoto.dirty;
      const imgExists = meterForm.controls.imrPhoto.value && meterForm.controls.imrPhoto.value.base64Img;
      if (dirty && imgExists) {
        const file = this.cameraService.getFileFromDataURI(meterForm.value.imrPhoto.base64Img, `imr_${moment().format('YYYYMMDDhhmmss')}`);
        this.unitService.uploadIMRDoc(this.unit.property.id, this.unit._id, {
          file,
          meter: meterForm.toMeter(),
          imr: meterForm.value.imr,
          utilityTypeId: meterForm.value.utilityTypeId,
        });
      }
    }

    for (const leakSensorForm of leakSensorForms) {
      const dirty = leakSensorForm.controls.photo && leakSensorForm.controls.photo.dirty;
      const imgExists = leakSensorForm.controls.photo && leakSensorForm.controls.photo.value &&
        leakSensorForm.controls.photo.value.base64Img;
      if (dirty && imgExists) {
        const file = this.cameraService.getFileFromDataURI(leakSensorForm.value.photo.base64Img, `${leakSensorForm.value.location}_${moment().format('YYYYMMDDhhmmss')}`);
        this.unitService.uploadLeakSensorPhoto(this.unit.property.id, this.unit._id, leakSensorForm.toLeakSensor(), file);
      }
    }

  }

  private close() {
    this.modalCtl.dismiss({ reload: this.hasSaved });
  }

  private async createForm() {
    const res = await Promise.all([
      UnitForm.Create(
        this.propertyService,
        this.meterTypeService,
        this.meterManufacturerService,
        this.unitService,
        this.userService,
        this.cameraService,
        this.modalCtl,
        this.unit,
      ),
      new Promise<void>((resolve) => setTimeout(() => resolve(), 2500)),
    ]);
    this.form = res[0];
  }

}
