import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ModalController, LoadingController, AlertController, IonToggle } from '@ionic/angular';
import { createAnimation } from '@ionic/core';
import { Subject, from, Observable, of } from 'rxjs';
import { bufferTime, filter, withLatestFrom, concatMap, delay, repeat, take, takeUntil } from 'rxjs/operators';

import { IDirectConnectDevice } from './../../../services/direct-connect/baseDirectConnectDevice';
import { DirectConnectRE4 } from './../../../services/direct-connect/RE4/RE4';
import { FeedbackService, FeedbackType } from './../../../services/feedback.service';
import { MobileUserService } from './../../../services/mobile-user.service';
import { ToastService } from './../../../services/toast.service';

@Component({
  selector: 'app-re4-modal',
  templateUrl: './re4-modal.component.html',
  styleUrls: ['./re4-modal.component.scss'],
})
export class Re4ModalComponent implements OnInit {

  @ViewChild('rapidCheckInToggle') public rapidCheckInToggle: IonToggle;

  @Input() public connectedDevice: IDirectConnectDevice;

  public re4: DirectConnectRE4;
  public showDetails = false;
  public partyBtnClicked = new Subject();
  public partyBtnDoubleClicked = this.partyBtnClicked.pipe(
    bufferTime(300),
    filter((taps) => taps.length === 2),
  );

  public leftLEDPartyColors$: Observable<string> =
    from(['green', 'off', 'red', 'off', 'blue', 'off', 'yellow', 'off', 'red', 'off', 'purple', 'off']).pipe(
      concatMap((color) => of(color).pipe(delay(500))),
      repeat(),
    );
  public rightLEDPartyColors$: Observable<string> =
    from(['off', 'purple', 'off', 'green', 'off', 'yellow', 'off', 'blue', 'off', 'green', 'off', 'red']).pipe(
      concatMap((color) => of(color).pipe(delay(300))),
      repeat(),
    );

  public isRFDebugging = false;

  private _subscriptions = [];

  constructor(
    private modalCtrl: ModalController,
    private loadCtrl: LoadingController,
    private alertCtrl: AlertController,
    private toast: ToastService,
    private feedback: FeedbackService,
    public userService: MobileUserService,
  ) { }

  ngOnInit() {
    if (!this.connectedDevice) { return; }
    this.re4 = this.connectedDevice.device as DirectConnectRE4;
    this.initialize();
  }

  private initialize() {
    this._subscriptions.forEach((s) => s.unsubscribe());
    this._subscriptions = [];
    if (!this.re4) { return; }
    this._subscriptions.push(
      this.re4.rapidCheckInEnabled$.subscribe((val) => {
        if (this.rapidCheckInToggle) {
          this.rapidCheckInToggle.checked = val || false;
        }
      }),
      this.partyBtnDoubleClicked.pipe(
        withLatestFrom(this.re4.partying$),
      ).subscribe(([doubleClicked, isPartying]) => {
        if (isPartying) {
          this.re4.stopTheParty();
        } else {
          this.re4.startTheParty();
        }
        this.animatePartyBtn();
        this.feedback.HapticFeedback(FeedbackType.TOGGLE_PARTY_MODE);
      }),
    );
  }

  public turnOnRfDebug() {
    this.re4.listenToDebugMessages();
    this.isRFDebugging = true;
  }

  private animatePartyBtn(): void {
    const el = document.getElementById('partyBtn');
    if (!el) { return; }
    const a = createAnimation()
      .addElement(el)
      .duration(1000)
      .beforeStyles({ 'transform': 'scale(1)' })
      .fromTo('opacity', '.5', '0')
      .fromTo('transform', 'scale(1)', 'scale(3)')
      .fromTo('background', '#1bd3ea', '#e45898')
      .easing('ease-out')
      .afterStyles({ 'transform': 'scale(1)' });
    a.play();
  }

  public async headerClicked(event: any): Promise<void> {
    if (event === 'Save') {
      if (this.re4.isDisconnected && this.re4.attemptReconnect) {
        const res = await this.re4.attemptReconnect();
        if (res) {
          await this.onReconnected(res);
        }
      } else {
        await this.save();
      }
    } else {
      if (this.isRFDebugging && this.re4.debugMsgs.length) {
        try {
          const l = await this.loadCtrl.create({message: 'Sending RF Debug email...'});
          await l.present();
          await this.userService.sendRFDebugReport(this.re4.debugMsgs, this.re4.serialNumberStr).toPromise();
          await l.dismiss();
          this.toast.queueToast('RF Debug Report sent!');
        } catch (e) {
          this.toast.queueToast('RF Debug Report failed to send');
        }
        this.modalCtrl.dismiss();
      } else {
        this.modalCtrl.dismiss();
      }
    }
  }

  public enableRapidCheckInClicked(value: boolean) {
    this.re4.updateRapidCheckInEnabled(value);
  }

  public async dropRoutingTableClicked() {
    const a = await this.alertCtrl.create({
      header: 'Are you sure?',
      message: `Are you sure you want to drop this Repeater\'s routing table?`,
      buttons: ['Ok', 'Cancel'],
      backdropDismiss: false,
    });
    await a.present();
    const res = await a.onDidDismiss();
    if (res.role !== 'cancel') {
      await this.dropRoutingTable();
    }
  }

  private async dropRoutingTable() {
    const l = await this.loadCtrl.create({
      message: 'Requesting drop...',
    });
    await l.present();
    const res = await this.re4.dropRouteTable();
    await l.dismiss();
    this.toast.queueToast(res ? 'Request to drop table received!' : 'Failed to send request');
  }

  public async sortRoutingTableClicked() {
    const a = await this.alertCtrl.create({
      header: 'Sort Network Table?',
      message: 'This can take a minute',
      buttons: ['Ok', 'Cancel'],
      backdropDismiss: false,
    });
    await a.present();
    const res = await a.onDidDismiss();
    if (res.role !== 'cancel') {
      await this.sortRoutingTable();
    }
  }

  private async sortRoutingTable() {
    const l = await this.loadCtrl.create({
      message: 'Requesting sort...',
    });
    await l.present();
    const res = await this.re4.sortRouteTable();
    await l.dismiss();
    this.toast.queueToast(res ? 'Request to sort table received!' : 'Failed to send request');
  }

  public async pullRoutingTableClicked() {
    if (!this.userService.user || !this.userService.user._id) {
      this.toast.queueToast('Unable to pull routing table at this time. (invalid user session)');
      return;
    }
    const a = await this.alertCtrl.create({
      header: 'Pull RE4 Routing Table',
      message: `A report of this Repeater\'s routing table will be sent to ${this.userService.user._id}`,
      buttons: ['Ok', 'Cancel'],
      backdropDismiss: false,
    });
    await a.present();
    const res = await a.onDidDismiss();
    if (res.role !== 'cancel') {
      this.pullRoutingTable();
    }
  }

  private async pullRoutingTable() {
    const l = await this.loadCtrl.create({
      message: 'Pulling network table...',
      backdropDismiss: true,
    });
    await l.present();
    try {
      this.re4.requestRouteInfo();
      let canceled = false;
      const countSubscription = this.re4.routeRequestPage.subscribe((val) => {
        if (l) {
          l.message = `Page ${val}... ${this.re4.routeTableCurrentlySorting ? ' \n Sorting...' : ''}`;
        }
      });
      const stopWaiting$ = new Subject();
      l.onWillDismiss().then((detail) => {
        if (detail.role === 'backdrop') {
          stopWaiting$.next();
          canceled = true;
          this.re4.routingTableRequestCanceled = true;
          this.toast.queueToast('Cancelled request for network table');
        }
      });
      const table = await this.re4.routingTable.pipe(take(1), takeUntil(stopWaiting$)).toPromise();
      countSubscription.unsubscribe();
      if (!canceled) {
        l.message = `Sending table to ${this.userService.user._id}...`;
        await this.userService.sendNetworkRoutingTable(table, this.re4.serialNumberStr).toPromise();
        await l.dismiss();
        this.toast.queueToast('Network table sent!');
      }
    } catch (e) {
      await l.dismiss();
      const a = await this.alertCtrl.create({
        header: 'Uh-oh!',
        message: 'Failed to pull the device\'s network table',
        buttons: ['Ok'],
      });
      await a.present();
    }
  }

  private async onReconnected(device: IDirectConnectDevice): Promise<void> {
    const pendingChanges = this.re4.userChanges;
    this.connectedDevice = device;
    this.re4 = device.device;
    this.initialize();
    this.re4.userChanges$.next(pendingChanges);
    const success = await this.re4.requestInfo();
    if (success) {
      await this.save();
    }
  }

  private async save(): Promise<void> {
    const l = await this.loadCtrl.create({ message: 'Saving...' });
    const success = await this.re4.applyChanges();
    await l.dismiss();
    if (!success) {
      const a = await this.alertCtrl.create({
        message: 'Unable to save changes to RE4 at this time.',
        header: 'Uh-oh!',
        buttons: ['Ok'],
      });
      await a.present();
    } else {
      this.toast.queueToast('Configuration saved');
      this.feedback.HapticFeedback(FeedbackType.BLE_CONNECT);
      this.re4.dropUserChanges();
    }
  }

}
