import { Message } from '@ncss/message';
import { RE4Info } from '@ncss/models';

import { ByteList } from 'byte-list';

export enum RE4BLEInfoMessageType {
  GROUP_MASK = 0x2050,
  INFO_MSG = 0x2051,
  REQUEST_INFO = 0x2052,
}

export class RE4BLEInfoMessage extends Message {
  public re4Info: RE4Info;

  constructor(serialNumber: number, type?: number) {
    super();
    this.type = type || RE4BLEInfoMessageType.REQUEST_INFO;
    this.re4Info = new RE4Info(serialNumber);
  }

  public serialize(): ByteList {
    const bytes = super.serialize();
    this.re4Info.serialize(bytes);
    return bytes;
  }

  public deserialize(bytes: ByteList): void {
    super.deserialize(bytes);
    this.re4Info = new RE4Info(0);
    this.re4Info.deserialize(bytes);
  }

}
