import { Property, User } from '@ncss/models';

import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import * as _ from 'lodash';

import { AppSettingsService } from '../../services/app-settings.service';
import { MobilePropertyService } from './../../services/mobile-property.service';


const KEY = 'property-names';

@Component({
  selector: 'app-selector-modal',
  templateUrl: './selector-modal.component.html',
  styleUrls: ['./selector-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectorModalComponent implements OnInit {

  public properties: {_id: string, name: string, gatewayId: number}[];
  public filteredProperties: {_id: string, name: string, gatewayId: number}[];
  public loadingProperties = false;
  public loadSkeletons = new Array(15).fill(1);

  private user: User;

  constructor(
    public appSettings: AppSettingsService,
    private modalCtrl: ModalController,
    private navParams: NavParams,
    private cd: ChangeDetectorRef,
    private mobilePropertyService: MobilePropertyService,
  ) { }

  ngOnInit() {
    this.user = this.navParams.get('user') || null;
    this.fetchProperties();
  }

  public fetchProperties() {
    this.loadingProperties = true;
    this.cd.markForCheck();
    this.retrieveCachedProperties();
    this.mobilePropertyService.getPropertyListNames()
    .subscribe((list: Array<{_id: string, name: string, gatewayId: number}>) => {
      this.properties = list && list.length ? list : [];
      this.filteredProperties = this.properties;
      this.loadingProperties = false;
      this.cd.markForCheck();
      this.cacheProperties(this.properties);
    }, (err) => {
      this.properties = this.properties || [];
      this.loadingProperties = false;
      this.cd.markForCheck();
    });
  }

  public close(item: any) {
    this.modalCtrl.dismiss(item);
  }

  public trackBy(index, property) {
    return property ? property._id : undefined;
  }

  public itemHeights(item, index) {
    return 50; // base tile height + top margin + bottom margin
  }

  search($event) {
    if (!$event || !$event.detail) {
      return;
    }

    if ($event.detail.value === '') {
      this.filteredProperties = this.properties;
      return;
    }

    this.filteredProperties = _.filter(this.properties, (property: Property) => {
      return _.includes(property.name.toLowerCase(), $event.detail.value.toLowerCase());
    }) as Array<{name: string, _id: string, gatewayId: number}>;
  }

  private cacheProperties(properties: Array<{_id: string, name: string, gatewayId: number}>) {
    if (!this.user || !this.user._id) { return; }
    try {
      window.localStorage.setItem(`${this.user._id}${KEY}`, JSON.stringify(properties));
    } catch (e) {
      console.log('couldn\'t cache properties');
    }
  }

  private retrieveCachedProperties() {
    if (!this.user || !this.user._id) { return; }
    let props: Array<{_id: string, name: string, gatewayId: number}> = [];
    try {
      props = JSON.parse(window.localStorage.getItem(`${this.user._id}${KEY}`));
    } catch (e) {
      console.log('couldn\'t retrieve properties');
    }

    if (props && props.length) {
      this.properties = props;
      this.filteredProperties = this.properties;
      this.loadingProperties = false;
      this.cd.markForCheck();
    }
  }

}
