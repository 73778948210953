import { Injectable } from '@angular/core';
import { Events } from '@ionic/angular';
import { Observable, Subject } from 'rxjs';

import { SerialKeyboardEventType } from '../components/serial-number-input/serial-keyboard/serial-keyboard.component';

@Injectable({
  providedIn: 'root',
})
export class SerialKeyboardService {
  private inputSubject = new Subject<string>();
  public input$: Observable<{}> = this.inputSubject;
  private _input = '';
  private _maxLength = 11;
  private subscriptions = [];

  constructor(
    private events: Events,
    ) {

      this.subscriptions.push(this.events.subscribe(SerialKeyboardEventType.CMD_KEY, (cmd: string) => {
        if (cmd === 'Backspace') {
          this._input = this._input ? this._input.substring(0, this._input.length - 1) : '';
          this.inputSubject.next(this._input);
        } else if (cmd === 'Close') {
          this.events.publish(SerialKeyboardEventType.HIDE);
        }
      }));

      this.subscriptions.push(this.events.subscribe(SerialKeyboardEventType.VALUE_KEY, (c: string) => {
        if (!this._input) {
          this._input = c;
        } else if (this._input.length < this._maxLength) {
          this._input += c;
        }
        this.inputSubject.next(this._input);
      }));
    }

  public showEquipmentKeyboard(startingInput: string = '', maxLength: number = 11) {
    this._input = startingInput;
    this._maxLength = maxLength;
    this.events.publish(SerialKeyboardEventType.SHOW, {contextType: 'equipment'});
  }

  public showNetworkKeyboard(startingInput: string = '', maxLength: number = 11) {
    this._input = startingInput;
    this._maxLength = maxLength;
    this.events.publish(SerialKeyboardEventType.SHOW, {contextType: 'network'});
  }

  public hideKeyboard() {
    this.events.publish(SerialKeyboardEventType.HIDE);
  }
}
