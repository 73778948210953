import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NavParams, ModalController, LoadingController, ToastController } from '@ionic/angular';
import { take, filter } from 'rxjs/operators';

import { PhoneValidator } from '../../directives/phone.validator';
import { ICameraResult, CameraResultStatus, CameraService } from './../../services/camera.service';
import { MobileUserService } from './../../services/mobile-user.service';

@Component({
  selector: 'app-update-account',
  templateUrl: './update-account.component.html',
  styleUrls: ['./update-account.component.scss'],
})
export class UpdateAccountComponent implements OnInit {

  public form: FormGroup;
  public billingCompanyOptions: Array<{ _id: string, name: string }> = [];
  public profilePicUrl;
  public initials: string;

  private selectedCompany: { _id: string, name: string };

  constructor(
    private fb: FormBuilder,
    private navParams: NavParams,
    private modalCtrl: ModalController,
    private cameraService: CameraService,
    private loadingCtrl: LoadingController,
    private toastCtrl: ToastController,
    private mobileUserService: MobileUserService,
  ) { }


  ngOnInit() {
    this.billingCompanyOptions = this.navParams.get('billingCompanies') || [];
    this.profilePicUrl = this.navParams.get('profilePic') || null;
    this.initials = this.navParams.get('initials') || '';
    this.selectedCompany = this.navParams.get('billingCompany') || null;
    this.form = this.fb.group({
      firstname: [this.navParams.get('firstname') || null, Validators.required],
      lastname: [this.navParams.get('lastname') || null, Validators.required],
      phone: [this.navParams.get('phone') || null, [Validators.required, PhoneValidator.Validate]],
    });

    if (this.billingCompanyOptions && this.billingCompanyOptions.length) {
      const billingCompanyControl = new FormControl(this.selectedCompany ? this.selectedCompany.name : null, [Validators.required]);
      this.form.addControl('billingCompany', billingCompanyControl);
    }

    this.form.get('firstname').valueChanges.subscribe((firstname) => {
      this.updateInitials(firstname, this.form.get('lastname').value);
    });
    this.form.get('lastname').valueChanges.subscribe((lastname) => {
      this.updateInitials(this.form.get('firstname').value, lastname);
    });
  }

  public headerClicked(event) {
    if (event === 'Close') {
      this.modalCtrl.dismiss({}, 'close');
    } else if (event === 'Save') {
      this.submit();
    }
  }

  public onProfilePicture() {
    const deleteText = this.profilePicUrl ? 'Delete Profile Picture' : undefined;
    this.cameraService.getPicture(deleteText)
      .then(async (imgResult: ICameraResult) => {
        const load = await this.loadingCtrl.create({
          message: 'Processing photo...',
        });

        if (imgResult.status === CameraResultStatus.CAMERA_PICTURE || imgResult.status === CameraResultStatus.UPLOADED_PICTURE) {
          load.present();
          this.uploadPicture(imgResult.base64Img)
            .then(() => {
              load.dismiss();
            }, () => { load.dismiss(); });
        } else if (imgResult.status === CameraResultStatus.DELETE) {
          load.present();
          this.removeProfileImg()
            .then(() => {
              load.dismiss();
            }, () => { load.dismiss(); });
        } else {
          load.dismiss();
        }
      });
  }

  private submit() {
    if (this.form.valid) {
      const company = this.billingCompanyOptions.find((c) => c.name === this.form.get('billingCompany').value);
      this.modalCtrl.dismiss({ ...this.form.value, billingCompany: company }, 'submit');
    }
  }

  private updateInitials(first: string, last: string) {
    this.initials = `${first ? first.charAt(0) : ''}${last ? last.charAt(0) : ''}`.toUpperCase();
  }

  private async uploadPicture(base64Img: string): Promise<any> {
    return this.mobileUserService.saveProfilePic(base64Img) // Upload Img
      .then(() => {
        this.mobileUserService.user$.pipe(
          filter((u) => u.profilePicUrl && u.profilePicUrl !== this.profilePicUrl),
          take(1))
        .subscribe((u) => {
          this.profilePicUrl = u.profilePicUrl || this.profilePicUrl || null;
        });
        this.mobileUserService.fetchUser(); // Get updated user info
        this.profileToast('Profile picture updated', 250);
      }, () => {
        this.profileToast('Unable to upload picture', 250);
      });
  }

  private async removeProfileImg() {
    this.profilePicUrl = null;
    if (!this.mobileUserService.user) { return; }
    return this.mobileUserService.update(this.mobileUserService.user._id, { profilePicUrl: null })
      .then(() => {
        this.profilePicUrl = null;
        this.profileToast('Profile picture deleted', 250);
      }, () => {
        this.profileToast('Unable to Remove Profile Picture', 250);
      });
  }

  private profileToast(msg: string, delayMs?: number) {
    setTimeout(async () => {
      const toast = await this.toastCtrl.create({
        message: msg,
        color: 'dark',
        showCloseButton: true,
        duration: 2000,
      });
      toast.present();
    }, delayMs);
  }

}
