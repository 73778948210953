import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { ModalPagerComponent } from '../../components/modal-pager/modal-pager.component';
import { DirectivesModule } from '../../directives/directives.module';


@NgModule({
  declarations: [
      ModalPagerComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    DirectivesModule,
  ],
  providers: [
  ],
  exports: [
    ModalPagerComponent,
  ],
})
export class ModalPagerModule {}
