import { ConversionUtils, Property, PermissionUtils, SubscriptionType, User, Gateway, BillingCompany, GW3Read, GatewaySystemFlagsV0 } from '@ncss/models';

import { Component, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AlertController, LoadingController, ModalController, ToastController, IonToggle } from '@ionic/angular';
import { createAnimation } from '@ionic/core';
import { Animation } from '@ionic/core/dist/types/utils/animation/animation-interface';
import * as _ from 'lodash';
import { Observable, Subscription, combineLatest, BehaviorSubject, fromEvent } from 'rxjs';
import { first, map, filter, debounceTime, buffer, distinctUntilChanged } from 'rxjs/operators';

import { IDirectConnectDevice } from '../../../services/direct-connect/baseDirectConnectDevice';
import { DirectConnectGateway301, IEthernetInfo, IWlanInfo } from '../../../services/direct-connect/gateway301/gateway301';
import { IWifiAP } from '../../../services/direct-connect/gateway301/messages/networkMessage';
import { BillingCompanyService } from './../../../services/billing-company.service';
import { FeedbackService, FeedbackType } from './../../../services/feedback.service';
import { MobilePropertyService } from './../../../services/mobile-property.service';
import { MobileUserService } from './../../../services/mobile-user.service';


export enum Gateway301ModalTabs {
  STATUS,
  NETWORK,
  CONFIGURE,
}

@Component({
  selector: 'app-gateway301-modal',
  templateUrl: './gateway301-modal.component.html',
  styleUrls: ['./gateway301-modal.component.scss'],
})
export class Gateway301ModalComponent {

  public Gateway301ModalTabs = Gateway301ModalTabs;
  @ViewChild('cellularToggle') public cellularToggle: IonToggle;

  @Input() public connectedDevice: IDirectConnectDevice;
  public cellularSignalStrengthStr = 'Unknown';
  public ethForm: FormGroup;
  public wlanForm: FormGroup;
  public remoteServerForm: FormGroup;
  public directConnectGateway: DirectConnectGateway301;
  public serialNumber$: Observable<string>;
  public showRemoteServer = false;
  public selectedTab = Gateway301ModalTabs.STATUS;
  public tabs = [
    { name: 'Status', value: Gateway301ModalTabs.STATUS, icon: 'icon-gw301' },
    { name: 'Configure', value: Gateway301ModalTabs.NETWORK, icon: 'icon-settings' },
  ];
  public loading;
  public cellularChecked$ = new BehaviorSubject<boolean>(false);
  public togglingCellular$ = new BehaviorSubject<boolean>(false);
  public property: Property;
  public disableCellularToggle = true;

  private subscriptions = [];
  private ledAnimations: Animation[] = [];
  private _doubleClickSubscription: Subscription;
  private _cellularCost = '$20';


  constructor(
    private modalCtrl: ModalController,
    private loadingCtrl: LoadingController,
    private alertCtrl: AlertController,
    private toastCtrl: ToastController,
    private propertyService: MobilePropertyService,
    private fb: FormBuilder,
    private mobileUserService: MobileUserService,
    private billingCompanyService: BillingCompanyService,
    private feedbackService: FeedbackService,
  ) { }

  public async ionViewWillEnter() {
    this.loading = await this.loadingCtrl.create({ message: 'Loading Info' });
    this.loading.present();

    this.showRemoteServer = this.mobileUserService.user$.value.isSuperAdmin;

    this.initForm();
    this.directConnectGateway = this.connectedDevice.device;
    this.serialNumber$ = this.directConnectGateway.info$.pipe(map((info) => {
      return info ? ConversionUtils.ConvertSerialNumberToString(info.id) : '';
    }));

    let loadedInfo = false;
    setTimeout(() => {
      if (this.loading && !loadedInfo) {
        this.loading.dismiss();
        this.modalCtrl.dismiss({}, 'error');
      }
    }, 15 * 1000);
    this.directConnectGateway.info$.pipe(first((value) => !!value)).subscribe((info) => {
      loadedInfo = true;
      this.loading.dismiss();
      this.directConnectGateway.requestDHCPInfo();
      this.directConnectGateway.scanNetworks();
      this.propertyService.findPropertyByGatewayId(info.data.deviceId, true).subscribe((p) => {
        this.property = p;
      });
    });

    this.subscriptions.push(combineLatest([
      this.directConnectGateway.info$,
      this.propertyService.property$,
      this.mobileUserService.user$,
    ]).subscribe(([gateway, property, user]) => {
      this.setupCellular(gateway, property, user);
      this.property = property;
      if (gateway) {
        this.updateRemoteServerFormGroup(gateway.remoteHost, gateway.remotePort);
      }
    }));
    this.subscriptions.push(this.directConnectGateway.eth$.subscribe((eth) => {
      this.updateEthFormGroup(eth);
    }));
    this.subscriptions.push(this.directConnectGateway.wlan$.subscribe((wlan) => {
      this.updateWlanFormGroup(wlan);
    }));

    this.initDoubleClickStream();

    this.subscriptions.push(this.directConnectGateway.partyModeEnabled$.pipe(distinctUntilChanged()).subscribe((enabled: boolean) => {
      if (enabled) {
        this.playLedAnimations();
      } else {
        this.pauseLedAnimations();
      }
    }));
  }

  private initDoubleClickStream() {
    if (this.selectedTab !== Gateway301ModalTabs.STATUS) {
      if (this._doubleClickSubscription) {
        this._doubleClickSubscription.unsubscribe();
      }
      return;
    }
    const click$ = fromEvent<MouseEvent>(document.getElementById('gatewayImg'), 'click');
    const doubleClick$ = click$.pipe(
      buffer(click$.pipe(debounceTime(250))),
      map((l) => l.length),
      filter((count) => count === 2),
    );
    this._doubleClickSubscription = doubleClick$.subscribe((count) => {
      if (this.directConnectGateway && this.directConnectGateway.info$.value && this.directConnectGateway.info$.value.data) {
        const val = !(new GatewaySystemFlagsV0(this.directConnectGateway.info$.value.data.flags.value)).partyModeEnabled();
        this.startTheParty(val);
      }
    });
  }

  public ionViewDidLeave() {
    this.loading.dismiss();
    _.forEach(this.ledAnimations, (a) => {
      a.stop();
      a.destroy();
    });
    this.ledAnimations = [];
    this.subscriptions.forEach((s) => s.unsubscribe());
    if (this._doubleClickSubscription) {
      this._doubleClickSubscription.unsubscribe();
    }
    this.propertyService.clearCurrentProperty();
  }

  public close() {
    this.loading.dismiss();
    this.modalCtrl.dismiss();
  }

  public startTheParty(val: boolean) {
    this.feedbackService.HapticFeedback(FeedbackType.TOGGLE_PARTY_MODE);
    this.directConnectGateway.startTheParty(val);
    this.tapEffectAnimate();
  }

  public save() { }

  public selectedTabChange(tab) {
    this.selectedTab = tab;
    if (this.selectedTab === Gateway301ModalTabs.NETWORK) {
      this.directConnectGateway.requestDHCPInfo();
    }
    if (this.selectedTab === Gateway301ModalTabs.STATUS) {
      setTimeout(() => {
        this.initDoubleClickStream(); // can only listen for double clicks on the gateway img after it has rendered
        if (this.directConnectGateway.partyModeEnabled$.value) {
          this.playLedAnimations();
        }
      });
    } else {
      if (this._doubleClickSubscription) {
        this._doubleClickSubscription.unsubscribe();
      }
      this.pauseLedAnimations();
    }
  }

  public async showCellularPermissionMessage() {
    if (this.disableCellularToggle) {
      const alert = await this.alertCtrl.create({
        header: '',
        message: this.getCellularErrorMessage(),
        buttons: [{
          text: 'Ok',
        }],
      });
      alert.present();
    }
  }

  public async cellularEnabledChange() {
    if (!this.cellularChecked$.value) {
      const alert = await this.alertCtrl.create({
        header: 'Enable Cellular',
        message: `Are you sure you want to enable cellular data? There is a ${this._cellularCost} a month subscription fee for this service.`,
        buttons: [{
          text: 'Cancel',
          handler: () => {
            this.cellularChecked$.next(false);
            this.cellularToggle.checked = false;
          },
        }, {
          text: 'Enable',
          handler: () => {
            this.setCellular(true);
            this.directConnectGateway.toggleCellular(true); // toggle cellular on gateway directly
          },
        }],
      });
      alert.present();
    } else { // don't toggle cellular off on the gateway directly (this will be done when the renewal date arrives)
      this.setCellular(false); // change cellular status on DB
    }
  }

  public async selectNetwork() {
    this.directConnectGateway.scanNetworks();
    const accessPoints = _.orderBy(this.directConnectGateway.availableNetworks$.value, 'rssi', 'desc');
    const inputs = [];
    _.forEach(accessPoints, (ap: IWifiAP) => {
      if (ap.ssid) {
        inputs.push({
          type: 'radio',
          label: ap.ssid,
          value: ap.ssid,
          checked: this.directConnectGateway.wlan$.value.connectedSSID === ap.ssid,
        });
      }
    });
    const alert = await this.alertCtrl.create({
      header: 'Choose Network',
      inputs,
      buttons: [
        { text: 'cancel', role: 'cancel' },
        {
          text: 'Select',
          handler: async (res) => {
            if (res && res !== this.directConnectGateway.wlan$.value.connectedSSID) {
              const ap = _.find(accessPoints, { ssid: res }) as any;
              const password = await this.getPasswordForNetwork(ap) as any;
              if (password) {
                this.tryToConnectToWifi(ap, password);
              }
            }
          },
        },
      ],
    });
    alert.present();
  }

  public async saveEth(form) {
    if (form.valid) {
      const loading = await this.loadingCtrl.create({ message: 'Saving' });
      await loading.present();
      let promise;
      if (form.value.isUsingDhcp) {
        promise = this.directConnectGateway.configureEth(true);
      } else {
        promise = this.directConnectGateway.configureEth(false, form.value.address, form.value.gateway, form.value.netmask);
      }
      promise.then(async () => {
        await loading.dismiss();
        const toast = await this.toastCtrl.create({ message: 'Saved Configuration', duration: 3000, color: 'dark' });
        toast.present();
      }).catch(async (err) => {
        await loading.dismiss();
      });
    }
  }

  public async saveWlan(form) {
    if (form.valid) {
      const loading = await this.loadingCtrl.create({ message: 'Saving' });
      await loading.present();
      let promise;
      if (form.value.isUsingDhcp) {
        promise = this.directConnectGateway.configureWlan(true);
      } else {
        promise = this.directConnectGateway.configureWlan(false, form.value.address, form.value.gateway, form.value.netmask);
      }
      promise.then(async () => {
        await loading.dismiss();
        const toast = await this.toastCtrl.create({ message: 'Saved Configuration', duration: 3000, color: 'dark' });
        toast.present();
      }).catch(async () => {
        await loading.dismiss();
      });
    }
  }
  public getSignalStrengthText(signal: number): { value: string, color: string } {
    if (signal >= 60) {
      return { value: 'Good', color: '#7bc147' };
    } else if (signal >= 30) {
      return { value: 'Fair', color: '#cebf10' };
    } else {
      return { value: 'Poor', color: '#E3515F' };
    }
  }

  public getSignalStrengthColor(signal: number): { value: number, color: string } {
    let color;

    if (signal >= 60) {
      color = '#3E89BF';
    } else if (signal >= 30) {
      color = '#7bc147';
    } else {
      color = '#cebf10';
    }

    return { value: signal / 100, color };
  }

  public async saveRemoteServer(form: FormGroup) {
    if (form.valid) {
      const loading = await this.loadingCtrl.create({ message: 'Saving' });
      await loading.present();

      try {
        await this.directConnectGateway.configureRemoteServer(form.get('host').value, form.get('port').value);
        await loading.dismiss();
        const toast = await this.toastCtrl.create({ message: 'Saved Remote Server', duration: 3000, color: 'dark' });
        toast.present();
        form.markAsPristine();
      } catch (e) {
        await loading.dismiss();
        console.error('Saving Remote Server Failed');
      }
    }
  }

  public forgetNetwork() {
    this.directConnectGateway.forgetWifiNetwork();
  }

  private tapEffectAnimate() {
    const tapEffect = document.getElementById('tapEffect');
    const animation = createAnimation()
      .addElement(tapEffect)
      .duration(1000)
      .beforeStyles({ 'visibility': 'visible' })
      .fromTo('transform', 'scale(0)', 'scale(1)')
      .fromTo('opacity', '.5', '0')
      .fromTo('background', '#1bd3ea', '#E45898')
      .easing('ease-out')
      .afterStyles({ 'visibility': 'hidden' });
    animation.play();
  }

  private initLedAnimations() {
    const led1 = document.getElementById('led1');
    const led2 = document.getElementById('led2');
    const led3 = document.getElementById('led3');
    const led4 = document.getElementById('led4');
    const a1 = createAnimation()
      .addElement(led1)
      .duration(600)
      .direction('alternate')
      .fromTo('opacity', '0', '1')
      .fromTo('visibility', 'hidden', 'visible')
      .iterations(Infinity)
      .afterStyles({ 'visibility': 'hidden', 'opacity': '0' });
    const a2 = createAnimation()
      .addElement(led2)
      .direction('alternate')
      .duration(500)
      .fromTo('opacity', '0', '1')
      .fromTo('visibility', 'hidden', 'visible')
      .iterations(Infinity)
      .afterStyles({ 'visibility': 'hidden', 'opacity': '0' });
    const a3 = createAnimation()
      .addElement(led3)
      .direction('alternate')
      .duration(400)
      .fromTo('opacity', '0', '1')
      .fromTo('visibility', 'hidden', 'visible')
      .iterations(Infinity)
      .afterStyles({ 'visibility': 'hidden', 'opacity': '0' });
    const a4 = createAnimation()
      .addElement(led4)
      .direction('alternate')
      .duration(300)
      .fromTo('opacity', '0', '1')
      .fromTo('visibility', 'hidden', 'visible')
      .iterations(Infinity)
      .afterStyles({ 'visibility': 'hidden', 'opacity': '0' });

    this.ledAnimations = [a1, a2, a3, a4];
  }

  private playLedAnimations() {
    if (!this.ledAnimations.length) {
      this.initLedAnimations();
    }
    for (const a of this.ledAnimations) {
      a.play();
    }
  }

  private pauseLedAnimations() {
    for (const a of this.ledAnimations) {
      a.stop();
      a.destroy();
    }
    this.ledAnimations = [];
  }

  private async setCellular(cellularEnabled: boolean) {
    this.togglingCellular$.next(true);
    this.propertyService.setCellular(this.property._id, cellularEnabled).subscribe(() => {
      this.togglingCellular$.next(false);
    }, (err) => {
      this.togglingCellular$.next(false);
      this.toastCtrl.create({
        message: 'Unable to toggle cellular at this time.',
        duration: 2000,
        color: 'dark',
      }).then((t) => t.present());
    });
  }

  private async tryToConnectToWifi(ap: IWifiAP, password: string) {
    const loading = await this.loadingCtrl.create({ message: 'Connecting to WiFi' });
    await loading.present();
    this.directConnectGateway.connectToWiFi(ap.ssid, password, ap.securityType)
      .then(async () => {
        await loading.dismiss();
        const toast = await this.toastCtrl.create({
          message: `Connected to ${ap.ssid}`,
          duration: 3000,
          color: 'dark',
        });
        toast.present();
      })
      .catch(async () => {
        await loading.dismiss();
        const errorAlert = await this.alertCtrl.create({
          header: 'Uh Oh',
          message: `Could not connect to ${ap.ssid}.  Please check that the password is correct and try again.`,
          buttons: ['Ok'],
        });
        errorAlert.present();
      });
  }

  private async getPasswordForNetwork(ap: IWifiAP): Promise<string> {
    return new Promise(async (resolve) => {
      const alert = await this.alertCtrl.create({
        header: 'Connect',
        message: `Enter the password for "${ap.ssid}"`,
        inputs: [
          {
            name: 'password',
            type: 'password',
            placeholder: 'Enter Password',
          },
        ],
        buttons: [
          {
            text: 'cancel', role: 'cancel', handler: () => {
              resolve(undefined);
            },
          },
          {
            text: 'Connect', handler: (res) => {
              resolve(res.password);
            },
          },
        ],
      });
      alert.present();
    }) as any;
  }

  private initForm() {
    this.ethForm = this.fb.group({
      isUsingDhcp: null,
      address: null,
      netmask: null,
      gateway: null,
    });
    this.wlanForm = this.fb.group({
      isUsingDhcp: null,
      address: null,
      netmask: null,
      gateway: null,
    });
    this.remoteServerForm = this.fb.group({
      host: 'www.nextcenturymeters.com',
      port: 44444,
    });
  }

  private updateEthFormGroup(eth: IEthernetInfo) {
    if (eth) {
      this.ethForm.patchValue({
        isUsingDhcp: eth.isUsingDhcp,
        address: eth.address,
        netmask: eth.netmask,
        gateway: eth.gateway,
      });
      this.ethForm.markAsPristine();
    }
  }

  private updateWlanFormGroup(wlan: IWlanInfo) {
    if (wlan) {
      this.wlanForm.patchValue({
        isUsingDhcp: wlan.isUsingDhcp,
        address: wlan.address,
        netmask: wlan.netmask,
        gateway: wlan.gateway,
      });
      this.wlanForm.markAsPristine();
    }
  }

  private updateRemoteServerFormGroup(host, port) {
    this.remoteServerForm.patchValue({
      host,
      port,
    });
    this.remoteServerForm.markAsPristine();
  }

  private setupCellular(g: Gateway, p: Property, u: User) {
    if (g && g.data instanceof GW3Read) {
      switch (g.data.cellularSignal()) {
        case 0:
          this.cellularSignalStrengthStr = 'Unavailable';
          break;
        case 1:
          this.cellularSignalStrengthStr = 'Poor';
          break;
        case 2:
          this.cellularSignalStrengthStr = 'Good';
          break;
        case 3:
          this.cellularSignalStrengthStr = 'Excellent';
          break;
        default:
          this.cellularSignalStrengthStr = 'Unknown';
          break;
      }
    }

    this.cellularChecked$.next(p && p.subscriptions && p.subscriptions['CELLULAR'] && p.subscriptions['CELLULAR'].enabled);
    if (!p || !p.billingCompany) {
      this.disableCellularToggle = true;
      return;
    }
    this.billingCompanyService.findById(p.billingCompany.id).subscribe((company) => {
      this.disableCellularToggle = !PermissionUtils.userHasPermissionsOnProperty(u, SubscriptionType.CELLULAR, company, p);
      const cost =
      BillingCompany.getSubscriptionCost(SubscriptionType.CELLULAR, company.subscriptionDefaults, company.waivedSubscriptions).cost;
      this._cellularCost = cost ? `$${cost / 100}` : this._cellularCost;
    }, (err) => {
      this.disableCellularToggle = true;
    });
  }

  private getCellularErrorMessage(): string {
    if (!this.property) {
      return 'This Gateway is not programmed to a property.';
    } else {
      return `You don\'t have permission to enable cellular on ${this.property.name}`;
    }
  }
}
