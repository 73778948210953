import { ByteList } from 'byte-list';

import { Command, CommandCode } from './command';

export const CheckForUpdateType = 0xA4;

export class CheckForUpdate extends Command {

  type = CheckForUpdateType;
  host: string;
  targetVersion?: { major: number, minor: number };

  constructor(host: string) {
    super(CommandCode.CHECK_FOR_UPDATE);
    this.host = host;
  }

  serialize(): ByteList {
    const bytes = super.serialize();
    let flags = ByteList.SetBit(0, !!this.host, 0);
    flags = ByteList.SetBit(flags, !!this.targetVersion, 1);
    bytes.writeByte(flags);
    if (this.host) {
      bytes.writeString(this.host);
    }
    if (this.targetVersion) {
      bytes.writeByte(this.targetVersion.major);
      bytes.writeByte(this.targetVersion.minor);
    }
    return bytes;
  }

  deserialize(bytes: ByteList) {
    super.deserialize(bytes);
    const flags = bytes.readByte();
    if (ByteList.GetBit(flags, 0)) {
      this.host = bytes.readString();
    }
    if (ByteList.GetBit(flags, 1)) {
      const major = bytes.readByte();
      const minor = bytes.readByte();
      this.targetVersion = { major, minor };
    }
  }

}
