import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CropperComponent } from 'angular-cropperjs';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss'],
})
export class ImageCropperComponent {

  @Input() imageString: string;
  @Output() onCrop: EventEmitter<any> = new EventEmitter();

  @ViewChild('angularCropper') public angularCropper: CropperComponent;
  public cropperConfig: any;
  public croppedImage = null;

  constructor() {
    this.cropperConfig = {
      dragMode: 'crop',
      aspectRatio: 4 / 3,
      autoCrop: true,
      movable: true,
      zoomable: true,
      scalable: true,
      autoCropArea: 0.8,
    };
  }

  saveImage() {
    const croppedImage: string = this.angularCropper.cropper.getCroppedCanvas().toDataURL('image/jpeg', (100 / 100));
    this.onCrop.emit(croppedImage);
  }

}
