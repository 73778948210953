import { MeterConfig, EncoderProtocol, UomTypes, UtilityTypes, UtilityTypeIds, UomTypeIds } from '@ncss/models';

import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';


@Component({
  selector: 'app-status-meter',
  templateUrl: './status-meter.component.html',
  styleUrls: ['./status-meter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusMeterComponent implements OnInit {

  public MeterConfig = MeterConfig;

  @Input() public transceiver: boolean;
  @Input() public meter: 1 | 2;
  @Input() public set utilityTypeId(val) {
    if (this._utilityTypeId !== val) {
      this._utilityTypeId = val;
      this._utilityType = UtilityTypes[val];
    }
  }
  @Input() public set uomTypeId(val) {
    if (this._uomTypeId !== val) {
      this._uomTypeId = val;
      this._uomTypeIdStr = UomTypes[val] ? UomTypes[val].name : '';
      this.updateReadStr();
    }
  }
  @Input() public set meterConfig(val) {
    if (this._meterConfig !== val) {
      this._meterConfig = val;
      this._meterConfigStr = this.getMeterConfigStr(val);
    }
  }
  @Input() public set encoderProtocol(val) {
    if (this._encoderProtocol !== val) {
      this._encoderProtocol = val;
      this._encoderProtocolStr = this.getEncoderProtocolStr(val);
    }
  }
  @Input() public set pulseCount(val) {
    if (this._pulseCount !== val) {
      this._pulseCount = val;
      this._pulseCountStr = (val || 0).toLocaleString('en-GB');
      this.updateReadStr();
    }
  }
  @Input() public set imr(val) {
    if (this._imr !== val) {
      this._imr = val;
      this._imrStr = (val || 0).toLocaleString('en-GB');
      this.updateReadStr();
    }
  }
  @Input() public set multiplier(val) {
    if (this._multiplier !== val) {
      this._multiplier = val;
      this.updateReadStr();
    }
  }
  @Input() public statusOk: boolean;
  @Input() public meterSerialNumber: string;
  @Input() public rfSignalStrength: number;

  public get utilityType() { return this._utilityType; }
  public get utilityTypeId() { return this._utilityTypeId; }
  public get meterConfig() { return this._meterConfig; }
  public get meterConfigStr() { return this._meterConfigStr; }
  public get encoderProtocol() { return this._encoderProtocol; }
  public get encoderProtocolStr() { return this._encoderProtocolStr; }
  public get pulseCountStr() { return this._pulseCountStr; }
  public get imrStr() { return this._imrStr; }
  public get readStr() { return this._readStr; }

  private _utilityType;
  private _utilityTypeId: UtilityTypeIds;
  private _uomTypeIdStr: string;
  private _uomTypeId: UomTypeIds;
  private _meterConfigStr;
  private _meterConfig: MeterConfig;
  private _encoderProtocolStr;
  private _encoderProtocol: EncoderProtocol;
  private _pulseCount: number;
  private _pulseCountStr = '0';
  private _multiplier: number;
  private _imr: number;
  private _imrStr = '0';
  private _readStr = '0';

  constructor(
  ) { }

  public ngOnInit() { }

  private updateReadStr() {
    this._readStr = (((this.pulseCount || 0) * (this.multiplier || 1)) + this.imr || 0).toLocaleString('en-GB') +
      (this._uomTypeIdStr ? ' ' + this._uomTypeIdStr : '');
  }

  private getEncoderProtocolStr(protocol: EncoderProtocol) {
    switch (protocol) {
      case EncoderProtocol.AMCO:
        return 'AMCO';
      case EncoderProtocol.NEPTUNE:
        return 'Neptune';
      case EncoderProtocol.SENSUS:
        return 'Sensus';
      case EncoderProtocol.ECO:
        return 'GWF ECO';
      case EncoderProtocol.UNKNOWN:
        return 'Unknown';
      default:
        return '';
    }
  }

  private getMeterConfigStr(config: MeterConfig) {
    switch (config) {
      case MeterConfig.PULSE_IN:
        return 'Pulse Input';
      case MeterConfig.ENCODER_IN:
        return 'Encoded';
      case MeterConfig.PULSE_OUT:
        return 'Pulse Out';
      default:
        return '';
    }
  }

}
