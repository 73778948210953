import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { ModalHeaderModule } from '../../components/modal-header/modal-header.module';
import { SelectorModalComponent } from './selector-modal.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    ModalHeaderModule,
  ],
  exports: [
    SelectorModalComponent,
  ],
  declarations: [
    SelectorModalComponent,
  ],
  entryComponents: [
    SelectorModalComponent,
  ],
  providers: [],
})
export class SelectorModalModule { }
