import { Message } from '@ncss/message';
import { RR4MessageType, RR4 } from '@ncss/models';

import { ByteList } from 'byte-list';

export class RR4BLEDeviceMessage extends Message {

  public rr4: RR4;

  constructor(type: RR4MessageType) {
    super();
    this.type = 0x1F40;
  }

  public serialize(): ByteList {
    const bytes = super.serialize();
    this.rr4 = this.rr4 || new RR4();
    this.rr4.serialize(bytes);
    return bytes;
  }

  public deserialize(byteList: ByteList) {
    super.deserialize(byteList);
    this.rr4 = new RR4();
    this.rr4.deserialize(byteList);
  }
}
