import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { MobileUserService } from './../mobile-user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {

  constructor(
    private mobileUserService: MobileUserService,
    private router: Router,
  ) { }

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.mobileUserService.token && this.mobileUserService.user) {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }

}
