import { Component, Input, OnInit } from '@angular/core';
import { IonSelect, AlertController } from '@ionic/angular';

import { MeterFormGroup } from '../../../../../angularUtilities/meter-form';
import { AppSettingsService } from '../../../../../services/app-settings.service';
import { BarcodeScannerService } from '../../../../../services/barcode-scanner.service';
import { DocumentUploadService } from '../../../../../services/document-upload.service';
import { UtilityFamily, UtilityTypes } from '@ncss/models';

@Component({
  templateUrl: './meter-form.component.html',
  styleUrls: ['./meter-form.component.scss'],
  selector: 'app-meter-form',
})
export class MeterFormComponent implements OnInit {
  @Input() meterForm: MeterFormGroup;
  showForm = false;
  unitId: string;
  barcodeScanAvailable = false;
  public isWaterMeter = false;

  constructor(
    public uploadService: DocumentUploadService,
    private alertCtl: AlertController,
    private settings: AppSettingsService,
    private barcodeScanner: BarcodeScannerService,
  ) { }

  ngOnInit(): void {
    this.unitId = this.meterForm.originalUnit()._id;
    this.barcodeScanAvailable = AppSettingsService.isFeatureEnabled(this.settings.appSettings.camera) && this.settings.cordovaAvailable;
    this.isWaterMeter = this.meterForm.controls.utilityTypeId && this.meterForm.controls.utilityTypeId.value &&
    this.meterForm.controls.utilityTypeId.value ?UtilityTypes[this.meterForm.controls.utilityTypeId.value].family === UtilityFamily.WATER : false
  }

  async manufacturerIdChanged(meterModelSelect: IonSelect) {
    const manufacturer =
      this.meterForm.controls.meterType.manufacturerOptions.find(
        (m) => m._id === this.meterForm.controls.meterType.controls.meterManufacturerId.value);
    if (manufacturer) {
      await this.meterForm.controls.meterType.manufacturerSelected(manufacturer);
      setTimeout(() => {
        meterModelSelect.open();
      });
    }
  }

  async onBarcodeClick() {
    const serial = await this.barcodeScanner.showBarcodeScanner(true, true);
    if (serial) {
      this.meterForm.controls.meterSerial.setValue(serial);
    }
  }

  meterTypeIdChanged() {
    const meterType =
      this.meterForm.controls.meterType.meterTypeOptions.find(
        (m) => m._id === this.meterForm.controls.meterType.controls.meterTypeId.value);
    if (meterType) {
      this.meterForm.controls.meterType.meterTypeSelected(meterType);
    }
  }

  async checkForIMRPhoto() {
    if (this.meterForm.controls.imr.dirty) {
      const confirmAlert = await this.alertCtl.create({
        header: 'Take Photo?',
        message: 'When updating the IMR of a meter, we recommend taking a picture of the meter. Would you like to take a photo now?',
        backdropDismiss: true,
        buttons: [
          { text: 'Take Photo', role: 'confirm' },
          { text: 'Not Now', role: 'cancel' },
        ],
      });
      await confirmAlert.present();
      const res = await confirmAlert.onDidDismiss();
      if (res && res.role === 'confirm') {
        await this.meterForm.controls.imrPhoto.onClick();
      }
    }
  }

}
