import { Alert, LeakSensorAlert, AlertTypes, AlertTypeHelper } from '@ncss/models';

import { Component, Input, OnChanges } from '@angular/core';

@Component({
  templateUrl: './open-alert-row.component.html',
  styleUrls: ['./open-alert-row.component.scss'],
  selector: 'app-open-alert-row',
})
export class OpenAlertRowComponent implements OnChanges {
  @Input() openAlerts: Alert[];

  openAlertRows: Array<{alertName: string, openedAt: Date}>;

  showAlerts = false;
  iconLeft = false;

  ngOnChanges() {
    this.iconLeft = this.openAlerts.findIndex((a) => a instanceof LeakSensorAlert) > -1;
    this.openAlertRows = this.openAlerts.map((a) => {
      return {
        alertName: (a.subType ? AlertTypeHelper.GetAlertSubTypeNameFromId(a.subType) : AlertTypes[a.type].name) + ' Alert',
        openedAt: a.openedAt,
      };
    });
  }
}
