
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { ConnectionHelperComponent } from './connection-helper.component';

@NgModule({
  declarations: [ConnectionHelperComponent],
  imports: [
    IonicModule,
    CommonModule,
  ],
  providers: [],
  exports: [ConnectionHelperComponent],
})
export class ConnectionHelperModule {}
