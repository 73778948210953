import { Lorax, Meter, LeakSensor } from '@ncss/models';

import { FormControl, ValidatorFn, AsyncValidatorFn } from '@angular/forms';
import { ModalController } from '@ionic/angular';

import { PhotoViewerComponent, PhotoViewerOutput } from '../components/photo-viewer/photo-viewer.component';
import { CameraResultStatus, CameraService } from '../services/camera.service';
import { MobileUserService } from '../services/mobile-user.service';


interface PhotoInputValue {
  url?: string;
  createdAt?: Date;
  createdBy?: string;
  base64Img?: string; // only there if just changed photo and it hasn't uploaded yet
}

export class PhotoControl extends FormControl {
  static FromLeakSensor(leakSensor: LeakSensor, cameraService: CameraService, modalCtl: ModalController, userService: MobileUserService) {
    const loraxRes = Lorax.getImageFromLeakSensor(leakSensor);
    const c = new PhotoControl(
      cameraService,
      modalCtl,
      userService,
      (loraxRes.error || loraxRes.result.url === '-') ? null : loraxRes.result,
    );
    c.context = 'leakSensor';
    return c;
  }


  static FromMeter(
    meter: Meter,
    cameraService: CameraService,
    modalCtl: ModalController,
    userService: MobileUserService,
    unitId: string,
    propertyId: string,
  ) {
    const loraxRes = Lorax.getImageFromMeter(meter);
    // hate this, but before MRA was a thing, IMR images were saved with just a name only and we need to build out the whole url
    if (!loraxRes.error && !loraxRes.result.url.includes('property-uploader-us-west-1')) {
      propertyId = propertyId.substring(2);
      unitId = unitId.substring(2);
      loraxRes.result.url = `https://property-uploader-us-west-1.s3.us-west-1.amazonaws.com/${propertyId}/${unitId}/${meter.utilityTypeId}/${loraxRes.result.url}`;
    }
    return new PhotoControl(
      cameraService,
      modalCtl,
      userService,
      (loraxRes.error || loraxRes.result.url === '-') ? null : loraxRes.result);
  }

  get canBeViewed() { return this.value && (this.value.url || this.value.base64Img); }

  value: PhotoInputValue | null;
  context: 'meter' | 'leakSensor' = 'meter';

  private constructor(
    private cameraService: CameraService,
    private modalCtl: ModalController,
    private userService: MobileUserService,
    initialValue: PhotoInputValue | null,
    validators?: ValidatorFn | ValidatorFn[],
    asyncValidators?: AsyncValidatorFn | AsyncValidatorFn[],
  ) {
    super(initialValue, validators, asyncValidators);
  }

  updateFromBase64(base64Img: string, userId: string) {
    this.setValue({
      base64Img,
      createdAt: new Date(),
      createdBy: userId,
    });
    this.markAsDirty();
  }

  async onClick() {
    if (this.canBeViewed) {
      const m = await this.modalCtl.create({
        component: PhotoViewerComponent,
        backdropDismiss: false,
        componentProps: {
          title: this.context === 'meter' ? 'Initial Meter Read' : 'Leak Sensor Photo',
          imageUrl: this.value ? (this.value.base64Img || this.value.url) : null,
        },
      });
      await m.present();
      const result: { data?: PhotoViewerOutput } = await m.onDidDismiss();
      if (result.data) {
        switch (result.data.action) {
          case 'delete':
            this.setValue(null);
            this.markAsDirty();
            break;
          case 'update':
            this.updateFromBase64(result.data.newBase64, this.userService.user ? this.userService.user._id : null);
            break;
          default:
            break;
        }
      }
    } else {
      const res = await this.cameraService.getPicture();
      if (res.status === CameraResultStatus.CAMERA_PICTURE || res.status === CameraResultStatus.UPLOADED_PICTURE) {
        this.updateFromBase64(res.base64Img, this.userService.user ? this.userService.user._id : null);
      }
    }
  }
}
