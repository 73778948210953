import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { ModalHeaderModule } from '../modal-header/modal-header.module';
import { PipesModule } from './../../pipes/pipes.module';
import { UnitNotesComponent } from './unit-notes.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ModalHeaderModule,
    PipesModule,
  ],
  exports: [UnitNotesComponent],
  declarations: [UnitNotesComponent],
  entryComponents: [],
  providers: [],
})
export class UnitNotesModule { }
