import { PipeLogic } from '@ncss/models';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'utilityType' })
export class UtilityTypePipe implements PipeTransform  {
  public transform(id: number, args?: string) {
    return PipeLogic.UtilityType(id, args);
  }
}
