import { MessageProperty } from '@ncss/message';

import { DataTypes } from 'byte-list';

import { GatewayMessage } from './gatewayMessage';

export enum GatewayLogMessageTypes {
  GROUP_MASK = 0x0300,

  // Gateway --> Cloud
  INFO_LOG = 0x0300,
  WARNING_LOG = 0x0301,
  ERROR_LOG = 0x0302,
}

export class GatewayLogMessage extends GatewayMessage {

  @MessageProperty(DataTypes.STRING)
  public message: string;

}
