import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';

import { IUserChanges } from '../../../../services/direct-connect/remoteReader/remoteReader';
import { QuickConfigurationService } from '../quickConfiguration.service';

@Component({
  selector: 'app-manage-configurations',
  templateUrl: './manage-configurations.component.html',
  styleUrls: ['./manage-configurations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageConfigurationsComponent implements OnInit {

  public get configurations$() { return this._configurations$.asObservable(); }

  private _configurations$ = new BehaviorSubject<Array<{ name: string, changes: IUserChanges }>>([]);

  constructor(
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private quickConfigService: QuickConfigurationService,
  ) { }

  public async ngOnInit() {
    this._configurations$.next(await this.quickConfigService.get('1'));
  }

  public close() {
    this.modalCtrl.dismiss();
  }

  public async delete(name: string) {
    const alert = await this.alertCtrl.create({
      header: `Delete "${name}"`,
      message: `Are you sure you want to delete "${name}"?`,
      buttons: ['Cancel', {
        text: 'Delete',
        handler: () => this.quickConfigService.delete('1', name).then((configurations) => this._configurations$.next(configurations)),
      }],
    });
    alert.present();
  }

}
