import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[app-highlighter]',
})
export class HighlighterDirective {

  @Input() public set accentWhenEnabled(val: boolean) {
    if (val) {
      this.fireEnabled();
    }
  }

  constructor( private el: ElementRef) { }

  public fireEnabled() {
    const div = document.createElement('div');
    div.style.backgroundColor = '#3E89BF';
    div.style.position = 'absolute';
    div.style.left = '10px';
    div.style.right = '10px';
    div.style.top = '10px';
    div.style.bottom = '10px';
    div.style.zIndex = '0';
    div.style.borderRadius = '5px';
    div.style.transition = 'all 2s ease-out';
    div.style.filter = 'blur(0rem)';
    div.style.opacity = '0.4';
    this.el.nativeElement.prepend(div);


    setTimeout(() => {
      div.style.top = '10px';
      div.style.bottom = '10px';
      div.style.left = '10px';
      div.style.right = '10px';
      div.style.opacity = '0';
      div.style.filter = 'blur(3rem)';
      setTimeout(() => {
        div.remove();
      }, 1000);
    }, 10);
  }
}
