import {
  MeterConfig,
  ConversionUtils,
  UtilityTypesOrderedList,
  UomTypeHelper,
  DeviceTypes,
  DeviceTypeIds,
  IDeviceType,
  DeviceTypeHelper,
  UtilityTypeIds,
  UtilityTypes,
} from '@ncss/models';

import { trigger, animate, style, transition } from '@angular/animations';
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  AfterViewInit
} from '@angular/core';
import { IonContent, ModalController, AlertController, IonToggle, ActionSheetController, LoadingController } from '@ionic/angular';
import * as _ from 'lodash';
import { filter, take, map } from 'rxjs/operators';

import { IDirectConnectDevice } from '../../../services/direct-connect/baseDirectConnectDevice';
import {
  DirectConnectRemoteReader,
  MeterConfigLabels,
  EndDeviceFormState,
  IOpenAlert,
} from '../../../services/direct-connect/remoteReader/remoteReader';
import { NumericInputComponent } from '../../numeric-input/numeric-input.component';
import { MobileDevicesService } from './../../../services/devices/mobile-devices.service';
import { MobileUnitService } from './../../../services/mobile-unit.service';
import { MobileUserService } from './../../../services/mobile-user.service';
import { MagnetInstructionComponent } from './magnet-instruction/magnet-instruction.component';
import { ManageConfigurationsComponent } from './manage-configurations/manage-configurations.component';
import { QuickConfigurationService } from './quickConfiguration.service';


export enum RemoteReaderModalTabs {
  STATUS,
  WIRING,
  PROGRAM,
}

@Component({
  selector: 'app-remote-reader-modal',
  templateUrl: './remote-reader-modal.component.html',
  styleUrls: ['./remote-reader-modal.component.scss'],
  animations: [
    trigger('fadeInItem', [
      transition(':enter', [
        style({ height: '0px' }),
        animate('200ms ease-out', style({ height: '44px' })),
      ]),
      transition(':leave', [
        animate('200ms ease-out', style({ height: '0px' })),
      ]),
    ]),
  ],
})
export class RemoteReaderModalComponent implements OnInit, OnDestroy, AfterViewInit {

  public RemoteReaderFormState = EndDeviceFormState;
  public MeterConfig = MeterConfig;
  public RemoteReaderModalTabs = RemoteReaderModalTabs;
  public DeviceTypeIds = DeviceTypeIds;
  public DeviceTypes = DeviceTypes;
  public PortConfigurations = [
    { key: MeterConfig.PORT_DISABLED, label: MeterConfigLabels[MeterConfig.PORT_DISABLED] },
    { key: MeterConfig.PULSE_IN, label: MeterConfigLabels[MeterConfig.PULSE_IN] },
    { key: MeterConfig.ENCODER_IN, label: MeterConfigLabels[MeterConfig.ENCODER_IN] },
  ];

  @ViewChild(IonContent) content: IonContent;
  @ViewChild('pulseOutToggle') public pulseOutToggle: IonToggle;
  @ViewChild('lcdAlwaysOnToggle') public lcdAlwaysOnToggle: IonToggle;
  @ViewChild('rapidCheckInToggle') public rapidCheckInToggle: IonToggle;
  @ViewChild('resetMeter1PulseCount') public resetMeter1PulseCount: IonToggle;
  @ViewChild('resetMeter2PulseCount') public resetMeter2PulseCount: IonToggle;
  @Input() public connectedDevice: IDirectConnectDevice;

  // UI State Properties
  public showMoreStatus = false;
  public showAlerts = false;
  public showMoreMeter1 = false;
  public showMoreMeter2 = false;
  public selectedTab = RemoteReaderModalTabs.STATUS;
  public tabs = [
    { name: 'Status', value: RemoteReaderModalTabs.STATUS, icon: 'icon-remote-reader' },
    { name: 'Wiring Guide', value: RemoteReaderModalTabs.WIRING, icon: 'icon-meter' },
  ];

  public remoteReader: DirectConnectRemoteReader;
  public serialNumber: string;
  public deviceType: IDeviceType;
  public isTR: boolean;

  private _subscriptions = [];

  constructor(
    public mobileUserService: MobileUserService,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private loadCtrl: LoadingController,
    private actionSheetCtrl: ActionSheetController,
    private quickConfigService: QuickConfigurationService,
    private unitService: MobileUnitService,
    private devicesService: MobileDevicesService,
  ) { }

  public ngOnInit() {
    if (!this.connectedDevice) { return; }
    this.remoteReader = this.connectedDevice.device as DirectConnectRemoteReader;
    if (!this.remoteReader) { return; }
    this.serialNumber = ConversionUtils.ConvertSerialNumberToString(this.connectedDevice.serialNumber);
    this.deviceType = DeviceTypeHelper.GetDeviceTypeBySerialNumber(this.serialNumber);
    this.fetchUnit();
    if (this.deviceType.id === DeviceTypeIds.REMOTE_READER_TRANSCEIVER) {
      this.isTR = true;
    }
  }

  public ngAfterViewInit() {
    this._subscriptions.push(this.remoteReader.pulseOutEnabled$.subscribe((val) => {
      // Bug in Ionic - setTimeout is a workaround to get the checked property to work correctly
      setTimeout(() => this.pulseOutToggle.checked = val, 0);
    }));
    this._subscriptions.push(this.remoteReader.lcdAlwaysOn$.subscribe((val) => {
      // Bug in Ionic - setTimeout is a workaround to get the checked property to work correctly
      setTimeout(() => this.lcdAlwaysOnToggle.checked = val, 0);
    }));
    this._subscriptions.push(this.remoteReader.rapidCheckInEnabled$.subscribe((val) => {
      // Bug in Ionic - setTimeout is a workaround to get the checked property to work correctly
      setTimeout(() => this.rapidCheckInToggle.checked = val, 0);
    }));
  }

  public ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  public close() {
    this.remoteReader.dropUserChanges();
    this.modalCtrl.dismiss();
  }

  public async quickActionsClicked() {
    const configurations = await this.quickConfigService.get('1');
    console.log('%c Configurations', 'color: cyan', configurations);
    const sheet = await this.actionSheetCtrl.create({
      header: 'Quick Actions',
      buttons: _.concat(_.orderBy(configurations, ['lastUsedAt', 'createdAt'], ['asc'])
        .map((config) => {
          return {
            text: `Apply "${config.name}"`,
            handler: () => {
              this.remoteReader.applyConfiguration(config.changes);
              this.quickConfigService.save('1', { ...config, lastUsedAt: new Date() });
            },
          };
        }).slice(0, 5),
        [{
          text: 'Save As New Configuration',
          handler: () => {
            this.getConfigurationNameFromUser().then((name: string) => {
              if (name) {
                this.quickConfigService.save('1', { name, changes: this.remoteReader.getConfiguration() });
              }
            });
          },
        }],
        configurations.length ? [{
          text: 'Manage Configurations',
          handler: () => this.modalCtrl.create({ component: ManageConfigurationsComponent }).then((modal) => modal.present()),
        }] : [],
        [{
          text: 'Cancel',
          role: 'cancel',
        } as any],
      ),
    });
    await sheet.present();
  }

  public async save() {
    if (this.remoteReader.needsCloudSync()) {
      const l = await this.loadCtrl.create({ message: 'Syncing...' });
      await l.present();
      const { errorMsg, success } = await this.syncMetersToCloud();
      await l.dismiss();
      if (!success) {
        const a = await this.alertCtrl.create({
          message: errorMsg || 'Unable to sync configurations with the NextCentury Cloud at this time.',
          header: 'Uh-oh!',
          buttons: ['Ok'],
        });
        await a.present();
        return;
      }
    }
    this.remoteReader.applyChanges();
    const modal = await this.modalCtrl.create({
      component: MagnetInstructionComponent,
      componentProps: {
        onWaitingConfirmation$: this.remoteReader.state$.pipe(map((s) => s === EndDeviceFormState.WAITING_CONFIRMATION)),
        onChangesApplied$: this.remoteReader.state$.pipe(
          filter((s) => s === EndDeviceFormState.CHANGES_APPLIED),
          map(() => true),
          take(1),
        ),
        serialNumber: this.remoteReader.serialNumber,
      },
    });
    modal.present();
    const res = await modal.onDidDismiss();
    if (res.data === 'canceled' || res.role === 'backdrop') {
      this.remoteReader.setFormToDirty(); // We do this because if they cancel or press 'back' we want the save button to still be enabled
    } else {
      this.remoteReader.resetPulseCount(1, false, true);
      this.remoteReader.resetPulseCount(2, false, true);
    }

  }

  private async syncMetersToCloud(): Promise<{ success: boolean, errorMsg?: string }> {
    const [oldMeter1, oldMeter2] = this.remoteReader.programmedMeters || [null, null];
    const [newMeter1, newMeter2] = this.remoteReader.getMetersForCloudSync();
    const unitId = this.remoteReader.programmedUnit._id;
    if (oldMeter1) {
      try {
        await this.unitService.removeMeter(unitId, oldMeter1.utilityTypeId).toPromise();
      } catch (e) {
        return { success: false };
      }
    }

    if (oldMeter2) {
      try {
        await this.unitService.removeMeter(unitId, oldMeter2.utilityTypeId).toPromise();
      } catch (e) {
        return { success: false };
      }
    }

    if (newMeter1) {
      try {
        await this.unitService.addMeter(unitId, newMeter1).toPromise();
      } catch (e) {
        return {
          success: false,
          errorMsg: e.status === 405 ? `A ${UtilityTypes[newMeter1.utilityTypeId].name} already exists on this unit.` : null,
        };
      }
    }

    if (newMeter2) {
      try {
        await this.unitService.addMeter(unitId, newMeter2).toPromise();
      } catch (e) {
        return {
          success: false,
          errorMsg: e.status === 405 ? `A ${UtilityTypes[newMeter2.utilityTypeId].name} already exists on this unit.` : null,
        };
      }
    }

    return { success: true };
  }

  private async fetchUnit() {
    if (!this.remoteReader) { return; }
    const location = await this.devicesService.getDeviceLocation(this.remoteReader.serialNumber).toPromise();
    if (!location || !location.unit) { return; }
    const unit = await this.unitService.findById(location.unit.id).toPromise();
    this.remoteReader.setProgrammedUnit(unit);
  }

  public factorySleep() {
    if (this.mobileUserService.user$.value && this.mobileUserService.user$.value['manufacturingUser']) {
      this.remoteReader.factorySleep = true;
      this.save();
    }
  }

  public factoryReset() {
    this.remoteReader.factoryReset = true;
    this.save();
  }

  public async disableMeter2Clicked() {
    const alert = await this.alertCtrl.create({
      header: 'Disable Meter',
      message: 'Are you sure you want to disable the Meter 2 port on this Remote Reader?',
      buttons: [
        { text: 'Cancel', role: 'cancel' },
        {
          text: 'Yes', handler: (selectedUomTypeId) => {
            this.remoteReader.setConfigType(2, MeterConfig.PORT_DISABLED);
          },
        },
      ],
    });
    await alert.present();
  }

  public trackAlertsBy(alert: IOpenAlert) {
    return alert.typeStr;
  }

  public alertClicked(alert: IOpenAlert) {
    if (alert && alert.typeStr === 'Tamper') {
      this.remoteReader.setClearTamper(!this.remoteReader.clearTamper);
    }
  }

  public async onUtilityTypeClicked(port: 1 | 2) {
    let selected: UtilityTypeIds;
    if (port === 2) {
      selected = this.remoteReader.meter2Info ? this.remoteReader.meter2Info.utilityTypeId : null;
    } else {
      selected = this.remoteReader.meter1Info ? this.remoteReader.meter1Info.utilityTypeId : null;
    }
    const availableOptions = this.remoteReader.getAvailableUtilities(port);
    const alert = await this.alertCtrl.create({
      header: 'Select Utility Type',
      inputs: UtilityTypesOrderedList.map((ut) => {
        const notAvailable = (availableOptions.findIndex((opt) => opt.id === ut.id) < 0);
        return {
          type: 'radio',
          label: ut.name + (notAvailable ? ' (not available)' : ''),
          value: ut.id,
          checked: selected === ut.id,
          disabled: notAvailable,
        };
      }) as any,
      buttons: [
        { text: 'Cancel', role: 'cancel' },
        {
          text: 'Ok', handler: (selectedUtilityTypeId) => {
            if (selectedUtilityTypeId !== selected) {
              this.remoteReader.setUtilityTypeId(port, selectedUtilityTypeId);
            }
          },
        },
      ],
    });
    await alert.present();
  }

  public async onUomTypeClicked(meterNumber: 1 | 2) {
    const utilityTypeId = meterNumber === 1 ? this.remoteReader.meter1Info.utilityTypeId : this.remoteReader.meter2Info.utilityTypeId;
    const uomTypeId = meterNumber === 1 ? this.remoteReader.meter1Info.uomTypeId : this.remoteReader.meter2Info.uomTypeId;
    const alert = await this.alertCtrl.create({
      header: 'Select Unit of Measure',
      inputs: UomTypeHelper.GetUomForUtilityTypeId(utilityTypeId).map((ut) => {
        return {
          type: 'radio',
          label: ut.name,
          value: ut.id,
          checked: uomTypeId === ut.id,
        };
      }) as any,
      buttons: [
        { text: 'Cancel', role: 'cancel' },
        {
          text: 'Ok', handler: (selectedUomTypeId) => {
            if (selectedUomTypeId !== uomTypeId) {
              this.remoteReader.setUomTypeId(meterNumber, selectedUomTypeId);
            }
          },
        },
      ],
    });
    await alert.present();
  }

  public async onConfigTypeClicked(meterNumber: 1 | 2) {
    const configType = meterNumber === 1 ? this.remoteReader.meter1Info.configType : this.remoteReader.meter2Info.configType;
    const alert = await this.alertCtrl.create({
      header: 'Select Signal Type',
      inputs: this.PortConfigurations.filter((c) => meterNumber === 2 || c.key !== MeterConfig.PORT_DISABLED).map((c) => {
        return {
          type: 'radio',
          label: c.label,
          value: c.key,
          checked: configType === c.key,
        };
      }) as any,
      buttons: [
        { text: 'Cancel', role: 'cancel' },
        {
          text: 'Ok', handler: (selectedConfigType) => {
            if (selectedConfigType !== configType) {
              this.remoteReader.setConfigType(meterNumber, selectedConfigType);
            }
          },
        },
      ],
    });
    await alert.present();
  }

  public resetPulseCountClicked(meter: 1 | 2) {
    const toggleValue = meter === 1
      ? (this.resetMeter1PulseCount && this.resetMeter1PulseCount.checked)
      : (this.resetMeter2PulseCount && this.resetMeter2PulseCount.checked);
    this.remoteReader.resetPulseCount(meter, toggleValue);
  }

  public pulseOutClicked() {
    if (
      this.remoteReader.meter2Info.configType === MeterConfig.PORT_DISABLED ||
      this.remoteReader.meter2Info.configType === MeterConfig.PULSE_OUT
    ) {
      this.remoteReader.setPulseOut(!this.remoteReader.pulseOutEnabled);
    }
  }

  public rapidCheckInClicked() {
    this.remoteReader.setRapidCheckInEnabled(!this.remoteReader.rapidCheckInEnabled);
  }

  public lcdAlwaysOnClicked() {
    this.remoteReader.setLCDAlwaysOn(!this.remoteReader.lcdAlwaysOn);
  }

  public configureMeter2() {
    const utilityType = this.getAvailableUtilityTypes(2)[0];
    this.remoteReader.configureMeter(2, MeterConfig.PULSE_IN,
      utilityType.id, UomTypeHelper.GetUomForUtilityTypeId(utilityType.id)[0].id, 10, 0);
    this.remoteReader.setPulseOut(false);
    this.pulseOutToggle.checked = false;
  }

  public getAvailableUtilityTypes(meterNumber: 1 | 2) {
    return _.filter(UtilityTypesOrderedList, (ut) => {
      if (meterNumber === 1) {
        return ut.id !== this.remoteReader.meter2Info.utilityTypeId;
      } else {
        return ut.id !== this.remoteReader.meter1Info.utilityTypeId;
      }
    });
  }

  public async onMultiplierChanged(meterNumber: 1 | 2, event, component: NumericInputComponent) {
    if (event && event.value) {
      this.remoteReader.setMultiplier(meterNumber, event.value);
    } else if (event && event.value === 0) {
      const alert = await this.alertCtrl.create({
        header: 'Invalid Multiplier',
        message: 'Oops, "0" is not a valid multiplier. Please enter a value that is greater than "0".',
        buttons: [
          { text: 'Ok', role: 'cancel' },
        ],
      });
      await alert.present();
      component.writeValue(meterNumber === 1 ? this.remoteReader.meter1Info.multiplier : this.remoteReader.meter2Info.multiplier);
    }
  }

  public onIMRChanged(meterNumber: 1 | 2, event) {
    if (event && (event.value || event.value === 0)) {
      this.remoteReader.setIMR(meterNumber, event.value);
    }
  }

  private getConfigurationNameFromUser(): Promise<string | null> {
    return new Promise((resolve) => {
      this.alertCtrl.create({
        header: 'Name This Configuration',
        message: 'Choose a name to describe this configuration for future use.',
        inputs: [{
          name: 'name',
          type: 'text',
          placeholder: 'Enter Name',
        }],
        buttons: [
          {
            text: 'Cancel',
            handler: () => resolve(null),
          },
          {
            text: 'Save',
            handler: (res) => {
              resolve(res.name);
            },
          },
        ],
      }).then((alert) => alert.present());
    });
  }
}
