import { BitwiseHelper, Flags } from '@ncss/models';

export class DC301Flags extends Flags {

  public get deviceMode(): number { return BitwiseHelper.GetBits(this.value, 0, 4); }
  public get bleConnected(): boolean { return !!BitwiseHelper.GetBits(this.value, 4, 1); }
  public get partyMode(): boolean { return !!BitwiseHelper.GetBits(this.value, 5, 1); }
  public get poweredOff(): boolean { return !!BitwiseHelper.GetBits(this.value, 6, 1); }
  public get lowBattery(): boolean { return !!BitwiseHelper.GetBits(this.value, 7, 1); }
  public get chargerConnected(): boolean { return !!BitwiseHelper.GetBits(this.value, 8, 1); }
  public get batteryConnected(): boolean { return !!BitwiseHelper.GetBits(this.value, 9, 1); }
  public get ad5065InCurrentLimit(): number { return BitwiseHelper.GetBits(this.value,  10, 4); }
  public get ad5065ChargeCurrentLimit(): number { return BitwiseHelper.GetBits(this.value, 14, 3); }
  public get ad5065Error(): boolean { return !!BitwiseHelper.GetBits(this.value, 17, 1); }
  public get max17262Error(): boolean { return !!BitwiseHelper.GetBits(this.value, 18, 1); }
  public get lastResetCause(): number { return BitwiseHelper.GetBits(this.value, 19, 4); }
  public get firmwareBuffered(): boolean { return !!BitwiseHelper.GetBits(this.value, 23, 1); }
  public get chargerVinOk(): boolean { return !!BitwiseHelper.GetBits(this.value, 24, 1); }
  public get chargerVinOv(): boolean { return !!BitwiseHelper.GetBits(this.value, 25, 1); }
  public get bleError(): boolean { return !!BitwiseHelper.GetBits(this.value, 26, 1); }
  public get reserved(): number { return BitwiseHelper.GetBits(this.value, 24, 5); }
}
