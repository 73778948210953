import { UtilityTypes, UtilityTypeIds } from '@ncss/models';

import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { ActionSheetController } from '@ionic/angular';
import { ActionSheetButton } from '@ionic/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MeterFormGroup } from '../../../../../angularUtilities/meter-form';
import { UnitForm } from '../../../../../angularUtilities/unit-form';

@Component({
  selector: 'app-equipment-tabs',
  templateUrl: './equipment-tabs.component.html',
  styleUrls: ['./equipment-tabs.component.scss'],
})
export class EquipmentTabsComponent implements OnInit, OnDestroy {
  @Input() unitForm: UnitForm;
  @Input() selectedTab: MeterFormGroup | 'leakSensors';

  @Output() tabSelected = new EventEmitter<MeterFormGroup | 'leakSensors'>();
  @Output() addMeter = new EventEmitter<UtilityTypeIds>();
  @Output() addLeakSensor = new EventEmitter();

  hasLeakSensorError = false;

  UtilityTypes = UtilityTypes;

  private destroyed = new Subject();

  constructor(private actionSheetCtl: ActionSheetController) { }

  ngOnInit() {
    this.unitForm.statusChanges.pipe(takeUntil(this.destroyed)).subscribe(() => {
      this.checkHasError();
    });
    this.checkHasError();
  }

  ngOnDestroy(): void {
    this.destroyed.next();
  }

  async addClicked() {
    const buttons: ActionSheetButton[] = this.unitForm.controls.meters.availableUtilities.map((u) => {
      return {
        text: u.name + ' Meter',
        icon: u.iconName,
        handler: () => {
          this.addMeter.emit(u.id);
        },
      };
    });
    buttons.push({
      text: 'Leak Sensor',
      icon: 'icon-leak-sensor',
      handler: () => {
        this.addLeakSensor.emit();
      },
    });
    buttons.push({
      text: 'Cancel',
      icon: 'icon-x',
      role: 'cancel',
    });
    const a = await this.actionSheetCtl.create({
      header: 'Equipment',
      buttons,
    });
    await a.present();
  }

  private checkHasError() {
    if (this.unitForm.invalid) {
      this.hasLeakSensorError = this.unitForm.controls.leakSensors.invalid;
    } else {
      this.hasLeakSensorError = false;
    }
  }
}
