import { ByteList } from 'byte-list';

import { Payload } from '../payload';

export const AckType = 0x01;

export class Ack extends Payload {

  type = AckType;
  requestId = 0;

  constructor(requestId = 0) {
    super();
    this.requestId = requestId;
  }

  serialize(): ByteList {
    const bytes = super.serialize();
    bytes.writeByte(this.requestId);
    return bytes;
  }

  deserialize(bytes: ByteList): void {
    super.deserialize(bytes);
    this.requestId = bytes.readByte();
  }

}
