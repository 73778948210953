import { Property, EndDeviceMessage, DeviceCountsHelper, DeviceTypeHelper, ConversionUtils, DeviceFamily } from '@ncss/models';

import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import * as _ from 'lodash';
import { combineLatest, Observable } from 'rxjs';
import { map, startWith, debounceTime } from 'rxjs/operators';

import { TestConnectMode, TestConnectService } from '../../../services/test-connect/test-connect.service';
import { IDirectConnectDevice } from './../../../services/direct-connect/baseDirectConnectDevice';
import { DirectConnectService } from './../../../services/direct-connect/direct-connect.service';
import { MobilePropertyService } from './../../../services/mobile-property.service';
import { DirectConnectInstructionMode } from './../../direct-connect-instructions/direct-connect-instructions.component';

interface IDeviceIdTile {
  id: number;
  idStr: string;
  icon: string;
}

@Component({
  selector: 'app-test-connect-modal',
  templateUrl: './test-connect-modal.component.html',
  styleUrls: ['./test-connect-modal.component.scss'],
})
export class TestConnectModalComponent implements OnInit, OnDestroy {

  @Input() property: Property;
  @Input() deviceTypes: 'meter' | 'network' | 'all' = 'all';

  public deviceIds$: Observable<IDeviceIdTile[]>;

  public DCInstructionModes: number[] = [
    DirectConnectInstructionMode.RR301_INSTRUCTION,
    DirectConnectInstructionMode.TR401_INSTRUCTION,
    DirectConnectInstructionMode.TR401_X_INSTRUCTION,
  ];

  private silencePopUpsOnClose = false;

  constructor(
    private propertyService: MobilePropertyService,
    private testConnectService: TestConnectService,
    public directConnectService: DirectConnectService,
    private modalCtrl: ModalController,
  ) { }

  ngOnInit() {
    if (!this.property) {
      this.property = this.propertyService.property;
    }
    this.silencePopUpsOnClose = this.directConnectService.popUpsSilenced;
    this.directConnectService.silenceDirectConnectPopups(true);
    if (this.deviceTypes === 'network') {
      this.DCInstructionModes = [
        DirectConnectInstructionMode.RE4_INSTRUCTION,
      ];
    } else if (this.deviceTypes === 'all') {
      this.DCInstructionModes = [
        DirectConnectInstructionMode.RR301_INSTRUCTION,
        DirectConnectInstructionMode.TR401_INSTRUCTION,
        DirectConnectInstructionMode.TR401_X_INSTRUCTION,
        DirectConnectInstructionMode.RE4_INSTRUCTION,
      ];
    }
    if (this.property) {
      this.testConnectService.setMode(TestConnectMode.PROPERTY, this.property.gateway.id);
    } else {
      this.testConnectService.setMode(TestConnectMode.READY);
    }
    this.deviceIds$ = combineLatest([
      this.testConnectService.devices$.pipe(
        map((arr) => this.deviceMsgsToIds(arr)),
      ),
      this.directConnectService.devices$.pipe(
        map((arr) => this.directConnectDevicesToIds(arr)),
      ),
    ]).pipe(
      debounceTime(50),
      map(([arr1, arr2]) => [...arr1, ...arr2]),
      map((ids) => _.uniqBy(ids, 'id')),
      startWith([]),
    );
  }

  ngOnDestroy() {
    this.testConnectService.stop();
  }

  public clear() {
    this.testConnectService.clear();
    this.directConnectService.clearDeviceList();
  }

  public onClose(serialNumber?: number) {
    this.directConnectService.silenceDirectConnectPopups(this.silencePopUpsOnClose);
    this.modalCtrl.dismiss(serialNumber);
  }

  private deviceMsgsToIds(msgs: EndDeviceMessage[]): IDeviceIdTile[] {
    const ids: IDeviceIdTile[] = [];
    _.forEach(msgs, (m) => {
      this.handleDeviceMsgForUnitContext(m, ids);
      this.handleDeviceMsgForNetworkContext(m, ids);
    });
    return ids.filter((i) => !!i);
  }

  private directConnectDevicesToIds(devices: IDirectConnectDevice[]): IDeviceIdTile[] {
    const ids: IDeviceIdTile[] = [];
    _.forEach(devices, (d) => {
      this.handleDirectConnectDeviceForMeterContext(d, ids);
      this.handleDirectConnectDeviceForNetworkContext(d, ids);
    });

    return ids.filter((i) => !!i);
  }

  private handleDirectConnectDeviceForNetworkContext(d: IDirectConnectDevice, ids: IDeviceIdTile[]): void {
    if (this.deviceTypes !== 'network' && this.deviceTypes !== 'all') { return; }
    const family = d.serialNumber ? DeviceTypeHelper.GetFamilyBySerialNumber(d.serialNumber) : null;
    if (family && family === DeviceFamily.REPEATER) {
      ids.push({
        id: d.serialNumber,
        idStr: d.serialNumber.toString(16).toUpperCase(),
        icon: DeviceTypeHelper.GetIconNameBySerialNumber(d.serialNumber) || 'icon-repeater',
      });
    }
  }

  private handleDirectConnectDeviceForMeterContext(d: IDirectConnectDevice, ids: IDeviceIdTile[]): void {
    if (this.deviceTypes !== 'meter' && this.deviceTypes !== 'all') { return; }
    if (d.serialNumber && DeviceCountsHelper.IsMeterReaderDevice(d.serialNumber, false)) {
      const deviceType = DeviceTypeHelper.GetDeviceTypeBySerialNumber(d.serialNumber);
      // convert rr base SN to both port 1 & 2 SNs
      if (deviceType && deviceType.family === DeviceFamily.REMOTE_READER &&
        ConversionUtils.GetBaseRRSerial(d.serialNumber) === d.serialNumber) {
        ids.push(
          {
            id: d.serialNumber + 1,
            idStr: (d.serialNumber + 1).toString(16).toUpperCase(),
            icon: DeviceTypeHelper.GetIconNameBySerialNumber(d.serialNumber) || 'icon-transceiver',
          },
          {
            id: d.serialNumber + 2,
            idStr: (d.serialNumber + 2).toString(16).toUpperCase(),
            icon: DeviceTypeHelper.GetIconNameBySerialNumber(d.serialNumber) || 'icon-transceiver',
          },
        );
      } else {
        ids.push({
          id: d.serialNumber,
          idStr: d.serialNumber.toString(16).toUpperCase(),
          icon: DeviceTypeHelper.GetIconNameBySerialNumber(d.serialNumber) || 'icon-transceiver',
        });
      }
    }
  }

  private handleDeviceMsgForUnitContext(m: EndDeviceMessage, ids: IDeviceIdTile[]): void {
    if (this.deviceTypes !== 'meter' && this.deviceTypes !== 'all') { return; }
    if (m.deviceLocation && m.deviceLocation.device && DeviceCountsHelper.IsMeterReaderDevice(m.deviceLocation.device.id, false)) {
      const icon = DeviceTypeHelper.GetIconNameBySerialNumber(m.deviceLocation.device.id) || 'icon-transceiver';
      ids.push({
        id: m.deviceLocation.device.id,
        idStr: m.deviceLocation.device.id.toString(16).toUpperCase(),
        icon,
      });
    } else if (m.deviceLocation && m.deviceLocation.device && m.deviceLocation.device.id) {
      const icon = DeviceTypeHelper.GetIconNameBySerialNumber(m.deviceLocation.device.id) || 'icon-leak-sensor';
      ids.push({
        id: m.deviceLocation.device.id,
        idStr: m.deviceLocation.device.id.toString(16).toUpperCase(),
        icon,
      });
    }
  }

  private handleDeviceMsgForNetworkContext(m: EndDeviceMessage, ids: IDeviceIdTile[]): void {
    if (this.deviceTypes !== 'network' && this.deviceTypes !== 'all') { return; }
    const family = m.deviceLocation && m.deviceLocation.device ?
      DeviceTypeHelper.GetFamilyBySerialNumber(m.deviceLocation.device.id) : null;
    if (family && family === DeviceFamily.REPEATER) {
      ids.push({
        id: m.deviceLocation.device.id,
        idStr: m.deviceLocation.device.id.toString(16).toUpperCase(),
        icon: DeviceTypeHelper.GetIconNameBySerialNumber(m.deviceLocation.device.id) || 'icon-repeater',
      });
    }
  }


}
