import { MeterConfig, DeviceTypeIds, DeviceTypes, IDeviceType, DeviceTypeHelper } from '@ncss/models';

import { Component, OnInit, OnDestroy, Input, ViewChild, AfterViewInit } from '@angular/core';
import { ModalController, AlertController, IonToggle, ActionSheetController } from '@ionic/angular';
import { ActionSheetButton } from '@ionic/core';
import { Observable } from 'rxjs';
import { map, filter, take, mapTo } from 'rxjs/operators';

import { MobileDevicesService } from './../../../services/devices/mobile-devices.service';
import { IDirectConnectDevice } from './../../../services/direct-connect/baseDirectConnectDevice';
import { EndDeviceFormState, MeterConfigLabels, IOpenAlert } from './../../../services/direct-connect/remoteReader/remoteReader';
import { DirectConnectTR4 } from './../../../services/direct-connect/tr4/tr4';
import { MobileUnitService } from './../../../services/mobile-unit.service';
import { MobileUserService } from './../../../services/mobile-user.service';
import { MagnetInstructionComponent } from './../remote-reader-modal/magnet-instruction/magnet-instruction.component';

@Component({
  selector: 'app-tr401-modal',
  templateUrl: './tr401-modal.component.html',
  styleUrls: ['./tr401-modal.component.scss'],
})
export class Tr401ModalComponent implements OnInit, OnDestroy, AfterViewInit {

  public TR401FormState = EndDeviceFormState;
  public MeterConfig = MeterConfig;
  public DeviceTypeIds = DeviceTypeIds;
  public DeviceTypes = DeviceTypes;
  public tabs: Array<{ name: string, value: string, icon: string, disabled$?: Observable<boolean> }> = [
    { name: 'Status', value: 'status', icon: 'icon-remote-reader' },
    { name: 'Wiring Guide', value: 'wiring', icon: 'icon-meter' },
  ];

  @ViewChild('resetPulseCount') public resetPulseCountToggle: IonToggle;
  @ViewChild('enableRapidCheckIn') public enableRapidCheckInToggle: IonToggle;
  @ViewChild('enable201Radio') public enable201RadioToggle: IonToggle;
  @Input() public connectedDevice: IDirectConnectDevice;

  public tr401: DirectConnectTR4;
  public showMoreDeviceInfo = false;
  public showMoreMeterInfo = false;
  public showAlerts = false;
  public selectedTab: 'wiring' | 'status' | 'program' = 'status';
  public imgUrl = 'TR401-Flat.svg';
  public deviceType: IDeviceType;

  private _subscriptions = [];
  private _quickActions: ActionSheetButton[] = [];

  constructor(
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    public mobileUserService: MobileUserService,
    private actionSheetCtrl: ActionSheetController,
    private devicesService: MobileDevicesService,
    private unitService: MobileUnitService,
  ) { }

  ngOnInit() {
    if (!this.connectedDevice) { return; }
    this.tr401 = this.connectedDevice.device as DirectConnectTR4;
    this.imgUrl = this.tr401.deviceModel === 'TR4-X' ? 'TR401-X-Flat.svg' : this.imgUrl;
    this.deviceType = DeviceTypeHelper.GetDeviceTypeBySerialNumber(this.tr401.serialNumber);
    this.fetchUnit();
    this.tabs = [
      ...this.tabs,
      {
        name: 'Program',
        value: 'program',
        icon: 'icon-property',
        disabled$: this.tr401.state$.pipe(map((s) => s === this.TR401FormState.DIRTY)),
      },
    ];
  }

  ngAfterViewInit() {
    this._subscriptions.push(
      this.tr401.resetPulseCount$.subscribe((val) => {
        setTimeout(() => {
          if (this.resetPulseCountToggle) {
            this.resetPulseCountToggle.checked = val;
          }
        }, 0);
      }),
      this.tr401.rapidCheckInEnabled$.subscribe((val) => {
        setTimeout(() => this.enableRapidCheckInToggle.checked = val, 0);
      }),
      this.tr401.use201Radio$.subscribe((val) => {
        setTimeout(() => {
          if (this.enable201RadioToggle) {
            this.enable201RadioToggle.checked = val;
          }
        }, 0);
      }),
    );
    this.initQuickActionButtons();
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  public enableRapidCheckInClicked(quickAction = false) {
    this.tr401.setRapidCheckInEnabled(!this.tr401.rapidCheckInEnabled);
    if (quickAction) {
      this.save(quickAction);
    }
  }

  public enable201RadioClicked() {
    this.tr401.setUse201Radio(!this.tr401.use201Radio);
  }

  public resetPulseCountClicked(quickAction = false) {
    this.tr401.setResetPulseCount(!this.tr401.resetPulseCount);
    if (quickAction) {
      this.save(quickAction);
    }
  }

  public async configTypeClicked(quickAction = false) {
    const alert = await this.alertCtrl.create({
      header: 'Select Signal Type',
      inputs: [
        {
          type: 'radio',
          label: MeterConfigLabels[MeterConfig.PULSE_IN],
          value: MeterConfig.PULSE_IN,
          checked: this.tr401.configType === MeterConfig.PULSE_IN,
        },
        {
          type: 'radio',
          label: MeterConfigLabels[MeterConfig.ENCODER_IN],
          value: MeterConfig.ENCODER_IN,
          checked: this.tr401.configType === MeterConfig.ENCODER_IN,
        },
      ],
      buttons: [
        { text: 'Cancel', role: 'cancel' },
        {
          text: 'ok',
          handler: (selectedConfig) => {
            if (selectedConfig !== this.tr401.configType) {
              this.tr401.setConfigType(selectedConfig);
            }
            if (quickAction) {
              this.save(quickAction);
            }
          },
        },
      ],
    });
    await alert.present();
  }

  public factorySleep() {
    if (this.mobileUserService.user && this.mobileUserService.user['manufacturingUser']) {
      this.tr401.factorySleep = true;
      this.save();
    }
  }

  public async save(quickAction = false) {
    this.tr401.applyChanges();
    const m = await this.modalCtrl.create({
      component: MagnetInstructionComponent,
      componentProps: {
        onWaitingConfirmation$: this.tr401.state$.pipe(
          map((s) => s === EndDeviceFormState.WAITING_CONFIRMATION),
        ),
        onChangesApplied$: this.tr401.state$.pipe(
          filter((s) => s === EndDeviceFormState.CHANGES_APPLIED),
          mapTo(true),
          take(1),
        ),
        serialNumber: this.tr401.serialNumber,
      },
    });
    m.present();
    const res = await m.onDidDismiss();
    if (quickAction || (res && res.data !== 'canceled')) {
      this.tr401.dropUserChanges();
    } else {
      this.tr401.markFormAsDirty();
    }
  }

  public close() {
    this.tr401.dropUserChanges();
    this.modalCtrl.dismiss();
  }

  public trackAlertsBy(alert: IOpenAlert) {
    return alert.typeStr;
  }

  public async quickActionsClicked() {
    this.initQuickActionButtons();
    const sheet = await this.actionSheetCtrl.create({
      header: 'Quick Actions',
      buttons: this._quickActions,
    });
    await sheet.present();
  }

  private initQuickActionButtons() {
    if (this.tr401.configType !== MeterConfig.ENCODER_IN) {
      this._quickActions = [
        { text: 'Set Signal Type', handler: () => { this.configTypeClicked(true); } },
        {
          text: `${this.tr401.rapidCheckInEnabled ? 'Disable' : 'Enable'} Rapid Check In`,
          handler: () => { this.enableRapidCheckInClicked(true); },
        },
        { text: 'Reset Pulse Count', handler: () => { this.resetPulseCountClicked(true); } },
      ];
    } else {
      this._quickActions = [
        { text: 'Set Signal Type', handler: () => { this.configTypeClicked(true); } },
        {
          text: `${this.tr401.rapidCheckInEnabled ? 'Disable' : 'Enable'} Rapid Check In`,
          handler: () => { this.enableRapidCheckInClicked(true); },
        },
      ];
    }
  }

  private async fetchUnit() {
    if (!this.tr401) { return; }
    const location = await this.devicesService.getDeviceLocation(this.tr401.serialNumber).toPromise();
    if (location && location.unit) {
      const u = await this.unitService.findFullUnitById(location.unit.id).toPromise();
      if (u) {
        this.tr401.setProgrammedUnit(u);
      }
    }
  }

}
