import { PipeLogic } from '@ncss/models';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'phoneNumber' })
export class PhoneNumberPipe implements PipeTransform  {
  public transform(value, args?) {
    return PipeLogic.PhoneNumber(value, args);
  }
}
