import { ByteList } from 'byte-list';

import { Status, StatusCode } from './status';

export const SyncProgressType = 0x04;

export class SyncProgress extends Status {

  type = SyncProgressType;
  progress?: number;
  syncedBytes?: number;

  constructor(progress?: number, total?: number) {
    super(StatusCode.SYNC_PROGRESS);
    this.progress = progress;
    this.syncedBytes = total;
  }

  serialize(): ByteList {
    const bytes = super.serialize();
    bytes.writeUInt32(this.progress || 0);
    bytes.writeUInt32(this.syncedBytes || 0);
    return bytes;
  }

  deserialize(bytes: ByteList): void {
    super.deserialize(bytes);
    this.progress = bytes.readUInt32();
    this.syncedBytes = bytes.readUInt32();
  }

}
