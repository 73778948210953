import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';

import { CameraResultStatus, CameraService, ICameraResult } from '../../services/camera.service';

export interface PhotoViewerOutput {
  action: 'delete' | 'update' | 'none';
  newBase64?: string;
}

@Component({
  selector: 'app-photo-viewer',
  templateUrl: './photo-viewer.component.html',
  styleUrls: ['./photo-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhotoViewerComponent {

  @Input() public title: string;
  @Input() public imageUrl: string;

  public showCropper = false;

  constructor(
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private cameraService: CameraService,
    private cd: ChangeDetectorRef,
  ) { }

  public headerButtonClicked(event) {
    this.modalCtrl.dismiss({ action: 'none' });
  }

  public toggleShowCropper(event) {
    this.showCropper = !this.showCropper;
  }

  public onCrop(base64CroppedImage) {
    this.modalCtrl.dismiss({ action: 'update', newBase64: base64CroppedImage });
  }

  public retakeImage() {
    this.cameraService.getPicture()
      .then(async (imgResult: ICameraResult) => {
        if (imgResult.status === CameraResultStatus.CAMERA_PICTURE || imgResult.status === CameraResultStatus.UPLOADED_PICTURE) {
          this.imageUrl = imgResult.base64Img;
          this.cd.markForCheck();
          this.modalCtrl.dismiss({ action: 'update', newBase64: this.imageUrl });
        }
      })
      .catch((err) => {
      });
  }

  public async deleteImage() {
    const alert = await this.alertCtrl.create({
      header: 'Are you sure',
      message: 'This is not reversible and the image cannot be recovered.',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        }, {
          text: 'Yes',
          handler: () => this.modalCtrl.dismiss({ action: 'delete' }),
        },
      ],
    });
    alert.present();
  }

}
