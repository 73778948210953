import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { AnimatedContainersModule } from '../../../../components/animated-containers/animated-containers.module';
import { GrowContainerModule } from '../../../../components/animations/grow-container/growContainer.component';
import { ComponentsModule } from '../../../../components/components.module';
import { CustomInputPopoverModule } from '../../../../components/custom-input-popover/custom-input-popover.module';
import { HopsAwayModule } from '../../../../components/device-details/hops-away/hops-away.module';
import { FormRowWrapperModule } from '../../../../components/form-row-wrapper/form-row-wrapper.module';
import { ModalHeaderModule } from '../../../../components/modal-header/modal-header.module';
import { ModalPagerModule } from '../../../../components/modal-pager/modal-pager.module';
import { NumericInputModule } from '../../../../components/numeric-input/numeric-input.module';
import { PhotoViewerModule } from '../../../../components/photo-viewer/photo-viewer.module';
import { RefresherModule } from '../../../../components/refresher/refresher.module';
import { SerialNumberInputModule } from '../../../../components/serial-number-input/serial-number-input.module';
import { UnitNotesComponent } from '../../../../components/unit-notes/unit-notes.component';
import { UnitNotesModule } from '../../../../components/unit-notes/unit-notes.module';
import { PipesModule } from '../../../../pipes/pipes.module';
import { EquipmentTileStatsModule } from '../../../../tiles/equipment-tile-stats/equipment-tile-stats.module';
import { EquipmentTabsComponent } from './equipment-tabs/equipment-tabs.component';
import { LeakSensorDetailsComponent } from './leak-sensor-details/leak-sensor-details.component';
import { MeterDetailsComponent } from './meter-details/meter-details.component';
import { MeterFormComponent } from './meter-form/meter-form.component';
import { NoEquipmentComponent } from './no-equipment/no-equipment.component';
import { OpenAlertRowComponent } from './open-alert-row/open-alert-row.component';
import { ReadHistoryChartComponent } from './read-history-chart/read-history-chart.component';
import { UnitDetailsComponent } from './unit-details.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    ModalHeaderModule,
    ModalPagerModule,
    PipesModule,
    NumericInputModule,
    AnimatedContainersModule,
    PhotoViewerModule,
    EquipmentTileStatsModule,
    HopsAwayModule,
    CustomInputPopoverModule,
    UnitNotesModule,
    PipesModule,
    GrowContainerModule,
    CustomInputPopoverModule,
    PhotoViewerModule,
    RefresherModule,
    ComponentsModule,
    SerialNumberInputModule,
    FormRowWrapperModule,
  ],
  declarations: [
    UnitDetailsComponent,
    ReadHistoryChartComponent,
    MeterDetailsComponent,
    NoEquipmentComponent,
    EquipmentTabsComponent,
    MeterFormComponent,
    LeakSensorDetailsComponent,
    OpenAlertRowComponent,
  ],
  providers: [],
  entryComponents: [UnitDetailsComponent, UnitNotesComponent],
  exports: [UnitDetailsComponent],

})
export class UnitDetailsModule { }
