import {
  RR301InfoMessageType,
  Utils,
  MeterConfig,
  RR3MessageType,
  LCD_ALWAYS_ON_KEY,
  METER_1_PULSE_RESET_KEY,
  METER_2_PULSE_RESET_KEY,
  BitwiseHelper,
  DeviceTypeHelper,
  RR3,
  RR301Info,
  TR4Info,
  TR4,
  DeviceTypeIds,
  TR4InfoMessageType,
  TR4MessageType,
  IDeviceType,
} from '@ncss/models';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BLE } from '@ionic-native/ble/ngx';
import { AlertController, Events, LoadingController, ModalController } from '@ionic/angular';
import { ByteList } from 'byte-list';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BehaviorSubject, Subscription, Subject, timer } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';

import { Gateway301ModalComponent } from '../../components/direct-connect-modals/gateway301-modal/gateway301-modal.component';
import { RemoteReaderModalComponent } from '../../components/direct-connect-modals/remote-reader-modal/remote-reader-modal.component';
import { Rr4ModalComponent } from '../../components/direct-connect-modals/rr4-modal/rr4-modal.component';
import { DirectConnectPopoverEvent } from '../../components/direct-connect-popover/direct-connect-popover.component';
import { AppSettingsService, IAppSettings } from '../app-settings.service';
import { BarcodeScannerService } from '../barcode-scanner.service';
import { FeedbackService, FeedbackType } from '../feedback.service';
import { Gw4ModalComponent } from './../../components/direct-connect-modals/gw4-modal/gw4-modal.component';
import { Re4ModalComponent } from './../../components/direct-connect-modals/re4-modal/re4-modal.component';
import { ReconnectModalComponent } from './../../components/direct-connect-modals/reconnect-modal/reconnect-modal.component';
import { Tr4eModalComponent } from './../../components/direct-connect-modals/tr4-e-modal/tr4-e-modal.component';
import { Tr401ModalComponent } from './../../components/direct-connect-modals/tr401-modal/tr401-modal.component';
import { MobileUserService } from './../mobile-user.service';
import {
  DirectConnectBaseDevice,
  DirectConnectDeviceStatus,
  IDirectConnectDevice,
  ISetupByteHandlerResult,
} from './baseDirectConnectDevice';
import { DirectConnect301 } from './directConnect301/directConnect301';
import { EndDeviceMessage } from './directConnect301/messages/endDeviceMessage';
import { InfoMessage } from './directConnect301/messages/infoMessage';
import { DirectConnectGateway301 } from './gateway301/gateway301';
import { DirectConnectGW4 } from './GW4/GW4';
import { NcssEncryptPlugin } from './NcssEncryptPlugin.service';
import { DirectConnectRE4 } from './RE4/RE4';
import { DirectConnectRemoteReader, EndDeviceFormState } from './remoteReader/remoteReader';
import { DirectConnectRR4 } from './RR4/RR4';
import { DirectConnectTR4E } from './tr4-e/tr4-e';
import { DirectConnectTR4 } from './tr4/tr4';

export enum DirectConnectStatus {
  DISABLED,
  READY,
  SCANNING,
  CONNECTING,
  CONNECTED,
}

const SCAN_TIMEOUT = 5 * 1000; // seconds
const RESCAN_TIMEOUT = 20 * 1000; // seconds
const STALE_DEVICE_TIMEOUT = 60; // seconds
const DEVICE_POPUP_SIGNAL_THRESH = 70; // percent
const CREATE_FACTORIES: { (bleDevice: any, manufacturerData: { serialNumber: number, byteList: ByteList }, isLimited?: boolean) }[] = [
  DirectConnectGateway301.create,
  DirectConnect301.create,
  DirectConnectRemoteReader.create,
  DirectConnectTR4.create,
  DirectConnectRR4.create,
  DirectConnectRE4.create,
  DirectConnectGW4.create,
  DirectConnectTR4E.create,
];
const DEVICE_NAMES = ['RR3', 'DC301', 'GW301', 'TR4', 'TR4-X', 'TR4-I', 'RE4', 'RR4', 'GW4', 'GW4-L', 'TR4-E'];

const LIMITED_CREATE_FACTORIES: {
  (bleDevice: any, manufacturerData: { serialNumber: number, byteList: ByteList }, isLimited?: boolean)
}[] = [
    DirectConnect301.create,
    DirectConnectRemoteReader.create,
    DirectConnectRR4.create,
  ];
const LIMITED_DEVICE_NAMES = ['RR3', 'DC301', 'RR4'];

const MANUFACTURING_SERVER = 'http://10.1.50.2:3000';

interface IDC301TestInfo {
  id: number;
  firmware: { major: number, minor: number };
  hardware: { major: number, minor: number };
  firmwareBle: { major: number, minor: number };
  batteryLevel: number;
  bleSignalStrength: number;
}

@Injectable({
  providedIn: 'root',
})
export class DirectConnectService {

  public get devices() { return this._devices$.value; }
  public get devices$() { return this._devices$.asObservable(); }

  public get connectedDevice() { return this._connectedDevice$.value; }
  public get connectedDevice$() { return this._connectedDevice$.asObservable(); }

  public get pairedProgrammer() { return this._pairedProgrammer$.value; }
  public get pairedProgrammer$() { return this._pairedProgrammer$.asObservable(); }

  public limitedAccess = false;
  public isScanning = false;
  public popUpsSilenced = false;

  private _devices$: BehaviorSubject<Array<IDirectConnectDevice>> = new BehaviorSubject([]);
  private _connectedDevice$: BehaviorSubject<IDirectConnectDevice> = new BehaviorSubject(null);
  private _pairedProgrammer$ = new BehaviorSubject<IDirectConnectDevice>(null);
  private _modal: HTMLIonModalElement;
  private _txBuffer = new ByteList();
  private _autoPairingWithProgrammer = false;
  private _endDeviceMessageSubscription: Subscription;
  private _requestBroadcastSubscription: Subscription;
  private _scanningEnabled = true;
  private _attemptingReconnect: string = null;
  private reconnectSuccess = new Subject<IDirectConnectDevice>();
  private _subscriptions: Subscription[] = [];
  private _latestUserId: string;
  private _reconnectingText$ = new BehaviorSubject<string>('');

  constructor(
    private ble: BLE,
    private events: Events,
    private appSettings: AppSettingsService,
    private loadingCtrl: LoadingController,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private feedback: FeedbackService,
    private mobileUserService: MobileUserService,
    private barcodeScanner: BarcodeScannerService,
    private httpService: HttpClient,
    private encryptPlugin: NcssEncryptPlugin,
  ) { }

  public init(userId?: string) {
    if (this._latestUserId === userId) {
      return;
    }
    this._latestUserId = userId;
    this._subscriptions.push(
      this.mobileUserService.isDirectConnectOnlyUser$.subscribe((isDCUser) => {
        this.limitedAccess = isDCUser;
        this._devices$.next([]);
        this.scanForDevices();
      }),
      // Subscribe to app settings changes
      this.appSettings.appSettings$.subscribe((settings: IAppSettings) => {
        if (this.appSettings.isIos && AppSettingsService.isFeatureEnabled(settings.ble) ||
          this.appSettings.isAndroid && AppSettingsService.isFeatureEnabled(settings.gps)) {
          this._scanningEnabled = true;
          this.scanForDevices();
        } else {
          this._scanningEnabled = false;
          this._devices$.next([]);
        }
      }),
      this._devices$.subscribe((devices) => {
        if (this.appSettings.appSettings.pairedProgrammerDeviceId && !this.pairedProgrammer && !this._autoPairingWithProgrammer) {
          const device =
            _.find(devices, (d) => d.device && d.device.serialNumber === this.appSettings.appSettings.pairedProgrammerDeviceId);
          if (device) {
            this._autoPairingWithProgrammer = true;
            this.pairProgrammer(device);
          }
        }
      }),
      this._pairedProgrammer$.subscribe((pairedProgrammed) => {
        if (this.pairedProgrammer) {
          console.log('%c DEVICE PAIRED', 'color: yellow');
          this._endDeviceMessageSubscription = (this.pairedProgrammer.device as DirectConnect301).onEndDeviceMessage$.subscribe((msg) => {
            console.log(`%c ${moment().format('hh:mm:ss')} RX EndDeviceMessage`, 'color: magenta', msg);
            this.checkForBleConnectDevices(this.getBleDeviceFromEndDeviceMessage(msg));
            if (this.connectedDevice && this.connectedDevice.isEndDevice && this.connectedDevice.serialNumber === msg.srcAddr) {
              console.log(`%c Connected Device:`, 'color: magenta', this.connectedDevice);
              this.handleConnectedDeviceMsg(this.connectedDevice, msg);
            }
          });
          this._devices$.next(_.filter(this.devices, (d) => d.bleDevice.id !== pairedProgrammed.bleDevice.id));
          this.checkForManufacturingTest();

          this._requestBroadcastSubscription = timer(3 * 1000, 10 * 1000).subscribe(() => {
            if (this.pairedProgrammer && this.pairedProgrammer.device && !this.connectedDevice) {
              (this.pairedProgrammer.device as DirectConnect301).requestBroadcast();
            }
          });
        } else {
          console.log('%c DEVICE UN-PAIRED', 'color: yellow');
          if (this._endDeviceMessageSubscription) {
            this._endDeviceMessageSubscription.unsubscribe();
          }
          if (this._requestBroadcastSubscription) {
            this._requestBroadcastSubscription.unsubscribe();
          }

          // Remove end devices from list since we can no longer connect to them.
          this.removeEndDeviceEntries();

          // If we are currently connected to and end device, disconnect it.
          if (this._connectedDevice$.value && this._connectedDevice$.value.isEndDevice) {
            this.disconnectEndDevice();
          }
        }
      }),
    );
    this.unPairProgrammer(false);
  }

  public destroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
    this.unPairProgrammer(false);
    this._devices$.next([]);
    this._subscriptions = [];
  }

  public clearDeviceList(): void {
    this._devices$.next([]);
  }

  public scanForDevices() {
    if (this.isScanning || !this._scanningEnabled) {
      return;
    }

    // Start Scanning
    this.isScanning = true;
    this.ble.startScan([]).subscribe(bleDevice => {
      this.checkForBleConnectDevices(bleDevice);
    }, () => {
      this.isScanning = false;
    });

    // Scanning Timeout
    setTimeout(() => {
      this.ble.stopScan().then(() => {
        this.removeStaleEntries();
        this.isScanning = false;
        setTimeout(() => {
          this.scanForDevices();
        }, RESCAN_TIMEOUT);
      });
    }, SCAN_TIMEOUT);
  }

  public setScanningEnabled(val) {
    if (val && this.appSettings.appSettings &&
      AppSettingsService.isFeatureEnabled(this.appSettings.appSettings.ble) &&
      AppSettingsService.isFeatureEnabled(this.appSettings.appSettings.gps)) {
      this._scanningEnabled = true;
    } else {
      this._scanningEnabled = false;
    }
  }

  public async pairProgrammer(directConnectDevice: IDirectConnectDevice) {
    if (!directConnectDevice || !directConnectDevice.bleDevice || !directConnectDevice.bleDevice.id) { return; }
    const connectionSuccessful = await this.connectBleDevice(directConnectDevice);
    if (connectionSuccessful) {
      this._autoPairingWithProgrammer = false;
      this.appSettings.setAppSettings({
        pairedProgrammerBleId: directConnectDevice.bleDevice.id,
        pairedProgrammerDeviceId: directConnectDevice.serialNumber,
      });
      this._pairedProgrammer$.next(directConnectDevice);
    }
  }

  public async unPairProgrammer(softUnprogram = true) {
    if (this._pairedProgrammer$.value) {
      await this.disconnectBleDevice(this._pairedProgrammer$.value);
    }

    this._pairedProgrammer$.next(null);
    this._connectedDevice$.next(null);

    // Cleans up connected programmer during live reloads (for development)
    if (this.appSettings.appSettings.pairedProgrammerBleId) {
      await this.ble.disconnect(this.appSettings.appSettings.pairedProgrammerBleId);
    }

    if (softUnprogram === false) {
      return;
    }

    this.appSettings.appSettings.pairedProgrammerDeviceName = null;
    this.appSettings.appSettings.pairedProgrammerDeviceId = null;
    this.appSettings.appSettings.pairedProgrammerBleId = null;
    this._autoPairingWithProgrammer = false;
    this.appSettings.updateAppSettings();
  }

  public async connectDevice(directConnectDevice: IDirectConnectDevice) {
    if (!directConnectDevice || !directConnectDevice.bleDevice || !directConnectDevice.bleDevice.id) {
      return;
    }
    const previousPopupSilencedValue = this.popUpsSilenced;
    this.silenceDirectConnectPopups(true);
    directConnectDevice.status = DirectConnectDeviceStatus.CONNECTING;
    const id = directConnectDevice.serialNumber;
    let loading = await this.loadingCtrl.create({
      message: id ? `Connecting to ${id.toString(16).toUpperCase()}...` : 'Connecting to device...',
    });
    loading.present();

    const loadingTimeout = setTimeout(() => {
      if (loading) {
        loading.dismiss().then(() => {
          loading = null;
        });
        directConnectDevice.isEndDevice ? this.disconnectEndDevice() : this.disconnectBleDevice();
        this.onFailedToConnectDevice(directConnectDevice);
      }
    }, 15 * 1000);
    // Connect to device and show loading message for at least 1 second
    // @ts-ignore
    const [time, connectSuccess] = await Promise.all([
      directConnectDevice.isEndDevice ? null : new Promise((resolve) => setTimeout(resolve, 1000)),
      directConnectDevice.isEndDevice ? this.connectEndDevice(directConnectDevice) : this.connectBleDevice(directConnectDevice),
    ]);
    if (connectSuccess) {
      clearTimeout(loadingTimeout);
      if (loading) {
        await loading.dismiss();
      }
      this._connectedDevice$.next(directConnectDevice);
      this._modal = await this.modalCtrl.create({
        component: this.getModalComponent(directConnectDevice),
        componentProps: {
          connectedDevice: directConnectDevice,
        },
      });
      this._modal.onDidDismiss().then((res) => {
        if (res.role === 'error') {
          this.onFailedToConnectDevice(directConnectDevice);
        }
        directConnectDevice.isEndDevice ? this.disconnectEndDevice() : this.disconnectBleDevice();
        this.silenceDirectConnectPopups(previousPopupSilencedValue);
      });
      this._modal.present();
    } else {
      clearTimeout(loadingTimeout);
      if (loading) {
        await loading.dismiss();
        this.onFailedToConnectDevice(directConnectDevice);
      }
      this.silenceDirectConnectPopups(previousPopupSilencedValue);
    }
  }

  private async onFailedToConnectDevice(device: IDirectConnectDevice) {
    this._connectedDevice$.next(null);
    const id = device.serialNumber;
    const alert = await this.alertCtrl.create({
      header: '',
      subHeader: '',
      message: id ? `Failed to connect to ${id.toString(16).toUpperCase()}` : 'Failed to connect..',
      buttons: ['Ok'],
    });
    await alert.present();
    let devices = [...this.devices];
    devices = devices.filter((d) => d.device.serialNumber !== device.device.serialNumber);
    this._devices$.next(devices);
  }

  public async disconnectEndDevice() {
    if (this._modal) {
      this._modal.dismiss();
      this._modal = null;
    }
    if (this.connectedDevice) {
      this.feedback.HapticFeedback(FeedbackType.BLE_DISCONNECT);
      this._connectedDevice$.next(null);
    }
  }

  public async disconnectBleDevice(directConnectDevice?: IDirectConnectDevice) {
    let connectedDevice: IDirectConnectDevice;
    if (!directConnectDevice) {
      connectedDevice = await this._connectedDevice$.getValue();
    } else {
      connectedDevice = directConnectDevice;
    }

    if (!connectedDevice || !connectedDevice.bleDevice || !connectedDevice.bleDevice.id) {
      this._connectedDevice$.next(null);
      return;
    }

    if (this._modal) {
      this._modal.dismiss();
      this._modal = null;
    }

    try {
      await this.ble.stopNotification(connectedDevice.bleDevice.id, connectedDevice.device.uuid.service, connectedDevice.device.uuid.read);
      await this.ble.disconnect(connectedDevice.bleDevice.id);
    } catch (e) {
      console.log(e);
    }
    this.feedback.HapticFeedback(FeedbackType.BLE_DISCONNECT);
    connectedDevice.subscriptions.forEach((s) => s.unsubscribe());
    connectedDevice.timers.forEach((t) => {
      clearInterval(t);
      clearTimeout(t);
    });

    connectedDevice.status = DirectConnectDeviceStatus.ADVERTISING;
    this._connectedDevice$.next(null);
  }

  public updatePopoverTimestamp(device: IDirectConnectDevice) {
    let deviceList = [...this._devices$.value];
    const existingDeviceIndex = _.findIndex(deviceList, { serialNumber: device.serialNumber }) as number;

    deviceList[existingDeviceIndex].popoverTimestamp = new Date();
    deviceList = _.orderBy(deviceList, ['bleSignalStrength'], ['desc']);
    this._devices$.next(deviceList);
  }

  public silenceDirectConnectPopups(value: boolean) {
    this.popUpsSilenced = value;
  }

  private connectEndDevice(directConnectDevice: IDirectConnectDevice): Promise<boolean> {
    return new Promise((resolve) => {
      this.feedback.HapticFeedback(FeedbackType.BLE_CONNECT);
      directConnectDevice.status = DirectConnectDeviceStatus.CONNECTED;
      resolve(true);
    });
  }

  private async attemptReconnectToBLEDevice(directConnectDevice: IDirectConnectDevice): Promise<IDirectConnectDevice> {
    if (!directConnectDevice || !directConnectDevice.bleDevice || !directConnectDevice.bleDevice.id) { return null; }
    const deviceFamily = DeviceTypeHelper.GetFamilyBySerialNumber(directConnectDevice.device.serialNumber);
    this._attemptingReconnect = directConnectDevice.bleDevice.id;
    this.scanForDevices();
    const reconnectModal = await this.modalCtrl.create({
      component: ReconnectModalComponent,
      componentProps: {
        reconnectingText$: this._reconnectingText$,
        deviceFamily,
      },
    });
    const canceled$ = new Subject<Boolean>();
    reconnectModal.onDidDismiss().then((res) => {
      if (res && res.data === 'cancel') {
        canceled$.next(true);
        canceled$.complete();
      }
    });
    await reconnectModal.present();
    this._reconnectingText$.next('Scanning for device...');
    const reconnected: IDirectConnectDevice = await this.reconnectSuccess.pipe(
      filter((device) => device.bleDevice.id === directConnectDevice.bleDevice.id),
      take(1),
      takeUntil(canceled$),
    ).toPromise();

    if (reconnected && reconnected.device) {
      reconnectModal.dismiss();
      this._attemptingReconnect = null;
      reconnected.device.isDisconnected = false;
      this._connectedDevice$.next(reconnected);
      this._reconnectingText$.next('');
      return reconnected;
    } else {
      this._attemptingReconnect = null;
      this._connectedDevice$.next(null);
      this._reconnectingText$.next('');
      return null;
    }
  }

  public async reconnectToDisconnectedBLEDevice(directConnectDevice: IDirectConnectDevice) {
    if (!directConnectDevice || !directConnectDevice.bleDevice || !directConnectDevice.bleDevice.id) { return; }
    this.clearDirectConnectDeviceSubscriptions(directConnectDevice);
    this._reconnectingText$.next('Connecting...');
    this.ble.connect(directConnectDevice.bleDevice.id).pipe(
    ).subscribe(
      () => {
        this._reconnectingText$.next('Connected!');
        const device = directConnectDevice.device as DirectConnectBaseDevice;
        this.feedback.HapticFeedback(FeedbackType.BLE_CONNECT);
        directConnectDevice.status = DirectConnectDeviceStatus.CONNECTED;
        const result: ISetupByteHandlerResult = device.setupByteHandlers(
          this.ble.startNotification(directConnectDevice.bleDevice.id, device.uuid.service, device.uuid.read),
        );
        directConnectDevice.subscriptions = [
          result.rxBytes,
          result.txBytes.subscribe((bytes) => this.sendBytes(bytes, directConnectDevice)),
        ];
        const t = setInterval(() => {
          try {
            this.ble.readRSSI(directConnectDevice.bleDevice.id).then((rssi) => {
              device.updateSignalStrength(DirectConnectBaseDevice.rssiToSignalStrength(rssi));
            });
          } catch (e) {
            clearInterval(t);
          }
        }, 5000);
        directConnectDevice.timers = [t];
        this.reconnectSuccess.next(directConnectDevice);
        this._connectedDevice$.next(directConnectDevice);
      },
      (err) => {
        this.clearDirectConnectDeviceSubscriptions(directConnectDevice);
        directConnectDevice.status = DirectConnectDeviceStatus.ADVERTISING;

        if (this._modal && !directConnectDevice.keepModalOpenOnDisconnect) {
          this._modal.dismiss();
          this._modal = null;
        } else if (this._modal) {
          directConnectDevice.device.isDisconnected = true;
          directConnectDevice.device.attemptReconnect = () => this.attemptReconnectToBLEDevice(directConnectDevice);
        }
      },
    );
  }

  private async connectBleDevice(directConnectDevice: IDirectConnectDevice): Promise<boolean> {
    const device = directConnectDevice.device as DirectConnectBaseDevice;
    device.updateSignalStrength(directConnectDevice.bleSignalStrength);
    return new Promise((resolve) => {
      this.ble.connect(directConnectDevice.bleDevice.id).subscribe(() => {
        // Device has connected
        this.feedback.HapticFeedback(FeedbackType.BLE_CONNECT);
        directConnectDevice.status = DirectConnectDeviceStatus.CONNECTED;
        const result: ISetupByteHandlerResult = device.setupByteHandlers(
          this.ble.startNotification(directConnectDevice.bleDevice.id, device.uuid.service, device.uuid.read),
        );

        // Add subscriptions to array and subscribe to tx bytes.
        directConnectDevice.subscriptions.push(
          result.rxBytes,
          result.txBytes.subscribe((byteList: ByteList) => {
            this.sendBytes(byteList, directConnectDevice);
          }),
        );

        setTimeout(() => {
          device.EncryptPlugin = this.encryptPlugin;
          device.requestInfo();
        }, 100);

        // Setup RSSI Check Timer
        const t = setInterval(() => {
          try {
            this.ble.readRSSI(directConnectDevice.bleDevice.id).then((rssi) => {
              device.updateSignalStrength(DirectConnectBaseDevice.rssiToSignalStrength(rssi));
            });
          } catch (e) {
            clearInterval(t);
          }
        }, 5000);
        directConnectDevice.timers.push(t);

        resolve(true);
      }, (err) => {
        // Disconnected Device
        this.clearDirectConnectDeviceSubscriptions(directConnectDevice);
        directConnectDevice.status = DirectConnectDeviceStatus.ADVERTISING;

        if (this._modal && !directConnectDevice.keepModalOpenOnDisconnect) {
          this._modal.dismiss();
          this._modal = null;
        } else if (this._modal) {
          directConnectDevice.device.isDisconnected = true;
          directConnectDevice.device.attemptReconnect = () => this.attemptReconnectToBLEDevice(directConnectDevice);
        }

        if (directConnectDevice.bleDevice.id === this.appSettings.appSettings.pairedProgrammerBleId) {
          this.unPairProgrammer(false);
        }

        resolve(false);
      });
    });
  }

  private sendBytes(byteList: ByteList, directConnectDevice: IDirectConnectDevice) {
    const index = this._txBuffer.index;
    this._txBuffer.concat(byteList);
    this._txBuffer.index = index;

    // Tx Bytes Recursive function
    const txBytes = () => {
      const length = this._txBuffer.length > 20 ? 20 : this._txBuffer.length;
      const tmp = this._txBuffer.readBytes(length);
      this._txBuffer.trimLeft(length);
      const bytes = this.arrayToBuffer(tmp);

      this.ble.writeWithoutResponse(
        directConnectDevice.bleDevice.id, directConnectDevice.device.uuid.service,
        directConnectDevice.device.uuid.write, bytes).then(() => {
          if (this._txBuffer.length > 0) {
            txBytes();
          } else {
            this._txBuffer = new ByteList();
          }
        });
    };
    txBytes();
  }

  private arrayToBuffer(buffer: Buffer): ArrayBuffer | any {
    return buffer.buffer.slice(
      buffer.byteOffset, buffer.byteOffset + buffer.byteLength,
    );
  }

  private checkForBleConnectDevices(bleDevice: any) {
    let device: IDirectConnectDevice = null;
    const manufacturerData = DirectConnectBaseDevice.getManufacturerData(bleDevice);
    if ((manufacturerData && manufacturerData.serialNumber) ||
      this.isBleDeviceTypeByName(bleDevice, this.limitedAccess ? LIMITED_DEVICE_NAMES : DEVICE_NAMES)) {
      _.forEach(!this.limitedAccess ? CREATE_FACTORIES : LIMITED_CREATE_FACTORIES, (func) => {
        if (device) {
          return false;
        }
        device = func(bleDevice, manufacturerData, this.limitedAccess);
      });
    }

    if (device) {
      device.scanTimestamp = new Date();
      device.bleSignalStrength = device.bleSignalStrength || DirectConnectBaseDevice.rssiToSignalStrength(device.bleDevice.rssi);
      if (device.device instanceof DirectConnectTR4E) {
        device.device.pairedProgrammer = this.pairedProgrammer.device;
      }
      this.addUpdateDevice(device);
    }
  }

  private addUpdateDevice(device: IDirectConnectDevice) {
    device.isEndDevice = this.isBleDeviceTypeByName(device.bleDevice, ['RR3', 'TR4', 'TR4-X']);
    let deviceList = [...this._devices$.value];
    const existingDeviceIndex = _.findIndex(deviceList, { serialNumber: device.serialNumber }) as number;

    if (!this._scanningEnabled) {
      return;
    }

    if (existingDeviceIndex >= 0) {
      // Update current device in list
      device.popoverTimestamp = deviceList[existingDeviceIndex].popoverTimestamp;
      device.scanTimestamp = deviceList[existingDeviceIndex].scanTimestamp;
      deviceList[existingDeviceIndex] = { ...device };
    } else {
      // Add new device to list
      deviceList.push(device);
    }

    // Direct Connect Popover
    if (!this.limitedAccess && this.appSettings.appSettings.bleProximity && device.bleSignalStrength > DEVICE_POPUP_SIGNAL_THRESH &&
      !device.popoverTimestamp && !this.popUpsSilenced) {
      this.events.publish(DirectConnectPopoverEvent.Show, device);
    }

    deviceList = _.orderBy(deviceList, ['bleSignalStrength'], ['desc']);
    this._devices$.next(deviceList);
    if (this._attemptingReconnect === device.bleDevice.id) {
      this.reconnectToDisconnectedBLEDevice(device);
    }
  }

  private removeEndDeviceEntries() {
    let deviceList = [...this._devices$.value];

    _.remove(deviceList, (device: IDirectConnectDevice) => {
      return device.isEndDevice && device.isEndDevice === true;
    });

    deviceList = _.orderBy(deviceList, ['bleSignalStrength'], ['desc']);
    this._devices$.next(deviceList);
  }

  private removeStaleEntries() {
    let deviceList = [...this._devices$.value];
    const currentTime = new Date();

    _.remove(deviceList, (device: IDirectConnectDevice) => {
      const timediff = (currentTime.getTime() - device.scanTimestamp.getTime()) / 1000;
      return timediff > STALE_DEVICE_TIMEOUT;
    });

    deviceList = _.orderBy(deviceList, ['bleSignalStrength'], ['desc']);
    this._devices$.next(deviceList);
  }

  private getModalComponent(device: IDirectConnectDevice) {
    if (!device || !device.serialNumber) {
      return null;
    }
    const deviceType: IDeviceType = DeviceTypeHelper.GetDeviceTypeBySerialNumber(device.serialNumber);
    switch (deviceType.id) {
      case DeviceTypeIds.REMOTE_READER:
      case DeviceTypeIds.REMOTE_READER_TRANSCEIVER:
        return RemoteReaderModalComponent;
      case DeviceTypeIds.TR4:
      case DeviceTypeIds.TR4_X:
      case DeviceTypeIds.TR4_I:
        return Tr401ModalComponent;
      case DeviceTypeIds.TR4_E:
        return Tr4eModalComponent;
      case DeviceTypeIds.GATEWAY_301:
        return Gateway301ModalComponent;
      case DeviceTypeIds.RR4:
      case DeviceTypeIds.RR4_TR:
        return Rr4ModalComponent;
      case DeviceTypeIds.RE4:
        return Re4ModalComponent;
      case DeviceTypeIds.GW4:
      case DeviceTypeIds.GW4_LITE:
        return Gw4ModalComponent;
      default:
        return null;
    }
  }

  private getBleDeviceFromEndDeviceMessage(msg: EndDeviceMessage) {
    const device = {
      name: DeviceTypeHelper.GetModelBySerialNumber(msg.srcAddr),
      id: msg.srcAddr,
      rssi: msg.firstHopRssi,
      msg,
    };
    return device;
  }

  private isBleDeviceTypeByName(bleDevice: any, name: String[]): boolean {
    if (!bleDevice || !bleDevice.name) {
      return false;
    }

    let found = false;

    _.forEach(name, (n) => {
      if ((bleDevice.name && bleDevice.name.indexOf(n) >= 0)
        || (bleDevice.advertising && bleDevice.advertising.kCBAdvDataLocalName
          && bleDevice.advertising.kCBAdvDataLocalName.indexOf(n) >= 0)) {
        found = true;
        return false;
      }
    });

    return found;
  }

  private handleConnectedDeviceMsg(device: IDirectConnectDevice, msg: EndDeviceMessage) {
    const type = DeviceTypeHelper.GetDeviceTypeBySerialNumber(device.serialNumber);
    if (!type) { return; }
    if (type.id === DeviceTypeIds.REMOTE_READER || type.id === DeviceTypeIds.REMOTE_READER_TRANSCEIVER) {
      const remoteReader = device.device as DirectConnectRemoteReader;
      remoteReader.updateFromMessage(msg);
      if (remoteReader.state === EndDeviceFormState.APPLYING_CHANGES) {
        this.handleRR3Ack(msg, remoteReader);
      }
    } else if (type.id === DeviceTypeIds.TR4 || type.id === DeviceTypeIds.TR4_X || type.id === DeviceTypeIds.TR4_I) {
      const tr401 = device.device as DirectConnectTR4;
      tr401.updateFromMessage(msg);
      if (tr401.state === EndDeviceFormState.APPLYING_CHANGES) {
        this.handleTR4Ack(msg, tr401);
      }
    } else if (type.id === DeviceTypeIds.TR4_E) {
      const tr4e = device.device as DirectConnectTR4E;
      if (tr4e.state === EndDeviceFormState.APPLYING_CHANGES) {
        tr4e.changesSuccessfullyApplied(msg);
      } else {
        tr4e.updateFromMessage(msg);
      }
    }
  }

  private handleTR4Ack(msg: EndDeviceMessage, tr401: DirectConnectTR4) {
    if (msg.msgType !== TR4MessageType.USER_EVENT) { return; }
    msg.device = msg.device as TR4;
    const res = new InfoMessage(msg.srcAddr, TR4InfoMessageType.DC301);
    res.info = res.info as TR4Info;
    res.info.userMsgResponse = true;
    res.info.serialNumber = msg.srcAddr;
    res.info.configType = tr401.configType;
    res.info.checkInInterval = msg.device.checkInInterval;

    if (tr401.resetPulseCount) {
      res.info.resetPulseCount = true;
    }

    if (!msg.device.rapidCheckInEnabled && tr401.rapidCheckInEnabled) {
      // start rapid check in
      res.info.startRapidCheckIn = true;
    } else if (msg.device.rapidCheckInEnabled && !tr401.rapidCheckInEnabled) {
      res.info.stopRapidCheckIn = true;
    }

    if (tr401.userChanges.hasOwnProperty('configType')) {
      res.info.setInfo = true;
      res.info.configType = tr401.configType === null ? MeterConfig.PULSE_IN : tr401.configType;
    }

    if (tr401.userChanges.hasOwnProperty('use201Radio')) {
      if (tr401.use201Radio) {
        res.info.enable201Radio = true;
      } else {
        res.info.disable01Radio = true;
      }
    }

    if (tr401.factorySleep && this.mobileUserService.user && this.mobileUserService.user['manufacturingUser']) {
      res.info.enterFactorySleep = true;
    }

    if (this.pairedProgrammer && this.pairedProgrammer.device instanceof DirectConnect301) {

      if (this.connectedDevice && this.connectedDevice.device instanceof DirectConnectTR4) {
        this.connectedDevice.device.changesBeingApplied();
      }
      let retryTimeout;
      this.pairedProgrammer.device.onEndDeviceMessage$.pipe(
        filter((m) => m.srcAddr === msg.srcAddr && m.msgType === TR4MessageType.RESPONSE),
        take(1),
      ).subscribe((deviceResponse) => {
        console.log('%c RESPONSE', 'color: cyan', deviceResponse);
        if (this.connectedDevice && this.connectedDevice.device instanceof DirectConnectTR4) {
          if (retryTimeout) {
            clearTimeout(retryTimeout);
          }
          this.connectedDevice.device.changesSuccessfullyApplied(deviceResponse);
        }
      });
      setTimeout(() => {
        console.log('%c TX TR4', 'color: cyan', msg);
        this.pairedProgrammer.device.sendInfoMessage(res);
        retryTimeout = setTimeout(() => {
          console.log('%c TX TR4 (1st Retry)', 'color: cyan', msg);
          res.frameId = null;
          this.pairedProgrammer.device.sendInfoMessage(res);
          retryTimeout = setTimeout(() => {
            console.log('%c TX TR4 (2nd Retry)', 'color: cyan', msg);
            res.frameId = null;
            this.pairedProgrammer.device.sendInfoMessage(res);
          }, 10000);
        }, 10000);
      }, 250);
    }
  }

  private handleRR3Ack(msg: EndDeviceMessage, remoteReader: DirectConnectRemoteReader, retries = 3) {
    msg.device = msg.device as RR3;
    if (msg.msgType === RR3MessageType.USER_EVENT) {
      const res = new InfoMessage(msg.srcAddr, RR301InfoMessageType.DC301);
      res.info = res.info as RR301Info;
      res.info.userMsgResponse = true;
      res.info.serialNumber = msg.srcAddr;
      res.info.clearTamperAlert = remoteReader.clearTamper;
      res.info.pulseOut = remoteReader.pulseOutEnabled;
      res.info.checkInInterval = msg.device.checkInInterval;

      if (msg.device.lcdAlwaysOn !== remoteReader.lcdAlwaysOn) {
        res.info.setLCDAlwaysOnState = true;
        res.info.key ^= LCD_ALWAYS_ON_KEY;
        res.info.lcdAlwaysOn = remoteReader.lcdAlwaysOn;
      }

      if (remoteReader.meter1Info.resetPulseCount) {
        res.info.meter1.resetPulseCount = true;
        res.info.key ^= METER_1_PULSE_RESET_KEY;
      }

      if (remoteReader.meter2Info.resetPulseCount) {
        res.info.meter2.resetPulseCount = true;
        res.info.key ^= METER_2_PULSE_RESET_KEY;
      }

      if (!msg.device.rapidCheckInEnabled && remoteReader.rapidCheckInEnabled) {
        // Start Rapid Check Ins
        res.info.startRapidCheckIn = true;
      } else if (msg.device.rapidCheckInEnabled && !remoteReader.rapidCheckInEnabled) {
        // Stop Rapid Check Ins
        res.info.stopRapidCheckIn = true;
      }

      // Configure Meter 1
      res.info.meter1.setInfo = true;
      res.info.meter1.configType = remoteReader.meter1Info.configType || MeterConfig.PULSE_IN;
      res.info.meter1.rawMultiplier = Utils.ComputeRawMultiplier(remoteReader.meter1Info.multiplier);
      res.info.meter1.imr = remoteReader.meter1Info.configType === MeterConfig.ENCODER_IN ? 0 : remoteReader.meter1Info.imr;
      res.info.meter1.utilityTypeId = remoteReader.meter1Info.utilityTypeId;
      res.info.meter1.uomTypeId = remoteReader.meter1Info.uomTypeId;

      // Configure Meter 2
      if (!remoteReader.pulseOutEnabled) {
        res.info.meter2.setInfo = true;
        res.info.meter2.configType = remoteReader.meter2Info.configType === MeterConfig.PULSE_OUT
          ? MeterConfig.PORT_DISABLED
          : remoteReader.meter2Info.configType;
        res.info.meter2.rawMultiplier = Utils.ComputeRawMultiplier(remoteReader.meter2Info.multiplier);
        res.info.meter2.imr = remoteReader.meter2Info.configType === MeterConfig.ENCODER_IN ? 0 : remoteReader.meter2Info.imr;
        res.info.meter2.utilityTypeId = remoteReader.meter2Info.utilityTypeId;
        res.info.meter2.uomTypeId = remoteReader.meter2Info.uomTypeId;
      }

      if (this.mobileUserService.user$.value && this.mobileUserService.user$.value['manufacturingUser'] && remoteReader.factorySleep) {
        res.info.enterFactorySleep = true;
      }

      if (remoteReader.factoryReset) {
        res.info.factoryReset = true;
      }

      if (this.pairedProgrammer && this.pairedProgrammer.device instanceof DirectConnect301) {

        if (this.connectedDevice && this.connectedDevice.device instanceof DirectConnectRemoteReader) {
          this.connectedDevice.device.changesBeingApplied();
        }
        let retryTimeout;
        let shouldRetry = res.info.factoryReset ? false : true;
        this.pairedProgrammer.device.onEndDeviceMessage$.pipe(
          takeUntil(remoteReader.state$.pipe(filter(s => s !== EndDeviceFormState.APPLYING_CHANGES && s !== EndDeviceFormState.WAITING_CONFIRMATION))),
          filter((m) => m.srcAddr === msg.srcAddr),
          take(1),
        ).subscribe((deviceResponse) => {
          console.log('deviceMessage', deviceResponse);
          clearTimeout(retryTimeout);
          if (deviceResponse && deviceResponse.msgType === RR3MessageType.RESPONSE && this.connectedDevice && this.connectedDevice.device instanceof DirectConnectRemoteReader) {
            if (res.info.enterFactorySleep) {
              // The RR3 does not allow the device to restart and go into factory sleep at the same time.
              // This will restart the RR3 once factory sleep has been entered
              // TODO: Move this into the RR3Info model in ncss/models
              res.info['_flags'] = BitwiseHelper.SetBits(res.info['_flags'], 11, 1, 1);  //  Restart RR
              res.info.key = 0x33; // RESTART_RR key
              this.pairedProgrammer.device.sendInfoMessage(res);
            }
            this.connectedDevice.device.changesSuccessfullyApplied(deviceResponse);
          } else if (deviceResponse && this.connectedDevice && this.connectedDevice.device instanceof DirectConnectRemoteReader && res.info.factoryReset) {
            this.connectedDevice.device.changesSuccessfullyApplied(deviceResponse);
            setTimeout(() => {
              this.disconnectEndDevice();
            }, 800);
          }
        });
        setTimeout(() => {
          console.log('%c TX RR3', 'color: magenta', msg);
          this.pairedProgrammer.device.sendInfoMessage(res);
          if (shouldRetry) {
            retryTimeout = setTimeout(() => {
              console.log('%c TX RR3 (1st Retry)', 'color: magenta', msg);
              res.frameId = null;
              this.pairedProgrammer.device.sendInfoMessage(res);
              retryTimeout = setTimeout(() => {
                console.log('%c TX RR3 (2nd Retry)', 'color: magenta', msg);
                res.frameId = null;
                this.pairedProgrammer.device.sendInfoMessage(res);
              }, 5000);
            }, 5000);
          }
        }, 350);
      }
    }
  }

  private async checkForManufacturingTest() {
    if (this.mobileUserService.user$.value && this.mobileUserService.user$.value['manufacturingUser']) {
      let unPair = true;
      const id = await this.barcodeScanner.showBarcodeScanner();
      if (!id) {
        unPair = false;
      } else if (id !== this.pairedProgrammer.serialNumber) {
        alert(`Scanned serial number: ${id.toString(16).toUpperCase()} does not match paired DC301 serial number:  ` +
          this.pairedProgrammer.serialNumber.toString(16).toUpperCase());
      } else {
        const device = this.pairedProgrammer.device as DirectConnect301;
        const info: IDC301TestInfo = {
          id: this.pairedProgrammer.serialNumber,
          firmware: device.firmwareVersion,
          hardware: device.hardwareVersion,
          firmwareBle: device.firmwareVersionBle,
          batteryLevel: device.batteryPercent,
          bleSignalStrength: this.pairedProgrammer.bleSignalStrength,
        } as any;

        try {
          const res: any = await this.httpService.post(`${MANUFACTURING_SERVER}/api/DC301/RF2Test`, info).toPromise();
          if (!res || !res.passed) {
            if (res.message && res.message.indexOf('already passed') !== -1) {
              unPair = false;
            }
            alert(`Failed test: ${res ? res.message : 'Unknown reason'}`);
          } else {
            alert('Passed CHARGE and RF #2 Tests');
          }
        } catch (e) {
          if (e && e.error && e.error.message) {
            alert('Failed to save test with following error: ' + e.error.message);
          }
        }
      }
      if (unPair) {
        this.unPairProgrammer();
      }
    }
  }

  private clearDirectConnectDeviceSubscriptions(device: IDirectConnectDevice) {
    if (!device) { return; }
    device.subscriptions.forEach((s) => s.unsubscribe());
    device.timers.forEach((t) => {
      clearTimeout(t);
      clearInterval(t);
    });
    device.subscriptions = [];
    device.timers = [];
  }

}
