import {
  Lorax,
  Meter,
  Unit,
  UtilityTypes,
  IUtilityType,
  DeviceTypeHelper,
  IDeviceType,
  DeviceFamily,
  MeterConfig,
  LoraxError,
  UtilityTypeIds,
  NM4IRead,
  MeterOrientation,
  DeviceTypes,
} from '@ncss/models';

import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter, ViewChild, OnInit } from '@angular/core';
import { PopoverController, ActionSheetController, IonSelect, AlertController } from '@ionic/angular';
import { ActionSheetButton } from '@ionic/core';
import * as moment from 'moment';
import { debounceTime } from 'rxjs/operators';

import { MeterFormGroup } from '../../../../../angularUtilities/meter-form';
import { MobileLiveDeviceService } from './../../../../../services/test-connect/live-device.service';
import { MobilePropertyService } from './../../../../../services/mobile-property.service';
import { ToastService } from '../../../../../services/toast.service';
import { CustomInputPopoverComponent, CustomInputPopoverType } from '../../../../../components/custom-input-popover/custom-input-popover.component';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'app-meter-details',
  templateUrl: './meter-details.component.html',
  styleUrls: ['./meter-details.component.scss'],
})
export class MeterDetailsComponent implements OnChanges, OnInit {
  @ViewChild('utility') utilitySelect: IonSelect;

  @Input() meterForm: MeterFormGroup;
  @Input() availableUtilities: IUtilityType[];
  @Output() delete = new EventEmitter<UtilityTypeIds>();
  @Output() save = new EventEmitter<{ alignReads?: boolean }>();
  @Output() onShouldRefresh = new EventEmitter();

  unitId: string;
  utilityType: IUtilityType;
  meterStatus: MeterStatus; // readonly meter things
  deviceStatus: DeviceStatus; // readonly device things
  showMoreStatus = false;
  MeterConfig = MeterConfig;
  hideSignalType = false;

  pinging = new BehaviorSubject(false);
  public orientation = 'horizontal';
  private changeSubscription: Subscription;

  constructor(
    private popCtl: PopoverController,
    private actionCtl: ActionSheetController,
    private alertCtl: AlertController,
    private properties: MobilePropertyService,
    private liveDevices: MobileLiveDeviceService,
    private toast: ToastService,
  ) { }

  ngOnInit(): void {
    this.onFormChanged();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.onFormChanged();
    if (this.changeSubscription) {
      this.changeSubscription.unsubscribe();
    }
    if (this.meterForm) {
      this.changeSubscription = this.meterForm.valueChanges.pipe(debounceTime(100)).subscribe(() => {
        this.onFormChanged();
      });
      this.unitId = this.meterForm.originalUnit()._id;
    }
  }

  async ping() {
    const deviceId: number = this.meterForm.controls.deviceId.value;
    const gatewayId: number = this.properties.property && this.properties.property.gateway ? this.properties.property.gateway.id : null;
    if (deviceId && gatewayId) {
      this.pinging.next(true);
      const response = await this.liveDevices.pingPoweredDevice(deviceId, gatewayId);
      this.pinging.next(false);
      this.toast.queueToast(response ? `Successfully Test Connected ${deviceId.toString(16).toUpperCase()}` : 'Test Connect Failed');
      this.onShouldRefresh.next();
    }
  }

  private onFormChanged() {
    if (this.meterForm) {
      const m = this.meterForm.toMeter();
      if (m.device.data instanceof NM4IRead) {
        if (m.device.data.device.meterOrientation === MeterOrientation.VERTICAL_OUTPUT_UP) {
          this.orientation = 'vertical-output-up';
        }
        else if (m.device.data.device.meterOrientation === MeterOrientation.VERTICAL_OUTPUT_DOWN) {
          this.orientation = 'vertical-output-down';
        } else {
          this.orientation = 'horizontal';
        }
      }
      const u = this.meterForm.originalUnit();
      u.meters = this.meterForm.parent.controls.map((meterForm) => meterForm.toMeter());
      this.meterStatus = getMeterStatus(m, u);
      this.deviceStatus = getDeviceStatus(m, u);
      this.utilityType = UtilityTypes[this.meterForm.controls.utilityTypeId.value];
      this.hideSignalType = this.meterForm.isEmbeddedTransceiver() ||
        (!this.meterForm.isDeviceFamily(DeviceFamily.TRANSCEIVER) && !this.meterForm.isDeviceFamily(DeviceFamily.REMOTE_READER));
    }
  }

  async programDeviceClicked(tapEvent?: Event) {
    if (tapEvent) { tapEvent.preventDefault(); }
    const p = await this.popCtl.create({
      component: CustomInputPopoverComponent,
      animated: true,
      backdropDismiss: true,
      cssClass: 'custom-popover',
      componentProps: {
        header: tapEvent ? 'Edit Serial Number' : 'Program Device',
        type: CustomInputPopoverType.SERIAL_INPUT_POPOVER,
        submitLabel: 'Program',
        initialValue: tapEvent ? this.meterForm.controls.deviceId.value : null,
        syncValidators: this.meterForm.controls.deviceId.validator,
        asyncValidators: this.meterForm.controls.deviceId.asyncValidator,
      },
    });
    await p.present();
    const res = await p.onDidDismiss();
    if (res.data) {
      this.meterForm.controls.deviceId.setValue(res.data.value);
    }
  }

  async quickActionsClicked() {
    const buttons: ActionSheetButton[] = [
      {
        text: `Switch Utility Type`,
        icon: this.utilityType.iconName,
        handler: async () => {
          this.meterForm.controls.utilityTypeId.enable();
          const alert: HTMLIonAlertElement = await this.utilitySelect.open();
          const res = await alert.onDidDismiss();
          if (res.data && res.data.values) {
            const newUtilityName = UtilityTypes[res.data.values] ? UtilityTypes[res.data.values].name : '';
            const confirmAlert = await this.alertCtl.create({
              header: 'Transfer History?',
              message: `Would you like the usage history from the ${this.utilityType.name} Meter to be transferred to the new ${newUtilityName} meter?`,
              backdropDismiss: false,
              buttons: [
                { text: 'No', role: 'no' },
                { text: 'Yes', role: 'yes' },
              ],
            });
            await confirmAlert.present();
            const confirm = await confirmAlert.onDidDismiss();
            await this.meterForm.setUtilityType(res.data.values);
            this.save.next({ alignReads: confirm.role === 'yes' });
          }
          return true;
        },
      },
      {
        text: `Delete ${this.utilityType.name} Meter`,
        icon: 'icon-trash-2',
        handler: () => {
          this.delete.next(this.utilityType.id);
        },
      },
      {
        text: 'Cancel',
        icon: 'icon-x',
        role: 'cancel',
      },
    ];
    if (this.meterForm.controls.deviceId.value && this.meterForm.controls.deviceId.valid) {
      buttons.unshift({
        text: `Replace ${this.meterForm.controls.deviceId.value.toString(16).toUpperCase()}`,
        icon: 'icon-transceiver',
        handler: () => {
          const oldId = this.meterForm.controls.deviceId.value;
          this.meterForm.controls.deviceId.setValue(null);
          this.programDeviceClicked().then(() => {
            if (!this.meterForm.controls.deviceId.value) {  // cancelled
              this.meterForm.controls.deviceId.setValue(oldId);
              this.save.next({ alignReads: false });
            }
          });
          return true;
        },
      });
    }

    if (this.meterForm.isEmbeddedTransceiver() && !this.meterForm.controls.deviceId.dirty && this.meterForm.controls.deviceId.valid) {
      buttons.unshift({
        text: `Test Connect ${this.meterForm.controls.deviceId.value.toString(16).toUpperCase()}`,
        icon: 'icon-radio',
        handler: () => {
          this.ping();
          return true;
        },
      });
    }
    const a = await this.actionCtl.create({
      header: 'Quick Actions',
      buttons,
    });
    await a.present();
  }
}

interface MeterStatus {
  readOrStatus: string;
  color: string;
  iconColor: string;
  icon: string;
  lastCheckInStr: string;
  hasReadError: boolean;
  dataHostingEnabled: boolean;
}

interface DeviceStatus {
  stats: Array<{ label: string, value: string }>;
  statsError: boolean;
  overallStatus: string;
  family: string;
  graphic: string;
  isProgrammed: boolean;
  showStatus: boolean;
}

function getDeviceStatus(meter: Meter, unit: Unit): DeviceStatus {
  let overallStatus: string;
  let family: string;
  let graphic: string;
  const stats: Array<{ label: string, value: string }> = [];
  const isProgrammed = isDeviceProgrammed(meter);
  let statsError = true;
  let showStatus = true;

  if (isProgrammed) {
    const loraxSignal = Lorax.getLinkQualityFromMeter(meter);
    overallStatus = loraxSignal.result.humanized + (!loraxSignal.error ? ' Signal' : '');
    statsError = !!loraxSignal.error;
    const isEncoded = Lorax.isMeterEncoded(meter);
    const deviceType = meter.device.deviceTypeId && DeviceTypes[meter.device.deviceTypeId]
      ? DeviceTypes[meter.device.deviceTypeId]
      : DeviceTypeHelper.GetDeviceTypeBySerialNumber(meter.device.id);
    showStatus = deviceType && deviceType.family !== DeviceFamily.MANUAL_READER;

    const loraxRead = Lorax.getMeterReadFromUnit(unit, meter.utilityTypeId);
    const readStatLabel: string = isEncoded ? 'Encoded Read' :
      (deviceType && (DeviceTypeHelper.GetIsEmbeddedTransceiver(deviceType.id) || deviceType.family === DeviceFamily.MANUAL_READER || deviceType.family === DeviceFamily.INTEGRATED_METER)) ? 'Meter Read' :
        'Pulses';
    const readStatValue: string =
      (deviceType.family === DeviceFamily.INTEGRATED_METER || loraxRead.error) ? loraxRead.result.fullStr : loraxRead.result.raw.toLocaleString();
    stats.push({
      label: readStatLabel,
      value: readStatValue,
    })

    stats.push({
      label: 'Temperature',
      value: Lorax.getTemperatureFromMeter(meter).result,
    });
    if (deviceType && !DeviceTypeHelper.GetIsEmbeddedTransceiver(deviceType.id)) {
      stats.push({
        label: 'Battery',
        value: Lorax.getBatteryFromMeter(meter).result.humanized,
      });
    }
    if (deviceType) {
      if (deviceType.clientFacingName) {
        stats.push({
          label: 'Model',
          value: deviceType.clientFacingName,
        });
      } else {
        stats.push({
          label: 'Model',
          value: deviceType.name,
        });
      }
    } else {
      stats.push({
        label: 'Model',
        value: 'Unknown',
      });
    }
    family = deviceType ? deviceType.clientFacingName : 'No Device Programmed';
    graphic = deviceTypeToGraphic(deviceType, meter);
  }

  return {
    showStatus,
    statsError,
    isProgrammed,
    overallStatus,
    stats,
    graphic,
    family,
  };
}

function getMeterStatus(meter: Meter, unit: Unit): MeterStatus {
  let readOrStatus = 'Not Installed';
  let color = '#999999';
  let iconColor = '#999999';
  let icon = 'icon-minus';
  let lastCheckInStr = '';
  let hasReadError = true;
  let dataHostingEnabled = true;

  if (!!unit && isDeviceProgrammed(meter)) {
    const loraxRead = Lorax.getMeterReadFromUnit(unit, meter.utilityTypeId);
    const meterOnline = Lorax.isMeterOnline(meter).result;
    const lastCheckIn = Lorax.getLastCheckInFromMeter(meter).result;
    readOrStatus = loraxRead.result.fullStr;
    hasReadError = !!loraxRead.error;
    dataHostingEnabled = !(loraxRead.error === LoraxError.DATA_HOSTING_NOT_ENABLED);
    color = !loraxRead.error ? UtilityTypes[meter.utilityTypeId].colorHex : '#999999';
    icon = loraxRead.error ? 'icon-minus' : meterOnline ? 'icon-check' : 'icon-x';
    iconColor = loraxRead.error ? '#999999' : meterOnline ? '#7bc147' : '#E3515F';
    lastCheckInStr = loraxRead.error === LoraxError.WAITING_FOR_CHECK_IN || loraxRead.error === LoraxError.NOT_PROGRAMMED ? ''
      : moment.duration(moment(lastCheckIn).diff(new Date())).humanize(true);
  }

  return {
    readOrStatus,
    color,
    iconColor,
    icon,
    lastCheckInStr,
    hasReadError,
    dataHostingEnabled,
  };
}

function isDeviceProgrammed(meter: Meter): meter is Meter & { device: { id: number } } {
  return !!meter.device && !!meter.device.id;
}

function deviceTypeToGraphic(deviceType: IDeviceType, meter: Meter) {
  if (!deviceType) { return 'transceiver.png'; }
  if (DeviceTypeHelper.GetIsEmbeddedTransceiver(deviceType.id)) {
    return 'Generic-Electric-Meter-1.png';
  }
  switch (deviceType.family) {
    case DeviceFamily.REMOTE_READER:
      return 'remote-reader.png';
    case DeviceFamily.MANUAL_READER:
      return 'manual-read-app.png';
    case DeviceFamily.INTEGRATED_METER:
      return meter.device.data && meter.device.data['device'] && meter.device.data['device'].lidOpened ?
        'next-meter-open.svg' : 'next-meter.png';
    default:
      return 'transceiver.png';
  }
}
