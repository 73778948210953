import { EndDeviceMessage, DeviceLocation, DeviceTypeHelper, DeviceFamily } from '@ncss/models';

import { ApplicationRef, Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';

import { MobileLiveDeviceService } from './live-device.service';

export enum TestConnectMode {
  READY,
  PAUSE,
  PROPERTY,
}

@Injectable({
  providedIn: 'root',
})
export class TestConnectService {

  private _devices: BehaviorSubject<Array<EndDeviceMessage>> = new BehaviorSubject([]);
  public devices$: Observable<EndDeviceMessage[]> = this._devices.asObservable();
  private _mode: BehaviorSubject<TestConnectMode> = new BehaviorSubject(TestConnectMode.READY);
  public mode$: Observable<TestConnectMode> = this._mode.asObservable();
  public selectedPropertyId: string = null;
  private subscriptions = [];

  constructor(
    private liveDevice: MobileLiveDeviceService,
    private appRef: ApplicationRef,
  ) { }

  public async setMode(mode: TestConnectMode, gatewayId?: number) {
    this.unsubscribeAll();
    const currentMode = await this.getCurrentMode();

    if (mode === TestConnectMode.PROPERTY && gatewayId) {
      this.subscribeProperty(gatewayId);
      if (currentMode !== TestConnectMode.PAUSE) {
        this._devices.next([]);
      }
    } else {
      this.unsubscribeAll();

      if (mode !== TestConnectMode.PAUSE) {
        this._devices.next([]);
      }
    }

    this._mode.next(mode);
  }

  public stop() {
    this.setMode(TestConnectMode.READY);
  }

  public clear() {
    this._devices.next([]);
  }

  public clearDeviceId(device: DeviceLocation) {
    this._devices.next(_.filter(this._devices.getValue(), (d: EndDeviceMessage) => d.deviceLocation.device.id !== device.device.id));
  }

  public getCurrentMode() {
    return this._mode.value;
  }

  private subscribeProperty(gatewayId: number) {
    if (!gatewayId) {
      return;
    }
    this.subscriptions.push(
      this.liveDevice.propertyEvents(gatewayId).subscribe((msg) => {
        if (msg instanceof EndDeviceMessage && msg.isPriority) {
          msg.timestamp = new Date();
          this.addMsg(msg);
        }
      }),
    );
  }

  private unsubscribeAll() {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach(s => s.unsubscribe());
      this.subscriptions = [];
    }
  }

  private async addMsg(msg: EndDeviceMessage) {
    const mode = await this.getCurrentMode();

    if (mode === TestConnectMode.PAUSE) {
      return;
    } else if (mode === TestConnectMode.READY) {
      this.setMode(TestConnectMode.READY);
      return;
    }

    const devices: EndDeviceMessage[] = await this._devices.value;
    if (this.shouldHandleEndDeviceMessage(msg)) {
      if (!this.isProgrammedToProperty(msg.deviceLocation)) {
        msg.deviceLocation.property = null;
      }
      devices.unshift(msg);

    }
    const devicesToDisplay = devices.slice(0, 25);
    this._devices.next(devicesToDisplay);
    this.appRef.tick();
  }

  private shouldHandleEndDeviceMessage(msg: EndDeviceMessage) {
    if (!msg.deviceLocation || !msg.deviceLocation.device || !msg.deviceLocation.device.id) {
      return false;
    }
    let deviceType;

    deviceType = DeviceTypeHelper.GetDeviceTypeBySerialNumber(msg.deviceLocation.device.id);
    if (deviceType && deviceType.family === DeviceFamily.GATEWAY) {
      return false;
    }
    return msg.type === 'END_DEVICE';
  }

  private isProgrammedToProperty(deviceLocation: DeviceLocation) {
    if (DeviceTypeHelper.GetFamilyBySerialNumber(deviceLocation.device.id) === DeviceFamily.REPEATER) {
      return !!deviceLocation.property;
    }
    return !!deviceLocation.unit;
  }

}
