import { PipeLogic } from '@ncss/models';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'percentage' })
export class PercentagePipe implements PipeTransform  {
  public transform(value, args?) {
    return PipeLogic.Percentage(value, args);
  }
}
