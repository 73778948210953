import { DeviceTypeHelper, DeviceTypeIds } from '@ncss/models';

import { trigger, animate, style, transition } from '@angular/animations';
import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, Input, ChangeDetectorRef } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';

import { FeedbackService, FeedbackType } from '../../../../services/feedback.service';


@Component({
  selector: 'app-magnet-instruction',
  templateUrl: './magnet-instruction.component.html',
  styleUrls: ['./magnet-instruction.component.scss'],
  animations: [
    trigger('fadeInOutX', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale3d(0.1, 0.1, 0.1) translate3d(-450px, 0, 0)' }),
        animate('1000ms cubic-bezier(0.175, 0.885, 0.32, 1)',
          style({ opacity: 1, transform: 'scale3d(1, 1, 1) translate3d(0, 0, 0)' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale3d(1, 1, 1) translate3d(0, 0, 0)' }),
        animate('500ms cubic-bezier(0.175, 0.885, 0.32, 1)',
          style({ opacity: 0, transform: 'scale3d(0.1, 0.1, 0.1) translate3d(500px, 0, 0)' })),
      ]),
    ]),
    trigger('fadeInOutY', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale3d(0.1, 0.1, 0.1) translate3d(0, -450px, 0)' }),
        animate('500ms cubic-bezier(.26,.05,.38,1)',
          style({ opacity: 1, transform: 'scale3d(1, 1, 1) translate3d(0, 0, 0)' })),
      ]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MagnetInstructionComponent implements OnInit, OnDestroy {

  public DeviceTypeIds = DeviceTypeIds;

  @Input() public onChangesApplied$: Observable<boolean>;
  @Input() public onWaitingConfirmation$: Observable<boolean>;
  @Input() public serialNumber: number;

  public changesApplied = false;
  public waitingConfirmation = false;
  public get verifyingText$() { return this._verifyingText$.asObservable(); }
  public imgUrl = 'assets/img/equipment/rr301.svg';
  public deviceTypeId: DeviceTypeIds;
  public context: 'magnet' | 'button' = 'magnet';

  private _subscriptions: Subscription[] = [];
  private _timer;
  private _waitingTimer;
  private _verifyingText$ = new BehaviorSubject<string>('Verifying');

  constructor(
    private modalCtrl: ModalController,
    private feedbackService: FeedbackService,
    private cd: ChangeDetectorRef,
  ) { }

  public ngOnInit() {
    const deviceType = DeviceTypeHelper.GetDeviceTypeBySerialNumber(this.serialNumber);
    this.deviceTypeId = deviceType ? deviceType.id : null;
    if (this.deviceTypeId === DeviceTypeIds.TR4 || this.deviceTypeId === DeviceTypeIds.TR4_I) {
      this.imgUrl = 'assets/img/equipment/TR401-Flat-Open.svg';
      this.context = 'button';
    } else if (this.deviceTypeId === DeviceTypeIds.TR4_X) {
      this.imgUrl = 'assets/img/equipment/TR401-X-Flat.svg';
    }
    this.setupSubscriptions();
  }

  public ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
    clearTimeout(this._timer);
    clearTimeout(this._waitingTimer);
  }

  public cancel() {
    clearTimeout(this._timer);
    clearTimeout(this._waitingTimer);
    this.modalCtrl.dismiss('canceled');
  }

  private setupSubscriptions() {
    if (this.onChangesApplied$) {
      this._subscriptions.push(this.onChangesApplied$.subscribe((val) => {
        if (val) {
          this.feedbackService.HapticFeedback(FeedbackType.BLE_CONNECT);
          this._timer = setTimeout(() => {
            this.modalCtrl.dismiss();
          }, 1500);
        }
        this.changesApplied = val;
        console.log('changesApplied', this.changesApplied);
        this.cd.detectChanges();
      }));
    }

    if (this.onWaitingConfirmation$) {
      this._subscriptions.push(this.onWaitingConfirmation$.subscribe((val) => {
        if (val) {
          this.feedbackService.HapticFeedback(FeedbackType.BLE_CONNECT);
          this._waitingTimer = setTimeout(() => {
            this._verifyingText$.next('Please wait...');
            this.cd.detectChanges();
          }, 5000);
        } else {
          if (this._waitingTimer) {
            clearTimeout(this._waitingTimer);
          }
          this._verifyingText$.next('Verifying');
        }
        this.waitingConfirmation = val;
        console.log('waitingConfirmation', this.waitingConfirmation);
        this.cd.detectChanges();
      }));
    }
  }

}
