import {
  DeviceTypeHelper,
  DeviceTypeIds,
  BitwiseHelper,
  ConversionUtils,
  RE4,
  RE4Info,
  NwkRoute,
} from '@ncss/models';

import { ByteList } from 'byte-list';
import { BehaviorSubject, Observable, combineLatest, timer, of, merge, Subject, interval } from 'rxjs';
import { map, distinctUntilChanged, filter, mapTo, take, pairwise, timeout, catchError, takeWhile } from 'rxjs/operators';

import {
  DirectConnectBaseDevice,
  IDirectConnectDevice,
  DirectConnectDeviceStatus,
  NCSS_BLE_WALK_BY_SERVICE_UUID,
  NCSS_BLE_WALK_BY_TX_ID,
  NCSS_BLE_WALK_BY_RX_ID,
} from './../baseDirectConnectDevice';
import { RE4DeviceRouteMsgType, DeviceRouteMessage } from './messages/DeviceRouteMessage';
import { DeviceRouteRequestMessage, RE4DeviceRouteRequestMsgType } from './messages/DeviceRouteRequestMessage';
import { RE4BLEDeviceMessage, RE4BLEMessageType } from './messages/RE4BLEDeviceMessage';
import { RE4BLEInfoMessage, RE4BLEInfoMessageType } from './messages/RE4BLEInfoMessage';
import { RE4RfDebugMsgType, RfDebugMessage } from './messages/RfDebugMessage';

export interface IRE4UserChanges {
  hasOwnProperty?: any;
  rapidCheckInEnabled?: boolean;
}

const TIME_BETWEEN_RETRIES = 3000;

interface IRE4AdvertisingData {
  rfSignal: number;
  temperature: number;
  powerPinState: boolean;
}

enum RE4InputVoltage {
  FIFTEEN_V = '15v',
  TWELVE_V = '12v',
  UNKNOWN_V = 'Unknown',
}

enum LedColors {
  BLUE = 'blue',
  RED = 'red',
  GREEN = 'green',
  OFF = 'off',
}

export class DirectConnectRE4 extends DirectConnectBaseDevice {

  public static useMetric = false;

  static create(bleDevice, manufacturingData: { serialNumber: number, byteList: ByteList }, isLimited = false): IDirectConnectDevice {
    if (!manufacturingData || !manufacturingData.serialNumber) {
      return null;
    }

    const deviceType = DeviceTypeHelper.GetDeviceTypeBySerialNumber(manufacturingData.serialNumber);
    if (deviceType && deviceType.id === DeviceTypeIds.RE4) {
      const re4 = new DirectConnectRE4();
      re4.serialNumber = manufacturingData.serialNumber;
      re4.serialNumberStr = manufacturingData.serialNumber.toString(16).toUpperCase();
      re4.deviceModel = deviceType.model;
      re4.deviceName = deviceType.name;
      re4.modelId = deviceType.id;
      if (bleDevice.rssi) {
        re4.updateSignalStrength(DirectConnectBaseDevice.rssiToSignalStrength(bleDevice.rssi));
      }
      re4.addAdvertisingData(manufacturingData.byteList);
      const directConnectDevice: IDirectConnectDevice = {
        status: DirectConnectDeviceStatus.ADVERTISING,
        serialNumber: re4.serialNumber,
        bleSignalStrength: bleDevice.rssi ? DirectConnectBaseDevice.rssiToSignalStrength(bleDevice.rssi) : null,
        bleDevice,
        device: re4,
        subscriptions: [],
        timers: [],
        keepModalOpenOnDisconnect: true,
      };
      return directConnectDevice;
    } else {
      return null;
    }
  }

  // scan response data
  public uuid = {
    service: NCSS_BLE_WALK_BY_SERVICE_UUID,
    write: NCSS_BLE_WALK_BY_TX_ID,
    read: NCSS_BLE_WALK_BY_RX_ID,
  };
  public serialNumber: number;
  public serialNumberStr: string;
  public deviceModel: string;
  public deviceName: string;

  public lastMsgAt$ = new BehaviorSubject<Date>(new Date());


  // represents the current configuration (without user changes)
  public re4$ = new BehaviorSubject<RE4 | IRE4AdvertisingData>(null);
  public get re4() { return this.re4$.value instanceof RE4 ? this.re4$.value : null; }

  public userChanges$ = new BehaviorSubject<IRE4UserChanges>({});
  public get userChanges() { return this.userChanges$.value; }

  public hasUserChanges$ = this.userChanges$.pipe(map((changes) => !this.checkChangesAreEmpty(changes)), distinctUntilChanged());

  public powerConnected$: Observable<boolean> = this.re4$.pipe(map((re4) => re4.powerPinState));
  public normalizedSignalStrength$: Observable<number> = this.re4$.pipe(map((re4) => re4.rfSignal));
  public normalizedSignalStrengthStr$: Observable<string> = this.normalizedSignalStrength$.pipe(
    map((strength) => this.normalizeSignalStrength(strength)),
    distinctUntilChanged(),
  );
  public powerLedColor$: Observable<LedColors> = combineLatest([
    this.powerConnected$,
    this.normalizedSignalStrength$,
    interval(1000).pipe(map((val, i) => i % 2 === 0 ? true : false)),
  ]).pipe(map(([powerConnected, signalStrength, blinkOff]) => {
    if (blinkOff) {
      return LedColors.OFF;
    } else if (!powerConnected) {
      return LedColors.RED;
    } else {
      return signalStrength > 0 ? LedColors.BLUE : LedColors.GREEN;
    }
  }));

  public temperature$: Observable<number> = this.re4$.pipe(map((re4) => re4.temperature));
  public temperatureStr$ = this.temperature$.pipe(
    map((temp) => {
      return DirectConnectRE4.useMetric ? `${temp.toFixed(1)} C` : `${ConversionUtils.ConvertCelsiusToFahrenheit(temp).toFixed(1)} F`;
    }),
    distinctUntilChanged(),
  );

  public firmwareVersionStr$: Observable<string> = this.re4$.pipe(
    map((re4) => this.getFirmwareString(re4)),
  );

  public hardwareVersionStr$: Observable<string> = this.re4$.pipe(
    map((re4) => this.getHardwareString(re4)),
  );

  public powerSupply$: Observable<RE4InputVoltage> = this.re4$.pipe(map((re4: RE4) => this.normalizeInputVoltage(re4.inputVoltage)));

  public rapidCheckInEnabled$ = combineLatest([
    this.re4$.pipe(filter((re4) => !!re4)),
    this.userChanges$,
  ]).pipe(map(([re4, changes]: [RE4, IRE4UserChanges]) => {
    if (changes.hasOwnProperty('rapidCheckInEnabled')) {
      return changes.rapidCheckInEnabled;
    } else {
      return re4.rapidCheckInEnabled;
    }
  }));

  public partying$: Observable<boolean> = this.re4$.pipe(
    map((re4: RE4) => re4.partyModeEnabled),
  );

  public overallStatus$: Observable<string> = this.re4$.pipe(
    map((re4) => this.getOverallStatus(re4).label),
  );
  public overallStatusDescription$: Observable<string> = this.re4$.pipe(
    map((re4) => this.getOverallStatus(re4).description),
  );

  public sendingMessage$ = new BehaviorSubject<boolean>(false);

  public attemptReconnect: () => Promise<IDirectConnectDevice>;

  public debugMsgs: RfDebugMessage[] = [];
  public debugMsgCount$ = new BehaviorSubject<number>(0);

  public routingTable = new Subject<NwkRoute[]>();
  public routingTableRequestCanceled = false;

  public routeRequestPage = new BehaviorSubject(1);
  public routeTableCurrentlySorting = false;
  private pendingNwkRoutes: NwkRoute[] = [];

  constructor() {
    super();
    this.messageService.registerMessageGroup({
      mask: RE4BLEMessageType.GROUP_MASK,
      messageClass: RE4BLEDeviceMessage,
      handler: this.onDeviceMessage.bind(this),
    });

    this.messageService.registerMessageGroup({
      mask: RE4DeviceRouteMsgType.RE4_DEVICE_ROUTE_MSG,
      messageClass: DeviceRouteMessage,
      handler: this.onRouteMessage.bind(this),
    });

    this.messageService.numberOfTxFlagBytes = 14;
  }

  public listenToDebugMessages() {
    this.messageService.registerMessageGroup({
      mask: RE4RfDebugMsgType.RE4_RF_DEBUG_MSG,
      messageClass: RfDebugMessage,
      handler: this.onRfDebugMessage.bind(this),
    });
  }

  private onRfDebugMessage(msg: RfDebugMessage) {
    this.debugMsgs.push(msg);
    this.debugMsgCount$.next(this.debugMsgs.length);
  }


  private onRouteMessage(msg: DeviceRouteMessage) {
    this.routeTableCurrentlySorting = msg.currentlySortingTable || false;
    this.sendingMessage$.next(false);
    if (this.routingTableRequestCanceled) {
      this.routeRequestPage.next(1);
      this.pendingNwkRoutes = [];
      return;
    }
    for (const r of msg.routes) {
      this.pendingNwkRoutes.push(new NwkRoute(r));
    }
    if (msg.routes && msg.routes.length >= 20) {
      this.routeRequestPage.next((this.routeRequestPage.value + 1));
      this.requestRouteInfo();
    } else {
      this.routingTable.next(this.pendingNwkRoutes);
      setTimeout(() => {
        this.routeRequestPage.next(1);
      }, 10);
      this.pendingNwkRoutes = [];
    }
  }

  private onDeviceMessage(msg: RE4BLEDeviceMessage): void {
    this.sendingMessage$.next(false);
    if (msg.re4) {
      this.lastMsgAt$.next(new Date());
      this.re4$.next(msg.re4);
    }
  }

  public requestRouteInfo(): Promise<boolean> {
    this.routingTableRequestCanceled = false;
    this.routeTableCurrentlySorting = false;
    const msg = new DeviceRouteRequestMessage();
    msg.type = RE4DeviceRouteRequestMsgType.RE4_DEVICE_ROUTE_REQUEST;
    msg.startingPage = this.routeRequestPage.value;
    msg.flags = 0;
    return this.sendMsg(msg, 1000);
  }

  public dropRouteTable(): Promise<boolean> {
    const msg = new DeviceRouteRequestMessage();
    msg.type = RE4DeviceRouteRequestMsgType.RE4_DEVICE_ROUTE_REQUEST;
    msg.flags = 0;
    msg.dropRoutingTable = true;
    msg.startingPage = 0;
    this.routingTableRequestCanceled = true;
    return this.sendMsg(msg);
  }

  public sortRouteTable(): Promise<boolean> {
    const msg = new DeviceRouteRequestMessage();
    msg.type = RE4DeviceRouteRequestMsgType.RE4_DEVICE_ROUTE_REQUEST;
    msg.flags = 0;
    msg.sortRoutingTable = true;
    msg.startingPage = 0;
    this.routingTableRequestCanceled = true;
    return this.sendMsg(msg);
  }

  public requestInfo(): Promise<boolean> {
    const msg = new RE4BLEInfoMessage(this.serialNumber);
    return this.sendMsg(msg);
  }

  public applyChanges(): Promise<boolean> {
    const msg = new RE4BLEInfoMessage(this.serialNumber, RE4BLEInfoMessageType.INFO_MSG);
    this.populateRE4InfoFromChanges(msg.re4Info, this.userChanges);
    return this.sendMsg(msg);
  }

  public addAdvertisingData(bytes: ByteList): void {
    const temperature = bytes.readInt8();
    const flags = bytes.readByte();
    const powerPinState = BitwiseHelper.getValueFromFlags(flags, 0);
    const rfSignal = BitwiseHelper.GetBits(flags, 1, 3);
    this.re4$.next({ temperature, powerPinState, rfSignal });
  }

  public updateRapidCheckInEnabled(value: boolean) {
    const changes = this.userChanges;
    if ((value && this.re4.rapidCheckInEnabled) || (!value && !this.re4.rapidCheckInEnabled)) {
      delete changes.rapidCheckInEnabled;
    } else {
      changes.rapidCheckInEnabled = value;
    }

    this.userChanges$.next(changes);
  }

  public dropUserChanges() {
    this.userChanges$.next({});
  }

  public startTheParty(): Promise<boolean> {
    const msg = new RE4BLEInfoMessage(this.serialNumber, RE4BLEInfoMessageType.INFO_MSG);
    msg.re4Info.setPartyMode = true;
    msg.re4Info.startTheParty = true;
    return this.sendMsg(msg);
  }

  public stopTheParty(): Promise<boolean> {
    const msg = new RE4BLEInfoMessage(this.serialNumber, RE4BLEInfoMessageType.INFO_MSG);
    msg.re4Info.setPartyMode = true;
    msg.re4Info.startTheParty = false;
    return this.sendMsg(msg);
  }

  private normalizeInputVoltage(inputVoltage: number): RE4InputVoltage {
    if (inputVoltage >= 14 && inputVoltage <= 16) {
      return RE4InputVoltage.FIFTEEN_V;
    } else if (inputVoltage >= 11 && inputVoltage <= 13) {
      return RE4InputVoltage.TWELVE_V;
    } else {
      return RE4InputVoltage.UNKNOWN_V;
    }
  }

  private normalizeSignalStrength(signalStrength: number): string {
    switch (signalStrength) {
      case 4:
        return 'Excellent';
      case 3:
      case 2:
        return 'Good';
      case 1:
        return 'Poor';
      default:
        return '-';
    }
  }

  private checkChangesAreEmpty(changes: IRE4UserChanges) {
    if (!changes) { return true; }
    const keys = Object.keys(changes);
    if (keys.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  private getFirmwareString(re4: RE4 | IRE4AdvertisingData) {
    if (re4 instanceof RE4) {
      return `Firmware v${re4.firmwareVersionMajor}.${re4.firmwareVersionMinor}`;
    } else {
      return '';
    }
  }

  private getHardwareString(re4: RE4 | IRE4AdvertisingData) {
    if (re4 instanceof RE4) {
      return `Hardware v${re4.hardwareVersionMajor}.${re4.hardwareVersionMinor}`;
    } else {
      return '';
    }
  }

  private getOverallStatus(re4: RE4 | IRE4AdvertisingData): { label: string, description?: string } {
    if (re4 instanceof RE4) {
      if (!re4.powerPinState) {
        return { label: 'Poor', description: 'This RE4 has been disconnected from its power source and will soon shut down.' };
      } else if (re4.inputVoltage < 11 || re4.inputVoltage > 16) {
        return { label: 'Poor', description: 'This RE4 seems to be using an incompatible power supply.' };
      } else if (!re4.gatewayConnected) {
        return { label: 'Not Communicating with Gateway', description: 'This RE4 is not communicating with a Gateway.' };
      } else {
        return { label: 'All Good', description: 'This RE4 is communicating with the Gateway.' };
      }
    } else {
      return { label: 'All Good' };
    }
  }

  private sendMsg(msg: RE4BLEInfoMessage | DeviceRouteRequestMessage, timeBetweenRetry?: number): Promise<boolean> {
      this.sendingMessage$.next(true);
      const sendAttempts: Observable<boolean> = timer(0, timeBetweenRetry || TIME_BETWEEN_RETRIES).pipe(mapTo(false), take(2));
      const changesApplied: Observable<boolean> = this.sendingMessage$.pipe(
        pairwise(),
        filter(([prev, curr]) => prev === true && curr === false),
        take(1),
        mapTo(true),
        timeout(TIME_BETWEEN_RETRIES * 3),
        catchError((err) => of(false)),
      );

      merge(changesApplied, sendAttempts).pipe(
        takeWhile((val) => val !== true),
      ).subscribe(() => {
        this.txBytes.next(this.messageService.serialize(msg));
      });

      return changesApplied.toPromise();
  }

  private populateRE4InfoFromChanges(re4Info: RE4Info, changes: IRE4UserChanges) {
    if (changes.hasOwnProperty('rapidCheckInEnabled')) {
      if (changes.rapidCheckInEnabled) {
        re4Info.startRapidCheckIn = true;
      } else {
        re4Info.stopRapidCheckIn = true;
      }
    }
  }
}
