import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { EquipmentTileStatsComponent } from './equipment-tile-stats.component';

@NgModule({
  declarations: [
      EquipmentTileStatsComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
  ],
  providers: [

  ],
  exports: [
    EquipmentTileStatsComponent,
  ],
})
export class EquipmentTileStatsModule {}
