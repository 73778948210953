import { Message } from '@ncss/message';
import { RR3MessageType, RR3, BitwiseHelper, TR4, TR4MessageType, TR4E, TR4EMessageType } from '@ncss/models';

import { ByteList } from 'byte-list';
import { forEach } from 'lodash';

const MASK = 0xFFF0;

export enum EndDeviceMessageType {
  GROUP_MASK = 0x1010,
}

export class EndDeviceMessage extends Message {

  public msgSize: number;
  public reserved: number;
  public id: number;
  public control: number;
  public srcAddr: number;
  public srcAddrMAC: number;
  public dstAddr: number;
  public dstAddrMAC: number;
  public firstHopAddr: number;
  public firstHopRssi: number;
  public msgType: RR3MessageType | TR4MessageType | TR4EMessageType;
  public device: RR3 | TR4 | TR4E;

  public get frameType() { return BitwiseHelper.GetBits(this.control, 0, 3); }

  public get ackRequested() { return ByteList.GetBit(this.control, 3); }

  public get frameVersion(): FrameVersion {
    return (ByteList.GetBit(this.control, 4) ? 1 : 0) +
      (ByteList.GetBit(this.control, 5) ? 2 : 0);
  }

  public get security() {
    return (ByteList.GetBit(this.control, 6) ? 1 : 0) +
      (ByteList.GetBit(this.control, 7) ? 2 : 0);
  }

  public get firstHopAntenna() {
    return (ByteList.GetBit(this.control, 8) ? 1 : 0) +
      (ByteList.GetBit(this.control, 9) ? 2 : 0);
  }

  public get payloadLSBF() { return ByteList.GetBit(this.control, 10); }

  constructor(type?: number) {
    super();
    this.type = type || EndDeviceMessageType.GROUP_MASK;
  }

  public serialize(): ByteList {
    const bytes = super.serialize() as ByteList;
    bytes.writeUInt16(this.msgSize);
    bytes.writeByte(this.reserved);
    bytes.writeByte(this.id);
    bytes.writeUInt16(this.control);
    bytes.writeUInt32(this.srcAddr);
    bytes.writeUInt32(this.srcAddrMAC);
    bytes.writeUInt32(this.dstAddr);
    bytes.writeUInt32(this.dstAddrMAC);
    bytes.writeUInt32(this.firstHopAddr);
    bytes.writeByte(this.firstHopRssi);
    bytes.writeUInt16(this.msgType);

    if ((this.msgType & MASK) === RR3MessageType.GROUP_MASK) {
      this.device = this.device || new RR3();
    }

    if ((this.msgType & MASK) === TR4MessageType.GROUP_MASK) {
      this.device = this.device || new TR4();
    }

    if ((this.msgType & MASK) === TR4EMessageType.GROUP_MASK) {
      this.device = this.device || new TR4E();
    }

    if (this.device) {
      this.device.serialize(bytes);
    }

    return bytes;
  }

  public deserialize(bytes: ByteList) {
    super.deserialize(bytes);
    this.msgSize = bytes.readUInt16();
    this.reserved = bytes.readByte();
    this.id = bytes.readByte();
    this.control = bytes.readUInt16();
    this.srcAddr = bytes.readUInt32();
    this.srcAddrMAC = bytes.readUInt32();
    this.dstAddr = bytes.readUInt32();
    this.dstAddrMAC = bytes.readUInt32();
    this.firstHopAddr = bytes.readUInt32();
    this.firstHopRssi = bytes.readByte();
    this.msgType = bytes.readUInt16();

    if ((this.msgType & MASK) === RR3MessageType.GROUP_MASK) {
      this.device = new RR3();
    }

    if ((this.msgType & MASK) === TR4MessageType.GROUP_MASK) {
      this.device = new TR4();
    }

    if ((this.msgType & MASK) === TR4EMessageType.GROUP_MASK) {
      this.device = new TR4E();
    }

    if (this.device) {
      this.device.deserialize(bytes);
    }
  }

  public toString() {
    let typeStr = '';
    if (!typeStr) {
      forEach(RR3MessageType, (val, key) => {
        if (val === this.msgType) {
          typeStr = key;
        }
      });
    }
    if (!typeStr) {
      forEach(TR4MessageType, (val, key) => {
        if (val === this.msgType) {
          typeStr = key;
        }
      });
    }
    if (!typeStr) {
      forEach(TR4EMessageType, (val, key) => {
        if (val === this.msgType) {
          typeStr = key;
        }
      });
    }
    const id = this.srcAddr ? this.srcAddr.toString(16).toUpperCase() : '';
    return `EndDeviceMessage.${typeStr || this.msgType.toString(16).toUpperCase()} ${id}`;
  }
}

export enum FrameVersion {
  V1 = 0,
  V2 = 1,
}
