import { BitHandler } from '@ncss/models';

import { ByteList } from 'byte-list';

type PayloadConstructor = new (...args: any[]) => Payload;

export abstract class Payload extends BitHandler {

  static Create(type: number) {
    if (this.registeredClasses[type]) {
      return new this.registeredClasses[type]();
    }
  }

  static Register(type: number, classType: PayloadConstructor) {
    this.registeredClasses[type] = classType;
  }

  // tslint:disable-next-line: member-ordering
  private static registeredClasses: { [type: number]: PayloadConstructor } = {};
  // tslint:disable-next-line: member-ordering
  private static nextId = 2;
  private static GetNextId() {
    const id = this.nextId;
    this.nextId = (this.nextId++) % 255;
    if (this.nextId < 2) {
      this.nextId = 2;
    }
    return id;
  }

  id: number;
  abstract type: number;

  constructor() {
    super();
    this.id = Payload.GetNextId();
  }

  serialize(): ByteList {
    const bytes = new ByteList();
    bytes.writeByte(this.type);
    bytes.writeByte(this.id);
    return bytes;
  }

  deserialize(bytes: ByteList) {
    this.type = bytes.readByte();
    this.id = bytes.readByte();
  }
}
