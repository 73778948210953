import { Message, MessageProperty } from '@ncss/message';
import { BitwiseHelper } from '@ncss/models';

import { DataTypes } from 'byte-list';


export enum RE4DeviceRouteRequestMsgType {
  RE4_DEVICE_ROUTE_REQUEST = 0x2080,
}

export class DeviceRouteRequestMessage extends Message {

  @MessageProperty(DataTypes.UINT32)
  deviceId: number;

  @MessageProperty(DataTypes.UINT16)
  startingPage: number;

  @MessageProperty(DataTypes.UINT16)
  flags: number;

  set dropRoutingTable(val: boolean) {
    this.flags = BitwiseHelper.setValueInFlags(this.flags, val, 0);
  }

  set sortRoutingTable(val: boolean) {
    this.flags = BitwiseHelper.setValueInFlags(this.flags, val, 1);
  }
}
