import { User, DeviceTypeHelper, DeviceFamily, IUserPermissionInfo } from '@ncss/models';

import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Events, Platform, PopoverController, ModalController, ToastController } from '@ionic/angular';
import * as _ from 'lodash';

import { DirectConnectPopoverComponent, DirectConnectPopoverEvent } from '../components/direct-connect-popover/direct-connect-popover.component';
import { AppSettingsService } from '../services/app-settings.service';
import { BarcodeScannerService } from '../services/barcode-scanner.service';
import { BillingCompanyService } from '../services/billing-company.service';
import { ConnectionService } from '../services/connection.service';
import { IDirectConnectDevice } from '../services/direct-connect/baseDirectConnectDevice';
import { DirectConnectService } from '../services/direct-connect/direct-connect.service';
import { GpsLocationService } from '../services/gps-location.service';
import { MobilePropertyService } from '../services/mobile-property.service';
import { MobileUserService } from '../services/mobile-user.service';
import { PropertyNavService } from '../services/property-nav.service';
import { UpdateAccountComponent } from './../components/update-account/update-account.component';
import { MobileCloudScannerService } from './../services/mobile-cloud-scanner.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent {

  public viewingProperty = false;
  private proximityPopoverOpen = false;

  constructor(
    public mobilePropertyService: MobilePropertyService,
    private platform: Platform,
    private statusBar: StatusBar,
    private appSettingsService: AppSettingsService,
    private propertyNavService: PropertyNavService,
    private gpsLocation: GpsLocationService,
    private connectionService: ConnectionService,
    private directConnectService: DirectConnectService,
    private billingCompanyService: BillingCompanyService,
    private mobileUserService: MobileUserService,
    private router: Router,
    private events: Events,
    private popoverCtrl: PopoverController,
    private cloudScannerService: MobileCloudScannerService,
    private barcodeScannerService: BarcodeScannerService,
    private splashScreen: SplashScreen,
    private deeplinks: Deeplinks,
    private modalCtrl: ModalController,
    private toastCtrl: ToastController,
  ) {
    this.initializeApp();
  }

  public initializeApp() {
    console.log('%c AppComponent.initializeApp()', 'color: cyan');
    this.platform.ready().then(async () => {
      console.log('%c AppComponent.initializeApp.platform.ready()', 'color: cyan');
      this.mobileUserService.fetchUser();
      try {
        await this.appSettingsService.init();
      } catch (e) {
        console.error('Error while initializing appSettingsService: ' + e);
      }
      this.mobileUserService.user$.subscribe((user) => {
        this.userLoaded(user);
      });

      this.statusBar.backgroundColorByName('white');
      this.statusBar.styleDefault();
      this.propertyNavService.init();
      this.connectionService.init();

      // Adjust Menu color if in property view
      this.router.events.subscribe((event: any) => {
        if (event instanceof NavigationEnd) {
          this.viewingProperty = event.url.indexOf('/property/') === 0;
          const propertyId = event.url.split('/')[2];
          if (propertyId && !isNaN(propertyId as any) &&
            (!this.mobilePropertyService.property ||
              (this.mobilePropertyService.property && this.mobilePropertyService.property._id !== propertyId))) {
            this.mobilePropertyService.loadProperty(`p_${propertyId}`);
          }
        }
      });

      // Subscribe to Direct Connect popover events
      this.events.subscribe(DirectConnectPopoverEvent.Show, async (device: IDirectConnectDevice) => {
        // Only show the Direct Connect popover if we are already logged-in
        if (this.mobileUserService.user$.value && this.mobileUserService.user$.value._id) {
          this.directConnectPopover(device);
        }
      });

      this.splashScreen.hide();
      this.setupDeeplinks();
    }).catch((e) => {
      this.mobileUserService.fetchUser();
    });
  }

  public setupDeeplinks() {
    this.deeplinks.route({
      '/welcome': 'welcome',
    }).subscribe((match) => {
      const internalPath = `${match.$route}?newUserToken=${match.$args['newUserToken']}&userEmail=${match.$args['userEmail']}`;
      this.router.navigateByUrl(internalPath);
    });
  }

  public menuOpen(open) {
    this.appSettingsService.isMenuOpen = open;
  }

  private userLoaded(user?: User) {
    if (user && this.mobileUserService.token) {
      const defaultPermissionInfo = user.getDefaultPermissionInfo();
      if (!user.firstname || !user.lastname || !user.phone || !defaultPermissionInfo) {
        this.updateUserAccountInfo(user, defaultPermissionInfo);
      }
      if (defaultPermissionInfo && (!this.billingCompanyService.billingCompany$.value ||
        this.billingCompanyService.billingCompany$.value._id !== defaultPermissionInfo.id)) {
        this.billingCompanyService.loadBillingCompany(defaultPermissionInfo.id);
      }
      this.gpsLocation.init();
      this.directConnectService.init(user._id);
      this.cloudScannerService.onStartScanning.subscribe(this.onStartCloudScanning.bind(this));
      this.cloudScannerService.onStopScanning.subscribe(this.onStopCloudScanning.bind(this));
    } else {
      this.mobilePropertyService.clearCurrentProperty();
      this.directConnectService.destroy();
    }
  }

  private async updateUserAccountInfo(user: User, defaultPermission: IUserPermissionInfo) {
    if (!user || !user.activatedOn) { return; }
    const top = await this.modalCtrl.getTop();
    if (top) { return; }
    const companies = _.sortBy(
      _.map(_.filter(user.permissions, (p) => p.id.indexOf('bc_') === 0), (p) => ({ _id: p.id, name: p.name })),
      (c) => c.name);

    // its now okay for a user to not have any permissions/default permissions
    if (user.firstname && user.lastname && user.phone && (!companies || !companies.length)) {
      return;
    }
    const modal = await this.modalCtrl.create({
      component: UpdateAccountComponent,
      backdropDismiss: false,
      componentProps: {
        firstname: user.firstname,
        lastname: user.lastname,
        phone: user.phone,
        billingCompany: { _id: defaultPermission ? defaultPermission.id : null, name: defaultPermission ? defaultPermission.name : null },
        billingCompanies: companies,
        profilePic: user.profilePicUrl,
        initials: user.initials,
      },
    });
    modal.present();
    const res = await modal.onDidDismiss();
    if (res && res.role === 'submit' && res.data) {
      const update = {
        firstname: res.data.firstname,
        lastname: res.data.lastname,
        phone: res.data.phone,
      };
      if (res.data.billingCompany && res.data.billingCompany._id) {
        update['defaultCompanyId'] = res.data.billingCompany._id;
      }
      this.mobileUserService.update(user._id, update).then(() => {
        this.toastCtrl.create({ message: 'Account Updated!', duration: 2000, color: 'dark' }).then((t) => t.present());
      });
    } else if (res.role === 'close') {
      this.mobileUserService.logout();
      this.router.navigateByUrl('/login');
    }
  }

  private async directConnectPopover(device: IDirectConnectDevice) {
    if (device) {
      // Don't open popover if one is already open
      if (this.proximityPopoverOpen) {
        return;
      }
      this.proximityPopoverOpen = true;
      const popover = await this.popoverCtrl.create({
        component: DirectConnectPopoverComponent,
        componentProps: { device },
        cssClass: 'direct-connect-popover',
        animated: false,
      });

      popover.onDidDismiss().then((result) => {
        const navToDevice = result['data'];
        if (navToDevice) {
          const deviceType = DeviceTypeHelper.GetDeviceTypeBySerialNumber(device.serialNumber);
          if (deviceType && deviceType.family === DeviceFamily.PROGRAMMERS) {
            this.directConnectService.pairProgrammer(device);
          } else {
            this.directConnectService.connectDevice(device);
          }
        }
        this.proximityPopoverOpen = false;
      });

      popover.present().then(() => {
        this.directConnectService.updatePopoverTimestamp(device);
      });
    }
  }

  private async onStartCloudScanning() {
    const appSettings = await this.appSettingsService.getAppSettings();
    if (!appSettings.cloudScanner || !appSettings.cloudScanner.enabled) {
      this.cloudScannerService.cancelScanning(
        'Cloud Scanner is disabled on your mobile device. You can re-enable it through your app\'s settings',
      );
      return;
    }

    this.barcodeScannerService.showBarcodeScanner()
      .then((serialNumber) => {
        if (serialNumber) {
          this.cloudScannerService.scanned(serialNumber as number);
        }
      });
  }

  private onStopCloudScanning() {
    console.log('%c STOP SCANNING', 'color: yellow');
  }
}
