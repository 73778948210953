import { PipeLogic } from '@ncss/models';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'orderBy' })
export class OrderByPipe implements PipeTransform  {
  public transform(array, args?) {
    return PipeLogic.OrderBy(array, args);
  }
}
