import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, AsyncValidatorFn } from '@angular/forms';
import { PopoverController } from '@ionic/angular';
import * as _ from 'lodash';

export enum CustomInputPopoverType {
  SERIAL_INPUT_POPOVER,
  NUMERIC_INPUT_POPOVER,
  TEXT_INPUT_POPOVER,
}

@Component({
  selector: 'app-custom-input-popover',
  templateUrl: './custom-input-popover.component.html',
  styleUrls: ['./custom-input-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomInputPopoverComponent implements OnInit {

  public CustomInputPopoverType = CustomInputPopoverType; // for HTML

  @Input() type: CustomInputPopoverType;
  @Input() header: string;
  @Input() placeholder: string;
  @Input() submitLabel: string;
  @Input() syncValidators: ValidatorFn | ValidatorFn[];
  @Input() asyncValidators: AsyncValidatorFn | AsyncValidatorFn[];
  @Input() initialValue;

  form: FormGroup;

  constructor(
    private popoverCtrl: PopoverController,
  ) { }

  ngOnInit() {
    this.form = new FormGroup({
      input: new FormControl(this.initialValue || null, this.syncValidators, this.asyncValidators),
    });
  }

  public cancelClicked() {
    this.popoverCtrl.dismiss();
  }

  public submitClicked() {
    if (this.form.valid) {
      this.popoverCtrl.dismiss({value: this.form.value.input});
    }
  }

}
