import {
  BitwiseHelper,
  RR3MessageType,
  DeviceTypeHelper,
  DeviceFamily,
  DeviceTypes,
  DeviceTypeIds,
  ConversionUtils,
  TR4MessageType,
  TR4EInfoMessageType,
  TR4EMessageType
} from '@ncss/models';

import { ByteList } from 'byte-list';
import * as _ from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';

import {
  DirectConnectBaseDevice,
  DirectConnectDeviceStatus,
  IDirectConnectDevice,
  NCSS_DIRECT_CONNECT_CHARACTERISTIC_NOTIFY,
  NCSS_DIRECT_CONNECT_CHARACTERISTIC_WRITE,
  NCSS_DIRECT_CONNECT_SERVICE_UUID
} from '../baseDirectConnectDevice';
import { DC301DeviceMessage, DC301DeviceMessageType, BatteryChargeStatusType } from './messages/dc301DeviceMessage';
import { DC301Flags } from './messages/dc301Flags';
import { DC301InfoMessage, DC301InfoMessageType } from './messages/dc301InfoMessage';
import { DC301_UPDATE_DATA_SIZE, DC301UpdateMessageType, DC301UpdateMessage } from './messages/dc301UpdateMessage';
import { DC301UpdateStatusMessageType, DC301UpdateStatusMessage, UpdateErrorCode } from './messages/dc301UpdateStatusMessage';
import { EndDeviceMessageType, EndDeviceMessage } from './messages/endDeviceMessage';
import { InfoMessage } from './messages/infoMessage';

export interface IFirmwareMetaData {
  versionMajor: number;
  versionMinor: number;
  transferId: number;
  blocks: Uint8Array[];
  blockIndex: number;
  lastFrameId: number;
  deviceType: number;
  timeoutTimer: any;
  retryCount: number;
}

export class DirectConnect301 extends DirectConnectBaseDevice {

  public static useMetric = false;

  static create(bleDevice: any, manufacturerData: { serialNumber: number, byteList: ByteList }, isLimited = false): IDirectConnectDevice {
    if (!manufacturerData || !manufacturerData.serialNumber || !manufacturerData.byteList) {
      return null;
    }

    if (DeviceFamily.PROGRAMMERS !== DeviceTypeHelper.GetFamilyBySerialNumber(manufacturerData.serialNumber)) {
      return null;
    }

    const directConnect = new DirectConnect301();
    directConnect.addAdvertisingData(manufacturerData.serialNumber, manufacturerData.byteList);

    const device: IDirectConnectDevice = {
      status: DirectConnectDeviceStatus.ADVERTISING,
      serialNumber: manufacturerData.serialNumber,
      bleSignalStrength: null,
      bleDevice: bleDevice,
      device: directConnect,
      subscriptions: [],
      timers: [],
    };

    return device;
  }

  public get serialNumber() { return this._serialNumber; }
  public get firmwareVersionBle() { return this._firmwareVersionBle; }
  public get bufferedFirmware() { return this._bufferedFirmware; }
  public get resetCount() { return this._resetCount; }
  public get bleResetCount() { return this._bleResetCount; }
  public get flags() { return this._flags; }

  public get batteryPercent() { return this._batteryPercent$.value; }
  public get batteryPercent$() { return this._batteryPercent$.asObservable(); }

  public get temperature() { return this._temperature$.value; }
  public get temperature$() { return this._temperature$.asObservable(); }

  public get batteryChargeStatus() { return this._batteryChargeStatus$.value; }
  public get batteryChargeStatus$() { return this._batteryChargeStatus$.asObservable(); }

  public get isCharging() { return this._isCharging$.value; }
  public get isCharging$() { return this._isCharging$.asObservable(); }

  public get deviceName() { return this._deviceName$.value; }
  public get deviceName$() { return this._deviceName$.asObservable(); }

  public get onEndDeviceMessage$() { return this._onEndDeviceMessage$.asObservable(); }
  public get firmwareUpdateProgress$() { return this._firmwareUpdateProgress$.asObservable(); }

  private _serialNumber: number;
  private _firmwareVersionBle: { major: number, minor: number } = { major: 0, minor: 0 };
  private _bufferedFirmware: { major: number, minor: number, transferId: number, blockNumber: number };
  private _batteryPercent$ = new BehaviorSubject<number>(null);
  private _temperature$ = new BehaviorSubject<number>(null);
  private _batteryChargeStatus$ = new BehaviorSubject<BatteryChargeStatusType>(null);
  private _isCharging$ = new BehaviorSubject<boolean>(false);
  private _deviceName$ = new BehaviorSubject<string>(DeviceTypes[DeviceTypeIds.DIRECT_CONNECT_PROGRAMMER].model);
  private _resetCount: number;
  private _bleResetCount: number;
  private _flags: DC301Flags;
  private _onEndDeviceMessage$ = new Subject<EndDeviceMessage>();
  private _connected = false;
  private _nextTransferId = 1;
  private _firmwareUpdateProgress$ = new Subject<{ blockNumber: number, totalBlocks: number }>();
  private _bufferingFirmwareMetaData: IFirmwareMetaData | null;

  constructor() {
    super();
    this.messageService.registerMessageGroup({
      mask: DC301DeviceMessageType.GROUP_MASK,
      messageClass: DC301DeviceMessage,
      handler: (msg: DC301DeviceMessage) => {
        this.handleDeviceMessage(msg);
      },
    });
    this.messageService.registerMessageGroup({
      mask: EndDeviceMessageType.GROUP_MASK,
      messageClass: EndDeviceMessage,
      handler: (msg: EndDeviceMessage) => {
        if (msg.msgType === RR3MessageType.USER_EVENT || msg.msgType === RR3MessageType.RESPONSE) {
          this._onEndDeviceMessage$.next(msg);
        } else if (msg.msgType === TR4MessageType.USER_EVENT || msg.msgType === TR4MessageType.RESPONSE) {
          console.log('%c Incoming TR401 Message:', 'color: cyan', msg);
          this._onEndDeviceMessage$.next(msg);
        } else if (msg.msgType === TR4EMessageType.USER_EVENT || msg.msgType === TR4EMessageType.RESPONSE) {
          console.log('%c Incoming TR4E Message:', 'color: magenta', msg);
          this._onEndDeviceMessage$.next(msg);
        }
      },
    });
    this.messageService.registerMessageGroup({
      mask: DC301UpdateStatusMessageType.GROUP_MASK,
      messageClass: DC301UpdateStatusMessage,
      handler: (msg: DC301UpdateStatusMessage) => {
        this.handleDCUpdateMessage(msg);
      },
    });
    this._flags = new DC301Flags();
  }

  public requestBroadcast() {
    const msg = new InfoMessage(0xFFFFFFFF, TR4EInfoMessageType.DC301_REQUEST);
    this.sendInfoMessage(msg);
  }

  public requestInfo() {
    const msg = new DC301InfoMessage(DC301InfoMessageType.REQUEST_INFO);
    this.txBytes.next(this.messageService.serialize(msg));
  }

  public setPartyMode(flag: boolean) {
    const msg = new DC301InfoMessage(DC301InfoMessageType.SET_INFO);
    msg.partyMode = flag;
    const bytes = this.messageService.serialize(msg);
    this.txBytes.next(bytes);
  }

  public factoryReset() {
    const msg = new DC301InfoMessage(DC301InfoMessageType.SET_INFO);
    msg.factoryReset = true;
    const bytes = this.messageService.serialize(msg);
    this.txBytes.next(bytes);
  }

  public setDeviceName(deviceName: string) {
    const msg = new DC301InfoMessage(DC301InfoMessageType.SET_INFO);
    msg.setDeviceName = true;
    msg.deviceName = deviceName;
    const bytes = this.messageService.serialize(msg);
    this.txBytes.next(bytes);
  }

  public sendInfoMessage(msg: InfoMessage) {
    console.log('%c sending info message', 'color: cyan', msg);
    const bytes = this.messageService.serialize(msg);
    this.txBytes.next(bytes);
  }

  public loadNewFirmware(data: Uint8Array, versionMajor: number, versionMinor: number, deviceType: DeviceTypeIds) {
    const blocks: Uint8Array[] = [];
    let blockIndex = 0;
    let byteIndex = 0;
    data.forEach((byte) => {
      if (blocks.length <= blockIndex) {
        const arr = new Uint8Array(DC301_UPDATE_DATA_SIZE);
        arr.fill(255);
        blocks.push(arr);
      }
      blocks[blockIndex][byteIndex] = byte;
      byteIndex++;
      if (byteIndex >= DC301_UPDATE_DATA_SIZE) {
        byteIndex = 0;
        blockIndex++;
      }
    });
    this._bufferingFirmwareMetaData = {
      versionMajor,
      versionMinor,
      blocks,
      deviceType,
      transferId: this.getTransferId(),
      blockIndex: 0,
      retryCount: 3,
      timeoutTimer: null,
      lastFrameId: null,
    };
    this.sendFirmwareChunk(this._bufferingFirmwareMetaData);
  }

  private addAdvertisingData(serialNumber: number, byteList: ByteList) {
    this._serialNumber = serialNumber;
    this.modelId = DeviceTypeHelper.GetIdBySerialNumber(serialNumber);
    this.uuid.service = NCSS_DIRECT_CONNECT_SERVICE_UUID;
    this.uuid.write = NCSS_DIRECT_CONNECT_CHARACTERISTIC_WRITE;
    this.uuid.read = NCSS_DIRECT_CONNECT_CHARACTERISTIC_NOTIFY;
    const rawHardwareVersion = byteList.readByte();
    this.hardwareVersion = {
      major: BitwiseHelper.GetBits(rawHardwareVersion, 0, 3),
      minor: BitwiseHelper.GetBits(rawHardwareVersion, 3, 5),
    };
    this.firmwareVersion = { major: byteList.readByte(), minor: byteList.readByte() };
    this._firmwareVersionBle = { major: byteList.readByte(), minor: byteList.readByte() };
    this._batteryPercent$.next(byteList.readByte());
    this._batteryChargeStatus$.next(byteList.readByte());
    this._isCharging$.next([
      BatteryChargeStatusType.BATTERY_CHARGE_TRICKLE,
      BatteryChargeStatusType.BATTERY_CHARGING_CC,
      BatteryChargeStatusType.BATTERY_CHARGING_CV,
    ].indexOf(this._batteryChargeStatus$.value) !== -1);
    this._temperature$.next(byteList.readInt8());
    this._flags.value = byteList.readUInt32();
    byteList.readByte(); // Reserved 1 byte
    // Check to ensure that BLE properly sent over the scan response bytes
    // that contain the rest of the Device Name from the DC
    if (byteList.length - byteList.index < 26) {
      return;
    }

    byteList.readUInt16(); // Reserved Scan Response 2 bytes
    this.emitDeviceName(_.trim(byteList.readString({ length: 24 }), '\0'));
  }

  private handleDeviceMessage(msg: DC301DeviceMessage) {
    this._connected = true;
    this.hardwareVersion = { major: msg.hardwareVersionMajor, minor: msg.hardwareVersionMinor };
    this.firmwareVersion = { major: msg.firmwareVersionMajor, minor: msg.firmwareVersionMinor };
    this._firmwareVersionBle = { major: msg.bleFirmwareVersionMajor, minor: msg.bleFirmwareVersionMinor };
    this._bufferedFirmware = {
      major: msg.rfFirmwareBufferedVersionMajor,
      minor: msg.rfFirmwareBufferedVersionMinor,
      transferId: msg.rfFirmwareBufferedVersionTransferId,
      blockNumber: msg.rfFirmwareBufferedVersionBlockNumber,
    };
    this.emitDeviceName(msg.deviceName);
    this._resetCount = msg.resetCount;
    this._bleResetCount = msg.bleResetCount;
    this._batteryPercent$.next(msg.batteryPercent);
    this._temperature$.next(msg.batteryTemp);
    this._batteryChargeStatus$.next(msg.batteryChargeStatus);
    this._isCharging$.next([
      BatteryChargeStatusType.BATTERY_CHARGE_TRICKLE,
      BatteryChargeStatusType.BATTERY_CHARGING_CC,
      BatteryChargeStatusType.BATTERY_CHARGING_CV,
    ].indexOf(msg.batteryChargeStatus) !== -1);
    this._flags.value = msg.rawFlags;
  }

  private emitDeviceName(deviceName: string) {
    let name = DeviceTypes[DeviceTypeIds.DIRECT_CONNECT_PROGRAMMER].model;
    if (this.serialNumber && !this._connected) {
      name = ConversionUtils.ConvertSerialNumberToString(this.serialNumber);
    }
    if (_.isString(deviceName) && deviceName) {
      name = deviceName;
    }
    this._deviceName$.next(name);
  }

  private stopLoadingFirmware() {
    if (this._bufferingFirmwareMetaData) {
      clearTimeout(this._bufferingFirmwareMetaData.timeoutTimer);
    }
    this._bufferingFirmwareMetaData = null;
  }

  private sendFirmwareChunk(metaData: IFirmwareMetaData) {
    let msgType = 0;
    if (metaData.deviceType === DeviceTypeIds.DIRECT_CONNECT_PROGRAMMER) {
      msgType = DC301UpdateMessageType.DC_UPDATE_MCU;
    } else if (metaData.deviceType === 22) { // Move this to DeviceTypes
      msgType = DC301UpdateMessageType.DC_UPDATE_BLE;
    } else {
      // What did you send me!
      this.stopLoadingFirmware();
      return;
    }
    const msg = new DC301UpdateMessage(msgType);
    msg.transferId = metaData.transferId as number;
    msg.deviceType = metaData.deviceType;
    msg.versionMajor = metaData.versionMajor;
    msg.versionMinor = metaData.versionMinor;
    msg.totalBlocks = metaData.blocks.length;
    msg.blockNumber = (metaData.blockIndex || 0) + 1;
    msg.data = (metaData.blocks || [])[metaData.blockIndex || 0];

    const bytes = this.messageService.serialize(msg);
    this.txBytes.next(bytes);
    console.log('Sent Firmware Block', msg);
    metaData.lastFrameId = msg.frameId as number;
    this._firmwareUpdateProgress$.next({ blockNumber: msg.blockNumber, totalBlocks: msg.totalBlocks });

    if (this._bufferingFirmwareMetaData) {
      this._bufferingFirmwareMetaData.timeoutTimer = setTimeout(() => {
        if (this._bufferingFirmwareMetaData && this._bufferingFirmwareMetaData.retryCount > 0) {
          this._bufferingFirmwareMetaData.retryCount--;
          this.sendFirmwareChunk(metaData);
        } else {
          this.stopLoadingFirmware();
        }
      }, 2000);
    }
  }

  private handleDCUpdateMessage(msg: DC301UpdateStatusMessage) {
    console.log('Received Update Message', msg);
    if (!this._bufferingFirmwareMetaData) {
      return;
    }

    clearTimeout(this._bufferingFirmwareMetaData.timeoutTimer);
    this._bufferingFirmwareMetaData.retryCount = 3;

    if (msg.errorCode === UpdateErrorCode.UPDATE_ERROR_NONE) {
      if (this._bufferingFirmwareMetaData.blockIndex < this._bufferingFirmwareMetaData.blocks.length) {
        this._bufferingFirmwareMetaData.blockIndex++;
        this.sendFirmwareChunk(this._bufferingFirmwareMetaData);
      }
    } else {
      this.stopLoadingFirmware();
    }
  }

  private getTransferId() {
    this._nextTransferId++;
    this._nextTransferId = this._nextTransferId % 0XFF;
    return this._nextTransferId;
  }
}
