
import { PipeLogic } from '@ncss/models';

import { Pipe, PipeTransform } from '@angular/core';


@Pipe({name: 'fileType'})
export class FileTypePipe implements PipeTransform {
  public transform(value: any, args?: any): any {
    return PipeLogic.FileType(value, args);
  }
}
