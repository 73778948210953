import { IUtilityType, UtilityTypes, UtilityTypeIds } from '@ncss/models';

import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  styleUrls: ['./no-equipment.component.scss'],
  templateUrl: './no-equipment.component.html',
  selector: 'app-no-equipment',
})
export class NoEquipmentComponent {
  utilities: IUtilityType[] = [
    UtilityTypes[UtilityTypeIds.ALL_WATER],
    UtilityTypes[UtilityTypeIds.GAS],
    UtilityTypes[UtilityTypeIds.ELECTRIC],
  ];
  moreUtilities: IUtilityType[] = [
    UtilityTypes[UtilityTypeIds.COLD_WATER],
    UtilityTypes[UtilityTypeIds.HOT_WATER],
    UtilityTypes[UtilityTypeIds.COMMERCIAL_WATER],
    UtilityTypes[UtilityTypeIds.THERMAL_USAGE],
    UtilityTypes[UtilityTypeIds.RUN_TIME],
  ];

  @Output() utilityClicked = new EventEmitter<UtilityTypeIds>();
  @Output() leakSensorClicked = new EventEmitter<void>();

  showMoreUtilities = false;

  constructor() {}
}
