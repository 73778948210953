import { ByteList } from 'byte-list';

import { Payload } from '../payload';

export const CommandType = 0xA0;

export enum CommandCode {
  FORCE_SYNC = 0x01,
  HEART_BEAT = 0x02,

  CELLULAR_ENABLE = 0x12,
  CELLULAR_DISABLE = 0x13,
  CELLULAR_FORCE_ENABLE = 0x14,
  CELLULAR_FORCE_DISABLE = 0x15,

  REQUEST_INFO = 0x20,
  REQUEST_ROUTING_TABLE = 0x21,

  SET_REMOTE_HOST = 0x30,
  FORGET_WIFI_NETWORK = 0x31,
  CHANGE_NETWORK = 0x32,
  CHECK_FOR_UPDATE = 0x33,

  START_THE_PARTY = 0xA0,
  STOP_THE_PARTY = 0xA1,
}

export class Command extends Payload {

  type = CommandType;
  commandCode?: CommandCode;

  constructor(code?: CommandCode) {
    super();
    this.commandCode = code;
  }

  serialize(): ByteList {
    const bytes = super.serialize();
    bytes.writeByte(this.commandCode);
    return bytes;
  }

  deserialize(bytes: ByteList): void {
    super.deserialize(bytes);
    this.commandCode = bytes.readByte();
  }

}
