import { Injectable } from '@angular/core';
import { IonicNativePlugin, cordova } from '@ionic-native/core';
import { ByteList } from 'byte-list';

export interface EncryptionKey {
  value: number[];
  delta: number;
  iterations: number;
}

@Injectable({
  providedIn: 'root',
})
export class NcssEncryptPlugin extends IonicNativePlugin {
  // name in package.json file of plugin
  static pluginName = 'ncssencrypt';

  // plugin id in the plugin.xml of plugin
  static plugin = 'cordova-plugin-ncss-encrypt';

  // clobbers target in plugin.xml
  static pluginRef = 'NCSSEncrypt';

  static platforms: string[] = ['iOS', 'android'];

  encrypt(bytes: ByteList | Buffer, deviceId: number, frameId: number): Promise<Buffer> {
    if (bytes instanceof Buffer) {
      bytes = new ByteList(bytes);
    }
    const data = [];
    bytes.index = 0;
    for (let i = 0; i < bytes.length; i++) {
      data.push(bytes.readByte());
    }
    return cordova(this, 'encrypt', {}, [{ data, deviceId, frameId }]).then((res) => {
      return Buffer.from(res);
    });
  }

  decrypt(bytes: ByteList | Buffer, deviceId: number, frameId: number): Promise<Buffer> {
    if (bytes instanceof Buffer) {
      bytes = new ByteList(bytes);
    }
    const data = [];
    bytes.index = 0;
    for (let i = 0; i < bytes.length; i++) {
      data.push(bytes.readByte());
    }
    return cordova(this, 'decrypt', {}, [{ data, deviceId, frameId }]).then((res) => {
      return Buffer.from(res);
    });
  }
}
