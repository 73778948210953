import { Component, Input, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, SimpleChanges, OnChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { AppSettingsService } from '../../services/app-settings.service';
import { ConnectionService } from '../../services/connection.service';
import { DocumentUploadService } from './../../services/document-upload.service';

@Component({
  selector: 'app-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageFooterComponent implements OnInit, OnChanges {

  @Input() public icons: {
    iconName: string;
    value: number;
    color?: string;
    hidden?: boolean;
  }[] = [];
  public showIcons = false;
  public filteredIcons$ = new BehaviorSubject<{
    iconName: string;
    value: number;
    color?: string;
    hidden?: boolean;
  }[]>([]);
  public uploadCount = 0;

  constructor(
    public appSettings: AppSettingsService,
    public connectionService: ConnectionService,
    public documentUploadService: DocumentUploadService,
    private cd: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.uploadCount = this.documentUploadService.uploadsInProgressCount$.value || 0;
    this.documentUploadService.uploadsInProgressCount$.subscribe((count) => {
      this.uploadCount = count;
      this.checkForUploads();
    });
    setTimeout(() => {
      this.showIcons = true;
      this.cd.markForCheck();
    }, 1000);
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes.icons) {
      this.checkForUploads();
    }
  }

  checkForUploads() {
    const i = this.icons ? [...this.icons] : [];
    if (this.uploadCount > 0) {
      i.push({iconName: 'icon-upload', color: 'primary', value: this.uploadCount});
    }
    this.filteredIcons$.next(i.filter((icon) => !icon.hidden));
    this.cd.markForCheck();
  }
}
