import { ConversionUtils } from '@ncss/models';

import { Injectable } from '@angular/core';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { NativeAudio } from '@ionic-native/native-audio/ngx';
import { LoadingController } from '@ionic/angular';

import { AppSettingsService } from './app-settings.service';

@Injectable()
export class BarcodeScannerService {

  public scanning = false;
  private initialized = false;
  private beepEnabled = false;

  constructor(
    private barcodeScanner: BarcodeScanner,
    private nativeAudio: NativeAudio,
    private appSettingsService: AppSettingsService,
    private loadingCtrl: LoadingController,
  ) { }

  public async showBarcodeScanner(returnString?: boolean, allFormats?: boolean): Promise<number | string> {
    const loading = await this.loadingCtrl.create();
    loading.present();
    if (!this.initialized) {
      this.init();
      this.initialized = true;
    }
    const options = {
      showTorchButton: true,
      resultDisplayDuration: 0,
      disableAnimations: true,
    };
    if (!allFormats) {
      options['formats'] = 'CODE_128,CODE_39';
    }
    return this.barcodeScanner.scan(options).then((result) => {
      loading.dismiss();
      if (result && result.text && returnString) {
        return result.text.trim() as any;
      } else if (result && result.text) {
        result.text = result.text.trim();
        return ConversionUtils.ConvertSerialNumberToNumberForFrontEnd(result.text);
      } else {
        return null;
      }
    }, () => {
      loading.dismiss();
      return null;
    });
  }

  private init() {
    this.appSettingsService.appSettings$.subscribe(appSettings => {
      if (appSettings.barcodeBeep && !this.beepEnabled) {

        this.nativeAudio.preloadComplex('beep', 'assets/sound/beep.mp3', .5, 1, 0)
          .then(() => {
            this.beepEnabled = true;
          }, (e) => {
            console.log('Could not load beep audio', e);
            this.beepEnabled = false;
          });

      } else if (!appSettings.barcodeBeep) {
        this.beepEnabled = false;
      }
    });
  }
}
