import { DeviceTypeIds, DeviceTypes, IDeviceType, DeviceTypeHelper } from '@ncss/models';

import { Component, OnInit, OnDestroy, Input, ViewChild, AfterViewInit } from '@angular/core';
import { ModalController, IonToggle, ActionSheetController, LoadingController } from '@ionic/angular';
import { ActionSheetButton } from '@ionic/core';
import { Observable, of } from 'rxjs';
import { filter, take, timeout, catchError, mapTo } from 'rxjs/operators';

import { DirectConnectTR4E } from '../../../services/direct-connect/tr4-e/tr4-e';
import { ToastService } from '../../../services/toast.service';
import { IDirectConnectDevice } from './../../../services/direct-connect/baseDirectConnectDevice';
import { EndDeviceFormState } from './../../../services/direct-connect/remoteReader/remoteReader';

@Component({
  selector: 'app-tr4e-modal',
  templateUrl: './tr4-e-modal.component.html',
  styleUrls: ['./tr4-e-modal.component.scss'],
})
export class Tr4eModalComponent implements OnInit, OnDestroy, AfterViewInit {

  public TR4FormState = EndDeviceFormState;
  public DeviceTypeIds = DeviceTypeIds;
  public DeviceTypes = DeviceTypes;

  @ViewChild('enableRapidCheckIn') public enableRapidCheckInToggle: IonToggle;
  @ViewChild('enableLegacyMode') public enableLegacyModeToggle: IonToggle;
  @Input() public connectedDevice: IDirectConnectDevice;

  public tr4e: DirectConnectTR4E;
  public showMoreDeviceInfo = false;
  public showMoreMeterInfo = false;
  public showAlerts = false;
  public selectedTab: 'wiring' | 'status' | 'program' = 'status';
  public imgUrl = 'TR401-Flat.svg';
  public deviceType: IDeviceType;
  public tabs: Array<{ name: string, value: string, icon: string, disabled$?: Observable<boolean> }> = [
    { name: 'Status', value: 'status', icon: 'icon-transceiver' },
  ];

  private _subscriptions = [];
  private _quickActions: ActionSheetButton[] = [];

  constructor(
    private modalCtrl: ModalController,
    private loadCtl: LoadingController,
    private actionSheetCtrl: ActionSheetController,
    private toast: ToastService,
  ) { }

  ngOnInit() {
    if (!this.connectedDevice) { return; }
    this.tr4e = this.connectedDevice.device as DirectConnectTR4E;
    this.deviceType = DeviceTypeHelper.GetDeviceTypeBySerialNumber(this.tr4e.serialNumber);
  }

  ngAfterViewInit() {
    this._subscriptions.push(
      this.tr4e.rapidCheckInEnabled$.subscribe((val) => {
        setTimeout(() => this.enableRapidCheckInToggle.checked = val, 0);
      }),
      this.tr4e.useLegacyMode$.subscribe((val) => {
        setTimeout(() => {
          if (this.enableLegacyModeToggle) {
            this.enableLegacyModeToggle.checked = val;
          }
        }, 0);
      }),
    );
    this.initQuickActionButtons();
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  public enableRapidCheckInClicked(quickAction = false) {
    this.tr4e.setRapidCheckInEnabled(!this.tr4e.rapidCheckInEnabled);
    if (quickAction) {
      this.save(quickAction);
    }
  }

  public enableLegacyModeClicked() {
    this.tr4e.setUseLegacyMode(!this.tr4e.useLegacyMode);
  }

  public async save(quickAction = false) {
    const a = await this.loadCtl.create({ message: 'Applying changes...' });
    await a.present();
    this.tr4e.applyChanges();
    const success: boolean = await (this.tr4e.state$.pipe(
      filter((s) => s === EndDeviceFormState.CHANGES_APPLIED),
      mapTo(true),
      take(1),
      timeout(35 * 1000),
      catchError(() => of(false)),
    ).toPromise());
    if (success) {
      this.toast.queueToast('Changes applied!');
      this.tr4e.dropUserChanges();
    } else {
      this.toast.queueToast('Failed to apply changes');
      this.tr4e.markFormAsDirty();
    }
    await a.dismiss();
  }

  public close() {
    this.tr4e.dropUserChanges();
    this.modalCtrl.dismiss();
  }

  public async quickActionsClicked() {
    this.initQuickActionButtons();
    const sheet = await this.actionSheetCtrl.create({
      header: 'Quick Actions',
      buttons: this._quickActions,
    });
    await sheet.present();
  }

  private initQuickActionButtons() {
    this._quickActions = [
      {
        text: `${this.tr4e.rapidCheckInEnabled ? 'Disable' : 'Enable'} Rapid Check In`,
        handler: () => { this.enableRapidCheckInClicked(true); },
      },
    ];
  }
}
