import { ConversionUtils } from '@ncss/models';

import { Component } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import * as _ from 'lodash';
import { Subject } from 'rxjs';

import { IDirectConnectDevice } from '../../../services/direct-connect/baseDirectConnectDevice';
import { DirectConnectService } from '../../../services/direct-connect/direct-connect.service';
import { DirectConnect301 } from '../../../services/direct-connect/directConnect301/directConnect301';
import { FeedbackService, FeedbackType } from '../../../services/feedback.service';


@Component({
  selector: 'app-dc301-modal',
  templateUrl: './dc301-modal.component.html',
  styleUrls: ['./dc301-modal.component.scss'],
})
export class DirectConnect301ModalComponent {

  public cellularEnabled = false;
  public serialNumber$ = new Subject<string>();
  public editName = false;
  public showAdvancedOptions = false;
  public partyButtonTouched = false;
  public directConnect: DirectConnect301;
  private subscriptions = [];

  constructor(
    public directConnectService: DirectConnectService,
    private modalCtrl: ModalController,
    private alertController: AlertController,
    private feedback: FeedbackService,
  ) { }

  public async ionViewWillEnter() {
    this.subscriptions.push(this.directConnectService.pairedProgrammer$.subscribe((pairedProgrammer: IDirectConnectDevice) => {
      const serialNumber: string = (pairedProgrammer && pairedProgrammer.serialNumber) ?
        ConversionUtils.ConvertSerialNumberToString(pairedProgrammer.serialNumber) : '';

      this.serialNumber$.next(serialNumber);

      if (!pairedProgrammer) {
        this.modalCtrl.dismiss();
      }
    }));
  }

  public ionViewDidLeave() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  public close() {
    this.modalCtrl.dismiss();
  }

  public save() {
    //
  }

  public toggleEditName(flag: boolean) {
    this.editName = flag;
  }

  public toggleAdvanced() {
    this.showAdvancedOptions = !this.showAdvancedOptions;
  }

  public unpairDevice() {
    setTimeout(() => {
      this.directConnectService.unPairProgrammer(); // For good
      this.close();
    }, 200);
  }

  public togglePartyMode() {
    const device = this.directConnectService.pairedProgrammer.device as DirectConnect301;
    device.setPartyMode(!device.flags.partyMode);
    this.feedback.HapticFeedback(FeedbackType.TOGGLE_PARTY_MODE);
  }

  public async factoryReset() {
    const alert = await this.alertController.create({
      header: 'Factory Reset',
      message: 'Are you sure you want to restore this device to its default settings',
      buttons: [{
        text: 'Yes',
        handler: () => {
          (this.directConnectService.pairedProgrammer.device as DirectConnect301).factoryReset();
          // TODO: We will need to remove this and move it to the service
          this.unpairDevice();
        },
      },
        'Cancel',
      ],
    });
    await alert.present();
  }

  public setDeviceName(deviceName) {
    if (deviceName) {
      const device = this.directConnectService.pairedProgrammer.device as DirectConnect301;
      device.setDeviceName(deviceName);
    }
    this.toggleEditName(false);
  }

  public getSignalStrengthText(signal: number): { value: string, color: string } {
    if (signal >= 60) {
      return { value: 'Good', color: '#7bc147' };
    } else if (signal >= 30) {
      return { value: 'Fair', color: '#cebf10' };
    } else {
      return { value: 'Poor', color: '#E3515F' };
    }
  }

  public getSignalStrengthColor(signal: number): { value: number, color: string } {
    let color;

    if (signal >= 60) {
      color = '#3E89BF';
    } else if (signal >= 30) {
      color = '#7bc147';
    } else {
      color = '#cebf10';
    }

    return { value: signal / 100, color };
  }

  public cleanInput(event: KeyboardEvent) {
    return /[a-z0-9\s'\-]/gi.test(event.key);
  }
}
