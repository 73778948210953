import { BillingCompany } from '@ncss/models';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { AppSettingsService, BackEndHost, IAppSettings } from './app-settings.service';

@Injectable({
  providedIn: 'root',
})
export class BillingCompanyService {

  public billingCompany$ = new BehaviorSubject<BillingCompany>(null);
  public loadingBillingCompany$ = new BehaviorSubject<boolean>(false);
  public rootUrl: string;

  constructor(
    private http: HttpClient,
    private appSettings: AppSettingsService,
  ) {
    this.appSettings.appSettings$.pipe(
      filter((settings: IAppSettings) => settings.backEnd !== this.rootUrl),
      map((settings: IAppSettings) => settings.backEnd),
    ).subscribe((backend) => {
      this.rootUrl = backend || BackEndHost.Production;
    });
  }

  public getBillingCompany(bcId: string, callback: (data) => void) {
    this.http.get(`${this.rootUrl}/api/BillingCompanies/${bcId}`).subscribe((bc) => callback(bc));
  }

  public findById(id: string, params?: { force: boolean }): Observable<BillingCompany> {
    if (params) {
      let p = new HttpParams();
      p = p.set('force', params.force ? 'true' : 'false');
      return this.http.get(`${this.rootUrl}/api/BillingCompanies/${id}`, { params: p })
        .pipe(map((bc) => bc ? new BillingCompany(bc) : null));
    }
    return this.http.get(`${this.rootUrl}/api/BillingCompanies/${id}`).pipe(map((bc) => bc ? new BillingCompany(bc) : null));
  }

  public async loadBillingCompany(id: string) {
    this.loadingBillingCompany$.next(true);
    return this.findById(id).subscribe((bc: BillingCompany) => {
      this.billingCompany$.next(bc);
      this.loadingBillingCompany$.next(false);
    });
  }

  public getBillingCompanyNamesList(): Observable<Array<{_id: string, name: string}>> {
    return this.http.get<Array<{_id: string, name: string}>>(`${this.rootUrl}/api/common/billing-company-names`);
  }
}
