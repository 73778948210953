import {
  SaveUserInfo,
  User,
  UserLocationInfo,
  ILoginResponse,
  IUserMobileDeviceStats,
  NwkRoute,
} from '@ncss/models';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Device } from '@ionic-native/device/ngx';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, merge, timer, Subject } from 'rxjs';
import { filter, take, map, startWith, distinctUntilChanged, mapTo } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

import { AppSettingsService, BackEndHost } from './app-settings.service';
import { DirectConnect301 } from './direct-connect/directConnect301/directConnect301';
import { DirectConnectGateway301 } from './direct-connect/gateway301/gateway301';
import { RfDebugMessage } from './direct-connect/RE4/messages/RfDebugMessage';
import { DirectConnectRE4 } from './direct-connect/RE4/RE4';
import { DirectConnectRemoteReader } from './direct-connect/remoteReader/remoteReader';
import { DirectConnectRR4 } from './direct-connect/RR4/RR4';
import { DirectConnectTR4 } from './direct-connect/tr4/tr4';


enum StorageKeys {
  TOKEN = 'token',
  USER_LAST_FETCHED_AT = 'userLastFetchedAt',
  DEVICE_ID = 'deviceId',
  USER = 'user',
}


export interface ICreateNoPermissionUser {
  _id: string;
  username: string;
  firstname: string;
  lastname: string;
  password: string;
}

@Injectable({
  providedIn: 'root',
})
export class MobileUserService {

  public get user() { return this._user$.value; }
  public get user$() { return this._user$; }

  public get token() { return this._token$.value; }
  public get token$() { return this._token$.asObservable(); }

  public get unauthorized$() { return this._unauthorized$.asObservable(); }


  public get isDirectConnectOnlyUser$(): Observable<boolean> {
    return this._user$.pipe(
      filter((u) => !!u),
      map((u) => u.permissions && u.permissions.length ? false : true),
      startWith(false),
      distinctUntilChanged(),
    );
  }

  public get useMetric() { return this.user && this.user.preferences && this.user.preferences.useMetric; }

  private _deviceId$ = new BehaviorSubject<string>(null);
  private _user$ = new BehaviorSubject<User>(null);
  private _token$ = new BehaviorSubject<string>(null);
  private _unauthorized$ = new Subject<void>();
  private _userLastFetchedAt: Date;
  private _isRefreshingToken = false;
  private _waitingTokenRefreshHandlers: Array<(value: ILoginResponse) => void> = [];

  constructor(
    public httpClient: HttpClient,
    private appSetting: AppSettingsService,
    private device: Device,
  ) {
    this.init();
  }

  public async fetchUser() {
    const res = await this.httpClient.get<User>(`${this.appSetting.appSettings.backEnd || BackEndHost.Production}/user`).toPromise();
    this.userFetched(res);
  }

  public async deleteAccount() {
    const userId = this.user ? this.user._id : null;
    if (userId) {
      try {
        await this.httpClient.delete(
          `${this.appSetting.appSettings.backEnd || BackEndHost.Production}/api/Users/${userId}`,
        ).toPromise();
        return true;
      } catch (e) {
        return false;
      }
    }
    return false;
  }

  public async login(emailOrUsername: string, password: string): Promise<ILoginResponse> {
    const [deviceInfo, deviceId] = await Promise.all([this.getDeviceInfo(), this.getValue(this._deviceId$).toPromise()]);
    const body: {
      email?: string,
      username?: string,
      password: string,
      deviceId: string,
      userMobileDeviceStats?: IUserMobileDeviceStats,
    } = { password, deviceId };
    if (emailOrUsername.includes('@')) {
      body.email = emailOrUsername;
    } else {
      body.username = emailOrUsername;
    }
    if (deviceInfo.appVersion) {
      body.userMobileDeviceStats = {
        operatingSystem: deviceInfo.operatingSystem,
        osVersion: deviceInfo.osVersion,
        manufacturer: deviceInfo.manufacturer,
        deviceModel: deviceInfo.deviceModel,
        appVersion: `ncss-${deviceInfo.appVersion}`,
        lastUsed: deviceInfo.lastUsed,
      };
    }

    try {
      const res = await this.httpClient.post<ILoginResponse>(
        `${this.appSetting.appSettings.backEnd || BackEndHost.Production}/login`, body).toPromise();
      return this.handleLoginResponse(res);
    } catch (e) {
      console.log('error trying to login', e);
    }
    return { success: false } as ILoginResponse;  // update models ILoginResponse to not require user or token and remove rememberMeInfo
  }

  public async logout() {
    this._token$.next(null);
    this._user$.next(null);
    this.removeItemInStorage(StorageKeys.TOKEN);
    this.removeItemInStorage(StorageKeys.USER);
  }

  public attemptTokenRefresh(): Promise<ILoginResponse> {
    return new Promise(async (resolve) => {
      if (this._isRefreshingToken) {
        this._waitingTokenRefreshHandlers.push(resolve);
      } else {
        this._isRefreshingToken = true;
        const [token, user, deviceId] = await Promise.all([
          this.getValue(this._token$).toPromise(),
          this.getValue(this._user$).toPromise(),
          this.getValue(this._deviceId$).toPromise(),
        ]);
        let result = { success: false };
        if (user && user._id && token && deviceId) {
          try {
            const res =
              await this.httpClient.post<ILoginResponse>(`${this.appSetting.appSettings.backEnd || BackEndHost.Production}/login`, {
                email: user._id,
                deviceId,
                refreshToken: token,
              }).toPromise();
            result = this.handleLoginResponse(res as ILoginResponse);
          } catch (e) {
            console.log('error trying to refresh token', e);
          }
        }
        resolve(result as ILoginResponse);
        _.forEach(this._waitingTokenRefreshHandlers, handler => handler(result as ILoginResponse));
        this._isRefreshingToken = false;
        this._waitingTokenRefreshHandlers = [];
      }
    });
  }

  public async getUserLastFetchedAt() {
    if (this._userLastFetchedAt) { return this._userLastFetchedAt; }
    const dateStr = localStorage.getItem(StorageKeys.USER_LAST_FETCHED_AT);
    if (!dateStr || dateStr === 'null') { return null; }
    this._userLastFetchedAt = new Date(dateStr);
    return this._userLastFetchedAt;
  }

  public unauthorizedEventOccurred() {
    this.logout();
    this._unauthorized$.next();
  }


  private userFetched(doc: any) {
    if (!doc) { return; }

    this._userLastFetchedAt = new Date();
    this.setInStorage(StorageKeys.USER_LAST_FETCHED_AT, this._userLastFetchedAt.toISOString());

    const user = new User(doc);
    if (!_.isEqual(user, this.user)) {
      this._user$.next(user);
      const useMetric = (user && user.preferences) ? user.preferences.useMetric : false;
      DirectConnectGateway301.useMetric = useMetric;
      DirectConnect301.useMetric = useMetric;
      DirectConnectRemoteReader.useMetric = useMetric;
      DirectConnectTR4.useMetric = useMetric;
      DirectConnectRR4.useMetric = useMetric;
      DirectConnectRE4.useMetric = useMetric;
    }
    this.setInStorage(StorageKeys.USER, user);
    return user;
  }

  private handleLoginResponse(res: ILoginResponse): ILoginResponse {
    if (res.token) {
      this.setToken(res.token);
    }
    if (res.user) {
      res.user = this.userFetched(res.user);
    }

    return res;
  }

  private async init() {
    const token = localStorage.getItem(StorageKeys.TOKEN);
    this.setToken(token);
    let deviceId = localStorage.getItem(StorageKeys.DEVICE_ID);
    if (!deviceId) {
      deviceId = uuid();
    }
    this.setDeviceId(deviceId);
    const user = JSON.parse(localStorage.getItem(StorageKeys.USER));
    this._user$.next(new User(user));
  }

  public setUser(user) {
    this.setInStorage(StorageKeys.USER, user);
    this._user$.next(user);
  }

  private setToken(token) {
    this.setInStorage(StorageKeys.TOKEN, token);
    this._token$.next(token);
  }

  private setDeviceId(deviceId) {
    this.setInStorage(StorageKeys.DEVICE_ID, deviceId);
    this._deviceId$.next(deviceId);
  }

  private setInStorage(key, value) {
    if (typeof value !== 'string') {
      value = JSON.stringify(value);
    }
    localStorage.setItem(key, value);
  }

  private removeItemInStorage(key: StorageKeys) {
    localStorage.removeItem(key);
  }

  public verifyUser(token: string, password: string): Promise<void> {
    return this.httpClient.post<void>(`${this.appSetting.appSettings.backEnd || BackEndHost.Production}/VerifyUser`,
      { password, token }).toPromise();
  }

  public findById(userId: string): Promise<User> {
    return this.httpClient.get(`${this.appSetting.appSettings.backEnd || BackEndHost.Production}/api/Users/${userId}`).toPromise()
      .then((user) => user ? new User(user) : null);
  }

  public async update(userId: string, info: SaveUserInfo): Promise<User> {
    return this.httpClient.put(`${this.appSetting.appSettings.backEnd || BackEndHost.Production}/api/Users/${userId}`, info).toPromise()
      .then((user) => new User(user))
      .then((user) => {
        this.setUser(user);
        return user;
      });
  }

  public async updateUserLocation(userId: string, location: UserLocationInfo): Promise<{ success: boolean }> {
    return this.httpClient.put
      (`${this.appSetting.appSettings.backEnd || BackEndHost.Production}/api/Users/${userId}/MobileLocation`, location).toPromise()
      .then((response: { success: boolean }) => {
        if (!response || !response.success) {
          return { success: false };
        } else {
          return response;
        }
      });
  }

  public changePassword(userId: string | number, currentPassword: string, newPassword: string)
    : Promise<{ success: boolean, message?: string }> {
    return this.httpClient.put<{ success: boolean, message?: string }>
      (`${this.appSetting.appSettings.backEnd || BackEndHost.Production}/api/Users/${userId}/Password`,
        { currentPassword, newPassword }).toPromise();
  }

  public async saveProfilePic(imgData): Promise<void> {
    return this.httpClient.post(`${this.appSetting.appSettings.backEnd || BackEndHost.Production}/api/UploadProfilePic`, {
      data: imgData,
    }).toPromise().then(() => {
      this.fetchUser();
    });
  }

  public viewedProperty(propertyId: string): Promise<void> {
    return this.httpClient.post<void>
      (`${this.appSetting.appSettings.backEnd || BackEndHost.Production}/api/ViewedProperty/${propertyId}`, null).toPromise();
  }

  public requestPasswordReset(email): Promise<void> {
    return this.httpClient.post<void>
      (`${this.appSetting.appSettings.backEnd || BackEndHost.Production}/ForgotPassword/${email}`, null).toPromise();
  }


  public reportBug(report: { title: string, description: string }) {
    return this.httpClient.post(`${this.appSetting.appSettings.backEnd || BackEndHost.Production}/api/ReportBug`, report).toPromise();
  }

  public createNoPermissionsUser(info: ICreateNoPermissionUser): Observable<User> {
    return this.httpClient.post<User>(`${this.appSetting.appSettings.backEnd || BackEndHost.Production}/api/DirectConnectUsers`, info);
  }

  public resendActivationEmail(email: string): Observable<{ success: boolean }> {
    return this.httpClient.post<{ success: boolean }>(`${this.appSetting.appSettings.backEnd || BackEndHost.Production}/api/DirectConnectUsers/${email}/ResendActivationEmail`, {});
  }

  public sendDiagnosticInfo(info) {
    return this.httpClient.post(`${this.appSetting.appSettings.backEnd || BackEndHost.Production}/api/NCSSAppDiagnostics`, info);
  }

  public sendNetworkRoutingTable(table: NwkRoute[], serialNumberStr: string) {
    return this.httpClient.post(`${this.appSetting.appSettings.backEnd || BackEndHost.Production}/api/Devices/SendNetworkTable/${serialNumberStr}`, table);
  }

  public sendRFDebugReport(msgs: RfDebugMessage[], serialNumberStr: string) {
    return this.httpClient.post(`${this.appSetting.appSettings.backEnd || BackEndHost.Production}/api/Devices/SendRFDebugReport/${serialNumberStr}`, msgs.map((m) => m.toString()));
  }


  private getDeviceInfo(): IUserMobileDeviceStats {
    return {
      appVersion: this.appSetting.appVersion,
      osVersion: this.device.version,
      operatingSystem: this.device.platform,
      manufacturer: this.device.manufacturer,
      deviceModel: this.device.model,
      lastUsed: new Date(),
    };
  }

  public getValue<T>(thing: Observable<T>): Observable<T> {
    return merge(
      thing.pipe(filter((val) => !!val)),
      timer(500).pipe(mapTo(null)),
    ).pipe(take(1));
  }
}
