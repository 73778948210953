import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  OnInit
} from '@angular/core';
import { Events } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';

import { SerialInputContextType } from '../serial-number-input.component';


export enum SerialKeyboardStatus {
  HIDDEN,
  SHOWN,
}

export enum SerialKeyboardEventType {
  SHOW = 'SHOW_S', // <--- IN
  HIDE = 'HIDE_S', // <--- IN
  OUTSIDE_TAP = 'OUTSIDE-TAP_S', // <--- IN
  SERIAL_PREVIEW = 'SERIAL_PREVIEW_S', // <--- IN
  VALUE_KEY = 'VALUE_KEY_S', // ---> OUT
  CMD_KEY = 'CMD_KEY_S', // ---> OUT
  CLEAR = 'CLEAR',
}

@Component({
  selector: 'app-serial-keyboard',
  templateUrl: 'serial-keyboard.component.html',
  styleUrls: ['serial-keyboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SerialKeyboardComponent implements OnInit, OnDestroy {

  public status: SerialKeyboardStatus = SerialKeyboardStatus.HIDDEN;
  public KeyboardStatus = SerialKeyboardStatus; // For HTML
  public deviceType$ = new BehaviorSubject<any>(null);
  public contextType: SerialInputContextType = 'equipment';

  constructor(
    private events: Events,
    private elementRef: ElementRef,
  ) { }

  ngOnInit() {
    this.events.subscribe(SerialKeyboardEventType.SHOW, this.show.bind(this));
    this.events.subscribe(SerialKeyboardEventType.HIDE, this.hide.bind(this));
    this.events.subscribe(SerialKeyboardEventType.OUTSIDE_TAP, this.onOutsideTap.bind(this));
    this.events.subscribe(SerialKeyboardEventType.SERIAL_PREVIEW, this.onSerialPreview.bind(this));
    document.addEventListener('keydown', this.onKeyboardValue.bind(this));
  }

  ngOnDestroy() {
    this.events.unsubscribe(SerialKeyboardEventType.SHOW, this.show.bind(this));
    this.events.unsubscribe(SerialKeyboardEventType.OUTSIDE_TAP, this.onOutsideTap.bind(this));
    this.events.unsubscribe(SerialKeyboardEventType.HIDE, this.hide.bind(this));
    this.events.unsubscribe(SerialKeyboardEventType.SERIAL_PREVIEW, this.onSerialPreview.bind(this));
    document.removeEventListener('keydown', this.onKeyboardValue.bind(this));
  }

  private onSerialPreview(serial: string) {
    this.deviceType$.next(this.getDeviceTypePreview(serial));
  }

  private onOutsideTap($event) {
    if (!this.elementRef.nativeElement.contains($event.target)) {
      this.onCmd('Close');
    }
  }

  public onValue(c: string, fromKeyboard?: boolean) {
    if (fromKeyboard && c.length > 1) {
      this.onCmd(c);
    } else {
      c = c.toUpperCase().replace(/([^A-F0-9])/gi, '');
      if (c) {
        this.events.publish(SerialKeyboardEventType.VALUE_KEY, c);
      }
    }
  }

  public onCmd(val: string) {
    this.events.publish(SerialKeyboardEventType.CMD_KEY, val);
  }

  private show(val) {
    this.contextType = val.contextType;
    if (this.status !== SerialKeyboardStatus.SHOWN) {
      this.status = SerialKeyboardStatus.SHOWN;
    }
    this.deviceType$.next({ name: '', icon: '' });
  }

  private hide() {
    this.status = SerialKeyboardStatus.HIDDEN;
    this.deviceType$.next({ name: '', icon: '' });
  }

  private onKeyboardValue(val) {
    if (this.status === SerialKeyboardStatus.SHOWN) {
      this.onValue(val.key, true);
    }
  }

  private getDeviceTypePreview(serialNumber: string): { name: string, icon: string } {
    if (!serialNumber) {
      return { name: '', icon: '' };
    }

    if (serialNumber.indexOf('BA') === 0 || serialNumber.indexOf('BB') === 0) {
      return { name: 'Transceiver', icon: 'icon-transceiver' };
    } else if (serialNumber.indexOf('BC') === 0) {
      return { name: 'Outdoor Transceiver', icon: 'icon-tr401x' };
    } else if (serialNumber.indexOf('FA') === 0) {
      return { name: 'Leak Sensor', icon: 'icon-leak-sensor' };
    } else if (serialNumber.indexOf('CA') === 0) {
      return { name: 'Repeater', icon: 'icon-repeater' };
    } else if (serialNumber.indexOf('DA1') === 0) {
      return { name: 'Cellular Gateway', icon: 'icon-gw301' };
    } else if (serialNumber.indexOf('DA0') === 0) {
      return { name: 'Gateway GW201', icon: 'icon-gw201' };
    } else if (serialNumber.indexOf('DA2') === 0) {
      return { name: '5G Cellular Gateway', icon: 'icon-gw301' };
    } else if (serialNumber.indexOf('DA') === 0) {
      return { name: 'Gateway', icon: 'icon-gw301' };
    } else if (serialNumber.indexOf('E1') === 0) {
      return { name: 'Pulse Converter', icon: '' };
    } else if (serialNumber.indexOf('A0') === 0) {
      return { name: '3rd Party Inovonics Device', icon: '' };
    } else if (serialNumber.indexOf('EA') === 0) {
      return { name: 'Remote Reader', icon: 'icon-remote-reader' };
    } else {
      return { name: '', icon: '' };
    }
  }
}
